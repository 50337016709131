import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../constants/objects.constants';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { midiaService } from '../../services/midiaService';
import { ordemServicoService } from '../../services/ordemServico.service';
import TrocaRelogioContainerItem from '../../views/ordemServico/AdminManaus/TrocaRelogioContainerItem';
import EscolherRelogioTroca from '../../views/ordemServico/EscolherRelogioTroca';
import { propertyConstantes } from '../../constants/property.constantes';
import FotoRelogio from '../FotoRelogio';

type Props = {};
type State = {
  tasks: any,
  entity: any
};

export class CardTrocaModeloRelogio extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.ordemServico.listModeloRelogioTrocaOrdemServico) {
      let relogios = [];
      for (
        let i = 0;
        i < props.ordemServico.listModeloRelogioTrocaOrdemServico.length;
        i++
      ) {
        relogios.push({
          posicao: i + 1,
          relogio:
            props.ordemServico.listModeloRelogioTrocaOrdemServico[i].relogio,
          valor: props.ordemServico.listModeloRelogioTrocaOrdemServico[i].valor
        });
      }
      this.state = {
        entity: '',
        relogios: relogios
      };
    } else {
      this.state = {
        entity: '',
        relogios: [
          { posicao: 1, relogio: {} },
          { posicao: 2, relogio: {} }
        ]
      };
    }
  }

  handleVoltar = () => {
    this.props.clearOS();
    this.props.history.goBack();
  };

  setOSToTrocaModelo = () => {
    this.props.loading(true);
    const { ordemServico } = this.props;

    ordemServicoService.reabrirTrocaModelo(ordemServico.id).then(
      r => {
        this.props.setOrdemServico(r.data.data);
        this.props.success({
          message: 'OS alterada com sucesso.'
        });
        this.props.loading(false);
      },
      error => {
        this.props.loading(false);
        this.props.error({
          message: 'Não foi possível alterar status OS.'
        });
        console.log(error);
      }
    );
  };

  addRow = () => {
    let { ordemServico, permissions } = this.props;
    if (
      ordemServico.statusOS.name === propertyConstantes.TROCA_MODELO ||
      ordemServico.statusOS.name === propertyConstantes.TROCA_MAIS_MODELO
    ) {
      let relogios = this.state.relogios;
      relogios.push({
        posicao: relogios[relogios.length - 1].posicao + 1,
        relogio: { pecas: [] }
      });
      this.setState(relogios);
    } else if (
      ordemServico.listModeloRelogioTrocaOrdemServico &&
      ordemServico.statusOS.name === propertyConstantes.ESCOLHA_DE_MODELO
    ) {
      //ordemServicoService.confirmChangeStatus(this.setOSToTrocaModelo);
    }
  };

  handleChangeItem = async (posicao: any, newItem: any) => {
    // console.log(newItem)
    const { relogios } = this.state;
    // console.log(relogios);
    // console.log(newItem);
    let newEntity = _.cloneDeep(relogios);

    //add item in object
    if (newItem) {
      newEntity = _.map(relogios, function(item) {
        return item.posicao === posicao
          ? { posicao, relogio: newItem.relogio, valor: newItem.valor,caixa:newItem.caixa }
          : item;
      });
    }
    // console.log(newEntity);
    this.setState({ relogios: newEntity });
  };

  removeItem = (posicao: any) => {
    const { relogios } = this.state;
    let newEntity = _.cloneDeep(relogios);

    _.remove(newEntity, function(currentObject) {
      return currentObject.posicao === posicao;
    });

    this.setState({ relogios: newEntity });
  };

  hasRelogio = () => {
    let hasRelogio = false;
    _.map(this.state.relogios, function(item) {
      if (item && item.relogio && item.relogio.id) {
        hasRelogio = true;
      }
    });
    return hasRelogio;
  };

  handleSaveRelogios = async () => {
    this.props.loading(true);

    const { relogios } = this.state;
    const { ordemServico } = this.props;

    console.log(relogios);

    ordemServicoService.saveModelosTrocaRelogio(relogios, ordemServico.id).then(
      response => {
        let newEntity = response.data.data;
        this.props.setOrdemServico(newEntity);
        this.props.loading(false);
        this.props.success({
          message: 'Solicitação realizada com sucesso.'
        });
      },
      error => {
        this.props.loading(false);
        this.props.error({ message: 'Erro ao salvar modelos.' });
      }
    );
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.ordemServico.listModeloRelogioTrocaOrdemServico) {
      let relogios = [];
      for (
        let i = 0;
        i < nextProps.ordemServico.listModeloRelogioTrocaOrdemServico.length;
        i++
      ) {
        relogios.push({
          posicao: i + 1,
          relogio:
            nextProps.ordemServico.listModeloRelogioTrocaOrdemServico[i]
              .relogio,
          valor:
            nextProps.ordemServico.listModeloRelogioTrocaOrdemServico[i].valor
        });
      }
      this.setState({ relogios: relogios });
    }
  }

  addModelosTrocaOrView = () => {
    let { ordemServico, permissions } = this.props;
    if (
      ordemServico.statusOS.name === propertyConstantes.TROCA_MODELO ||
      ordemServico.statusOS.name === propertyConstantes.TROCA_MAIS_MODELO
    ) {
      return (
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_ESCOLHA_MODELO_TROCA}
          ability={permissions}
        >
          {this.state.relogios.map((relogio, index) => (
            <TrocaRelogioContainerItem
              onChange={this.handleChangeItem}
              key={relogio.posicao}
              posicao={relogio.posicao}
              removeItem={this.removeItem}
              relogio={relogio}
            />
          ))}

          <div className="text-right py-2 pt-2" onClick={this.addRow}>
            <span className="icon-badge">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>{' '}
            <a>Relógios </a>
          </div>
          <div className="col-12 d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-primary justify-content-start white my-4 mx-2"
              onClick={() =>
                ConfirmAcationService.confirmMsg(
                  this.props.handleReverterTroca,
                  'Cancelar',
                  'Deseja reverter a troca de modelo?',
                  'Ao confirmar a troca de modelo será revertida.'
                )
              }
            >
              TROCA REVERTIDA
            </button>
            <button
              type="button"
              onClick={this.handleSaveRelogios}
              className="btn btn-primary my-4 mx-2"
              disabled={!this.hasRelogio()}
            >
              Concluir
            </button>
          </div>
        </Can>
      );
    } else if (
      ordemServico.statusOS.name === propertyConstantes.ESCOLHA_DE_MODELO ||
      (ordemServico.statusOS.name === propertyConstantes.EXPIRADA &&
        ordemServico.listModeloRelogioTrocaOrdemServico &&
          ordemServico.listModeloRelogioTrocaOrdemServico.length > 0 &&
        !ordemServico.relogioTrocaOrdemServico)
    ) {
      return (
        <React.Fragment>
          {ordemServico.listModeloRelogioTrocaOrdemServico.map(
            (item, index) => (
              <EscolherRelogioTroca
                key={item.relogio.id}
                relogio={item.relogio}
                ordemServico={ordemServico}
                valor={item.valor}
                caixa={item.caixa}
              />
            )
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    let { ordemServico } = this.props;
    return (
      <React.Fragment>
        {this.addModelosTrocaOrView()}
        {ordemServico.relogioTrocaOrdemServico && (
          <div className="row conteudoTab d-flex flex-column flex-lg-row align-items-center">
            {ordemServico.relogioTrocaOrdemServico.codigoRastreio && (
              <div className="form-group col-12 col-sm-12">
                <label className="miniLabel">Código de rastreio</label>
                <p className="txtBlue bold">
                  {ordemServico.relogioTrocaOrdemServico.codigoRastreio}
                </p>
              </div>
            )}
            <div className="flex-column col-12 col-md-6">
              <div className="form-row">
                <div className="form-group col-12">
                  <label className="miniLabel">Código do relógio</label>
                  <p>
                    {
                      ordemServico.relogioTrocaOrdemServico.relogio
                        .codigoRelogio
                    }
                  </p>
                </div>
                <div className="form-group col-6 col-sm-6">
                  <label className="miniLabel">Marca</label>
                  <p>
                    {ordemServico.relogioTrocaOrdemServico.relogio.marca
                      ? ordemServico.relogioTrocaOrdemServico.relogio.marca.nome
                      : ''}
                  </p>
                </div>
                <div className="form-group col-6 col-sm-6">
                  <label className="miniLabel">Valor</label>
                  <p>
                    {ordemServico.relogioTrocaOrdemServico.valor
                      ? ordemServicoHelper.formatMoney(
                          parseFloat(
                            ordemServico.relogioTrocaOrdemServico.valor
                          ),
                          2,
                          ',',
                          '.'
                        )
                      : 'R$ 0,00'}
                  </p>
                </div>
                <div className="form-group col-12 col-sm-6">
                  <label className="miniLabel">Descricao</label>
                  <p>
                    {ordemServico.relogioTrocaOrdemServico.relogio.descricao
                      ? ordemServico.relogioTrocaOrdemServico.relogio.descricao
                      : ''}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex-column col-12 col-md-4 offset-md-2 text-right">
              <FotoRelogio
                noMargin
                relogio={
                  ordemServico && ordemServico.relogioTrocaOrdemServico.relogio
                    ? ordemServico.relogioTrocaOrdemServico.relogio
                    : null
                }
              />
            </div>
          </div>
        )}
        <hr className="hrBold" />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;
  const { permissions } = state.authentication;

  return {
    cliente,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error, clear },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  withRouter(CardTrocaModeloRelogio)
);
