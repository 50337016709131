//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const osAguardandoImportacaoService = {
  findByFiltro,
 getOs,
  savePedido,
  /*getTotalDocAtendido,
  find*/
};
/*
function find(input) {
  return defaultService.doGet(`${urlsConstants.DOC}find/${input}`);
}

function getTotalDocAtendido() {
  return defaultService.doGet(`${urlsConstants.DOC}getTotalDocAtendido`);
}
*/
function savePedido(marca) {
  return defaultService.doGet(
    `${urlsConstants.OS_AGUARDANDO_IMPORTACAO}solicitarInvoce/${marca}`

  );
}

function getOs(marca,status) {
  let url = `${urlsConstants.OS_AGUARDANDO_IMPORTACAO}getOs/${marca}/${status}`;
  return defaultService.doGet(url);
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.OS_AGUARDANDO_IMPORTACAO}list`,
    options,
    filter
  );
}
