import React, { PureComponent } from 'react';
import Pagination from 'react-js-pagination';

type Props = {
  sizePerPage?: any,
  page: any
};
type State = {
  entity: any
};

export class FooterTablePagination extends PureComponent<Props, State> {
  static defaultProps = {
    sizePerPage: 10,
    page: 1
  };
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.props.page
    };
  }
  handleAction = () => {};

  handlePageChange = pageNumber => {
    this.setState({ currentPage: pageNumber }, () => {
      this.props.onPageChange(pageNumber);
    });
  };

  getPaginationLegend(currentPage, registerPerPage, total, numberOfPage) {
    if (total > 0) {
      let begin;
      if (currentPage == 1) begin = 1;
      else begin = (currentPage - 1) * registerPerPage + 1;

      let end;
      if (numberOfPage == currentPage) {
        end = total;
      } else {
        end = currentPage * registerPerPage;
      }

      return (
        <p className="total-text small">
          Exibindo {end} de {total} registros
        </p>
      );
    }
  }
  render() {
    let numberOfPage = Math.floor(
      this.props.dataTotalSize / this.props.sizePerPage
    );
    if (this.props.dataTotalSize % this.props.sizePerPage != 0)
      numberOfPage = numberOfPage + 1;
    return (
      <div className="react-bs-table-pagination">
        <div className="row ">
          <div className="col-12 col-sm-6">
            {this.getPaginationLegend(
              this.state.currentPage,
              this.props.sizePerPage,
              this.props.dataTotalSize,
              numberOfPage
            )}
          </div>
          <div className="col-12 col-sm-6 small">
            {this.props.dataTotalSize > 0 && (
              <Pagination
                innerClass={
                  'react-bootstrap-table-page-btns-ul pagination float-right'
                }
                activePage={this.state.currentPage}
                itemsCountPerPage={this.props.sizePerPage}
                totalItemsCount={this.props.dataTotalSize}
                pageRangeDisplayed={5}
                onChange={this.handlePageChange}
                activeClass="active"
                itemClass="page-item"
                linkClass="page-link"
                // firstPageText="Primeira"
                // lastPageText="Última"
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
