import React, { PureComponent } from 'react';
import { urlsConstants } from '../../constants/url.constant';
import { connect } from 'react-redux';

type Props = {
  children?: any,
  foto: any
};
type State = {
  entity: any
};

export class UserAvatar extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  render() {
    let url = null;
    if (this.props.foto) {
      if (this.props.foto.data) {
        url = this.props.foto.data;
      } else {
        url = `${urlsConstants.MIDIA_IMAGE}${this.props.foto.id}`;
      }
    }

    return (
      <React.Fragment>
        <img
          src={url ? url : require('../../img/avatarDefault.jpg')}
          className="fotoPerfil rounded-circle"
          name="fotoPerfil"
          alt="Foto de perfil"
          width={this.props.width}
          height={this.props.height}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { refresh } = state.authentication;
  return {
    refresh
  };
}

export default connect(mapStateToProps)(UserAvatar);
