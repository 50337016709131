//@flow
import * as React from 'react';
import SelectInput from './SelectInput';

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean,
  service: any
};

type State = {
  options: Array<any>
};
export default class StatusSelectInput extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    let options = [];
    options = this.props.service();
    this.setState({ options });
  }

  render() {
    return (
      <SelectInput
        label={this.props.label}
        valueKey={this.props.valueKey?this.props.valueKey:'id'}
        labelKey={'nome'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={this.props.multi}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        clearable={this.props.clearable}
      />
    );
  }
}
