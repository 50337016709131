//@flow
import { AbilityBuilder } from '@casl/ability';
import { objectsConstants } from '../constants/objects.constants';

const ROLE_REPRESENTANTE_LEGAL = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, 'HomePostoAutorizado');
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_FINANCEIRO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_FINANCEIRO_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_POSTO);
  can(objectsConstants.VIEW, objectsConstants.MENU_COLABORADOR);
  can(objectsConstants.VIEW, objectsConstants.ABILITY_TROCA_MAIS_MODELOS);
  can(objectsConstants.VIEW, objectsConstants.EDIT_PROFILE);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.ROLE_ADMIN_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_REPARAR_OS);
});

const CENTRO_TECNICO = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, 'HomePostoAutorizado');
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.MENU_COLABORADOR);
  can(objectsConstants.VIEW, objectsConstants.ABILITY_TROCA_MAIS_MODELOS);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.ROLE_ADMIN_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_REPARAR_OS);
});
const ATENDENTE = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, 'HomePostoAutorizado');
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_POSTO);
  can(objectsConstants.VIEW, objectsConstants.MENU_COLABORADOR);
  can(objectsConstants.VIEW, objectsConstants.ABILITY_TROCA_MAIS_MODELOS);
  can(objectsConstants.VIEW, objectsConstants.EDIT_PROFILE);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.ROLE_ADMIN_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_REPARAR_OS);
});
const ROLE_ADMIN = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, 'HomeAdministrator');
  can(objectsConstants.VIEW, 'MenuPostoAutorizado');
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ABILITY_TROCA_MAIS_MODELOS);
  can(objectsConstants.VIEW, objectsConstants.ROLE_ADMIN);
  can(objectsConstants.VIEW, objectsConstants.MENU_COLABORADOR);
  can(objectsConstants.VIEW, objectsConstants.EDIT_PROFILE);
  can(objectsConstants.VIEW, objectsConstants.ROLES_ADMIN);
  can(objectsConstants.VIEW, objectsConstants.MENU_MANAUS_TROCA);
  can(objectsConstants.VIEW, objectsConstants.ROLE_FINANCEIRO);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.ROLE_ADMIN_POSTO);
  can(objectsConstants.VIEW, objectsConstants.ROLE_REPARAR_OS);
  can(
    objectsConstants.VIEW,
    objectsConstants.ABILITY_ORDEM_SERVICO_ASSOCIAR_POSTO_HABILITADO
  );
});

const ROLE_MANAUS = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.MENU_DOC_ADMIN_MANAUS_POSTO);
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.ROLE_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.HOME_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.EDIT_DOC);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.CONSULTA_OS);
});

const ROLE_MANAUS_TROCA = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.ROLE_MANAUS_TROCA);
  can(objectsConstants.VIEW, objectsConstants.HOME_MANAUS_TROCA);
  can(objectsConstants.VIEW, objectsConstants.MENU_MANAUS_TROCA);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.CONSULTA_OS);
});

const ROLE_ASSISTENCIA_TECNICA_SAO_PAULO = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, objectsConstants.EDIT_DOC);
  can(objectsConstants.VIEW, objectsConstants.IMPORT_PECA);
  can(
    objectsConstants.VIEW,
    objectsConstants.ROLE_ASSISTENCIA_TECNICA_SAO_PAULO
  );
  can(objectsConstants.VIEW, objectsConstants.MENU_SAO_PAULO_DOC);
  can(objectsConstants.VIEW, objectsConstants.REPORT);
  can(objectsConstants.VIEW, objectsConstants.CONSULTA_OS);
});

const ADMIN_CONSULTA = AbilityBuilder.define(can => {
  can(objectsConstants.VIEW, objectsConstants.MENU_OS_ADMIN_MANAUS);
  can(objectsConstants.VIEW, objectsConstants.ADMIN_CONSULTA);
  can(objectsConstants.VIEW, objectsConstants.CONSULTA_OS);
});

const DEFAULT = AbilityBuilder.define(can => {});

export function getPermission(user) {
  const ability = AbilityBuilder.define(can => {
    let listRolePermission = user.role.listRolePermission;
    for (let i = 0; i < listRolePermission.length; i++) {
      let permission = listRolePermission[i];
      console.log(permission);
      can(permission.action, permission.useCase.name);
    }
  });
  return ability;
}
