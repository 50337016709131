//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import type { OrdemServicoType } from '../types/ordemServico.type';

export const marcaService = {
  find,
  findByFiltro,
  findMarcaTerceiros,
  findCadastroManual,
  doSave,
  getById
};

function find(input) {
  return defaultService.doGet(`${urlsConstants.FIND_MARCA}${input}`);
}
function findMarcaTerceiros(input) {
  return defaultService.doGet(
    `${urlsConstants.FIND_MARCA}marcaTerceiros/${input}`
  );
}
function findCadastroManual(input) {
  return defaultService.doGet(
    `${urlsConstants.FIND_MARCA}cadastroManual/${input}`
  );
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.MARCA}list`,
    options,
    filter
  );
}
function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.MARCA, entity);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.MARCA}${id}`);
}
