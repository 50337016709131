import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import HomeAdministrator from './HomeAdministrator';
import HomePostoAutorizado from './HomePostoAutorizado';
import { Can } from '@casl/react';
import { objectsConstants } from '../../constants/objects.constants';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import HomeManaus from './HomeManaus';
import HomeManausTroca from './HomeManausTroca';
import HomeAdministratorConsulta from './HomeAdministratorConsulta';
import TaskView from '../task/TaskView';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import CardDocs from '../../components/Cards/CardDocs';
import ColaboradorView from '../colaboradores/ColaboradorView';
import { CardItem } from '../../components/templates/CardItem';
import PostoAutorizadoFormView from '../postoAutorizado/PostoAutorizadoFormView';
import CardSelectPosto from '../../components/Cards/CardSelectPosto';
import CardCompraDePecas from '../../components/Cards/CardCompraDePecas';
import CardFinanceiro from '../../components/Cards/CardFinanceiro';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import _ from 'lodash';
import { userService } from '../../services/user.service';

type Props = {};
class Home extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: {}, postoAutorizado: {} };
  }

  onChangeSelect = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  handleSelectPosto = postoAutorizado => {
    this.setState({ postoAutorizado });
  };

  componentDidMount() {
    if (this.props.user.extension) {
      postoAutorizadoService.doGet(this.props.user.extension).then(response => {
        let posto = response.data.data;
        this.props.loadCompany({ company: posto });
      });
    }
  }

  render() {
    const { permissions, user } = this.props;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Olá bem vindo(a)"
          subTitle={user.name}
          canBack={false}
        />
        <div className="flex-column">
          <Can
            I={objectsConstants.VIEW}
            a={objectsConstants.ABILITY_HOME_TASK}
            ability={permissions}
          >
            <TaskView />
          </Can>
          {
            (console.log(this.props),
            (this.props.company.aprovado == true || userService.isAdmin()) && (
              <>
                <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_HOME_DOCS}
                  ability={permissions}
                >
                  <CardDocs />
                </Can>

                <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_POSTO_HOME}
                  ability={permissions}
                >
                  <CardItem>
                    <PostoAutorizadoFormView
                      entity={this.props.company ? this.props.company : {}}
                      readOnly={true}
                    />
                  </CardItem>
                </Can>
                <Can
                  I={objectsConstants.VIEW}
                  a={objectsConstants.ABILITY_HOME_COLABORADOR}
                  ability={permissions}
                >
                  <ColaboradorView />
                </Can>
              </>
            ))
          }
        </div>
        <div className="flex-column">
          {(this.props.company.aprovado == true || userService.isAdmin()) && (
            <>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_HOME_SELECT_POSTO}
                ability={permissions}
              >
                <CardSelectPosto
                  onChangeSelect={this.onChangeSelect}
                  entity={this.state.entity}
                  onSelectPosto={this.handleSelectPosto}
                />
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_FINANCEIRO}
                ability={permissions}
              >
                <CardFinanceiro
                  postoAutorizado={
                    this.props.company
                      ? this.props.company
                      : this.state.postoAutorizado
                  }
                />
              </Can>
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_HOME_ORDEM_SERVICO}
                ability={permissions}
              >
                <BoxOrdemServico
                  postoAutorizado={
                    this.props.company
                      ? this.props.company
                      : this.state.postoAutorizado
                  }
                />
              </Can>

              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_HOME_COMPRA_PECA}
                ability={permissions}
              >
                <CardCompraDePecas />
              </Can>
            </>
          )}
        </div>

        <Can
          I={objectsConstants.VIEW}
          a="HomePostoAutorizado"
          ability={permissions}
        >
          <HomePostoAutorizado />
        </Can>
        <Can
          I={objectsConstants.VIEW}
          a="HomeAdministrator"
          ability={permissions}
        >
          <HomeAdministrator />
        </Can>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.HOME_MANAUS}
          ability={permissions}
        >
          <HomeManaus />
        </Can>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.HOME_MANAUS_TROCA}
          ability={permissions}
        >
          <HomeManausTroca />
        </Can>

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ADMIN_CONSULTA}
          ability={permissions}
        >
          <HomeAdministratorConsulta />
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions, company } = state.authentication;

  return {
    user,
    permissions,
    company
  };
}
const mapDispatch = ({ authentication: { loadCompany } }) => ({
  loadCompany: company => loadCompany(company)
});

export default connect(mapStateToProps, mapDispatch)(Home);
