//@flow
import React, { PureComponent } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../../../css/crop_reabrir_OS.css';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';

type Props = {
  onToogle: any,
  checked: boolean,
  type: string,
  label: string,
  value: any
};

export default class ItemOrcamentoOS extends PureComponent<Props> {
  constructor(props) {
    super(props);

    this.state = {
      checked: false
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.checkAll) {
      this.setState({ checked: nextProps.checkAll });
    }
    if (nextProps.uncheckAll) {
      this.setState({ checked: false });
    }
  }

  toggleLine = e => {
    e.preventDefault();
    this.setState({ checked: !this.state.checked }, () => {
      this.props.onToogle(this.props.id, this.state.checked);
    });
  };

  render() {
    return (
      <li className="listService action" onClick={this.toggleLine}>
        <div className="d-flex bd-highlight ">
          <div className="p-2 bd-highlight">
            <div className="bottom">
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={() => {}}
                />
                <i className="icon-checkbox" />
              </label>
            </div>
          </div>
          <div className="p-2 bd-highlight">
            <div className="serv_txt">
              <div className="miniTxt">{this.props.type}</div>
              <p>{this.props.label}</p>
            </div>
          </div>
          <div className="ml-auto p-2 bd-highlight">
            <div className="h5_2">
              <div className="miniTxt">Valor final</div>
              <div className="h5Green">
                {this.props.value
                  ? ordemServicoHelper.formatMoney(
                      parseFloat(this.props.value),
                      2,
                      ',',
                      '.'
                    )
                  : 'R$ 0,00'}
              </div>
            </div>
          </div>
          <div className="bd-highlight" />
        </div>
      </li>
    );
  }
}
