//@flow
import * as React from 'react';
import ChatOsRowMessage from './ChatOsRowMessage';
import SockJsClient from 'react-stomp';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { oauthHeaderJson } from '../../helpers/oauth-header';
import { mensagemService } from '../../services/messagem.service';
import '../../css/chat_style.css';
import { dateHelper } from '../../helpers/date.helper';
import connect from 'react-redux/es/connect/connect';
import { ToolTipFeedBack } from '../../components/Utils/ToolTipFeedBack';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import { userService } from '../../services/user.service';
class Chat extends React.PureComponent<{}> {
  constructor(props: Props) {
    super(props);
    console.log(props);
    this.state = {
      messages: [],
      message: '',
      mentions: [],
      sender: this.props.user
    };
  }

  handleMessages(msgs) {
    const messages = _.cloneDeep(this.state.messages);
    if (typeof msgs.data.id !== 'undefined') {
      messages.push(msgs.data);
    } else {
      while (messages.length > 0) {
        messages.pop();
      }
      msgs.data.listMessage.map(msg => {
        messages.push(msg);
      });

        mensagemService.updadeViewReceiver(this.props.match.params.receiver);
    }

    this.setState({ messages });
    //TODO
    //this.props.addTotalMessagesOs(messages.length);
  }

  onChangeFile = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      let imageVO = {
        filename: files[0].name,
        length: files[0].size,
        contentType: files[0].type
      };
      let sendMidia = this.sendMidia;
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function(event) {
        imageVO.data = event.target.result;
        sendMidia(imageVO);
      };
    }
  };

  sendMidia = midia => {
    console.log(
      'Enviando nova mensagem (' +
        mensagemService.getWsUrl() +
        mensagemService.getWsTopic(
          this.props.user.id,
          this.props.match.params.receiver
        ) +
        ')' +
        midia
    );
    this.clientRef.sendMessage(
      '/app' +
        mensagemService.getWsTopic(
          this.props.user.id,
          this.props.match.params.receiver
        ),
      JSON.stringify({
        attachment: midia,
        sender: this.props.user
      })
    );

    console.log('Mensagem enviada');
  };

  sendMessage = msg => {
    console.log(
      'Enviando nova mensagem (' +
        mensagemService.getWsUrl() +
        mensagemService.getWsTopic(
          this.props.user.id,
          this.props.match.params.receiver
        ) +
        ')' +
        this.state.message
    );
    this.clientRef.sendMessage(
      '/app' +
        mensagemService.getWsTopic(
          this.props.user.id,
          this.props.match.params.receiver
        ),
      JSON.stringify({
        message: this.state.message,
        sender: this.props.user
      })
    );

    this.setState({ message: '' });

    console.log('Mensagem enviada');
  };

  addMessage() {
    const messages = _.cloneDeep(this.state.messages);
    let msg = {
      id: 0,
      mensagem: this.state.message,
      sender: this.state.sender,
      dateTime: dateHelper.now()
    };
    messages.push(msg);
    this.setState({ messages });
  }

  handleChange(event) {
    const { value } = event.target;

    const message = value;

    this.setState({ message });
  }

  openInputFile = (event: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      return false;
    }
    this.inputFile.click();
  };

  handleChangeAction(action: any, index, any) {
    const messages = _.cloneDeep(this.state.messages);

    messages[index].action = action;
    this.setState({ messages });
  }

  render() {
    const wsSourceUrl = mensagemService.getWsUrl();

    const topic = mensagemService.getWsTopic(
      this.props.user.id,
      this.props.match.params.receiver
    );

    let headers = oauthHeaderJson();
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={'CONVERSA'}
          subTitle={this.props.location.state.receiverName}
          canBack={true}
        />
        <div className="cardSeculus">
          <SockJsClient
            url={wsSourceUrl}
            topics={[topic, '/app' + topic]}
            onMessage={msgs => {

            }}
            subscribeHeaders={headers}
            headers={headers}
            ref={client => {
              this.clientRef = client;
            }}
          />

          <div className="container">
            <div className="containerChat">
              {this.state.messages.map((message, index) => (
                <ChatOsRowMessage
                  key={index}
                  message={message}
                  sender={message.sender}
                  foto={message.sender.foto}
                  currentUser={this.state.sender}
                  index={index}
                  onChangeActin={this.handleChangeAction}
                />
              ))}
              {this.state.messages.length > 0 && (
                <p ref={ref => (this._div = ref)} />
              )}
            </div>
            <div className="col-12">
              <div className="row align-items-center inputMessage">
                <div className="col-8 col-sm-9 input-group borderInput">
                  <textarea
                    className="form-control chat"
                    placeholder="@ Digite sua mensagem"
                    onChange={this.handleChange.bind(this)}
                    value={this.state.message}
                  />
                  {/* <textarea
                type="text"
                className="form-control chat"
                placeholder="Digite sua mensagem"
                onChange={this.handleChange}
                aria-label="Digite sua mensagem"
                value={this.state.message}
              /> */}
                </div>
                <div className="col-1 p-0 text-center">
                  <a ref="">
                    <i
                      className="icon-anexo"
                      alt="Ícone para enviar anexos"
                      onClick={this.openInputFile}
                    />
                  </a>
                </div>

                <div>
                  <input
                    type="file"
                    accept={this.props.accept ? this.props.accept : '*'}
                    onChange={this.onChangeFile}
                    className="d-none img-profile-input"
                    maxLength="1024"
                    ref={input => {
                      this.inputFile = input;
                    }}
                  />

                  {this.props.erroMensagem && (
                    <ToolTipFeedBack
                      target={this.props.id}
                      messageValidate={this.props.erroMensagem}
                    />
                  )}
                </div>

                <div className="col-3 col-sm-2 px-0 text-center">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm green "
                    onClick={this.sendMessage}
                  >
                    <i className="icon-send" alt="Avião de papel" />
                    Enviar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;

  return {
    user
  };
}

const mapDispatch = ({
  chat: { addTotalMessagesOs },
  pageTitle: { changePageTitle }
}) => ({
  addTotalMessagesOs: totalMessages => addTotalMessagesOs(totalMessages),
  changePageTitle: (mainTitle: string, subTitle: string) =>
    changePageTitle({ mainTitle, subTitle })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(Chat));
