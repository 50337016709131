import React, { PureComponent } from 'react';
import '../../css/boxPostoAutorizadoFinanceiro.css';

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};

export class PostoAutorizadoFinanceiroDisabled extends PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity
    };
  }

  render() {
    return (
      <div className="card cardSeculus d-flex flex-column l1">
        <div className="topoCard d-flex flex-row">
          <h3 className="w-100">financeiro</h3>
        </div>
        <div className="conteudoCard d-flex flex-column flex-sm-column">
          <div className="col-sm-12 d-flex flex-column align-items-center pr-3">
            <div className="justify-content-center pl-3">
              <h1 className="h1-valor">R$ 0,00</h1>
            </div>
            <div className="justify-content-center pl-3">
              <p className="p-msg">
                A loja ainda não é um posto autorizado e por esse motivo não
                pode gerar renda com serviços prestados
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
