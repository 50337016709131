//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import PostoAutorizadoView from './PostoAutorizadoView';
import PostoHabilitadoEdit from '../postoHabilitado/PostoHabilitadoEdit';

export default class PostoAutorizado extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/postoAutorizado/:id" component={PostoAutorizadoView} />
          <Route
            path="/postoHabilitado/novo/"
            component={PostoHabilitadoEdit}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
