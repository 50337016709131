import * as React from 'react';
import { UserAvatar } from '../../components/menu/UserAvatar';
import { ordemServicoService } from '../../services/ordemServico.service';

export default class OrdemServicoHistorico extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.state = { listHistorico: [] };
  }

  componentDidMount() {
    this.findHistorico(this.props.id);
  }
  componentWillReceiveProps(nexProps) {
    if (nexProps.id) {
      this.findHistorico(nexProps.id);
    }
  }
  findHistorico(id) {
    ordemServicoService.findHistoricoByOS(id).then(response => {
      let listHistorico = response.data.data;
      this.setState({ listHistorico });
    });
  }
  render() {
    return (
      <div className="historico">
        <ul>
          {this.state.listHistorico.map(function(historico, index) {
            return (
              <li className={historico.className} key={index}>
                <div className="bullet">{index + 1}</div>
                <div className="pb-1 pb-sm-4 pl-2 pl-xl-0">
                  <div className="titulo">{historico.tipo}</div>
                  <div className="data">{historico.data}</div>
                  <div className="autor d-flex align-items-center">
                    <UserAvatar foto={historico.usuario.foto} />
                    <div className="por ml-1">
                      Por: {historico.usuario.name}
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
