//@flow
import * as React from 'react';
import 'react-select/dist/react-select.css';
import '../../css/selectInput.css';
import { connect } from 'react-redux';
import { propertyConstantes } from '../../constants/property.constantes';
import SelectInput from './SelectInput';
import { tecnicoService } from '../../services/tecnico.service';
import _ from 'lodash';
type Props = {
  id?: string,
  label: string,
  name: string,
  value?: any,
  size?: number,
  onChange: any,
  parent?: any,
  onFetchData?: any,
  valueKey: string,
  labelKey: string,
  options?: any,
  multi?: boolean,
  required: boolean,
  messageValidate?: string,
  returnFullObject: boolean,
  clearable: boolean,
  creatable: boolean,
  onNewOptionClick: any,
  newOptionCreator: any,
  cache: boolean,
  placeholder: string,
  style: any,
  searchable?: boolean,
  disabled?: boolean
};

type State = {};

class TecnicoInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.insertTecnico = this.insertTecnico.bind(this);
    this.state = {
      tecnico: this.props.tecnico,
      options: []
    };
  }

  handleSelectChange = (name, value) => {
    console.log(name);
    console.log(value);
    console.log(this);
    this.setState({ tecnico: value }, () => {
      this.props.onChange(value);
    });
  };
  componentDidMount() {
    this.fetchData(this.props.postoAutorizado);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.postoAutorizado &&
      nextProps.postoAutorizado != this.props.postoAutorizado
    ) {
      this.fetchData(nextProps.postoAutorizado);
    }
  }

  fetchData(parent) {
    let fetch = tecnicoService.findByPosto(parent);
    fetch.then(
      data => {
        console.log(data);
        let options = [];
        let noResultsText = this.state.noResultsText;
        if (data.data.length < 1) {
          noResultsText = 'Nenhum resultado encontrado.';
        }
        this.setState({ options: data.data, noResultsText: noResultsText });
      },
      error => {
        console.log('Erro ao montar o combo', error);
      }
    );
  }
  insertTecnico(value) {
    console.log(value);
    tecnicoService
      .doSave(value.value, this.props.postoAutorizado)
      .then(response => {
        let options = _.cloneDeep(this.state.options);
        console.log(response);
        options.push(response.data);
        this.setState({ options });
        this.handleSelectChange('tecnico', response.data);
      });
  }

  render() {
    return (
      <React.Fragment>
        {this.props.statusOS === propertyConstantes.CENTRO_TECNICO && (
          <SelectInput
            ype="text"
            name="tecnico"
            label="Técnico"
            placeholder=""
            labelKey={'nome'}
            labelValue={'id'}
            size={2}
            style={['col-12 col-sm-12']}
            returnFullObject={true}
            searchable={true}
            id={'tecnico'}
            ref={input => {
              this._tecnico = input;
            }}
            creatable={true}
            onChange={this.handleSelectChange}
            value={this.state.tecnico}
            required={true}
            parent={this.props.postoAutorizado}
            options={this.state.options}
            onNewOptionClick={this.insertTecnico}
            multi={false}
          />
        )}
        {this.props.statusOS !== propertyConstantes.CENTRO_TECNICO && (
          <div className="form-group col-12 col-sm-12">
            <label className="miniLabel">Técnico</label>
            <p>{this.props.tecnico ? this.props.tecnico.nome : ''}</p>
          </div>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(TecnicoInput);
