//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const enderecoService = {
  findPais,
  findEstado,
  findCidade,
  findEstadoExact,
  findCidadeExact
};

function findPais(input) {
  return defaultService.doGet(`${urlsConstants.FIND_PAIS}${input}`);
}
function findEstado(pais: number, input: string) {
  return defaultService.doGet(
    `${urlsConstants.FIND_ESTADO}${pais.id}/${input}`
  );
}
function findCidade(estado: number, input: string) {
  console.log(estado);

  if(estado && estado.id) {
    return defaultService.doGet(
      `${urlsConstants.FIND_CIDADE}${estado.id}/${input}`
    );
  }else{
    return defaultService.doGet(
      `${urlsConstants.FIND_CIDADE}0/${input}`
    );
  }
}

function findEstadoExact(uf, pais) {
  return defaultService.doGet(
    `${urlsConstants.FIND_ESTADO_EXACT}${uf}/${pais.id}`
  );
}

function findCidadeExact(cidade, estado) {
  return defaultService.doGet(
    `${urlsConstants.FIND_CIDADE_EXACT}${cidade}/${estado.id}`
  );
}
