import React, { PureComponent } from 'react';
import BtnPrimary from '../Buttons/BtnPrimary';

type Props = {
  onBack: any,
  onForward: any,
  step: number
};
export class HeaderCardComponent extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      step: props.step
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ step: nextProps.step });
  }

  getStateStep(step: number) {
    if (this.state.step === step) {
      return 'active';
    } else if (this.state.step > step) {
      return 'checked';
    }
  }

  getPercent() {
    if (this.state.step === 1) {
      return 10;
    } else if (this.state.step === 3) {
      return 50;
    } else if (this.state.step === 4) {
      return 70;
    } else if (this.state.step === 5) {
      return 90;
    } else if (this.state.step === 6) {
      return 105;
    } else {
      return this.state.step * 16;
    }
  }

  setStep = (step: number) => {
    this.setState({ step: step });
  };

  render() {
    const statebarWidht = `calc(${this.getPercent()}% - 15px)`;

    return (
      <React.Fragment>
        <div className="d-flex flex-column align-items-center my-2 mx-4">
          <h2 className="text-center">
            Cadastro de posto autorizado <b>Seculus</b>
          </h2>
        </div>
        <div className="wizardNav align-items-center">
          <div className="wizard-navigation">
            <div className="progress-with-circle">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="1"
                aria-valuemin="1"
                aria-valuemax="3"
                style={{ width: statebarWidht }}
              />
            </div>
            <ul className="nav nav-pills">
              <li
                className={`w-20 ${this.getStateStep(1)}`}
                onClick={e => {
                  this.setStep(1);
                }}
              >
                <a className="linkWizard" href="#empresa" data-toggle="tab">
                  <div className="tit">Empresa</div>
                  <div className="icone-circle ">
                    <div className="num"> 1 </div>
                  </div>
                </a>
              </li>
              <li
                className={`w-20 ${this.getStateStep(2)}`}
                onClick={e => {
                  this.setStep(2);
                }}
              >
                <a className="linkWizard" href="#responsavel" data-toggle="tab">
                  <div className="tit">Responsável</div>
                  <div className="icone-circle">
                    <div className="num"> 2 </div>
                  </div>
                </a>
              </li>
              <li
                className={`w-20 ${this.getStateStep(3)}`}
                onClick={e => {
                  this.setStep(3);
                }}
              >
                <a className="linkWizard" href="#termo" data-toggle="tab">
                  <div className="tit">Termo de Uso</div>
                  <div className="icone-circle">
                    <div className="num"> 3 </div>
                  </div>
                </a>
              </li>
              <li
                className={`w-20 ${this.getStateStep(4)}`}
                onClick={e => {
                  this.setStep(4);
                }}
              >
                <a className="linkWizard" href="#confirmacao" data-toggle="tab">
                  <div className="tit">Confirmação</div>
                  <div className="icone-circle">
                    <div className="num"> 4 </div>
                  </div>
                </a>
              </li>
              <li
                className={`w-20 ${this.getStateStep(5)}`}
                onClick={e => {
                  this.setStep(5);
                }}
              >
                <a className="linkWizard" href="#conclusao" data-toggle="tab">
                  <div className="tit">Conclusão</div>
                  <div className="icone-circle">
                    <div className="num"> 5 </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
