//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { docService } from '../../services/doc.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { DocListFilter } from './DocListFilter';

const columns = [
  {
    path: 'numDoc',
    title: 'Nº DOC',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDate',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: false },
  {
    path: 'statusDoc.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    isStatus: 'statusDoc.className',
    className: 'txtUppercase'
  }
];

type State = {
  modal: boolean
};

class DocList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;
    let responsavel = null;
    if(this.props.match&&this.props.match.params&& this.props.match.params.responsavel){
      responsavel=this.props.match.params.responsavel
    }
    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      modal,
      fields,
      responsavel,
      fieldsExport: [
        'numDoc',
        'creationDateDoc',
        'postoAutorizado',
        'codigoSAP',
        'postoAutorizadoCidade',
        'codigoPeca',
        'codigoPeca2',
        'descricaoPeca',
        'codigoRelogio',
        'nomeMarca',
        'numOs',
        'dataOs',
        'garantiaValue',
        'quantidade',
        'valor',
        'valorTotal',
        'quantidadeAtendida',
        'quantidadeEstoque',
        'faturar',
        'codigoRastreio'
      ],
      labelsExport: [
        'Número do DOC',
        'Data do DOC',
        'Posto Autorizado',
        'Código SAP',
        'Local do posto autorizado',
        'Código da peça 2',
        'Código da peça ',
        'Descrição da peça',
        'Código do Relogio',
        'Marca',
        'Código da OS',
        'Data da OS',
        'Garantia',
        'Quantidade',
        'Valor da peça',
        'Valor total',
        'Quantidade Atendida',
        'Estoque',
        'Faturar',
        'Código de Rastreio'
      ]
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleRowClick = (row: e) => {
    this.props.history.push('/doc/view/' + row.id);
  };

  renderCustom(row: any, doc: any, column: any) {
    return tableHelper.renderCustom(row, doc, column);
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let posto = this.props.user.extension
      ? { id: this.props.user.extension }
      : null;
    let defaultFilter = { endereco: {}, posto,responsavel:this.state.responsavel };

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Doc´s" subTitle=" " canBack={false} />
        <ListagemPage
          title={`DOC(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={docService}
          labelNewButton="Nova OS"
          // classSignal="temSinal"
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={defaultFilter}
          onRowClick={this.handleRowClick}
          FormFilter={DocListFilter}
          showNewButton={false}
          controller="doc"
          fields={this.state.fields}
          fieldsExport={this.state.fieldsExport}
          labelsExport={this.state.labelsExport}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;
  const { user } = state.authentication;

  return {
    subTitle,
    mainTitle,
    user
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(DocList);
