//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const postoHabilitadoService = {
  doSave,
  find
};

function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.POSTO_HABILITADO, entity);
}

function find(description) {
  return defaultService.doGet(
    `${urlsConstants.FIND_POSTO_HABILITADO}${description}`
  );
}
