//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TrocaModeloListAdmin from './TrocaModeloListAdmin';

export default class TrocaModelosAdmin extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/trocaModeloAdmin"
            exact
            component={TrocaModeloListAdmin}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
