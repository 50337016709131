//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../components/ToastIcons/IconSucessToast';

export default {
  state: { totalMessages: 0 },
  reducers: {
    addTotalMessagesOs: (state, payload) => {
      return Object.assign({}, state, {
        totalMessages: payload
      });
    }
  }
};
