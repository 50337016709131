import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import React from 'react';
import { objectsConstants } from '../../constants/objects.constants';
const defaultPosto = {
  id: 1,
  telefoneComercial: '(31) 3243-3472',
  nome: 'NEW TIME BH ',
  aprovado: false,
  endereco: {
    id: 80,
    rua: 'Avenida Amazonas',
    cep: '30180902',
    numero: '115',
    complemento: 'Sala 208',
    bairro: 'Centro',
    cidade: {
      id: 2,
      nome: 'Belo Horizonte',
      estado: {
        id: 13,
        nome: 'Minas Gerais',
        pais: { id: 1, nome: 'Brasil' },
        uf: 'MG'
      }
    }
  }
};

class GoogleMapsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      makers: [],
      postoAtual: {}
    };
    // binding this to event-handler functions
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClick = this.onMapClick.bind(this);
  }
  onMarkerClick = (props, marker, e, postoLocal) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
      postoAtual: postoLocal
    });
  };
  onMapClick = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  getAddres = local => {
    let endereco = '';
    if (local.endereco) {
      endereco =
        this.props.validaCampo(local.endereco.rua) +
        ', ' +
        this.props.validaCampo(local.endereco.numero) +
        ' ' +
        this.props.validaCampo(local.endereco.complemento) +
        ' - ' +
        this.props.validaCampo(local.endereco.bairro) +
          ' , cep: ' +
          this.props.validaCampo(local.endereco.cep)+
          ', ' +
        this.props.validaCampo(local.endereco.cidade.nome) +
        ' | ' +
        this.props.validaCampo(local.endereco.cidade.estado.uf);
    }

    return endereco;
  };

  render() {
    const style = {
      width: '100%',
      height: '50%',
      marginLeft: 'auto',
      marginRight: 'auto'
    };
    let _this = this;
    let center = (this.props.locais[0] && this.props.locais[0].cordenadas) || {
      lat: -19.9325502,
      lng: -43.9275688
    };
    let zoomLocal = null;
    if (this.props.selectedLocal && this.props.selectedLocal.cordenadas) {
      console.log('novo centro');
      center = this.props.selectedLocal.cordenadas;
      zoomLocal = 15;
    }

    return (
      <Map
        xs={12}
        style={{ width: '100%', height: '100%', position: 'relative' }}
        google={this.props.google}
        onClick={this.onMapClick}
        zoom={zoomLocal ? zoomLocal : this.props.locais.length > 1 ? 6 : 12}
        initialCenter={center}
        center={center}
        ref={map => (this.map = map)}
      >
        {this.props.locais.map(postoLocal => {
          return (
            <Marker
              name={postoLocal.nome}
              position={postoLocal.cordenadas}
              icon={{
                url:
                  'https://seculusdigital.vteximg.com.br/arquivos/icon-marker.png',
                anchor: new this.props.google.maps.Point(32, 32),
                scaledSize: new this.props.google.maps.Size(32, 32)
              }}
              onClick={(props, marker, e) => {
                this.onMarkerClick(props, marker, e, postoLocal);
              }}
            />
          );
        })}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div className="balao">
            <p>
              <b>
                {_this.props
                  .validaCampo(this.state.postoAtual.nome)
                  .toUpperCase()}
              </b>{' '}
            </p>
            <p>
              <b>Endereço: </b>
              {this.getAddres(this.state.postoAtual)}
            </p>
            <p>
              <b>Telefone: </b>
              {this.state.postoAtual.telefoneComercial}
            </p>
            <p>
              <b>Horário de funcionamento: </b>
             <b> {_this.props.validaCampo(this.state.postoAtual.horaFuncionamento)}</b>
            </p>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  api: objectsConstants.codeGoogle
})(GoogleMapsContainer);
