import React, { PureComponent } from 'react';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import 'react-confirm-alert/src/react-confirm-alert.css';

import TopoTitleComponente from '../Topo/TopoTitleComponente';
import Toast from '../../components/Toasts/Toast';
import { ordemServicoService } from '../../services/ordemServico.service';
import SelectInput from '../../components/Inputs/SelectInput';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { alertActions } from '../../actions/alert.action';
import IconSucessToast from '../../components/ToastIcons/IconSucessToast';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import { objectsConstants } from '../../constants/objects.constants';

type State = { initialValues: any };

class AlterarStatusOSEdit extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      listOS: [],
      status: '',
      search: {}
    };
  }

  handleSelectChange = (name: string, option: any) => {
    this.addOS(option);
  };

  handleStatusChange = (name: String, option: any) => {
    console.log(option);
    this.setState({ status: option });
  };

  doSave = () => {
    let toastrOptionsSuccess = { icon: <IconErrorToast /> };
    alertActions.clear();
    const { status, listOS } = this.state;
    if (listOS.length > 0 && status !== '') {
      let sendingList = [];
      for (let i = 0; i < listOS.length; i++) {
        sendingList.push({ id: listOS[i].id, statusOS: status });
      }

      this.props.loading(true);
      ordemServicoService
        .trocaStatusOS(sendingList)
        .then(response => {
          alertActions.clear();
          toastrOptionsSuccess = { icon: <IconSucessToast /> };
          alertActions.success(
            objectsConstants.messageSucesso,
            '',
            toastrOptionsSuccess
          );
          this.props.loading(false);
          this.props.history.push('/home');
        })
        .catch(() => {
          this.props.loading(false);
          alertActions.error(
            'Não foi possível alterar o status',
            '',
            toastrOptionsSuccess
          );
        });
    } else if (this.state.status === '') {
      alertActions.error(
        'Campo status não preenchido',
        '',
        toastrOptionsSuccess
      );
    } else {
      alertActions.error(
        'Não há elementos a serem alterados',
        '',
        toastrOptionsSuccess
      );
    }
  };

  addOS = value => {
    const { listOS } = this.state;
    if (_.get(value, 'id')) {
      let newArray = _.concat(listOS, value);
      this.setState({ listOS: newArray });
    }
  };

  removeOS = (listOS, index) => {
    let newArray = _.cloneDeep(listOS);
    newArray.splice(index, 1);
    this.setState({ listOS: newArray });
  };

  itemRow = (input, posicao) => {
    return (
      <div>
        <label>Número da OS </label>
        {input.numOs}
        <label> Status da OS</label>
        {input.statusOS}
        />
      </div>
    );
  };

  clearError = () => {
    return '';
  };

  render() {
    const { search, status } = this.state;
    let titlePage;
    if (this.props.location.state) {
      titlePage = this.props.location.state.titlePage;
    }
    return (
      <form>
        <Toast />
        <TopoTitleComponente
          mainTitle="Ordem Serviço"
          subTitle="Alterar Status OS"
          canBack={true}
        />
        <div className="d-flex flex-row justify-content-center">
          <div className="cardSeculus flex-column mb-0">
            <div className="topoCard d-flex flex-column flex-sm-row">
              <h3 className="flex-grow-1">pesquisar os</h3>
            </div>

            <div className="row">
              <SelectInput
                type="text"
                name="ordemServico"
                label="Pesquise pelo número da OS"
                placeholder="Digite o número da OS"
                required={false}
                style={['col-sm-8']}
                onFetchData={ordemServicoService.findOS}
                valueKey="numOs"
                labelKey="numOs"
                returnFullObject={true}
                searchable={true}
                id={'ordemServico'}
                onChange={this.handleSelectChange}
                value={this.state.search}
                helpText={'Digite o número da OS'}
              />
              <StatusSelectInput
                label="Status"
                placeholder={'Status'}
                id={'statusOS'}
                name="statusOS"
                onChange={this.handleStatusChange}
                type={'text'}
                size={12}
                style={'col-sm-4'}
                value={status}
                service={ordemServicoHelper.getListStatus}
              />
            </div>
          </div>
        </div>

        <div className="cardSeculus flex-column mb-0">
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">
              Ordens de Serviço inseridas ({this.state.listOS.length})
            </h3>
          </div>
          <div className="row pl-4 pt-2">
            <div className="column">
              <label style={{ fontSize: 16 }} className="miniTxt">
                {' '}
                Nº da OS
              </label>{' '}
              <br />
            </div>
            <div className="column pl-5">
              <label style={{ fontSize: 16 }} className="miniTxt">
                {' '}
                Status da OS{' '}
              </label>{' '}
              <br />
            </div>
          </div>
          {this.state.listOS && this.state.listOS.length > 0}{' '}
          {this.state.listOS.map((os, index) => {
            return (
              <div className="row pl-3 pt-2" key={index}>
                <div className="column">
                  <label
                    className="pr-3"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {' '}
                    {_.get(os, 'numOs')}{' '}
                  </label>
                </div>
                <div className="column pl-4">
                  <label
                    className="pr-3"
                    style={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {' '}
                    {_.get(os, 'statusOS.description')}{' '}
                  </label>
                </div>
                <div className="text-right px-5">
                  <img
                    className="icon pr-1"
                    src={require('../../img/icon_excluir.svg')}
                  />
                  <a onClick={() => this.removeOS(this.state.listOS, index)}>
                    remover
                  </a>
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex flex-row flex-sm-row ">
          <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
              type="button"
              className="btn btn-secondary btn30 white"
              onClick={() => this.props.history.goBack()}
            >
              Cancelar
            </button>
          </div>
          <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
              type="button"
              className="btn btn-secondary btn30 green"
              onClick={() => this.doSave()}
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    );
  }
}

const mapDipatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDipatch)(AlterarStatusOSEdit);
