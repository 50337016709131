//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ImportacaoValoresPecasListagem from './ImportacaoValoresPecasListagem';

export default class ImportacaoValoresPecas extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/importacaoValoresPecas"
            exact
            component={ImportacaoValoresPecasListagem}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
