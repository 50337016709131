//@flow
import * as React from 'react';

import { relatorioService } from '../../../services/relatorio.service';
import AnaliseAtendimentoEprChart from './AnaliseAtendimentoEprChart';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import moment from 'moment';
import { analiseAtendimentoEprService } from '../../../services/analiseAtendimentoEprService.service';
import connect from 'react-redux/es/connect/connect';
import { tableHelper } from '../../../helpers/tableHelper';
import AnaliseAtendimentoEprFormFilter from './AnaliseAtendimentoEprFormFilter';
import AnaliseAtendimentoEprDetails from './AnaliseAtendimentoEprDetails';

const columns = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'nomePeca',
    title: 'Peça',
    isKey: false,
    dataSort: true
  },
  {
    path: 'codigoPeca',
    title: 'Código',
    isKey: false,
    dataSort: true
  },
  {
    path: 'nomeMarca',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'atendida.description',
    title: 'Atendido',
    isKey: false,
    dataSort: false,
    hasClassName: 'atendida.className'
  }
];

class AnaliseAtendimentoEpr extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      fields,
      defaultFilter: {
        fimAno:ano,
        inicioAno:ano,
        inicioMes:1,
        fimMes:mes
      },
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="ANÁLISE ATENDIMENTO EPR "
          canBack={false}
        />
        <CardRelatorio
          title={`ANÁLISE ATENDIMENTO EPR (${this.props.subTitle})`}
          FormFilter={AnaliseAtendimentoEprFormFilter}
          Chart={AnaliseAtendimentoEprChart}
          showTable={true}
          renderChartOnConstruct={true}
          methodFindChart={analiseAtendimentoEprService.findChart}
          tableService={analiseAtendimentoEprService}
          controller={'relatorio/analiseAtendimentoEpr'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          onHandleFilter={this._handleFilterChange}
          DetalhePage={AnaliseAtendimentoEprDetails}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(AnaliseAtendimentoEpr);
