//@flow
import * as React from 'react';
import ChatOsRowMessage from './ChatOsRowMessage';
import SockJsClient from 'react-stomp';
import _ from 'lodash';

import { oauthHeaderJson } from '../../helpers/oauth-header';
import { mensagemService } from '../../services/messagem.service';
import { userService } from '../../services/user.service';
import '../../css/chat_style.css';
import { dateHelper } from '../../helpers/date.helper';
import connect from 'react-redux/es/connect/connect';
import { ToolTipFeedBack } from '../../components/Utils/ToolTipFeedBack';
import { MentionInput } from '../../components/Inputs/MentionInput';
import { midiaService } from '../../services/midiaService';

class ChatOs extends React.PureComponent<{}> {
  constructor(props: Props) {
    super(props);
    userService.me().then(response => {
      this.setState({ sender: response.data });
    });
    this.state = {
      messages: [],
      message: '',
      sender: {},
      mentions: []
    };
  }

  handleMessages(msgs) {
    const messages = _.cloneDeep(this.state.messages);
    if (typeof msgs.data.id !== 'undefined') {
      messages.push(msgs.data);
    } else {
      while (messages.length > 0) {
        messages.pop();
      }
      msgs.data.listMessage.map(msg => {
        messages.push(msg);
      });
      mensagemService.updadeViewReceiverOS(this.props.os);
    }

    this.setState({ messages });
    this.props.addTotalMessagesOs(messages.length);
  }

  onChangeFile = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    console.log(files);
    if (files.length) {
      let imageVO = {
        filename: files[0].name,
        length: files[0].size,
        contentType: files[0].type
      };
      console.log(imageVO);
      let sendMidia = this.sendMidia;
      console.log(sendMidia);
      let reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function(event) {
        imageVO.data = event.target.result;
        sendMidia(imageVO);
      };
    }
  };

  sendMidia = midia => {
    midiaService.save(midia).then(response => {
      console.log(
        'Enviando nova mensagem (' +
          mensagemService.getWsUrl() +
          mensagemService.getWsTopicOS(this.props.os) +
          ')' +
          midia
      );
      this.clientRef.sendMessage(
        '/app/chat/os/' + this.props.os,
        JSON.stringify({
          attachment: response.data,
          sender: this.state.sender
        })
      );

      console.log('Mensagem enviada');
    });
  };
  removeMention = textareaValue => {
    const { mentions } = this.state;
    for (let i = 0; i < mentions.length; i++) {
      if (mentions[i].finish >= textareaValue.length - 1) {
        let newMentions = _.map(mentions, _.clone);
        const start = mentions[i].start;
        newMentions.splice(i);
        return start;
      }
    }
  };
  addMention = mention => {
    const { mentions } = this.state;
    let newMentions = _.map(mentions, _.clone);
    newMentions.push(mention);
    this.setState({ mentions: newMentions });
  };
  sendMessage = msg => {
    if (this.state.message.length > 0) {
      console.log(
        'Enviando nova mensagem (' +
          mensagemService.getWsUrl() +
          mensagemService.getWsTopicOS(this.props.os) +
          ')' +
          this.state.message
      );

      this.clientRef.sendMessage(
        '/app/chat/os/' + this.props.os,
        JSON.stringify({
          message: this.state.message,
          sender: this.state.sender,
          mentions: this.state.mentions
        })
      );

      this.setState({ message: '', mentions: [] });

      console.log('Mensagem enviada');
    } else {
      console.log('Mensagem Vazia');
    }
  };

  addMessage() {
    const messages = _.cloneDeep(this.state.messages);
    let msg = {
      id: 0,
      mensagem: this.state.message,
      sender: this.state.sender,
      dateTime: dateHelper.now()
    };
    messages.push(msg);
    this.setState({ messages });
  }

  handleChange = value => {
    const message = value;

    this.setState({ message });
  };

  openInputFile = (event: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      return false;
    }
    this.inputFile.click();
  };

  render() {
    const wsSourceUrl = mensagemService.getWsUrl();

    const topic = mensagemService.getWsTopicOS(this.props.os);

    let headers = oauthHeaderJson();
    return (
      <React.Fragment>
        <SockJsClient
          url={wsSourceUrl}
          topics={[topic, '/app' + topic]}
          onMessage={msgs => {
            this.handleMessages(msgs);
          }}
          subscribeHeaders={headers}
          headers={headers}
          ref={client => {
            this.clientRef = client;
          }}
        />

        <div className="container">
          <div className="containerChat">
            {this.state.messages.map((message, index) => (
              <ChatOsRowMessage
                key={index}
                message={message}
                sender={message.sender}
                foto={message.sender.foto}
                currentUser={this.state.sender}
              />
            ))}
            {this.state.messages.length > 0 && (
              <p ref={ref => (this._div = ref)} />
            )}
          </div>
          <div className="col-12">
            <div className="row align-items-center inputMessage">
              <div className="col-8 col-sm-9 input-group borderInput">
                <MentionInput
                  os={this.props.os}
                  onChange={this.handleChange}
                  value={this.state.message}
                  onAddMetion={this.addMention}
                  onRemoveMention={this.removeMention}
                  sendMessage={this.sendMessage}
                />
                {/* <textarea
                type="text"
                className="form-control chat"
                placeholder="Digite sua mensagem"
                onChange={this.handleChange}
                aria-label="Digite sua mensagem"
                value={this.state.message}
              /> */}
              </div>
              <div className="col-1 p-0 text-center">
                <a ref="">
                  <i
                    className="icon-anexo"
                    alt="Ícone para enviar anexos"
                    onClick={this.openInputFile}
                  />
                </a>
              </div>

              <div>
                <input
                  type="file"
                  accept={this.props.accept ? this.props.accept : '*'}
                  onChange={this.onChangeFile}
                  accept="image/x-png,image/gif,image/jpeg"
                  className="d-none img-profile-input"
                  maxLength="1024"
                  ref={input => {
                    this.inputFile = input;
                  }}
                />

                {this.props.erroMensagem && (
                  <ToolTipFeedBack
                    target={this.props.id}
                    messageValidate={this.props.erroMensagem}
                  />
                )}
              </div>

              <div className="col-3 col-sm-2 px-0 text-center">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm green "
                  onClick={this.sendMessage}
                >
                  <i className="icon-send" alt="Avião de papel" />
                  Enviar
                </button>
              </div>
              <small className="pl-3 pt-1 obsTxt">
                *Para mencionar alguém utilize o @
              </small>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ chat: { addTotalMessagesOs } }) => ({
  addTotalMessagesOs: totalMessages => addTotalMessagesOs(totalMessages)
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(ChatOs);
