//@flow
import * as React from 'react';

export default class CardSeculusFull extends React.Component<{}> {
  render() {
    return (
      <div className={'card cardSeculus cardFull d-flex flex-column'}>
        {this.props.children}
      </div>
    );
  }
}
