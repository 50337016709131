//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const pesquisaSatisfacaoClientesService = {
  findByFiltro,
  getMediaPostoAutorizado
};

function getMediaPostoAutorizado(id) {
  return defaultService.doGet(
    `${urlsConstants.RELATORIO}getAvaliacaoPosto/${id}`
  );
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO}postoAutorizadoClientes`,
    options,
    filter
  );
}
