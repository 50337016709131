import axios from 'axios';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const garantiaClienteService = {
    save,
    findByFiltro,
    getById,
    getByCpfCliente,
    doDelete,
};

function save(garantia) {
    return  axios.post(urlsConstants.GARANTIA_CLIENTE,garantia);
}

function findByFiltro(
    options: Options,
    filter: any
): Promise<Array<OrdemServicoType>> {
    return defaultService.findByFiltro(
        `${urlsConstants.GARANTIA_CLIENTE_SECULUS}list`,
        options,
        filter
    );
}

function getById(id){
    return defaultService.doGet(`${urlsConstants.GARANTIA_CLIENTE_SECULUS}${id}`);
}

function getByCpfCliente(cpf) {
    return defaultService.doGet(`${urlsConstants.GARANTIA_CLIENTE_SECULUS}find/GarantiasValidas/${cpf}`);
}

function doDelete(id) {
    return defaultService.doDelete(`${urlsConstants.GARANTIA_CLIENTE_SECULUS}${id}`);
}