//@flow
import * as React from 'react';
import { Redirect } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import CardMeuPerfil from './MeuPerfil';
import CardHistorico from '../../components/Cards/CardHistorico';
import CardConfigGerais from '../../components/Cards/CardConfigGerais';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import { colaboradorService } from '../../services/colaborador.service';
import { Can } from '@casl/react';

type State = { colaborador: any };

class ConfiguracoesView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { colaborador: {} };
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      colaboradorService.getById(this.props.match.params.id).then(result => {
        let colaborador = result.data.data;
        this.setState({ colaborador });
      });
    }
  }

  render() {
    const { permissions } = this.props;
    const { colaborador } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Configurações"
          subTitle=" "
          canBack={false}
        />
        <div className="flex-column">
          <CardMeuPerfil
            colaborador={colaborador}
            titlePageEdit="Configurações"
          />
          <Can
            I={objectsConstants.VIEW}
            a={objectsConstants.ABILITY_CONFIGURACOES}
            ability={permissions}
          >
            <CardConfigGerais />
          </Can>
        </div>
        <div className="flex-column">
          {colaborador.id && <CardHistorico id={colaborador.id} />}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}
export default connect(
  mapStateToProps,
  null
)(ConfiguracoesView);
