import React, { PureComponent } from 'react';
import FormInput from './FormInput';
import { messages, translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { FormRow } from '../FormRow';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange: any,
  onBlur: any,
  children?: any,
  required: boolean,
  messageValidate?: string,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any,
  confirmPasswordValue?: string | string[] | number,
  confirmPasswordLabel: string,
  confirmPasswordName: string,
  confirmPasswordId: string,
  confirmPasswordPlaceholder: string
};
class PasswordInput extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };

  constructor(props: Props) {
    super(props);
    const { messages, name } = this.props;
    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    this.state = { messageValidate: messageValidate };
  }
  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  render() {
    const { name } = this.props;
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }
    const value = this.props.value;
    let messageValidate;
    if (
      this.props.value &&
      this.props.confirmPasswordValue &&
      this.props.value !== this.props.confirmPasswordValue
    ) {
      //TODO Melhorar componente
      // messageValidate = messages.INVALID_CONFIRM_PASSWORD;
    }
    return (
      <FormRow>
        <FormInput
          type="password"
          name={this.props.name}
          label={this.props.label}
          placeholder={this.props.placeholder}
          messageValidate={messageValidate}
          required={this.props.required}
          style={this.props.style}
          size={this.props.size}
          id={this.props.id}
          onChange={this.props.onChange}
          value={this.props.value}
          password={this.props.confirmPasswordValue}
        />
        <FormInput
          type="password"
          name={this.props.confirmPasswordName}
          label={this.props.confirmPasswordLabel}
          placeholder={this.props.confirmPasswordPlaceholder}
          messageValidate={messageValidate}
          required={this.props.required}
          style={this.props.style}
          size={this.props.size}
          id={this.props.confirmPasswordId}
          password={this.props.value}
          onChange={this.props.onChange}
          value={this.props.confirmPasswordValue}
        />
      </FormRow>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(PasswordInput);
