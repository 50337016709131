//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompraPecaList from './CompraPecaList';
import CompraPecaNew from './CompraPecaNew';
import CompraPecaView from './CompraPecaView';

export default class CompraPeca extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/compraPeca/novo" component={CompraPecaNew} />
          <Route path="/compraPeca/view" component={CompraPecaView} />
          <Route path="/compraPeca" exact component={CompraPecaList} />
        </Switch>
      </React.Fragment>
    );
  }
}
