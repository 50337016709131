//@flow
import * as React from 'react';
// import type { ClienteType } from '../../types/cliente.type';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {
  objectsConstants,
  constHelper
} from '../../constants/objects.constants';
import { Can } from '@casl/react';
import { propertyConstantes } from '../../constants/property.constantes';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import _ from 'lodash';
import { UserAvatar } from '../menu/UserAvatar';
import { dateHelper } from '../../helpers/date.helper';
import { colaboradorService } from '../../services/colaborador.service';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';

type Props = {
  colaborador: any,
  titlePageEdit: string
};

const endPoint = '/ColaboradorCrud/edit/';
class CardColaborador extends React.Component<Props> {
  disableEnabledUser = () => {
    let { colaborador } = this.props;
    this.props.loading(true);
    _.set(colaborador, 'status', !colaborador.status);
    _.unset(colaborador, 'foto');
    colaboradorService
      .doSave(colaborador)
      .then(response => {
        this.props.history.push('/colaboradorcrud');
        this.props.success({ message: 'Alterado com sucesso.' });
        this.props.loading(false);
      })
      .catch(() => {
        this.props.success('Não foi possível inativar o colaborador.');
        this.props.loading(false);
      });
  };

  getBtnActiveInative = () => {
    const { colaborador } = this.props;
    let textAction = colaborador.status ? 'INATIVAR' : 'ATIVAR';
    return (
      <button
        type="button"
        className={`btn btn-primary btn-sm ${
          colaborador.status ? 'red' : 'green'
        }`}
        data-toggle="modal"
        data-target="#editarDados"
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            this.disableEnabledUser,
            textAction + ' COLABORADOR',
            'Você confirma que deseja ' + textAction + ' o colaborador:',
            '' + colaborador.name + ''
          );
        }}
      >
        {textAction}
      </button>
    );
  };

  render() {
    const { colaborador, permissions, titlePageEdit } = this.props;
    return (
      <div className="card cardSeculus miniCard d-flex flex-column ">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">colaborador</h3>
          <div className="boxButon float-right d-sm-flex">
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_EDITAR_COLABORADOR}
              ability={permissions}
            >
              {this.getBtnActiveInative()}
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  this.props.history.push({
                    pathname: endPoint,
                    state: {
                      colaborador: this.props.colaborador,
                      titlePage: titlePageEdit
                    }
                  });
                }}
              >
                editar dados
              </button>
            </Can>
          </div>
        </div>

        <div className="conteudoCard mt-2">
          <TopoTitleComponente
            mainTitle={this.props.titlePageEdit}
            subTitle={'Colaborador - ' + colaborador.name}
            canBack={true}
          />
          <div className="imgPerfil row justify-content-center">
            <div className=" text-center">
              <UserAvatar foto={colaborador.foto} />
            </div>
          </div>
          <div className="dadosPerfil row justify-content-center mt-2">
            <div className="text-center">
              <h4 className="mb-0">{_.get(constHelper, colaborador.role)}</h4>
            </div>
          </div>

          <div className="row ">
            <div className="col-12">
              <label className="miniLabel">Nome:</label>
              <div className="mb-2">
                <b>{colaborador.name}</b>
              </div>
            </div>

            <div className="col-6">
              <label className="miniLabel">CPF:</label>
              <div className="mb-2">{colaborador.cpf}</div>
            </div>
            <div className="col-6">
              <label className="miniLabel">Celular:</label>
              <div className="mb-2">{colaborador.celular}</div>
            </div>

            <div className="col-6">
              <label className="miniLabel">E-mail:</label>
              <div className="mb-2">
                {colaborador.email ? colaborador.email : 'Não informado'}
              </div>
            </div>
            <div className="col-6">
              <label className="miniLabel">Data de nascimento:</label>
              <div className="mb-2">
                {dateHelper.format(colaborador.dataNascimento, {
                  pattern: 'DD/MM/YYYY'
                })}
              </div>
            </div>
            {/* {colaborador.telefones.map((telefone, index) => {
              return (
                <div className="col-6" key={telefone.id}>
                  <label className="miniLabel">
                    {propertyConstantes[telefone.tipo]}:
                  </label>
                  <div className="mb-2">{telefone.numero}</div>
                </div>
              );
            })} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CardColaborador));
