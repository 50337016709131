import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../index/customIndex.css';
import SectionLoginBase from './SectionLoginBase';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class ResetPasswordSucess extends PureComponent<Props, State> {
  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  render() {
    return (
      <SectionLoginBase>
        <div className="index_btCadastro" />
        <h1 className="mb-2 text-center">Redefinir Senha</h1>
        <p className="mb-4 text-center txtGreen">Senha alterada com sucesso</p>

        <form className="index_form" onSubmit={this.handleRecoverPassword}>
          {/* <!--toast error--> */}
          <div className="mx-3 pt-3 pb-3">
            <label>&nbsp;</label>
          </div>
          <div className="mb-5">
            <NavLink
              to={{
                pathname: `/index`,
                state: { goToLogin: true }
              }}
              className="btn btn-primary"
            >
              Efetuar login
            </NavLink>
          </div>
        </form>
      </SectionLoginBase>
    );
  }
}

export default connect()(ResetPasswordSucess);
