//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { formsServices } from '../../services/forms.service';
import _ from 'lodash';
import { HeaderComponent } from '../../components/RegisterComponents/HeaderComponent';
import { alertActions } from '../../actions/alert.action';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';

type Props = {
  entity: any,
  step: number
};

type State = {
  term: string,
  marked: boolean,
  entity: any
};

class RegisterThirdStep extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.state = {
      term: formsServices.getTerm(),
      marked: false,
      entity: _.cloneDeep(this.props.entity)
    };
  }

  markAsRead = () => {
    const { marked } = this.state;
    let newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, 'termAccepted', marked);
    this.setState({
      entity: newEntity
    });
  };

  toggleCheckBox = () => {
    this.setState(
      {
        marked: !this.state.marked
      },
      () => {
        this.markAsRead();
      }
    );
  };

  nextStep = () => {
    const toastrOptionsSuccess = { icon: <IconErrorToast /> };
    if (this.state.marked) {
      this.props.loading(true);
      postoAutorizadoService.doRegister(this.state.entity).then(response => {
        this.props.handleEntity(this.state.entity, 4, true);
        localStorage.removeItem('tempCadastro');
        this.props.loading(false);
      });
    } else {
      alertActions.clear();
      alertActions.error(
        'Leia o termo e aceite-o em seguida.',
        '',
        toastrOptionsSuccess
      );
    }
  };

  previousStep = () => {
    this.props.step(2);
  };

  render() {
    return (
      <React.Fragment>
        <div className="tab-pane active" id="termo">
          <div className="form-row">
            <h3>Termo de Uso</h3>
          </div>
          <div className="mx-3 termo"><h2>TERMOS E CONDIÇÕES GERAIS DE USO DA PLATAFORMA DE SERVIÇOS DE POSTOS AUTORIZADOS SECULUS</h2>
            <p>A Plataforma de Serviços de Postos Autorizados Seculus, de propriedade da Seculus da Amazônia Ind. e Com. S.A, com sede administrativa à Avenida Afonso Pena, 3577 - Belo Horizonte - MG, CNPJ 34.516.088/0002-00, é um ambiente completo de gestão de ordens de serviços, registros de dados de clientes, relatórios e ferramenta de comunicação entre os parceiros. Ao fazer uso da Plataforma de Serviços de Postos Autorizados Seculus você concorda que leu, entendeu e aceitou os termos, regras e condições aqui dispostos. Caso você não esteja de acordo com esta publicação e não aceite o termo, a plataforma cessará o seu acesso e uso.</p>
            <p>A Plataforma de Serviços de Postos Autorizados ficará disponível na Internet 24 horas por dia. Uma vez cadastrado, você poderá ter acesso livre, mediante login e senha fornecidos pelo próprio sistema. O acesso e a utilização da Plataforma é imprescindível para a manutenção, atendimento, informação e toda gestão para prestação dos serviços de assistência técnica. A candidatura para ser um Agente Autorizado, como prestador de serviços de assistência técnica, é realizada nesta Plataforma por meio de um pré-cadastro, e este sendo aprovado, o candidato passará a ser um agente oficial, prestador de serviço de Posto de Assistência Técnica, com um contrato de prestação de serviços a ser assinado entre as partes. </p>
            <p>A Seculus da Amazônia é responsável por toda atualização e manutenção da Plataforma, disponibilizando acesso gratuito a um ambiente virtual completo que prevê o credenciamento, registros de clientes, consultas e pedidos de peças, relatórios, comunicação geral, gestão de logins e usuários e ferramentas variadas para a boa gestão de um Posto Autorizado. </p>
            <p>Cada usuário terá à sua disposição apenas as informações relacionadas às atividades do seu posto de serviço.</p>
            <p>Você é o único responsável por providenciar todos os recursos para a utilização da plataforma, como os serviços de conexão à internet e o equipamento adequado. </p>
            <p>Mantenha o ambiente de seu computador seguro, com uso de ferramentas disponíveis como antivírus e firewall, entre outras, atualizadas, de modo a contribuir na prevenção de riscos eletrônicos.</p>
            <p>Você deve resguardar sua senha, que é de uso individual e intransferível, ou seja, você é o único responsável por manter a confidencialidade da sua conta e todas as atividades e responsabilidades associadas a ela. Em  nenhuma hipótese você poderá disponibilizar o acesso ao sistema a quaisquer terceiros, preservando pela confidencialidade das informações. Comunique a Seculus imediatamente sobre qualquer uso não autorizado de sua conta ou violação de segurança. </p>
            <p>Assegure-se de sair de seu login ao final de cada sessão, pois você responde por qualquer uso indevido do sistema que for realizado com seu login e senha de acesso. A Seculus da Amazônia não pode e não será responsável por qualquer perda ou dano resultante de sua falha em cumprir com esta obrigação ou como resultado da utilização de sua conta, com ou sem o seu conhecimento. A Seculus se reserva ao direito de excluir qualquer cadastro que não esteja dentro da política de utilização dos sistemas da empresa. </p>
            <p>Em relação ao uso dos serviços da Plataforma de Postos Autorizados Seculus, você concorda em: </p>
            <p>a) -  Fornecer informações verdadeiras, exatas, atuais e completas; </p>
            <p>b) - Manter e atualizar prontamente seus dados para mantê-los verdadeiros, exatos, atuais e completos, e </p>
            <p>(c) Cumprir o presente Termo em sua totalidade. A não observância reserva à Seculus da Amazônia o direito de suspender ou encerrar sua conta, recusar ou restringir qualquer uso futuro dos serviços.</p>
            <p>Você concorda em alimentar a plataforma de postos autorizados, registrando todos os andamentos das ordens de serviços cadastradas, bem como todas as informações necessárias para emissão dos relatórios e segurança geral nas relações com os clientes, exclusivamente e tão somente, das marcas autorizadas e pertencentes ao portfólio da Seculus da Amazônia. </p>
            <p>Está ciente que deverá manter comunicação de qualidade com a equipe de gestão desta plataforma, por este sistema e quaisquer outros canais disponibilizados, valorizando a rapidez, clareza da informação e prontidão em todas as necessidades pertinentes à parceria.</p>
            <p>Todo o conteúdo da Plataforma de Postos Autorizados Seculus é de caráter confidencial e de propriedade da Seculus da Amazônia, sendo vedado:</p>
            <p>a) Sua divulgação a terceiros, no todo ou em parte, sem o consentimento prévio e expresso da Seculus da Amazônia S.A.</p>
            <p>b) Sua utilização indevida, sujeitando o infrator às penalidades previstas em lei.</p>
            <p>Você reconhece que a Plataforma de Postos Autorizados Seculus, a tecnologia subjacente dos serviços e todos os outros programas, materiais, informações, imagens, tabelas de preços, descrições de peças e produtos, saldos de estoques e quaisquer outros dados são de propriedade exclusiva da Seculus da Amazônia S.A, protegidos e sujeitos às leis de direitos autorais. </p>
            <p>Portanto, não é permitida a modificação, cópia, distribuição, transmissão, reprodução, publicação, criação de trabalhos derivados do site, venda de quaisquer informações, material, software, produto ou serviço deste sistema, bem como sua utilização com propósitos ilegais, que venham desrespeitar marcas registradas ou material protegido por direitos autorais e fora dos termos constantes ou qualquer outro que viole a legislação vigente no país.</p>
            <p>A Seculus da Amazônia é proprietária, licenciadora ou distribuidora oficial das marcas que identificam seus produtos, serviços e logotipos utilizados nesta Plataforma de Postos Autorizados. Por tal razão, nenhuma marca ou logotipos disponíveis neste ambiente podem ser utilizados sem a prévia e expressa autorização da empresa. Outras marcas que possam se encontrar neste ambiente, licenciadas ou distribuídas oficialmente pela Seculus da Amazônia S.A, são de propriedade de seus respectivos donos, não sendo autorizada em nenhuma hipótese o seu uso, sem prévia e expressa autorização da empresa proprietária. </p>
            <p>Você não poderá fazer engenharia reversa, montagem reversa ou tentar descobrir o código-fonte do sistema para cópia de tecnologia ou extração de qualquer conteúdo da empresa e seus serviços. Também não poderá armazenar conteúdos não autorizados ou parcela significativa de qualquer conteúdo, tão pouco espelhar informações da empresa ou dos serviços em qualquer servidor, pois estará sujeito às responsabilidades conforme as leis vigentes.</p>
            <p>Concorda em manter absoluta confidencialidade sobre todas as informações recebidas, acessadas e coletadas, sejam da Seculus da Amazônia ou de seus clientes, pessoas físicas ou jurídicas, sob pena de ser responsabilizado pelas perdas e danos. Assim como em notificar, por escrito, a Seculus de qualquer uso ou divulgação de informações privadas em violação destes termos. </p>
            <p>O escopo deste Termo inclui a coleta e o uso de informações do parceiro, seus funcionários e clientes da Seculus da Amazônia que venham a acessar e fazer uso desta plataforma. Serão coletadas informações que identificam você como um usuário: razão social, nome completo, e-mail; número do CPF/CNPJ; endereço completo, número de telefone e imagens de documentos. A Seculus da Amazônia reserva-se ao direito de coletar a qualquer tempo relatórios relativos ao uso, desempenho e frequência dos acessos à Plataforma de Postos Autorizados. </p>
            <p>A Seculus da Amazônia S.A valoriza a confidencialidade de dados e compromete-se a cumprir todas as exigências legais relacionadas à privacidade de informações pessoais, com objetivo de respeitar todas as leis e regulamentações vigentes. Neste sentido, não comercializa, troca ou disponibiliza a terceiros, informações sobre seus usuários. O acesso às informações coletadas é restrito aos funcionários e pessoas autorizadas para este fim e aqueles que se utilizem indevidamente dessas informações, ferindo nossa Política de Privacidade, estarão sujeitos às medidas disciplinares e legais cabíveis. A Seculus da Amazônia S.A utilizará os melhores esforços para manter a integridade e a confidencialidade das informações e embora utilize tecnologias avançadas para segurança, reconhece-se a inexistência e impossibilidade de existir um sistema de informações cuja segurança seja totalmente garantida. Todavia, obriga-se a aplicar modernos meios e tecnologias de proteção e segurança das informações que armazena ou venha a armazenar, não se responsabilizando, contudo, pelos danos decorrentes do acesso e/ou utilização por terceiros, seja dolosa ou culposamente, que eventualmente consigam subverter o sistema de segurança da informação.</p>
            <p>Todos os usuários da Plataforma de Postos Autorizados permitem a divulgação de seus respectivos dados, como: nome do usuário, razão social ou fantasia, região e foto do perfil), na Plataforma e quaisquer outros canais adjacentes utilizados pela  Seculus da Amazônia.</p>
            <p>A Seculus da Amazônia S.A compromete-se a não editar, acessar ou monitorar os conteúdos de mensagens enviadas e recebidas pelos usuários, exceto nos casos que impliquem:</p>
            <p>a) Obediência às determinações e obrigações legais;</p>
            <p>b) Defesa e proteção dos direitos ou propriedades da plataforma;</p>
            <p>c) Zelar pela segurança pessoal de seus usuários ou do público em geral.</p>
            <p>A Seculus da Amazônia S.A poderá adicionar, alterar ou eliminar características, nomenclaturas, funcionalidades, ferramentas e outros aspectos no ambiente da Plataforma de Gestão de Postos Autorizados a qualquer momento e estes Termos continuarão a aplicar-se aos serviços modificados.</p>
            <p>Qualquer atualização nos Termos de Responsabilidade, quando necessário, com ou sem aviso prévio, será dada por meio de uma nova mensagem quando você realizar um novo login, sendo necessário uma nova assinatura virtual. A versão mais recente destes Termos pode ser revista, clicando em "Termos de Serviço", localizado na parte inferior das páginas da plataforma. A versão mais atual dos Termos substituirá todas as versões anteriores.</p>
            <p>Belo Horizonte, 30 de Abril de 2019. </p>
            <p>Seculus da Amazônia Ind. e Com. S.A </p>
           </div>
          <div className="fixed-bottom termos">
            <label className="checkbox mt-1">
              <input
                type="checkbox"
                style={{ width: 0, height: 0 }}
                onClick={this.toggleCheckBox}
              />
              <i className="icon-checkbox" onClick={this.markAsRead} />
              Concordo com todo termo acima e me responsabilizo em cumprir todo
              o contrato.
            </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(RegisterThirdStep);
