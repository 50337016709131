//@flow
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';

export const colaboradorService = {
  findByFiltro,
  find,
  doSave,
  getById,
  doGet,
  getHistoricoForUser
};
function doGet(url) {
  return defaultService.doGet(url);
}
function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.USER, entity);
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.USER}list`,
    options,
    filter
  );
}

function find(input) {
  defaultService.doGet(`${urlsConstants.FIND_USER}${input}`);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.USER}${id}`);
}

function getHistoricoForUser(id) {
  return defaultService.doGet(`${urlsConstants.HISTORICO_USER}${id}`);
}
