import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { analiseFalhasService } from '../../../services/analiseFalhas.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class AnaliseFalhasDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { totalRelogio: 0 ,totalOs:0};
  }

  updateItem = filter => {
    analiseFalhasService
      .findChartResult(
        { goupByItem: 'relogio.codigoRelogio', countDistinct: true },
        filter
      )
      .then(response => {
        let data = response.data;
        let totalRelogio = 0;
        data.data.map((item, i) => {
          totalRelogio += item.count;
        });
        this.setState({
          totalRelogio
        });
      });
      analiseFalhasService
      .findChartResult(
        { goupByItem: 'os.id', countDistinct: true },
        filter
      )
      .then(response => {
        let data = response.data;
        let totalOs = 0;
        data.data.map((item, i) => {
          totalOs += item.count;
        });
        this.setState({
          totalOs
        });
      });
  };
  componentDidMount() {
    this.updateItem(this.props.filter);
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
          <div className="col flex-fill text-center">
            <div className="miniTxt">Total de sintomas</div>
            <p>{this.props.totalSintomas}</p>
          </div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Total de relógios</div>
            <p>{this.state.totalRelogio}</p>
          </div>
          <div className="col flex-fill text-center">
            <div className="miniTxt">Total de Ordem de Serviços</div>
            <p>{this.state.totalOs}</p>
          </div>
        </div>
        <hr />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  return {
    totalSintomas: subTitle
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true }
)(AnaliseFalhasDetails);
