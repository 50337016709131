import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup, Label } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';

type Props = {
  label: string,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onBlur: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength: number,
  style: any
};
export class ViewOnly extends PureComponent<Props> {
  static defaultProps = {
    size: 12
  };

  handleBorderColor = () => {
    const { value } = this.props;
  };

  render() {
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    const value = this.props.value;
    const { messageValidate } = this.props;
    return (
      <FormGroup className={`${colSize} ${this.props.style}`}>
        {this.props.label && (
          <Label for={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}
        <p className="mt-10">
          <i>{this.props.value ? this.props.value : this.props.placeholder}</i>
        </p>
      </FormGroup>
    );
  }
}
