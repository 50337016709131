import _ from 'lodash';
import React, { PureComponent } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { propertyConstantes } from '../../constants/property.constantes';
import { ordemServicoService } from '../../services/ordemServico.service';
import  CardPecaItem  from './CardPecaItem';
import { CardPecaItemView } from './CardPecaItemView';
import { PaCardPecaItem } from './PaCardPecaItem';
import { PaCardPecaItemView } from './PaCardPecaItemView';

type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

export class CardPecaComponente extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    if (props.listPecaOrdemServico && props.listPecaOrdemServico.length > 0) {
      const pecas = _.map(props.listPecaOrdemServico, function(peca, index) {
        return { posicao: index, peca: _.clone(peca) };
      });
      this.state = {
        pecas: pecas,
        idOs: props.ordemServico.id
      };
      props.onAddPeca(pecas);
    } else {
      this.state = {
        pecas: [{ posicao: 0, peca: { valorFinal: 0 } }],
        idOs: props.ordemServico.id
      };
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.listPecaOrdemServico &&
      nextProps.listPecaOrdemServico.length > 0
    ) {
      if (nextProps.ordemServico.id != this.state.idOs) {
        this.setState(
          {
            pecas: _.map(nextProps.listPecaOrdemServico, function(
              servico,
              index
            ) {
              return { posicao: index, peca: _.clone(servico) };
            }),
            idOs: nextProps.ordemServico.id
          },
          () => {
            nextProps.onAddPeca(this.state.pecas);
          }
        );
      }
    } else {
      if (nextProps.ordemServico.id != this.state.idOs) {
        this.setState(
          {
            pecas: [{ posicao: 0, peca: {} }],
            idOs: nextProps.ordemServico.id
          },
          () => {
            nextProps.onAddPeca(this.state.pecas);
          }
        );
      }
    }
  }

  handleChangeItem = (posicao: any, newItem: any) => {
    const { pecas } = this.state;
    let newEntity = _.cloneDeep(pecas);

    //add item in object
    if (newItem) {
      newEntity = _.map(pecas, function(item) {
        return item.posicao === posicao ? { posicao, peca: newItem } : item;
      });
    } //remove item from array
    else {
      if (newEntity.length > 1) {
        _.remove(newEntity, function(currentObject) {
          return currentObject.posicao === posicao;
        });
      } else {
        //alerta de erro
      }
    }
    this.setState({ pecas: newEntity }, () => {
      this.props.onAddPeca(this.state.pecas);
    });
  };

  setService = () => {};

  itemRow = (peca, posicao) => {
    if (this.props.osType === propertyConstantes.OS) {
      return (
        <CardPecaItem
          key={posicao}
          id={posicao}
          entity={peca.peca}
          submit={this.props.submit}
          idOs={this.state.idOs}
          ordemServico={this.props.ordemServico}
          cliente={this.props.ordemServico.cliente}
          onChange={this.handleChangeItem}
          onRemovePeca={this.removePeca}
        />
      );
    } else {
      return (
        <PaCardPecaItem
          key={posicao}
          id={posicao}
          entity={peca.peca}
          submit={this.props.submit}
          idOs={this.state.idOs}
          ordemServico={this.props.ordemServico}
          cliente={this.props.ordemServico.cliente}
          onChange={this.handleChangeItem}
          onRemovePeca={this.removePeca}
        />
      );
    }
  };

  addRow = () => {
    this.addItem();
  };
  removePeca = (index, id) => {
    let pecas = _.map(this.state.pecas, _.clone);
    pecas.splice(index, 1);
    this.setState({ pecas: pecas }, () => {
      this.props.onAddPeca(this.state.pecas);
    });
  };
  addItem() {
    let pecas = _.map(this.state.pecas, _.clone);
    pecas.push({ posicao: this.state.pecas.length, peca: {} });
    this.setState({ pecas: pecas });
  }

  viewPeca = (peca, posicao) => {
    if (this.props.osType === propertyConstantes.OS) {
      return <CardPecaItemView key={posicao} entity={peca} ordemServico={this.props.ordemServico} />;
    } else {
      return <PaCardPecaItemView key={posicao} entity={peca} />;
    }
  };
  editOs = () => {
    ordemServicoService.confirmChangeStatus(this.props.onConfirmChangeStatus);
  };
  getBotaoAddPeca = () => {
    if (
      this.props.statusOS === propertyConstantes.CENTRO_TECNICO ||
      this.props.statusOS === propertyConstantes.APROVAR_ORCAMENTO
    ) {
      if (this.props.statusOS === propertyConstantes.APROVAR_ORCAMENTO) {
        return (
          <div className="text-right py-2" onClick={this.editOs}>
            <span className="icon-badge">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>{' '}
            <a >Editar </a>
          </div>
        );
      } else {
        return (
          <div className="text-right py-2" onClick={this.addRow}>
            <span className="icon-badge">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
            </span>{' '}
            <a >peças </a>
          </div>
        );
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-column col-12 pt-2">
          <div className="miniTxt mb-1">Solicitação de peças</div>
          {this.state.pecas.map((peca, index) => {
            if (this.props.statusOS === propertyConstantes.CENTRO_TECNICO) {
              return this.itemRow(peca, index);
            } else {
              if (peca && peca.peca && peca.peca.peca) {
                return this.viewPeca(peca, index);
              }
            }
          })}

          {this.getBotaoAddPeca()}
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(CardPecaComponente);
