//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import CpfInput from '../../components/Inputs/CpfInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import FormInput from '../../components/Inputs/FormInput';
import RoleSelectInput from '../../components/Inputs/RoleSelectInput';
import Toast from '../../components/Toasts/Toast';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { objectsConstants } from '../../constants/objects.constants';
import { colaboradorService } from '../../services/colaborador.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import PhoneInput from '../../components/Inputs/PhoneInput';
import SelectInput from '../../components/Inputs/SelectInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { userService } from '../../services/user.service';

// type Props = { titlePage: any };
type State = { initialValues: any };

const NewColaborador = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  email: Yup.string().required('E-mail é obrigatório'),
  cpf: Yup.string().required('Celular é obrigatório'),
  celular: Yup.string().required('CPF é obrigatório'),
  dataNascimento: Yup.string().required('Data de Nascimento é obrigatório'),
  confirmPassword: Yup.string().test(
    'valid-confirmPassword',
    'As Senhas não correspondem',
    function() {
      const { parent, createError } = this;
      if (
        parent.id == undefined &&
        parent.password !== parent.confirmPassword
      ) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  password: Yup.string().test(
    'valid-password',
    'Senha é obrigatório',
    function() {
      const { parent, createError } = this;
      if (parent.id == undefined && parent.password === undefined) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  role: Yup.string().test('valid-role', 'Perfil é obrigatório', function() {
    const { parent, createError } = this;
    if (parent.id == undefined && parent.role === undefined) {
      return createError();
    } else {
      return true;
    }
  })
});

class ColaboradorCrudEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        name: '',
        email: '',
        cpf: '',
        celular: '',
        dataNascimento: '',
        status: true
      },
      colaborador: {}
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.colaborador) {
      let colaborador = this.props.location.state.colaborador;
      if (colaborador.dataNascimento) {
        colaborador.dataNascimento = moment(colaborador.dataNascimento);
      }
      this.setState({ initialValues: colaborador });
    }
  }

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  getDataUrl = entity => {
    if (entity.foto && entity.foto.id == undefined && entity.foto.data) {
      return entity.foto.data;
    }
  };

  isAdminDinamico(values: any) {
    let permissions = this.props.permissions;
    let isAdmin =
      !values.id ||
      permissions.can(
        objectsConstants.VIEW,
        objectsConstants.ABILITY_ALTERAR_PERFIL
      )
        ? true
        : false;
    return isAdmin;
  }

  render() {
    const { colaborador } = this.state;

    let titlePage;
    if (this.props.location.state) {
      titlePage = this.props.location.state.titlePage;
    }

    return (
      <Formik
        validationSchema={NewColaborador}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          colaboradorService
            .doSave(values)
            .then(response => {
              actions.setSubmitting(false);
              this.props.success({ message: 'Colaborador salvo com sucesso.' });
              this.props.loading(false);
              this.props.history.push('/colaboradorcrud');
            })
            .catch(() => {
              actions.setSubmitting(false);
              this.props.loading(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle={this.props.location.state.titlePage}
                subTitle={
                  values.id != undefined ? ' Editar dados' : 'Novo colaborador'
                }
                canBack={true}
              />
              {!_.isEmpty(errors) &&
                this.showError(objectsConstants.messageCampoObrigatorio)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">colaborador</h3>
                </div>

                <div className="form-row">
                  <UploadPictureRegister
                    name="foto"
                    onChange={this.changeImage}
                    onChange={imageVO => setFieldValue('foto', imageVO.image)}
                    idImage={values.foto ? values.foto.id : null}
                    dataUrl={this.getDataUrl(values)}
                    id="foto"
                    maxHeight={200}
                    maxWidth={200}
                    height={100}
                  />
                  {this.isAdminDinamico(values) && (
                    <div className="form-group col-12 col-sm-5">
                      <label>Perfil *</label>
                      <RoleSelectInput
                        id={'role'}
                        returnFullObject={false}
                        noSize={true}
                        value={values.role}
                        onChange={(name, value) => setFieldValue('role', value)}
                        erroMensagem={errors.role}
                      />
                    </div>
                  )}
                    
                  {console.log(userService.getCurrentUser().extension)}
                  {values.role && !userService.getCurrentUser().extension && (values.role.name == objectsConstants.PERFIL_ATENDENTE || values.role.name == objectsConstants.PERFIL_RESPONSAVEL || values.role.name == objectsConstants.PERFIL_TECNICO) && (
                    <SelectInput
                      type="text"
                      name="postoAutorizado"
                      label="Autorizada"
                      placeholder="Autorizada"
                      style={['col-2 col-sm-3']}
                      onFetchData={postoAutorizadoService.find}
                      valueKey="id"
                      labelKey="nomeFantasia"
                      returnFullObject={true}
                      searchable={true}
                      id={'posto'}
                      onChange={setFieldValue}
                      value={values.postoAutorizado}
                    />
                  )}

                  <div className="form-group col-12 col-lg-12">
                    <label>Nome*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.name &&
                        touched.name &&
                        'error'}`}
                      name="name"
                      placeholder="Digite o nome completo"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-12 col-lg-12">
                    <label className="title">CPF*:</label>
                    <CpfInput
                      placeholder="Digite o CPF"
                      erroMensagem={errors.cpf}
                      name="cpf"
                      id="cpf"
                      value={values.cpf}
                      onChange={(name, value) => setFieldValue('cpf', value)}
                    />
                  </div>

                  <div className="form-group col-12 col-lg-12 ">
                    <label>E-mail *</label>
                    <FormInput
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Digite o e-mail"
                      value={values.email}
                      onChange={handleChange}
                      noSize={true}
                      erroMensagem={errors.email}
                    />
                  </div>

                  <div className="form-group col-6 col-lg-6 ">
                    <label>Celular </label>
                    <PhoneInput
                      type="number"
                      name="celular"
                      placeholder="Digite o celular"
                      erroMensagem={errors.celular}
                      required={true}
                      id={'celular'}
                      ref={input => {
                        this._celular = input;
                      }}
                      onChange={handleChange}
                      value={values.celular}
                      noSize={true}
                    />
                    {/* <input
                        type="text"
                        className={`form-control ${errors.celular &&
                          touched.celular &&
                          'error'}`}
                        name="celular"
                        placeholder="Digite o celular"
                        value={values.celular}
                        onChange={handleChange}
                      /> */}
                  </div>

                  <div className="form-group col-6 col-lg-6 ">
                    <label>Data de Nascimento *</label>
                    <DatePickerInput
                      placeholder="-- / -- / ----"
                      erroMensagem={errors.dataNascimento}
                      name="dataNascimento"
                      id="dataNascimento"
                      value={values.dataNascimento}
                      //value={"02/21/2019"}
                      onChange={(name, value) =>
                        setFieldValue('dataNascimento', value)
                      }
                      noSize={true}
                    />
                  </div>

                  {!this.props.hidePassWord && (
                    <React.Fragment>
                      <FormInput
                        type="password"
                        name={'password'}
                        label={'Senha'}
                        placeholder={'Senha'}
                        messageValidate={errors.password}
                        required={true}
                        style={['col-6 col-lg-6']}
                        id={'password'}
                        onChange={handleChange}
                        value={values.password}
                      />
                      <FormInput
                        type="password"
                        name={'confirmPassword'}
                        label={'Confirmar Senha'}
                        placeholder={'Confirmar Senha'}
                        messageValidate={errors.confirmPassword}
                        required={true}
                        style={['col-6 col-lg-6']}
                        id={'confirmPassword'}
                        onChange={handleChange}
                        value={values.confirmPassword}
                      />
                    </React.Fragment>
                  )}

                  {/* <div className="form-group col-6 col-lg-4 ">
                    <label>CEP*</label>
                    <ZipCodeInput
                      type="number"
                      id={'cep'}
                      className={`${errors.endereco &&
                        errors.endereco.cep &&
                        touched.endereco &&
                        touched.endereco.cep &&
                        'error'}`}
                      name="endereco.cep"
                      size={100}
                      placeholder="Somente números"
                      value={values.endereco.cep}
                      onChange={handleChange}
                    />
                  </div>

                  <PaisInput
                    handleSelectChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    name="endereco.pais"
                    value={values.endereco.pais}
                    style={'col-6 col-lg-4'}
                    className={`${errors.endereco &&
                      errors.endereco.pais &&
                      touched.endereco &&
                      touched.endereco.pais &&
                      'error'}`}
                    required={true}
                  />

                  <EstadoInput
                    handleSelectChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    name="endereco.estado"
                    value={values.endereco.estado}
                    style={'col-6 col-lg-4'}
                    parentValue={values.endereco.pais}
                    className={`${errors.endereco &&
                      errors.endereco.estado &&
                      touched.endereco &&
                      touched.endereco.estado &&
                      'error'}`}
                    required={true}
                  />

                  <div className="form-group col-12 col-lg-8 ">
                    <label>Rua*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.endereco &&
                        errors.endereco.rua &&
                        touched.endereco &&
                        touched.endereco.rua &&
                        'error'}`}
                      name="endereco.rua"
                      placeholder="Rua"
                      value={values.endereco.rua}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-4 col-lg-4 ">
                    <label>Número*</label>
                    <input
                      type="number"
                      className={`form-control ${errors.endereco &&
                        errors.endereco.numero &&
                        touched.endereco &&
                        touched.endereco.numero &&
                        'error'}`}
                      name="endereco.numero"
                      placeholder="Número"
                      value={values.endereco.numero}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-8 col-lg-4 ">
                    <label>Bairro*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.endereco &&
                        errors.endereco.bairro &&
                        touched.endereco &&
                        touched.endereco.bairro &&
                        'error'}`}
                      name="endereco.bairro"
                      placeholder="Bairro"
                      value={values.endereco.bairro}
                      onChange={handleChange}
                    />
                  </div>

                  <CidadeInput
                    handleSelectChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    name="endereco.cidade"
                    value={values.endereco.cidade}
                    style={'col-6 col-lg-4'}
                    parentValue={values.endereco.estado}
                    className={`${errors.endereco &&
                      errors.endereco.cidade &&
                      touched.endereco &&
                      touched.endereco.cidade &&
                      'error'}`}
                    required={true}
                  />

                  <div className="form-group col-6 col-lg-4 ">
                    <label>Complemento</label>
                    <input
                      type="text"
                      className="form-control"
                      name="endereco.complemento"
                      placeholder="Complemento"
                      value={values.endereco.complemento}
                      onChange={handleChange}
                    />
                  </div> */}

                  {/* {values.telefones.map((value, index) => (
                    <React.Fragment key={index}>
                      <TipoTelefoneInput
                        handleChange={handleChange}
                        name={`telefones[${index}].tipo`}
                        value={value.tipo}
                      />

                      <div className="form-group col-5 col-lg-4">
                        <label>Número Telefone *</label>
                        <PhoneInput
                          type="number"
                          id={`Phone${index}`}
                          className="form-control"
                          size={100}
                          name={`telefones[${index}].numero`}
                          placeholder="Somente números"
                          value={value.numero}
                          onChange={handleChange}
                          className={`${errors.telefone && 'error'}`}
                        />
                      </div>

                      <div className="form-group col-3 col-lg-4 d-flex align-items-end justify-content-center">
                        <div className="text-right pb-2">
                          <span className="icon-badge">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                          </span>{' '}
                          <a
                            onClick={() =>
                              this.addTelefone(setFieldValue, values.telefones)
                            }
                          >
                            Telefone{' '}
                          </a>
                        </div>
                      </div>
                    </React.Fragment>
                  ))} */}
                </div>

                <div className="d-flex flex-row flex-sm-row ">
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn30 white"
                      disabled={isSubmitting}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="submit"
                      className="btn btn-secondary btn30 green"
                      disabled={isSubmitting}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

function mapStateToProps(state) {
  const { permissions, user } = state.authentication;
  return {
    permissions,
    user
  };
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
  // colaboradorSet: { setColaborador }
}) => ({
  loading: (load: boolean) => loading({ load }),
  // setColaborador: colaborador => setColaborador({ colaborador }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(ColaboradorCrudEdit);
