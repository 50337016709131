//@flow
import * as React from 'react';
import { enderecoService } from '../../services/endereco.service';
import SelectInput from './SelectInput';

export default class CidadeInput extends React.Component<{
  handleSelectChange: Function<any>,
  value: string,
  name: string,
  style?: string,
  size?: number,
  parentValue: any,
  required: boolean
}> {
  render() {
    return (
      <SelectInput
        type="text"
        name={this.props.name}
        id={this.props.id}
        label="Cidade"
        placeholder="Cidade"
        onFetchData={enderecoService.findCidade}
        valueKey="id"
        labelKey="nome"
        required={this.props.required}
        style={this.props.style}
        size={this.props.size}
        returnFullObject={true}
        searchable={true}
        onChange={this.props.handleSelectChange}
        value={this.props.value}
        parent={this.props.parentValue ? this.props.parentValue : { id: 0 }}
        className={`${this.props.className}`}
      />
    );
  }
}
