//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Pie } from 'react-chartjs-2';
import connect from 'react-redux/es/connect/connect';
import Datatable from '../../../components/table/Datatable';
import { urlsConstants } from '../../../constants/url.constant';
import { tableHelper } from '../../../helpers/tableHelper';
import { ordemServicoService } from '../../../services/ordemServico.service';
import { relatorioService } from '../../../services/relatorio.service';
import { relatorioRepresamentoPreReparoService } from '../../../services/relatorioRepresamentoPreReparo.service';
import RepresamentoPreReparoChartHistory from './RepresamentoPreReparoChartHistory';
import HeaderList from '../../../components/templates/HeaderList';
import { Redirect } from 'react-router-dom';

const columns = [
  {
    path: 'numeroOS',
    title: 'Número da OS',
    isKey: true,
    dataSort: true,
    className: 'colunaDestaque'
  },
  {
    path: 'doc',
    title: 'Número do DOC',
    isKey: false,
    dataSort: true
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código Relogio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'marcaOS',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'posto.nomeFantasia',
    title: 'Autorizada',
    isKey: false,
    dataSort: true
  },
  {
    path: 'dataCriacao',
    title: 'Data de Criação',
    isDate: true,
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'dataStatusAtual',
    title: 'Data do último status',
    isKey: false,
    isDate: true,
    dataSort: true
  },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: true
  },
  {
    path: 'dias',
    title: 'Dias',
    isKey: false,
    dataSort: true
  }
];

class RelatorioRepresamentoPreReparoChart extends React.PureComponent<{}> {
  constructor(props) {
    super(props);
    this.state = { filter: {}, data: [] };
  }

  updateChart = filter => {
    this.props.loading(true);
    let filterHistory = _.cloneDeep(filter);
    filterHistory.somenteEmAberto = true;
    relatorioService.represamentoPreReparo(filterHistory).then(
      result => {
        this.setState({ data: result.data, filter });
        this.props.loading(false);
      },
      () => {
        this.props.loading(false);
      }
    );
    this.props.loading(true);
    relatorioService.represamentoPreReparo(filter).then(
      result => {
        this.setState({ dataHistory: result.data });
        this.props.loading(false);
      },
      () => {
        this.props.loading(false);
      }
    );
  };

  onClickLegend = (e, legendItem) => {
    const filter = _.cloneDeep(this.state.filter);

    _.set(filter, 'index', legendItem.index);

    this.setState({ filter });
    if (this.refs.dataTable) {
      this.refs.dataTable.getWrappedInstance().updateTable();
    }
  };

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.entity) {
      this.setState({ filter: nextProps.entity }, () => {
        console.log(this.state.entity);
      });
    }
  }

  setClass(obj, teste) {
    obj.currentTarget.style.cursor = 'pointer';
  }

  handleRowClick = (row: e) => {
    this.props.loading(true);
    let urlOS = urlsConstants.ORDEM_SERVICO + row.id;
    ordemServicoService.doGet(urlOS).then(response => {
      this.props.loading(false);
      let os = response.data.data;
      this.props.setOrdemServico(os);
      this.props.setCliente(os.cliente);

      this.setState({
        redirect: true,
        id: row.id
      });
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={`/ordemServico/view/${this.state.id}`} />;
    }
    let data = null;
    let data25Dias = null;
    let totalReparos = null;
    console.log(this.state.data);
    if (this.state.data && this.state.data.historico) {
      totalReparos =
        this.state.data.atual.qtdOsMais25Dias +
        this.state.data.atual.qtdOsMais15Dias +
        this.state.data.atual.qtdOsMais5Dias +
        this.state.data.atual.qtdOsMenos5Dias;
      console.log(this.state.data.atual.qtdOsMenos5Dias);
      console.log(this.state.data.atual.qtdOsMais5Dias);
      console.log(this.state.data.atual.qtdOsMais15Dias);
      console.log(this.state.data.atual.qtdOsMais25Dias);
      data25Dias = {
        labels: [
          'OSs em aberto acima de 25 dias',
          'OSs em aberto acima de 15 dias e menos de 25 dias',
          'OSs em aberto acima de 5 dias e menos de 15 dias',
          'OSs em aberto menos de 5 dias',
          'Todas as OSs'
        ],
        datasets: [
          {
            data: [
              this.state.data.atual.qtdOsMais25Dias,
              this.state.data.atual.qtdOsMais15Dias,
              this.state.data.atual.qtdOsMais5Dias,
              this.state.data.atual.qtdOsMenos5Dias,
              0 // totalOS data = 0 para que não haja interferência no pieChart
            ],
            backgroundColor: ['red', 'yellow', 'green', 'blue', 'purple'],
            hoverBackgroundColor: ['red', 'yellow', 'green', 'blue', 'purple']
          }
        ]
      };
    }
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });

    return (
      <React.Fragment>
        <RepresamentoPreReparoChartHistory data={this.state.data.historico} />

        <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
          <div className="col flex-fill">
            <h3 className="flex-grow-1">Represamento atual</h3>
          </div>

          <div className="col flex-fill text-center">
            <div className="miniTxt">Total</div>
            <p>{totalReparos}</p>
          </div>
        </div>
        {data25Dias && (
          <Pie
            data={data25Dias}
            height={50}
            options={{
              responsive: true,
              legend: {
                display: true,
                position: 'left',
                onClick: this.onClickLegend,
                onHover: this.setClass,
                labels: {
                  usePointStyle: true
                }
              },
              tooltips: {
                enabled: true
              }
            }}
          />
        )}

        {this.state.filter && this.state.filter.index != null && (
          <React.Fragment>
            <div className="row separator mt-5 " />
            <div className="row pb-2 " />
            <HeaderList
              title={''}
              showNewButton={false}
              filter={this.state.filter}
              controller={'relatorio/represamentoPreReparo'}
              fields={fields}
              hideExport={false}
            />
            <Datatable
              defaultOrder={this.props.defaultOrder}
              defaultDirection={this.props.defaultDirection}
              service={relatorioRepresamentoPreReparoService}
              filter={this.state.filter}
              onRowClick={this.handleRowClick}
              submitFilter={this.state.submitFilter}
              ref="dataTable"
            >
              {tableColumn}
            </Datatable>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load })
});
export default connect(null, mapDispatch, null, { withRef: true })(
  RelatorioRepresamentoPreReparoChart
);
