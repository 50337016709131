//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../constants/objects.constants';
import relogioBG from '../../img/relogioBG.png';
import { midiaService } from '../../services/midiaService';
import EscolherRelogioTrocaBtn from '../cliente/Componentes/EscolherRelogioTrocaBtn';
import { Can } from '@casl/react';
import { ordemServicoService } from '../../services/ordemServico.service';
import FotoRelogio from '../../components/FotoRelogio';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';

class EscolherRelogioTroca extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  showError = () => {
    this.props.error({ message: objectsConstants.messageCampoObrigatorio });
    return '';
  };

  saveTrocaModelo = () => {
    const { ordemServico, relogio } = this.props;
    this.props.loading(true);
    ordemServicoService
      .doSaveRelogioTrocaOrdemServico(relogio.id, ordemServico.id)
      .then(
        response => {
          let newEntity = response.data.data;
          this.props.setOrdemServico(newEntity);
          this.props.loading(false);
          this.props.success({
            message: 'Modelo escolhido com sucesso.'
          });
        },
        error => {
          this.props.error('Erro ao escolher modelo.');
        }
      );
  };

  render() {
    const { relogio, permissions, valor,caixa } = this.props;

    return (
      <React.Fragment>
        <form
          noValidate
          className="row conteudoTab d-flex flex-column flex-lg-row "
        >
          <div className="flex-column col-12 col-md-3 text-center">
            <FotoRelogio relogio={relogio ? relogio : null} width={'70%'} />
          </div>
          <div className="flex-column col-12 col-md-10">
            <div className="form-row pt-5">
              <div className="form-group col-12 col-sm-6">
                <label className="miniLabel">Código do relógio</label>
                <p>{relogio.codigoRelogio}</p>
              </div>
              <div className="form-group col-12 col-sm-6">
                <label className="miniLabel">Marca</label>
                <p>
                  <strong>{relogio.marca.nome}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <EscolherRelogioTrocaBtn
              onAprove={this.saveTrocaModelo}
              modelo={relogio.codigoRelogio}
            />
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Valor</label>
            <p>
              <strong>
                {valor
                  ? ordemServicoHelper.formatMoney(
                      parseFloat(valor),
                      2,
                      ',',
                      '.'
                    )
                  : 'R$ 0,00'}
              </strong>
            </p>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Tamanho da Caixa</label>
            <p>
              <strong>
               {caixa}
              </strong>
            </p>
          </div>
        </form>
        <hr />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: msg => error(msg)
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(EscolherRelogioTroca);
