//@flow
import * as React from 'react';
import CardCliente from '../../components/Cards/CardCliente';
import connect from 'react-redux/es/connect/connect';
import type { ClienteType } from '../../types/cliente.type';
import { propertyConstantes } from '../../constants/property.constantes';

type Props = { cliente: ClienteType };

class OrdemServicoNew extends React.Component<Props> {
  render() {
    const { cliente } = this.props;
    const { garantia } = this.props;
    return (
      <React.Fragment>
        <div className="flex-column">
          <CardCliente cliente={cliente} />
        </div>
        <div className="flex-column">
          <div className="card cardSeculus miniCard d-flex flex-column ">
            <div className="topoCard d-flex flex-column flex-sm-column" />
            <div className="conteudoCard">
              <div className="col-sm-12 d-flex flex-column align-items-center">
                <div className="justify-content-center mt-3">
                  <span className="icon-os_x icon-lg">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                    <span className="path7" />
                  </span>
                </div>
                <div className="justify-content-center mt-1">
                  <h3>Tipo de OS</h3>
                </div>
                <div className="col-10 justify-content-center mt-2">
                  <p className="text-center">
                    Clique em alguma opção abaixo para criar uma nova ordem
                  </p>
                </div>
                <button
                  type="button"
                  className="btn btn-primary my-3 mx-2"
                  onClick={() => {
                    this.props.createOrdemServico({
                      osType: propertyConstantes.OS
                    });
                    this.props.history.push('/ordemServico/novo');
                  }}
                >
                   Ordem de serviço
                </button>

                <button
                  type="button"
                  className="btn btn-secondary btn-sm mb-2"
                  onClick={() => {
                    this.props.createOrdemServico({
                      osType: propertyConstantes.PA
                    });
                    this.props.history.push('/ordemServico/novo');
                  }}
                >
                  Pronto atendimento
                </button>
                
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;

  return {
    cliente
  };
}

const mapDispatch = ({ ordemServico: { createOrdemServico } }) => ({
  createOrdemServico: osType => createOrdemServico(osType)
});

export default connect(
  mapStateToProps,
  mapDispatch
)(OrdemServicoNew);
