import { Can } from '@casl/react';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CardItem } from '../../components/templates/CardItem';
import Toast from '../../components/Toasts/Toast';
import { objectsConstants } from '../../constants/objects.constants';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import ColaboradorView from '../colaboradores/ColaboradorView';
import PostoAutorizadoFormView from '../postoAutorizado/PostoAutorizadoFormView';
import RegisterFinalize from '../register/RegisterFinalize';
import CardFinanceiro from '../../components/Cards/CardFinanceiro';
import TaskView from '../task/TaskView';

type Props = {};
class HomePostoAutorizado extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    postoAutorizadoService.doGet(this.props.user.extension).then(response => {
      let posto = response.data.data;
      this.props.loadCompany({ company: posto });
    });
  }
  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        {!this.props.company.aprovado && <RegisterFinalize />}
        {this.props.company.aprovado && (
          <React.Fragment>
            <Toast />
            <div className="flex-column">
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ROLE_POSTO}
                ability={permissions}
              >
                <CardItem>
                  <PostoAutorizadoFormView
                    entity={this.props.company}
                    readOnly={true}
                  />
                </CardItem>
              </Can>
              <ColaboradorView />
            </div>
            <div className="flex-column">
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_FINANCEIRO}
                ability={permissions}
              >
                <CardFinanceiro postoAutorizado={this.props.company} />
              </Can>
              <BoxOrdemServico postoAutorizado={this.props.company} />
              <TaskView />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, company, permissions } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages,
    company,
    permissions
  };
}

const mapDispatch = ({ authentication: { loadCompany } }) => ({
  loadCompany: company => loadCompany(company)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(HomePostoAutorizado);
