import * as React from 'react';
import connect from "react-redux/lib/connect/connect";
import TopoTitleComponente from "../Topo/TopoTitleComponente";
import Toast from "../../components/Toasts/Toast";
import CardCliente from "../../components/Cards/CardCliente";
import BoxOrdemServico from "../boxOrdemServico/BoxOrdemServico";
import CardClienteOrdemServico from "../../components/Cards/CardClienteOrdemServico";
import RepresamentoPreReparoSemanal from "./represamentoPreReparoSemanal/RepresamentoPreReparoSemanal";

class Dashboard extends React.PureComponent<Props, State> {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <React.Fragment>
                <TopoTitleComponente
                    mainTitle="Painel de Situação"
                    subTitle={'Painel de Situação'}
                    canBack={true}
                />
                <Toast/>
                <div className="container-fluid">
                    <div className="flex-column">

                        <RepresamentoPreReparoSemanal/>



                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    const {subTitle} = state.title;

    return {
        subTitle
    };
}

export default connect(
    mapStateToProps,
    null
)(Dashboard);
