//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import RedirectCrud from '../../components/Buttons/RedirectCrud';
import SelectInput from '../../components/Inputs/SelectInput';
import NovaOsModal from '../../components/Modals/NovaOsModal';
import { objectsConstants } from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { dateHelper } from '../../helpers/date.helper';
import { clienteService } from '../../services/cliente.service';
import { ordemServicoService } from '../../services/ordemServico.service';
import { userService } from '../../services/user.service';
// import * as Yup from 'yup';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';

type State = {
  entity: PostoAutorizadoType,
  modal: boolean
};
class BoxOrdemServico extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let modal = false;
    let urlOs = '/ordemServico';
    // if (userService.isAdminManaus()) {
    //   urlOs = '/ordemServicoAdmin';
    // }
    this.state = { modal, totalOs: 0, search: {}, redirect: false, urlOs };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleSelectChange = (name: string, option: any) => {
    if (option && option.classe === 'OrdemServico') {
      this.props.loading(true);
      let urlOS = urlsConstants.ORDEM_SERVICO + option.id;
      ordemServicoService.doGet(urlOS).then(response => {
        let os = response.data.data;
        this.props.setOrdemServico(os);
        this.props.setCliente(os.cliente);
        this.props.history.push('/ordemServico/view/' + option.id);
        this.props.loading(false);
      });
    } else if (option && option.classe === 'Cliente') {
      this.props.loading(true);
      clienteService.getById(option.id).then(response => {
        let cliente = response.data.data;
        this.props.clearOS();
        this.props.setCliente(cliente);
        this.props.history.push('/cliente/view/' + option.id);
        this.props.loading(false);
      });
    }
  };

  componentDidMount() {
    this.findTotalOs(this.props.postoAutorizado);
  }
  componentWillReceiveProps(nexProps) {
    if (
      nexProps.postoAutorizado &&
      nexProps.postoAutorizado.id !== this.props.postoAutorizado.id
    ) {
      this.findTotalOs(nexProps.postoAutorizado);
    }
  }
  findTotalOs = postoAutorizado => {
    //TODO carregar os dados do servidor.
    let filter = {};
    if (postoAutorizado) {
      filter.idPosto = postoAutorizado.id;
    }
    ordemServicoService.getTotalOSMes(filter).then(response => {
      this.setState({ totalOs: response.data.data });
    });
  };

  handleNewOS = () => {
    this.setState({ redirect: true });
  };

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  render() {
    const { totalOs, search } = this.state;
    const { permissions } = this.props;

    return (
      <React.Fragment>
        <div
          className={`card cardSeculus ${
            this.props.hideReparadas ? 'miniCard mh-0' : ''
          } d-flex flex-column a1`}
        >
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">ordens de serviço</h3>
            {!this.props.hideReparadas && (
              <Can
                I={objectsConstants.VIEW}
                a={objectsConstants.ABILITY_CADASTRAR_OS}
                ability={permissions}
              >
                <div className="boxButon float-right d-sm-flex">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#novaOS"
                    onClick={this.toggle}
                  >
                    nova os
                  </button>
                </div>
              </Can>
            )}
          </div>
          <div className="flex-fill d-flex flex-column flex-sm-row">
            {!this.props.hideReparadas && (
              <div className="col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
                <div className="justify-content-center">
                  <span className="icon-os_x icon-lg">
                    <span className="path1 " />
                    <span className="path2 " />
                    <span className="path3 " />
                    <span className="path4 " />
                    <span className="path5 " />
                    <span className="path6 " />
                    <span className="path7 " />
                  </span>
                </div>
                <div className="justify-content-center pl-3">
                  <h2 className="destaqueTxtCard">{totalOs}</h2>
                  <h4 className="subDestaqueTxtCard">Ordens reparadas</h4>
                  <h4 className="subDestaqueTxtCard">
                    {dateHelper.currentMonthName()} {dateHelper.currentYear()}
                  </h4>
                </div>
              </div>
            )}
            <SelectInput
              type="text"
              name="os"
              label="Pesquise nome, CPF ou nº OS"
              placeholder="Digite o nome, CPF ou nº OS"
              required={false}
              style={[
                `${!this.props.hideReparadas ? 'col-sm-6' : 'col-sm-12'}`
              ]}
              onFetchData={ordemServicoService.find}
              valueKey="id"
              labelKey="nome"
              returnFullObject={true}
              searchable={true}
              //messageValidate={validate.portadorDeficiencia}
              id={'findOs'}
              onChange={this.handleSelectChange}
              value={search}
              helpText={'Digite o nome, CPF ou nº OS'}
            />
          </div>
          <RedirectCrud toPage={this.state.urlOs} />
        </div>
        <NovaOsModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  const { permissions } = state.authentication;
  return {
    subTitle,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setCliente, clearOS, setOrdemServico },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({})
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(BoxOrdemServico));
