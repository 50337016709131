//@flow
import * as React from 'react';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { relatorioService } from '../../../services/relatorio.service';
import RelatorioVolumesPorStatusFormFilter from './volumesPorStatusFormFilter';
import RelatorioVolumesPorStatusChart from './volumesPorStatusChart';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';

export default class RelatorioVolumesPorStatus extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="Volumes por status "
          canBack={false}
        />
        <CardRelatorio
          methodFindChart={relatorioService.volumesPorStatus}
          FormFilter={RelatorioVolumesPorStatusFormFilter}
          renderChartOnConstruct={true}
          Chart={RelatorioVolumesPorStatusChart}
          hideExport={true}
          defaultValue={'Mês/Ano'}
        />
      </React.Fragment>
    );
  }
}
