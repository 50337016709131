//@flow
import React, { PureComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../css/datePicker.css';
import InputMask from 'react-input-mask';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import moment from 'moment';
import { bool } from 'prop-types';

type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean,
};

class DatePickerInput extends PureComponent<Props> {
  _datePicker;
  static defaultProps = {
    required: false,
    size: 12,
  };

  handleBorderColor = (messageValidate) => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor,
    };
  };

  handleChange = (date: any) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, date);
    }
  };

  render() {
    const { messages, name } = this.props;

    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    let selectedValue = this.props.value ? moment(this.props.value) : null;
    return (
      <FormGroup
          className={`${this.props.noSize ? '' : colSize} ${this.props.style}`}
      >
        {this.props.label && (
          <Label for={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}

        <DatePicker
          disabled={this.props.disabled}
          id={this.props.id}
          name={this.props.name}
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
          selected={selectedValue}
          onChange={this.handleChange}
          required={this.props.required}
          locale="pt-br"
          style={{ borderColor: 'red' }}
          placeholderText={this.props.placeholder}
          onBlur={this.props.handleOnBlur}
          customInput={<InputMask mask="99/99/9999" value={this.props.value} />}
          popperPlacement={this.props.popperPlacement}
          maxDate={this.props.maxDate ? moment(this.props.maxDate) : null}
          minDate={this.props.minDate ? moment(this.props.minDate) : null}
        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert,
  };
}

export default connect(mapStateToProps)(DatePickerInput);
