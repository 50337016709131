//@flow
import * as React from 'react';
import { marcaService } from '../../../services/marca.service';
import SelectInput from '../../../components/Inputs/SelectInput';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import { objectsConstants } from '../../../constants/objects.constants';
import { Can } from '@casl/react';

import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import EstadoInput from '../../../components/Inputs/EstadoInput';
import CidadeInput from '../../../components/Inputs/CidadeInput';
type Props = { onChangeText: any, entity: any, onChangeSelect: any };
class RelatorioRepresamentoPreReparoFormFilter extends React.PureComponent<Props> {
  handleMesAno = (name, month, year) => {
    this.props.onChangeMonthYear(month, year, name);
  };

  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="-Selecione-"
          style={['col-12 col-sm-4']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <EstadoInput
            name="estado"
            value={entity.estado}
            style={'col-4 col-sm-2'}
            parentValue={{ id: 1 }}
            handleSelectChange={this.props.onChangeSelect}
            label="UF"
          />
          <CidadeInput
            name="cidade"
            value={entity.cidade}
            style={'col-4 col-sm-2'}
            parentValue={{ id: 1 }}
            handleSelectChange={this.props.onChangeSelect}
            parentValue={entity.estado}
          />
          <SelectInput
            type="text"
            name="postoAutorizado"
            label="Autorizada"
            placeholder="-Selecione-"
            style={['col-6 col-sm-4']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            //messageValidate={validate.portadorDeficiencia}
            id={'postoAutorizado'}
            onChange={this.props.onChangeSelect}
            value={entity.postoAutorizado}
          />
        </Can>
        <MonthPickerInput
          placeholder={'Mês/Ano'}
          name="inicio"
          label="Inicio Mês/Ano"
          onChange={this.handleMesAno}
          size={6}
          style={['col-6 col-sm-3']}
          id={'inicioAnoMes'}
          defaultValue={true}
        />
        <MonthPickerInput
          placeholder={'Mês/Ano'}
          name="fim"
          label="Fim Mês/Ano"
          onChange={this.handleMesAno}
          size={6}
          style={['col-6 col-sm-3']}
          id={'anoMes'}
          defaultValue={true}
        />
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps)(
  RelatorioRepresamentoPreReparoFormFilter
);
