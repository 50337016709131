//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {ImportacaoPecasListFilter, ImportacaoPecasValoresListFilter} from './ImportacaoPecasValoresListFilter';
import ImportacaoPecasModal from '../../components/Modals/ImportacaoPecasModal';
import { importacaoPecasService } from '../../services/importacaoPecasService';
import SocketConect from '../../components/SocketItem/SocketConect';
import FileInput from '../../components/Inputs/FileInput';
import {importacaoPecasValoresService} from "../../services/importacaoPecasValoresService";
import ImportacaoPecasValoresEstoqueModal from "../../components/Modals/ImportacaoPecasValoresEstoqueModal";

const columns = [
  {
    path: 'id',
    title: 'ID',
    isKey: true,
    dataSort: false,
    isDate: false,
  },
  {
    path: 'dataCriacao',
    title: 'Data importcação',
    isKey: false,
    dataSort: false,
    isDate: true,
  },
  {
    path: 'totalPecas',
    title: 'Total de Peças',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'totalPecasImportadas',
    title: 'Peças importadas',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'logFile',
    title: 'Log de importação',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'status.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    isStatus: 'status.className',
  },
];

type State = {
  modal: boolean,
};

class ImportacaoPecasValoresListagem extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      modal,
      fields,
    };
  }

  updateTable = () => {
    this._listagemPage.getWrappedInstance().updateTable();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  handleRowClick = (row: e) => {
    // this.props.history.push('/doc/view/' + row.id);
  };

  renderCustom(row: any, importacao: any, column: any) {
    if (column.path == 'logFile') {
      if (!importacao.logFile) {
        return '--';
      } else {
        return (
          <FileInput
            emptyLabel="Nenhum arquivo"
            name="logFile"
            entity={importacao}
            fileItem={importacao.logFile}
            readOnly={true}
          />
        );
      }
    }
    return tableHelper.renderCustom(row, importacao, column);
  }

  successCallbackModal = () => {
    console.log('successCallback');
    this.toggle();
    this.props.loading(false);
    this.props.success({
      message: 'Arquivo enviado com sucesso, aguarde a importação dos dados.',
    });
    this.updateTable();
  };
  errorCallbackModal = () => {
    console.log('errorCallbackModal');
    this.toggle();
    this.props.loading(false);
    this.props.error({
      message: 'Não foi possível enviar o arquivo.',
    });
  };

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let posto = this.props.user.extension
      ? { id: this.props.user.extension }
      : null;
    let defaultFilter = { endereco: {}, posto };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Importações de Peças"
          subTitle=" "
          canBack={false}
        />
        <SocketConect
          urlConect={'/service/importacao/0'}
          handleMessage={this.updateTable}
        />

        <ListagemPage
          title={`Importações de peças`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={importacaoPecasValoresService}
          labelNewButton="Nova Importação"
          // classSignal="temSinal"
          defaultOrder={'dataCriacao'}
          defaultDirection={'desc'}
          defaultFilter={defaultFilter}
          onRowClick={this.handleRowClick}
          FormFilter={ImportacaoPecasValoresListFilter}
          showNewButton={true}
          controller="Importacao"
          fields={this.state.fields}
          ref={(listagemPage) => (this._listagemPage = listagemPage)}
        >
          {tableColumn}
        </ListagemPage>

        <ImportacaoPecasValoresEstoqueModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
          successCallback={this.successCallbackModal}
          errorCallback={this.errorCallbackModal}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;
  const { user } = state.authentication;

  return {
    subTitle,
    mainTitle,
    user,
  };
}

const mapDispatch = ({
  alert: { success, error },
  load: { loading },
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  setCliente: (cliente) => setCliente({ cliente }),
  setOrdemServico: (ordemServico) => setOrdemServico({ ordemServico }),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(ImportacaoPecasValoresListagem);
