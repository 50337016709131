import * as React from 'react';
import { FormRow } from '../../components/FormRow';
import FormInput from '../../components/Inputs/FormInput';
import icon_alert from '../../img/icon_alert.svg';
import TaskRow from '../Cards/TaskRow';
import './TaskBox.css';
import _ from 'lodash';

type Props = {};
type State = {
  tasks: any,
  entity: any
};
export default class TaskBox extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: '' };
  }

  handleChangeFilter = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    this.setState({ entity: value }, () => {
      this.props.onChange( name, value);
    });
  };

  render() {
    return (
      <div className="cardSeculus">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">MINHAS TAREFAS</h3>
        </div>
        <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
          <div className="col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
            <div className="justify-content-center">
              <img src={icon_alert} width={70} height={70} alt="logo" />
            </div>
            <div className="justify-content-center pl-3">
              <h2 className="destaqueTxtCard">{this.props.taskListSize}</h2>
              <h4 className="subDestaqueTxtCard">TAREFAS PARA HOJE</h4>
            </div>
          </div>
          <div className="col-sm-6 d-flex align-items-center px-4">
            <div className="form-group">
              <FormRow>
                {
                  <FormInput
                    type="text"
                    name="description"
                    label="Pesquise por uma tarefa"
                    placeholder="Pesquise por uma tarefa"
                    size={12}
                    id={'description'}
                    ref={input => {
                      this._pesquisa = input;
                    }}
                    onChange={this.handleChangeFilter}
                    value={this.state.entity}
                  />
                }
              </FormRow>
            </div>
          </div>
        </div>
        {this.props.taskList &&
          this.props.taskList.map(task => (
            <TaskRow task={task} key={task.id} />
          ))}
        {this.props.children}
      </div>
    );
  }
}
