//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RelatorioVolumeTroca from './volumeTrocas/RelatorioVolumeTroca';
import ConsumoPecas from './consumoPecas/ConsumoPecas';
import ConsumoBaterias from './consumoBaterias/ConsumoBaterias';
import AnaliseFalhas from './analiseFalhas/AnaliseFalhas';
import AnaliseAtendimentoEpr from './analiseAtendimentoEpr/AnaliseAtendimentoEpr';
import GarantiaEstoque from './garantiaEstoque/GarantiaEstoque';
import OsTipoPessoa from './osTipoPessoa/OsTipoPessoa';
import PecasNaoAtendidasEpr from './pecasNaoAtendidasEpr/PecasNaoAtendidasEpr';
import PesquisaSatisfacao from './pesquisaSatisfacao/PesquisaSatisfacao';
import PesquisaSatisfacaoPosto from './pesquisaSatisfacao/PesquisaSatisfacaoPosto';
import OsExpirada from "./osExpiradas/OsExpirada";
import TrocaBateriaAjustePulseira from "./trocaBateriaAjustePulseira/TrocaBateriaAjustePulseira";
import AnaliseFalhasServico from './analiseFalhasServico/AnaliseFalhasServico';

export default class Report extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/report/volumetroca" component={RelatorioVolumeTroca} />
          <Route path="/report/consumoPecas" exact component={ConsumoPecas} />
          <Route
            path="/report/consumoBaterias"
            exact
            component={ConsumoBaterias}
          />
          <Route path="/report/analiseFalhas" exact component={AnaliseFalhas} />
        <Route path="/report/analiseFalhasServico" exact component={AnaliseFalhasServico} />
          <Route
            path="/report/analiseAtendimentoEpr"
            exact
            component={AnaliseAtendimentoEpr}
          />
          <Route
            path="/report/garantiaEstoque"
            exact
            component={GarantiaEstoque}
          />
          <Route
            path="/report/osExpirada"
            exact
            component={OsExpirada}
          />
          <Route path="/report/osTipoPessoa" exact component={OsTipoPessoa} />
          <Route
            path="/report/pecasNaoAtendidasEpr"
            exact
            component={PecasNaoAtendidasEpr}
          />

          <Route
            path="/report/pesquisaSatisfacao"
            exact
            component={PesquisaSatisfacao}
          />

          <Route
              path="/report/trocaBateriaAjustePulseira"
              component={TrocaBateriaAjustePulseira}
          />

          <Route
            path="/report/pesquisaSatisfacao/details/:id"
            exact
            component={PesquisaSatisfacaoPosto}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
