//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../constants/objects.constants';
import relogioBG from '../../img/relogioBG.png';
import { marcaService } from '../../services/marca.service';
import { midiaService } from '../../services/midiaService';
import { relogioService } from '../../services/relogio.service';

import TablePecas from './Components/TablePecas';
import SelectInput from '../../components/Inputs/SelectInput';
import { pecaService } from '../../services/peca.service';
import FotoRelogio from '../../components/FotoRelogio';

class RelogioContainerItem extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      values: {},
      listPecas: [],
      pecasAdded: [],
      marcaTerceiros: false
    };
  }

  showError = () => {
    this.props.error({ message: objectsConstants.messageCampoObrigatorio });
    return '';
  };

  handleSelectChange = (name: any, option: any) => {
    const { values } = this.state;
    let newEntity = _.cloneDeep(values);
    _.set(newEntity, name, option);
    this.setState({ values: newEntity }, () => {
      if (this.state.values.relogio) {
        pecaService
          .findByRelogioCompraPeca(this.state.values.relogio)
          .then(result => {
            console.log(result.data);
            this.setState({ listPecas: result.data });
          });
      }
    });
  };

  handleChangePecas = newPeca => {
    const { pecasAdded } = this.state;
    let newEntity = _.cloneDeep(pecasAdded);
    let marcaTerceiros = this.state.values.relogio.marca.marcaTerceiros;
    var existentePeca = newEntity.find(function(peca) {
      return peca.peca.id === newPeca.peca.id;
    });

    console.log('IS TERCEIRO', marcaTerceiros);
    //update object in array se já exitir
    if (existentePeca) {
      //se tem quantidade atualiza item no array
      if (parseInt(newPeca.quantidade) > 0) {
        newEntity = _.map(pecasAdded, function(item) {
          return item.peca.id === newPeca.peca.id
            ? {
                peca: item.peca,
                quantidade: newPeca.quantidade,
                valorCompra: marcaTerceiros
                  ? newPeca.valorCompra
                  : item.valorCompra,
                solicitarPeca: item.solicitarPeca,
                codigoPeca: newPeca.codigoPeca
              }
            : item;
        });
      } else {
        //se nao tem quantidadae de peca remove item do array
        _.remove(newEntity, function(currentObject) {
          return currentObject.peca.id === newPeca.peca.id;
        });
      }
    } else if (parseInt(newPeca.quantidade) > 0) {
      newEntity.push(newPeca);
    }

    this.setState({ pecasAdded: newEntity }, () => {
      this.props.onChange(this.props.posicao, {
        relogio: this.state.values.relogio,
        pecas: newEntity
      });
    });
  };

  isNotNullTerceiros() {
    const { marcaTerceiros } = this.state.relogio.value.marcaTerceiros;

    if (marcaTerceiros !== null) return marcaTerceiros;
  }

  render() {
    const { values } = this.state;

    return (
      <React.Fragment>
        <form
          noValidate
          className="row conteudoTab d-flex flex-column flex-lg-row pt-3"
        >
          <div className="flex-column col-12 col-md-4 text-center">
            <FotoRelogio
              relogio={values.relogio ? values.relogio : null}
              width={'70%'}
            />
          </div>
          <div className="flex-column col-12 col-md-6">
            <div className="form-row">
              <SelectInput
                type="text"
                id={'relogioInput'}
                name={'relogio'}
                label={`Código do relógio*`}
                placeholder="Código do relógio"
                onFetchData={relogioService.find}
                valueKey="codigoRelogio"
                labelKey="codigoRelogio"
                returnFullObject={true}
                searchable={true}
                onChange={this.handleSelectChange}
                value={values.relogio}
                style={['col-12 col-sm-12']}
                parent={values.marca}
              />
              <SelectInput
                type="text"
                name="marca"
                label="Marca"
                placeholder="Marca"
                required={true}
                style={['col-12 col-sm-12']}
                onFetchData={marcaService.find}
                valueKey="id"
                labelKey="nome"
                returnFullObject={true}
                searchable={true}
                id={'marca'}
                onChange={this.handleSelectChange}
                value={values.marca}
                value={
                  values.relogio && values.relogio.marca
                    ? values.relogio.marca
                    : values.marca
                }
              />
            </div>
          </div>
        </form>
        <hr />
        <div className="react-bs-table-container">
          {this.state.listPecas.length > 0 && (
            <TablePecas
              listPecas={this.state.listPecas}
              onSetPeca={this.handleChangePecas}
              marcaTerceiros={this.state.values.relogio.marca.marcaTerceiros}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;

  return {
    cliente
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: msg => error(msg)
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null
)(RelogioContainerItem);
