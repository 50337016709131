//@flow
import _ from 'lodash';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import RedirectCrud from '../../components/Buttons/RedirectCrud';
import SelectInput from '../../components/Inputs/SelectInput';
import { compraPecaService } from '../../services/compraPeca.service';
// import * as Yup from 'yup';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';

type State = {
  entity: PostoAutorizadoType,
  modal: boolean
};

class CardCompraDePecas extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let modal = false;
    this.state = { modal, totalOs: 0, search: {}, redirect: false };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleSelectChange = (name: string, option: any) => {
    if (option) {
      this.props.loading(true);
      compraPecaService.getById(option.id).then(response => {
        let ordemServico = response.data.data;
        this.props.setExtension(ordemServico.postoAutorizado.id);
        this.props.setOrdemServico(ordemServico);
        this.props.loading(false);
        this.props.history.push('/compraPeca/view');
      });
    }
  };

  componentDidMount() {
    //TODO carregar os dados do servidor.
    compraPecaService.getTotal().then(response => {
      this.setState({ totalOs: response.data.data });
    });
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  render() {
    const { totalOs, search } = this.state;
    const { permissions } = this.props;

    return (
      <React.Fragment>
        <div className="card cardSeculus d-flex flex-column a1">
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Compra de peças</h3>
          </div>
          <div className="flex-fill d-flex flex-column flex-sm-row">
            <div className="col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
              <div className="justify-content-center">
                <span className="icon-cpX icon-lg">
                  <span className="path1 " />
                  <span className="path2 " />
                  <span className="path3 " />
                  <span className="path4 " />
                  <span className="path5 " />
                  <span className="path6 " />
                  <span className="path7 " />
                </span>
              </div>
              <div className="justify-content-center pl-3">
                <h2 className="destaqueTxtCard">{totalOs}</h2>
                <h4 className="subDestaqueTxtCard">Compras Atendidas</h4>
              </div>
            </div>
            <SelectInput
              type="text"
              name="os"
              label="Pesquise uma compra"
              placeholder="Digite Posto, cidade ou nº CP"
              required={false}
              style={['col-sm-6']}
              onFetchData={compraPecaService.find}
              valueKey="id"
              labelKey="nome"
              returnFullObject={true}
              searchable={true}
              //messageValidate={validate.portadorDeficiencia}
              id={'findCP'}
              onChange={this.handleSelectChange}
              value={search}
              helpText={'Digite Posto, cidade ou nº CP'}
            />
          </div>
          <RedirectCrud toPage={'/compraPeca'} />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  const { permissions } = state.authentication;
  return {
    subTitle,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setCliente, clearOS, setOrdemServico },
  load: { loading },
  postoAutorizado: { setExtension }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  setExtension: (extension: number) => setExtension(extension)
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CardCompraDePecas));
