//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const importacaoPecasService = {
  findAll,
  findByFiltro,
  importarPecas,
};
function findAll() {
  return defaultService.doGet(`${urlsConstants.IMPORTACOES_FINDALL}`);
}
function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.IMPORTACOES_PECAS}list`,
    options,
    filter
  );
}
function importarPecas(archive: any) {
  return defaultService.doPost(
    urlsConstants.IMPORTACOES_PECAS_IMPORTAR,
    archive
  );
}
