//@flow
import * as React from 'react';

type Props = {};

export default class ExportExcelButton extends React.Component<Props> {
  render() {
    return (
      <button
        type="button"
        className="btn btn-secondary justify-content-start white"
        onClick={this.props.onClick}
      >
        <span className="icon-excel">
          <span className="path1" />
          <span className="path2" />
        </span>{' '}
        Exportar para excel
      </button>
    );
  }
}
