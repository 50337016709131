//@flow
import _ from 'lodash';
import * as React from 'react';
import MoneyInput from '../../components/Inputs/MoneyInput';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { postoValorAtendimentoGarantiaService } from '../../services/postoValorAtendimentoGarantiaService';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import { grupoMarcaService } from '../../services/grupoMarca.service';

class FormValoresGarantia extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: { valor: 0 }
    };
  }

  handleChangeValue = () => {
    let { entity } = this.state;
    const { filter } = this.props;
    entity.postoAutorizado = filter.postoAutorizado;
    entity.estado = filter.estado;
    entity.cidade = filter.cidade;
    this.props.loading(true);
    postoValorAtendimentoGarantiaService
      .saveValores(entity)
      .then(response => {
        if (this.props.updateTable) {
          this.props.updateTable();
        }
        this.props.success({ message: 'Valores alterados com sucesso.' });
      })
      .catch(() => {
        this.props.success('Não foi possível alterar os valores.');
      });
  };

  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };
  render() {
    const { entity } = this.state;
    const { filter } = this.props;
    let textAction = filter.postoAutorizado
      ? 'Alterar valores'
      : 'Alterar todos os valores';
    return (
      <React.Fragment>
        <div className="flex-fill d-flex flex-column flex-sm-row">
          <div className="col-12 col-sm-3 d-flex flex-row align-items-center pr-3 border-right">
            <div className="justify-content-center pl-3">
              <h2 className="destaqueTxtCard" />
              <h4 className="subDestaqueTxtCard">{textAction}</h4>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="form-row">
              <FormSelectInput
                name="grupoMarca"
                label="Grupo de Marcas"
                placeholder=""
                style={['col-6 col-sm-8']}
                returnFullObject={true}
                searchable={true}
                id={'grupoMarca'}
                ref={input => {
                  this._servico = input;
                }}
                onChange={this.handleSelectChange}
                value={entity.grupoMarca}
                service={grupoMarcaService.findAll}
                required={false}
              />
              <MoneyInput
                type="text"
                name="valor"
                label="Valor"
                placeholder="Valor"
                size={3}
                className={['col-6 col-sm-4']}
                returnFullObject={true}
                searchable={true}
                id={'valor'}
                ref={input => {
                  this._valor = input;
                }}
                onChange={this.handleSelectChange}
                value={entity.valor}
              />
            </div>
          </div>
          <div className="col-12 col-sm-1 d-flex flex-row align-items-center">
            <div className="form-row">
              <a
                href="#"
                disabled={entity.grupoMarca && true}
                onClick={() => {
                  ConfirmAcationService.confirmMsg(
                    this.handleChangeValue,
                    textAction,
                    'Você confirma que deseja ' +
                      textAction +
                      ' atendimento para o grupo de marca:',
                    entity.grupoMarca.nome +
                      ': ' +
                      ordemServicoHelper.formatMoney(
                        parseFloat(entity.valor),
                        2,
                        ',',
                        '.'
                      )
                  );
                }}
              >
                <img
                  className="icon"
                  src={require('../../img/btn_confirm.svg')}
                />{' '}
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(FormValoresGarantia));
