//@flow
import moment from 'moment';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { dateHelper } from '../../helpers/date.helper';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import RedirectCrud from '../Buttons/RedirectCrud';
import MonthPickerInput from '../Inputs/MonthPickerInput';
import { userService } from '../../services/user.service';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { ordemServicoService } from '../../services/ordemServico.service';
import { financeiroService } from '../../services/financeiroService';

type Props = { entity: any };

class CardFinanceiro extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    let valorTotal = ordemServicoHelper.formatMoney(parseFloat(0), 2, ',', '.');
    let monthYear =
      dateHelper.monthName(moment().month()) + ' ' + moment().year();
    this.state = {
      edit: false,
      valorTotal,
      receitaGarantia: 0,
      receitaPecasEServicos: 0,
      monthYear,
      month: moment().month(),
      year: moment().year(),
      filtro: {}
    };
  }

  getTotalMonths = (month, year) => {
    let dataInicio = moment()
      .month(month)
      .year(year)
      .date(1)
      .hour(0)
      .minute(0);

    let dataFim = moment()
      .month(month)
      .year(year)
      .hour(23)
      .minute(59);

    dataFim = dataFim.date(dataFim.endOf('month').format('DD'));

    let filtro = {
      dataInicio: dataInicio.format('YYYY-MM-DDTHH:mm:ss'),
      dataFim: dataFim.format('YYYY-MM-DDTHH:mm:ss')
    };

    console.log(dataInicio, dataFim);

    financeiroService.findDetailsFinanceiroByFilter(filtro).then(response => {
      const details = response.data;
      let valorTotal =
        details.valorGarantiaNacional +
        details.valorGarantiaInternacional +
        details.valorForaGarantia;
      valorTotal = ordemServicoHelper.formatMoney(
        parseFloat(valorTotal),
        2,
        ',',
        '.'
      );
      let receitaGarantia =
        details.valorGarantiaNacional + details.valorGarantiaInternacional;
      receitaGarantia = ordemServicoHelper.formatMoney(
        parseFloat(receitaGarantia),
        2,
        ',',
        '.'
      );

      let receitaPecasEServicos = details.valorForaGarantia;
      receitaPecasEServicos = ordemServicoHelper.formatMoney(
        parseFloat(receitaPecasEServicos),
        2,
        ',',
        '.'
      );
      this.setState({
        valorTotal,
        receitaGarantia,
        receitaPecasEServicos,
        filtro
      });
    });
  };

  componentDidMount() {
    this.getTotalMonths(this.state.month, this.state.year);
  }

  handleSatartDate = (name, month, year) => {
    let monthYear = dateHelper.monthName(month - 1) + ' ' + year;
    let _this = this;
    this.setState({ monthYear }, () => {
      _this.getTotalMonths(month - 1, year);
    });
  };

  render() {
    const { entity } = this.props;
    const { isPosto } = this.state;
    return (
      <React.Fragment>
        {this.props.children}
        <div className="card cardSeculus d-flex flex-column a1">
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Financeiro</h3>
          </div>

          <div className="flex-fill d-flex flex-column flex-sm-row">
            <div className="col d-flex flex-row align-items-center pr-3">
              <div className="justify-content-center">
                <span className="icon-finance icon-lg">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </div>
              <div className="col-10 col-sm-6">
                <div className="form-row">
                  <MonthPickerInput
                    placeholder={'Mês/Ano'}
                    name="dataInicio"
                    label="Mês de recebimento"
                    onChange={this.handleSatartDate}
                    size={6}
                    style={['col-12']}
                    id={'dataInicio'}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr></hr>

          <div className="col-12 d-inline-flex pt-3 pt-sm-0">
            <div className="col flex-fill text-center">
              <div className="miniTxt">Receita relógios na garantia</div>
              <p>{this.state.receitaGarantia}</p>
            </div>
            <div className="sinal">+</div>
            <div className="col flex-fill text-center">
              <div className="miniTxt">Receitas com peças e serviços</div>
              <p>{this.state.receitaPecasEServicos}</p>
            </div>
            <div className="sinal">=</div>
            <div className="col flex-fill text-center">
              <div className="miniTxt">Receita total</div>
              <h2 className="destaqueTxtCard financeiro text-center">
                <h2>{this.state.valorTotal}</h2>
              </h2>
            </div>
          </div>

          {<RedirectCrud toPage={'/financeiro'} filter={this.state.filtro} />}
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, null)(CardFinanceiro);
