//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmAcationService } from '../../../services/ConfirmAcationService';
import { objectsConstants } from '../../../constants/objects.constants';
import { Can } from '@casl/react';
import { FormGroup, Label } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import DateTimePicker from '../../../components/Inputs/DateTimePicker';
import moment from 'moment';
type Props = {
  onConfirm: any,
  onCancel: any
};

class RelogioEntregueBtn extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = { dtEntrega: moment().format('YYYY-MM-DDTHH:mm:ss') };
  }

  handleChange = (name, date) => {
    console.log(date);
    this.setState({
      dtEntrega: date.format('YYYY-MM-DDTHH:mm:ss')
    });
  };

  handleConfirm = () => {
    console.log(this.state.dtEntrega);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>Confirmar entrega</h3>
            <p className="p-msg">Confirma a entrega do relógio ao cliente?</p>
            <p className="p-msg">
              <b>Ao clicar em confirmar a OS será finalizada.</b>
            </p>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.props.onConfirm(this.state.dtEntrega);
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  render() {
    const { permissions, ordemServico } = this.props;
    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FINALIZAR_OS}
          ability={permissions}
        >
          <div className="d-flex flex-row-reverse bd-highlight btn-bold">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.handleConfirm}
            >
              FINALIZAR OS
            </button>
          </div>
        </Can>

        {ordemServico && ordemServico.statusOS.description}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(RelogioEntregueBtn);
