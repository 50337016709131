//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import OrdemServicoList from './OrdemServicoList';
import OrdemServicoEdit from './OrdemServicoEdit';
import OrdemServicoNew from './OrdemServicoNew';
import OrdemServicoTask from './OrdemServicoTask';

export default class OrdemServico extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/ordemServico/task/:id" component={OrdemServicoTask} />
          <Route path="/ordemServico/inicio" component={OrdemServicoNew} />
          <Route path="/ordemServico/view/:id" component={OrdemServicoEdit} />
          <Route path="/ordemServico/novo" exact component={OrdemServicoEdit} />
          <Route
            path="/ordemServico/novo/:showCardNovaOS"
            component={OrdemServicoEdit}
          />
          <Route path="/ordemServico" exact component={OrdemServicoList} />
        </Switch>
      </React.Fragment>
    );
  }
}
