//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import { cacheHelper } from '../helpers/cache.helper';

export const midiaService = {
  get,
  getData,
  getFotoRelogio,
  save
};

function get(id) {
  return `${urlsConstants.MIDIA_IMAGE}${id}`;
}

function save(midia) {
  return defaultService.doSave(`${urlsConstants.MIDIA_IMAGE}`, midia);
}

function getFotoRelogio(codigoRelogio) {
  return defaultService.doGet(
    `${urlsConstants.MIDIA_IMAGE}integracao/${codigoRelogio}`
  );
}

async function getData(id) {
  const result = await defaultService
    .doGet(`${urlsConstants.MIDIA_IMAGE_JSON}${id}`)
    .then(response => {
      return `data:${response.data.data.contentType};base64,${response.data.data.data}`;
    });
  return result;
}
