//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import CidadeInput from '../../components/Inputs/CidadeInput';
import EstadoInput from '../../components/Inputs/EstadoInput';
import SelectInput from '../../components/Inputs/SelectInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import FormSelectInput from '../Inputs/FormSelectInput';

type Props = { entity: any };

class CardSelectPosto extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { edit: false };
  }

  componentDidMount() {
    const { entity } = this.props;
    this.findTotalPostos(entity);
  }
  findTotalPostos() {
    postoAutorizadoService.getTotal().then(result => {
      this.setState({ totalPosto: result.data.data });
    });
  }

  handleRowClick = () => {
    const { entity } = this.props;
    this.props.onSelectPosto(entity.postoAutorizado);
  };

  render() {
    const { entity } = this.props;
    let serviceFindPosto = entity.cidade
      ? postoAutorizadoService.findByCidade
      : postoAutorizadoService.find;
    return (
      <React.Fragment>
        {this.props.children}
        <div className="card cardSeculus d-flex flex-column a1">
          <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Posto autorizado</h3>
          </div>

          <div className="flex-fill d-flex flex-column flex-sm-row">
            <div className="col-12 col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
              <div className="justify-content-center">
                <span className="icon-posto_x icon-lg">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </span>
              </div>
              <div className="justify-content-center pl-3">
                <h2 className="destaqueTxtCard">{this.state.totalPosto}</h2>
                <h4 className="subDestaqueTxtCard">POSTOS CREDENCIADOS</h4>
              </div>
            </div>
            <div className="col-12 col-sm-6 ">
              <div className="form-row">
                <EstadoInput
                  name="estado"
                  value={entity.estado}
                  style={'col-4'}
                  size={4}
                  parentValue={{ id: 1 }}
                  handleSelectChange={this.props.onChangeSelect}
                  label="UF"
                />
                <CidadeInput
                  name="cidade"
                  value={entity.cidade}
                  style={'col-8'}
                  size={8}
                  handleSelectChange={this.props.onChangeSelect}
                  parentValue={entity.estado}
                />
                <FormSelectInput
                  name="grupoMarca"
                  label="Grupo de Marcas"
                  placeholder=""
                  style={['col-6 col-sm-8']}
                  returnFullObject={true}
                  searchable={true}
                  id={'grupoMarca'}
                  ref={input => {
                    this._servico = input;
                  }}
                  onChange={this.props.onChangeSelect}
                  value={entity.grupoMarca}
                  service={grupoMarcaService.findAll}
                  required={false}
                />
                <SelectInput
                  type="text"
                  name="postoAutorizado"
                  label="Posto autorizado"
                  placeholder="Posto autorizado"
                  style={['col-12']}
                  onFetchData={serviceFindPosto}
                  valueKey="id"
                  labelKey="nomeFantasia"
                  returnFullObject={true}
                  searchable={true}
                  id={'postoAutorizado'}
                  onChange={this.props.onChangeSelect}
                  value={entity.postoAutorizado}
                  parent={entity.cidade}
                />

                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-secondary float-right"
                    disabled={entity.postoAutorizado ? false : true}
                    onClick={this.handleRowClick}
                  >
                    {' '}
                    Selecionar{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(null, null)(CardSelectPosto);
