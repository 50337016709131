import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import RatingItem from '../../../components/rating/RatingItem';
import { CardItem } from '../../../components/templates/CardItem';
import { objectsConstants } from '../../../constants/objects.constants';
import { pesquisaSatisfacaoService } from '../../../services/pesquisaSatisfacaoService';
import _ from 'lodash';

class PostoAvaliacaoAtendimento extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      respostas: [],
      totalPesquisas: 0
    };
  }

  componentDidMount() {
    this.updateTable();
  }

  updateTable = () => {
    this.props.loading(true);
    pesquisaSatisfacaoService
      .getMediaPostoAutorizado({ filter: this.props.entity })
      .then(response => {
        let data = response.data;
        this.setState({
          totalPesquisas: data.totalPesquisas,
          totalRespostas: data.totalRespostas,
          respostas: data.respostas
        });
        this.props.loading(false);
      });
  };

  getMedia = () => {
    const { respostas } = this.state;
    let average = _.meanBy(respostas, r => r.mediaAvaliacao);
    let avaliacaoPosto = _.isNaN(average) ? 0 : average.toFixed(1);
    let payLoad = { avaliacaoPosto };
    this.props.setTotal(payLoad);
    return avaliacaoPosto;
  };

  getRespostaInList = (resposta, listResposta) => {
    var respostaItem = listResposta.find(function(item) {
      return item.resposta.name === resposta;
    });

    return parseFloat(respostaItem.mediaAvaliacao).toFixed(1);
  };

  getPercentualPopulacao = () => {
    let total = (this.state.totalRespostas * 100) / this.state.totalPesquisas;
    return total.toFixed(1);
  };

  render() {
    const { respostas } = this.state;
    let _mySelf = this;
    return (
      <React.Fragment>
        <CardItem
          title={`PESQUISA DE SATISFAÇÃO (${
            this.state.totalRespostas
          } AVALIAÇÕES)`}
          width={this.props.width}
        >
          {respostas.length > 0 && (
            <React.Fragment>
              <div className="col px-4">
                {objectsConstants.QUESTIONARIO_PESQUISA_ARRAY.map(item => {
                  let mediaItem = _mySelf.getRespostaInList(item.id, respostas);
                  return (
                    <RatingItem
                      key={item.id}
                      label={item.label}
                      item={item}
                      rating={mediaItem}
                      readonly={true}
                    />
                  );
                })}

                <RatingItem
                  label="Nota Média"
                  classLabel="bold"
                  readonly={true}
                  rating={this.getMedia()}
                />
              </div>
              <hr />
              <div className="col-12 px-4">
                <div className="row">
                  <div className="col-6 d-flex flex-row align-items-left pr-3">
                    <p>
                      {' '}
                      {`Número de respondentes: `}
                      <b>{this.state.totalRespostas}</b>{' '}
                    </p>
                  </div>
                  <div className="col-6 d-flex flex-row align-items-left pr-3">
                    <p>
                      {' '}
                      {`% da População: `}
                      <b>{this.getPercentualPopulacao()}%</b>
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </CardItem>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading },
  report: { setTotal }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  setTotal: totals => setTotal(totals)
});
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(PostoAvaliacaoAtendimento);
