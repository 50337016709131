//@flow
import moment from 'moment';
import * as React from 'react';
import CpfInput from '../../components/Inputs/CpfInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import FormInput from '../../components/Inputs/FormInput';
import SelectInput from '../../components/Inputs/SelectInput';
import { relogioService } from '../../services/relogio.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class GarantiaCrudListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    console.log('entity');
    console.log(entity);
    return (
      <React.Fragment>
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataEntradaInicio"
          label="Data da entrada (Início)"
          value={
            entity.dataEntradaInicio ? moment(entity.dataEntradaInicio) : null
          }
          onChange={this.props.onChangeDate}
          size={2}
          id={'dataEntradaInicio'}
        />
        <DatePickerInput
          name="dataEntradaFim"
          label="Data da entrada (Fim) "
          placeholder={'--/--/--'}
          value={entity.dataEntradaFim ? moment(entity.dataEntradaFim) : null}
          onChange={this.props.onChangeDate}
          size={2}
          id={'dataEntradaFim'}
        />
        <CpfInput
          label="CPF"
          placeholder={'CPF'}
          id={'cpf'}
          name="cpf"
          value={entity.cpf}
          onChange={this.props.onChangeDate}
          type={'text'}
          style={['col-6 col-sm-2']}
        />

        <FormInput
          label="Número da Nota Fiscal"
          placeholder="Número da Nota Fiscal"
          type="text"
          id={'numeroNF'}
          name="numeroNF"
          value={entity.numeroNF}
          onChange={this.props.onChangeDate}
          style={['col-6 col-sm-2']}
        />

        <SelectInput
          type="text"
          id={'relogio'}
          name={'relogio'}
          label={`Código do relógio *`}
          placeholder="Código do relógio"
          onFetchData={relogioService.find}
          valueKey="codigoRelogio"
          labelKey="codigoRelogio"
          returnFullObject={true}
          searchable={true}
          onChange={this.props.onChangeSelect}
          value={entity.relogio}
          style={['col-2 col-sm-2']}
        />
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data da nota (Início)"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={2}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data da nota (Fim) "
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={2}
          id={'dataFim'}
        />
      </React.Fragment>
    );
  }
}
