//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import { perfilService } from '../../services/perfil.service';
import _ from 'lodash';
import SelectInput from '../../components/Inputs/SelectInput';

class FormValoresGarantia extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      options: []
    };
  }

  insertPerfil = value => {
    console.log(value);
    perfilService.doSave(value.description).then(response => {
      let options = _.cloneDeep(this.state.options);
      console.log(response);
      options.push(response.data);
      this.setState({ options });
      this.props.onChangeSelect('role', response.data);
    });
  };

  componentDidMount() {
    perfilService.findAll().then(
      data => {
        let options = [];
        options = options.concat(data.data);
        this.setState({ options: options });
      },
      error => {
        console.log('Erro ao montar o combo', error);
      }
    );
  }

  render() {
    const { entity } = this.props;
    const { filter } = this.props;

    return (
      <React.Fragment>
        <SelectInput
          type="text"
          name="role"
          label="Perfil"
          placeholder="Digite o nome do perfil"
          required={false}
          valueKey="id"
          labelKey="description"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'findPerfil'}
          onChange={this.props.onChangeSelect}
          value={entity.role}
          helpText={
            'Selecione o perfil que deseja configurar, ou Digite nome para criar um novo'
          }
          options={this.state.options}
          onNewOptionClick={this.insertPerfil}
          creatable
          multi={false}
        />
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
}) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(FormValoresGarantia));
