//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import connect from "react-redux/es/connect/connect";
import { alertActions } from '../../actions/alert.action';
import { HeaderComponent } from '../../components/RegisterComponents/HeaderComponent';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import RegisterFirstStepForm from './RegisterFirstStepForm';
import { objectsConstants } from '../../constants/objects.constants';
//import ApiCep from "../../services/zipCode.service";
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import RegisterEmpresaFormFull from './RegisterEmpresaFormFull';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  country: Array<any>,
  states: Array<any>,
  cities: Array<any>,
  validateObject: any,
  onBack: any,
  step: number
};

class RegisterFirstStep extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity)
    };
  }

  nextStep = () => {
    const toastrOptionsSuccess = { icon: <IconErrorToast /> };
    //TODO modificar maneira que foi feita
    console.log(this)
    postoAutorizadoService

      .validStepOne(this._form.wrappedInstance.getEntity())
      .then(response => {
        alertActions.clear();
        this.props.handleEntity(this._form.wrappedInstance.getEntity(), 2);
      });
  };

  previousStep = () => {};

  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        <div className="form-row align-items-center">
          <div className="col-12 col-lg-7">
            <h3 className="text-lg-left text-center">Dados da empresa</h3>
          </div>
        </div>
        <div className="tab-pane active" id="empresa">
          <RegisterFirstStepForm
            entity={entity}
            ref={forms => {
              this._form = forms;
            }}
            FormContent={RegisterEmpresaFormFull}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
                       alert: { success, error, clear, info },
                       load: { loading },
                     }) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(RegisterFirstStep);

