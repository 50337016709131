import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './ImageInput.css';
import _ from 'lodash';
import { urlsConstants } from '../../constants/url.constant';
let download = require('downloadjs');

let fileInput;

type Props = {
  onChange: any,
  name: string,
  imagens: Array,
  title: string,
  subititle: string
};
type State = {
  entity: any
};

class MuliplesImageLoad extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity,
      image: null,
      imagens: []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearState) {
      this.setState({ imagens: [] });
    }
  }

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;
      let props = this.props;

      for (let i = 0; i < files.length; i++) {
        let item = files[i];
        resize(item, maxWidth, maxHeight, function(imageVO) {
          let { imagens } = self.state;
          let newArrayImagens = _.cloneDeep(imagens);
          newArrayImagens.push(imageVO);
          self.setState({ imagens: newArrayImagens }, () => {
            self.props.onChange(newArrayImagens);
          });
        });
      }
    }
  };

  removeImage = (e, image) => {
    e.stopPropagation();
    let { imagens } = this.state;
    let newArrayImagens = _.cloneDeep(imagens);
    _.remove(newArrayImagens, function(item) {
      return (image.id && image.id === item.id) || image.data === item.data;
    });

    this.setState({ imagens: newArrayImagens }, () => {
      this.props.onChange(newArrayImagens);
    });
  };

  openInputFile = (event: any, image: any) => {
    if (event) event.preventDefault();
    if (this.props.readOnly) {
      let dataUrl = `${urlsConstants.MIDIA_IMAGE}${image.id}`;
      let x = new XMLHttpRequest();
      x.open('GET', dataUrl, true);
      x.responseType = 'blob';
      x.onload = function(e) {
        download(x.response, 'relogio.jpg', 'image/jpg');
      };
      x.send();
      return false;
    }
    this.fileInput.click();
  };

  dataUrl = () => {
    return require('../../img/fotoBG.png');
  };

  getImage = (image, readOnly, canRemove, index) => {
    let dataUrl;
    if (image.id) {
      dataUrl = `${urlsConstants.MIDIA_IMAGE}${image.id}`;
    } else {
      dataUrl = image.data;
    }
    if (dataUrl) {
      return (
        <div className="buttonImage" key={index}>
          <img
            src={dataUrl}
            alt={this.props.alt}
            className={`img-responsive pr-1 action ${this.props.className}`}
            onClick={event => this.openInputFile(event, image)}
            height={this.props.height ? this.props.height + 'px' : '100%'}
          />
          {!readOnly && (
            <div className="overlayImage">
              <i
                className="icon-foto"
                alt="Icone para alterar imagem"
                onClick={this.openInputFile}
              />
            </div>
          )}
          {canRemove && (
            <div className="removeImage">
              <i
                className="icon-x"
                alt="Icone para alterar imagem"
                onClick={e => {
                  this.removeImage(e, image);
                }}
              />
            </div>
          )}
        </div>
      );
    }
  };

  render() {
    var dataUrl = this.state.dataUrl ? this.state.dataUrl : this.dataUrl();
    var removeImage = this.state.showRemove;

    let image =
      !this.props.readOnly &&
      this.getImage({ data: dataUrl }, this.props.readOnly, false);

    return (
      <div className="buttonImage ImageInner">
        <input
          type="file"
          accept="image/*"
          onChange={this.onChange}
          multiple={true}
          className="d-none img-profile-input"
          ref={input => {
            this.fileInput = input;
          }}
        />
        {image}
        {this.state.imagens.map((image, index) =>
          this.getImage(image, true, true, index)
        )}
        {this.props.imagens &&
          this.props.imagens.map((image, index) =>
            this.getImage(image, true, false, image.id)
          )}
      </div>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

function mapStateToProps(state) {
  const { currentTask } = state.task;

  return {
    currentTask
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(MuliplesImageLoad);

function resize(file, maxWidth, maxHeight, fn, autocropper) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function(event) {
    var dataUrl = event.target.result;
    var image = new Image();
    image.src = dataUrl;
    image.onload = function() {
      var resizedDataUrl = resizeImage(image, maxWidth, maxHeight, 0.7);
      let imageVO = {
        filename: file.name,
        length: file.size,
        contentType: file.type,
        data: resizedDataUrl
      };
      fn(imageVO);
    };
  };
}

function resizeImage(image, maxWidth, maxHeight, quality) {
  var canvas = document.createElement('canvas');

  var width = image.width;
  var height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL('image/jpeg', quality);
}
