//@flow
import * as React from 'react';
import ChartPie from './ChartPie';

export default class AnaliseFalhasChart extends React.PureComponent<
  Props,
  State
> {
  static defaultProps = { data: [] };
  constructor(props) {
    super(props);
    this.state = {
      filter: props.entity
    };
  }

  updateChart = filter => {
    this.setState({ filter });
  };

  render() {
    const { filter } = this.state;

    return (
      <React.Fragment>
      {/*  {this.props.data.length > 0 && (
          <React.Fragment>
            <div className="row">
              <div className="col">
                <ChartPie
                  title="Marca"
                  groupItem="marca.nome"
                  filter={filter}
                />
              </div>
              <div className="col">
                <ChartPie
                  title="Parte"
                  groupItem="pos.peca.nome"
                  filter={filter}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ChartPie
                  title="Causa"
                  groupItem="pos.causa.nome"
                  filter={filter}
                />
              </div>
              <div className="col">
                <ChartPie
                  title="Sintoma"
                  groupItem="pos.sintoma.nome"
                  filter={filter}
                  dispatcthTotal={true}
                  totalItem="totalSintomas"
                />
              </div>
            </div>
          </React.Fragment>
        )}*/}
      </React.Fragment>
    );
  }
}
