//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { tableHelper } from '../../../helpers/tableHelper';
import { garantiaEstoqueService } from '../../../services/garantiaEstoque.service';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import GarantiaEstoqueFormFilter from './GarantiaEstoqueFormFilter';

//Nº
const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'mes',
    title: 'Mês',
    isKey: false,
    dataSort: true,
    isMonth: true
  },
  {
    path: 'dataAbertura',
    title: 'Dt. Abertura',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  {
    path: 'dataCompra',
    title: 'Dt. compra',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'estoque',
    title: 'Estoque',
    isKey: false,
    dataSort: true,
    yesNo: true
  }
];

class GarantiaEstoque extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields,
      defaultFilter: {
        estoque: false
      },
      filter: {}
    };
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="GARANTIA DE ESTOQUE  "
          canBack={false}
        />
        <CardRelatorio
          title={`GARANTIA DE ESTOQUE  (${this.props.subTitle})`}
          FormFilter={GarantiaEstoqueFormFilter}
          showTable={true}
          tableService={garantiaEstoqueService}
          controller={'relatorio/garantiaEstoque'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(GarantiaEstoque);
