//@flow
import * as React from 'react';

import { relatorioService } from '../../../services/relatorio.service';
import ConsumoPecasFormFilter from './ConsumoPecasFormFilter';
import ConsumoPecasChart from './ConsumoPecasChart';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import moment from 'moment';
import { consumoPecasService } from '../../../services/consumoPecas.service';
import connect from 'react-redux/es/connect/connect';

const columns = [
  {
    path: 'nomePeca',
    title: 'Peça',
    isKey: true,
    dataSort: true,
    className: 'colunaDestaque'
  },
  {
    path: 'nomeMarca',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'quantidade',
    title: 'Quantidade',
    isKey: false,
    dataSort: true
  }
];

class ConsumoPecas extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();

    let dataFim;

    let dataInicio = moment()
      .month(0)
      .year(dateNow.year())
      .date(1);

    dataFim = moment()
      .month(dateNow.month())
      .year(dateNow.year());

    dataFim = dataFim.date(dataFim.endOf('month').format('DD'));

    this.state = {
      fields,
      defaultFilter: {
        dataInicio,
        dataFim
      }
    };
  }
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="CONSUMO DE PEÇAS "
          canBack={false}
        />
        <CardRelatorio
          title={`CONSUMO DE PEÇAS (${this.props.subTitle})`}
          FormFilter={ConsumoPecasFormFilter}
          Chart={ConsumoPecasChart}
          showTable={true}
          tableService={consumoPecasService}
          controller={'relatorio/consumoPecas'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(ConsumoPecas);
