import React, { PureComponent } from 'react';
import ImageInput from './Inputs/ImageInput';
import CropperView from './Imagen/CropperView';
import { connect } from 'react-redux';
import { translate } from '../helpers/message.helper';
import { ToolTipFeedBack } from './Utils/ToolTipFeedBack';

type State = {
  image: any
};
class UploadPictureRegister extends PureComponent<Props, State> {
  defaultProps = {
    maxHeight: 500,
    maxWidth: 500
  };
  constructor(props: Props) {
    super(props);
    this.changeImage = this.changeImage.bind(this);
    this.state = {
      image: null,
      idImage: props.idImage,
      loadedImage: false,
      dataUrl: props.dataUrl ? props.dataUrl : null
    };
  }

  UNSAFE_componentWillReceiveProps(nexProps) {
    if (
      nexProps.dataUrl != this.props.dataUrl ||
      nexProps.idImage != this.props.idImage
    ) {
      this.setState({
        dataUrl: nexProps.dataUrl,
        idImage: nexProps.idImage
      });
    }
  }

  changeImage(image) {
    this.setState({ image });
  }
  onCropper = dataUrl => {
    let image = this.state;
    image.data = dataUrl;
    image.image.id = this.props.idImage;
    this.setState({ dataUrl, image: null, loadedImage: true });
    this.props.onChange(image);
  };
  render() {
    const { image } = this.state;
    const { messages, name, label, styleFormat } = this.props;
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (messageValidate) {
      this.props.error(messageValidate);
    }
    //TODO criar props corretamente
    return (
      <div className="col-12 col-lg-3 text-center">
        {!this.props.hideLabel && (
          <div className="col-12">
            <label>{label ? label : 'Foto'}</label>
          </div>
        )}
        <div className="col-12">
          <CropperView
            modal={image ? true : false}
            onCropper={this.onCropper}
            titleBtn={'Cortar imagem'}
            title="Cortar imagem"
            subititle={''}
            maxHeight={300}
            maxWidth={300}
            dataUrl={image ? image.data : null}
            onChange={this.props.onChange}
          />
          <a role="button" id={this.props.id}>
            <ImageInput
              height={this.props.height}
              maxHeight={this.props.maxHeight}
              maxWidth={this.props.maxWidth}
              onChange={this.changeImage}
              dataUrl={this.state.dataUrl}
              id={this.props.idImage}
              className={
                styleFormat ? styleFormat : 'fotoPerfil rounded-circle'
              }
            />
            {messageValidate && (
              <ToolTipFeedBack
                target={this.props.id}
                messageValidate={messageValidate}
              />
            )}
          </a>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}
const mapDispatch = ({
  alert: { error, clear },
  authentication: { login }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),

  login: (user, password) => login({ user, password })
});
export default connect(mapStateToProps, mapDispatch)(UploadPictureRegister);
