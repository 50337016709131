import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import FotoRelogio from '../../components/FotoRelogio';
import ListagemPage from '../../components/templates/ListagemPage';
import { objectsConstants } from '../../constants/objects.constants';
import { tableHelper } from '../../helpers/tableHelper';
import { garantiaClienteService } from '../../services/garantiaCliente.service';
import { midiaService } from '../../services/midiaService';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import GarantiaCrudListFilter from './GarantiaCrudListFilter';

const columns = [
  {
    path: 'dataEntrada',
    title: 'Data da Entrada',
    isKey: false,
    isDate: true,
    dataSort: false,
  },
  {
    path: 'cliente.nome',
    title: 'Nome do cliente',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'cliente.documento',
    title: 'CPF',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'numeroNF',
    pathFilter: 'numeroNF',
    title: 'Número da Nota Fiscal',
    isKey: true,
    dataSort: false,
  },
  {
    path: 'cliente.email',
    pathFilter: 'email',
    title: 'CPF',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'dataNF',
    title: 'Data da Nota Fiscal',
    isKey: false,
    isDate: true,
    dataSort: false,
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código do Relogio',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'relogio.foto',
    title: 'Foto do Relogio',
    isKey: false,
    dataSort: false,
  },
  {
    path: 'notaFiscal',
    title: 'Nota Fiscal Data',
    isKey: false,
    dataSort: false,
  },
];

const endPoint = '/cadastroGarantia/novo/';
const endPointView = '/cadastroGarantia/edit/';

class GarantiaCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Cadastro de Garantia',
      },
    });
  };

  renderCustom(row: any, entity: any, column: any) {
    if (column.path == 'notaFiscal') {
      return (
        <img
          src={midiaService.get(entity.notaFiscal ? entity.notaFiscal.id : 0)}
          height={'75px'}
          width={'75px'}
          noMargin
        />
      );
    } else {
      if (column.path == 'relogio.foto') {
        return (
          <FotoRelogio
            relogio={entity.relogio}
            height={'75px'}
            width={'75px'}
            noMargin
          />
        );
      } else {
        return tableHelper.renderCustom(row, entity, column);
      }
    }
  }

  render() {
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });

    const tableColumn = columns.map((column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Garantia"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`Garantia`}
          newButtonActionClick={this.handleNewButton}
          delete={() => {}}
          service={garantiaClienteService}
          labelNewButton="Nova Garantia"
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={GarantiaCrudListFilter}
          controller="GarantiaCliente"
          fields={fields}
          roleNewButton={objectsConstants.ABILITY_CADASTRO_DE_RELOGIO}
          showNewButton={false}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

export default GarantiaCrudList;
