import React, { PureComponent } from 'react';
import { UserAvatar } from '../../components/menu/UserAvatar';
import { dateHelper } from '../../helpers/date.helper';
import { connect } from 'react-redux';
import { midiaService } from '../../services/midiaService';
import ChatOsRowTrocaPeca from './ChatOsRowTrocaPeca';

type Props = {
  children?: any,
  onChange: any
};
type State = {
  entity: any
};

class ChatOsRowMessage extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      message: {},
      sender: {}
    };
  }

  formatDateTime(dateTime) {
    return dateHelper.format(dateTime);
  }

  isMeClass() {
    const me = 'row message my d-flex flex-row-reverse';
    const notMe = 'row message d-flex flex-row';
    if (!this.props.message.action) {
      if (this.props.sender.id == this.props.currentUser.id) {
        return me;
      } else {
        return notMe;
      }
    } else {
      if (
        this.props.message.action &&
        ((!this.props.message.action.completed &&
          this.props.sender.id == this.props.currentUser.id) ||
          (this.props.message.action.completed &&
            this.props.message.action.userComplete.id ==
              this.props.currentUser.id))
      ) {
        return me;
      } else {
        return notMe;
      }
    }
  }
  getSender() {
    if (!this.props.message.action) {
      return this.props.sender;
    } else {
      return this.props.message.action.userComplete;
    }
  }
  getMessage() {
    if (!this.props.message.action && this.props.message.mensagem) {
      let msg = this.props.message.mensagem;

      if (msg.indexOf('\n') != -1) {
        while (msg.indexOf('\n') != -1) {
          msg = msg.replace('\n', '<br/>');
        }
      }
      return <p dangerouslySetInnerHTML={{ __html: msg }} />;
    }
    if (this.props.message.action && this.props.message.action.completed) {
      return <p>{this.props.message.action.messageCompleted}</p>;
    }
  }

  focus = () => {
    this._div.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    return (
      <div className={this.isMeClass()} ref={ref => (this._div = ref)}>
        {this.props.message.action && !this.props.message.action.completed && (
          <ChatOsRowTrocaPeca
            action={this.props.message.action}
            onChangeAction={this.props.onChangeAction}
            index={this.props.index}
          />
        )}
        {(!this.props.message.action ||
          (this.props.message.action &&
            this.props.message.action.completed)) && (
          <React.Fragment>
            <div className="col-3 col-sm-2">
              <UserAvatar foto={this.getSender().foto} />
            </div>
            <div className="msg px-0">
              <div className="boxMessage">
                <h4>{this.getSender().name}</h4>

                {this.getMessage()}
                {this.props.message.attachment && (
                  <p>
                    <img
                      src={midiaService.get(this.props.message.attachment.id)}
                      className={`img-fluid ${this.props.className}`}
                    />
                  </p>
                )}
              </div>
              <h5>{this.formatDateTime(this.props.message.dateTime)}</h5>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(null, null, null, { withRef: true })(ChatOsRowMessage);
