//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompraPecaView from '../CompraPecaView';
import CompraPecaListAdmin from './CompraPecaListAdmin';

export default class CompraPecaAdminManaus extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/compraPecaAdmin/view" component={CompraPecaView} />
          <Route
            path="/compraPecaAdmin"
            exact
            component={CompraPecaListAdmin}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
