import React, { Component } from 'react';
import InputTrigger from 'react-input-trigger';
import { ImageProfile } from '../../components/Inputs/ImageProfile';
import { ordemServicoService } from '../../services/ordemServico.service';
import _ from 'lodash';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { propertyConstantes } from '../../constants/property.constantes';

type Props = {
  children?: any,
  onChange: any,
  onAddMetion: any,
  onRemoveMention: any
};
type State = {
  entity: any
};

export class MentionInputUser extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.user.extension) {
      postoAutorizadoService.doGet(this.props.user.extension).then(response => {
        let posto = response.data.data;
        this.setState({ posto });
      });
    }
  }

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }

  render() {
    const { user } = this.props;
    const { posto } = this.state;
    const NOME = 'nome';
    let isAdmin = user.role === objectsConstants.ROLE_ADMIN ? true : false;
    let isManaus =
      user.role === objectsConstants.ROLE_MANAUS ||
      user.role === objectsConstants.ROLE_MANAUS_TROCA
        ? true
        : false;
    return (
      <div className="d-flex flex-row align-items-center person">
        <ImageProfile
          name={''}
          dataUrl={this.getAvatar(user)}
          className={'rounded-circle avatar-img'}
        />
        <div className="dadosUser flex-grow-1">
          <div className="nomeMsg">{user.name}</div>
          {posto && <div className="setor">{posto.nomeFantasia}</div>}
          {isAdmin && <div className="setor">Seculus</div>}
          {isManaus && <div className="setor">Manaus</div>}
          {posto && (
            <div className="localidade">
              {' '}
              {_.get(posto.cidade, NOME)} - {_.get(posto.estado, 'uf')}
            </div>
          )}

          {isAdmin && <div className="localidade"> Belo Horizonte - MG</div>}
          {isManaus && <div className="localidade"> Manaus - AM</div>}
        </div>
        <div className="cargo">{_.get(constHelper, user.role)}</div>
      </div>
    );
  }
}
