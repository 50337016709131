//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import ListagemPage from '../../../components/templates/ListagemPage';
import { urlsConstants } from '../../../constants/url.constant';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { tableHelper } from '../../../helpers/tableHelper';
import { ordemServicoService } from '../../../services/ordemServico.service';
import OrdemServicoListFilterAdmin from './OrdemServicoListFilterAdmin';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import NovaOsModal from '../../../components/Modals/NovaOsModal';

const columns = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: false
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: false },
  {
    path: 'estoque.description',
    title: 'Estoque',
    isKey: false,
    dataSort: false
  },
  { path: 'garantia', title: 'Garantia', yesNo: true, dataSort: false },
  {
    path: 'dataStatusAtual',
    title: 'Data do status atual',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'atendido', title: 'Atendido', isKey: false, dataSort: false },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    isKey: false,
    isFarol: true,
    path: 'diasCorridos'
  }
];

type State = {
  modal: boolean
};

const NewClienteInit = Yup.object().shape({
  documentoModal: Yup.string()
    .min(11, 'CPF ou CNPJ inválido!')
    .max(14, 'CPF ou CNPJ inválido!')
    .required('CPF ou CNPJ é obrigatório')
});

const endPoint = '/ordemServico/';
class OrdemServicoListAdmin extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      modal,
      fields
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleRowClick = (row: e) => {
    let urlOS = urlsConstants.ORDEM_SERVICO + row.id;
    ordemServicoService.doGet(urlOS).then(response => {
      let os = response.data.data;
      this.props.setOrdemServico(os);
      this.props.setCliente(os.cliente);
      this.props.history.push('/ordemServicoAdmin/view/' + row.id);
    });
  };

  renderCustom(row: any, ordemServico: any, column: any) {
    if (column.isFarol) {
      let classFarol = ordemServicoHelper.getFarol(ordemServico);
      return <div className={`farol ${classFarol}`} />;
    } else {
      return tableHelper.renderCustom(row, ordemServico, column);
    }
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Ordens de Serviço"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`ORDENS DE SERVIÇO(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={ordemServicoService}
          defaultOrder={'diasCorridos desc,id'}
          labelNewButton="Nova OS"
          defaultFilter={{ endereco: {} }}
          onRowClick={this.handleRowClick}
          FormFilter={OrdemServicoListFilterAdmin}
          showNewButton={true}
          controller="ordemServico"
          fields={this.state.fields}
          classSignal={'temSinal'}
        >
          {tableColumn}
        </ListagemPage>
        <NovaOsModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(mapStateToProps, mapDispatch)(OrdemServicoListAdmin);
