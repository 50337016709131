//@flow
import * as React from 'react';
import { Line } from 'react-chartjs-2';
import connect from 'react-redux/es/connect/connect';

class RepresamentoPreReparoChartHistory extends React.PureComponent<{}> {
  constructor(props) {
    super(props);
  }

  setClass(obj, teste) {
    obj.currentTarget.style.cursor = 'pointer';
  }

  render() {
    let data = null;
    let data25Dias;
    if (this.props.data) {
      let labels = [];
      let qtOSMais25DiasDataSet = {
        label: '+ de 25',
        data: [],
        borderColor: 'red',
        backgroundColor: 'red',
        fill: false,
        lineTension: 0.1
      };
      let qtOSMais15DiasDataSet = {
        label: '15 a 25',
        data: [],
        borderColor: 'yellow',
        backgroundColor: 'yellow',
        fill: false,
        lineTension: 0.1
      };
      let qtOSMais5DiasDataSet = {
        label: '5 a 15',
        data: [],
        borderColor: 'green',
        backgroundColor: 'green',
        fill: false,
        lineTension: 0.1
      };
      let qtOSMenos5DiasDataSet = {
        label: '0 a 5',
        data: [],
        borderColor: 'blue',
        backgroundColor: 'blue',
        fill: false,
        lineTension: 0.1
      };
      let total25Dias = 0;
      let total15Dias = 0;
      let total5Dias = 0;
      let totalMenos5Dias = 0;
      for (var i = 0; i < this.props.data.length; i++) {
        labels.push(this.props.data[i].mes + '/' + this.props.data[i].ano);
        qtOSMais25DiasDataSet.data.push(this.props.data[i].qtdOsMais25Dias);
        total25Dias = total25Dias + this.props.data[i].qtdOsMais25Dias;
        qtOSMais15DiasDataSet.data.push(this.props.data[i].qtdOsMais15Dias);
        total15Dias = total15Dias + this.props.data[i].qtdOsMais15Dias;
        qtOSMais5DiasDataSet.data.push(this.props.data[i].qtdOsMais5Dias);
        total5Dias = total5Dias + this.props.data[i].qtdOsMais5Dias;
        qtOSMenos5DiasDataSet.data.push(this.props.data[i].qtdOsMenos5Dias);
        totalMenos5Dias = totalMenos5Dias + this.props.data[i].qtdOsMenos5Dias;
      }
      let datasets = [];
      datasets.push(qtOSMenos5DiasDataSet);
      datasets.push(qtOSMais5DiasDataSet);
      datasets.push(qtOSMais15DiasDataSet);
      datasets.push(qtOSMais25DiasDataSet);
      data = { labels, datasets };
      data25Dias = {
        labels: [
          'OSs em aberto acima de 25 dias',
          'OSs em aberto acima de 15 dias e menos de 25 dias',
          'OSs em aberto acima de 5 dias e menos de 15 dias',
          'OSs em aberto menos de 5 dias'
        ],
        datasets: [
          {
            data: [total25Dias, total15Dias, total5Dias, totalMenos5Dias],
            backgroundColor: ['red', 'yellow', 'green', 'blue'],
            hoverBackgroundColor: ['red', 'yellow', 'green', 'blue']
          }
        ]
      };
    }

    return (
      <React.Fragment>
        <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
          <div className="col flex-fill">
            <h3 className="flex-grow-1">Histórico de represamento</h3>
          </div>
        </div>
        {data && <Line data={data} height={50} />}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico },
  load: { loading }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load })
});
export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(RepresamentoPreReparoChartHistory);
