//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { colaboradoresHelper } from '../../helpers/colaboradores.helper';
import RoleSelectInput from '../../components/Inputs/RoleSelectInput';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import { grupoMarcaService } from '../../services/grupoMarca.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class MarcaCrudListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Nome"
          placeholder={'Nome'}
          id={'name'}
          name="name"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-4'}
          value={entity.name}
        />
        <FormSelectInput
          name="grupoMarca"
          label="Grupo de Marcas"
          placeholder=""
          size={8}
          className={'col-12 col-sm-8'}
          returnFullObject={true}
          searchable={true}
          id={'grupoMarca'}
          ref={(input) => {
            this._servico = input;
          }}
          onChange={this.props.onChangeSelect}
          value={entity.grupoMarca}
          service={grupoMarcaService.findAll}
          required={false}
        />
      </React.Fragment>
    );
  }
}
