//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { tableHelper } from '../../helpers/tableHelper';
import { compraPecaService } from '../../services/compraPeca.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import CompraPecaListFilter from './CompraPecaListFilter';
import NovaCompraModal from '../../components/Modals/NovaCompraModal';
import { userService } from '../../services/user.service';
import { objectsConstants } from '../../constants/objects.constants';
const columns = [
  {
    path: 'numOs',
    title: 'Nº CP',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },

  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'quantidadePecas', title: 'Peças', isKey: false, dataSort: true },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    isKey: false,
    isFarol: true,
    path: 'diasCorridos'
  }
];

type State = {
  modal: boolean
};

const endPoint = '/compraPeca/';
class CompraPecaList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });

    this.state = {
      modal,
      fields
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleRowClick = (row: e) => {
    this.props.loading(true);
    compraPecaService.getById(row.id).then(response => {
      this.props.setOrdemServico(response.data.data);
      this.props.loading(false);
      this.props.history.push('/compraPeca/view');
    });
  };

  renderCustom(row: any, ordemServico: any, column: any) {
    if (column.isFarol) {
      let classFarol = ordemServicoHelper.getFarol(ordemServico);
      return <div className={`farol ${classFarol}`} />;
    } else {
      return tableHelper.renderCustom(row, ordemServico, column);
    }
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Compra de Peças"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`COMPRA DE PECAS(${this.props.subTitle})`}
          roleNewButton={objectsConstants.ABILITY_SOLICITAR_COMPRA_PECA}
          newButtonActionClick={() => {
            if (userService.isAdmin()) {
              this.toggle();
            } else {
              this.props.history.push('/compraPeca/novo');
            }
          }}
          delete={() => {}}
          service={compraPecaService}
          defaultOrder={'creationDateTime'}
          labelNewButton="Nova Compra"
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={CompraPecaListFilter}
          showNewButton={true}
          controller="compraPeca"
          fields={this.state.fields}
          defaultDirection={'desc'}
          classSignal="temSinal"
        >
          {tableColumn}
        </ListagemPage>
        <NovaCompraModal
          isModalVisible={this.state.modal}
          toggleModal={this.toggle}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  const { permissions } = state.authentication;

  return {
    subTitle,
    permissions
  };
}

const mapDispatch = ({
  load: { loading },
  ordemServico: { setOrdemServico }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  loading: (load: boolean) => loading({ load })
});

export default connect(mapStateToProps, mapDispatch)(CompraPecaList);
