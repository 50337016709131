import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { validateInput } from '../../helpers/validateInputs.helper';

type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

export class CardServicoItemView extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity
    };
  }

  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <div className="form-row borderForm formView">
          <div className="form-group col-12 col-sm-8">
            <label className="miniLabel">Servico*</label>
            <p>{entity.servico.servico.nome}</p>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel green">Valor Final*</label>
            <p className="txtGreen">
              {ordemServicoHelper.formatMoney(
                parseFloat(entity.servico.valorFinal),
                2,
                ',',
                '.'
              )}{' '}
            </p>
          </div>
          {entity&&entity.servico&&entity.servico.servico&&entity.servico.servico.tipo&&entity.servico.servico.tipo === 'TROCA_BATERIA'&&(
          <React.Fragment>
            <div className="form-group col-12 col-sm-3">
            <label className="miniLabel">Quantidade*</label>
            <p>{entity.servico.quantidade}</p>
          </div>
          <div className="form-group col-12 col-sm-3">
            <label className="miniLabel">Calibre*</label>
            <p className="txtGreen">
              {
               entity.servico.calibre}
            </p>
          </div>
          </React.Fragment>
            )}
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(CardServicoItemView);
