import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormRow } from '../../components/FormRow';
import { objectsConstants } from '../../constants/objects.constants';
import { validateInput } from '../../helpers/validateInputs.helper';
import { servicoService } from '../../services/servico.service';
import FormSelectInput from '../Inputs/FormSelectInput';
import MoneyInput from '../Inputs/MoneyInput';
import { propertyConstantes } from "../../constants/property.constantes";
import FormInput from '../Inputs/FormInput';
type Props = { entity: any };
type State = {
  servicos: any,
  entity: any
};

const objectValidateBranchVO = [
  objectsConstants.servicoValidate,
  objectsConstants.valorFinalValidate
];

export class CardServicosItem extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity
    };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(entity, name, value);
    this.setState({ entity }, () => {
      if (this.validateForms()) {
        this.props.onChange(this.props.id, entity);
      }
    });
  };

  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity }, () => {
      if (this.validateForms()) {
        this.props.onChange(this.props.id, newEntity);
      }
    });
  };
  getBotaoRemoveServico = () => {

      return (
        <div className="text-right py-2">
          <a onClick={()=>this.props.removeServico(this.state.entity.posicao,this.state.entity.servico.id)}>
            remover
          </a>
        </div>
      );

  };
  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity.servico[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });

    this.setState({ validateObject: validate });

    if (_.isEmpty(validate)) {
      isValid = true;
    }
    return isValid;
  };

  render() {
    const { entity } = this.state;
    const {submit} = this.props;

    return (
      <React.Fragment>
        <div className="borderForm">
          <FormRow>
            <FormSelectInput
              name="servico.servico"
              label="Serviço"
              placeholder=""
              size={8}
              className={'col-12 col-sm-8'}
              returnFullObject={true}
              searchable={true}
              id={'servico'}
              ref={input => {
                this._servico = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.servico.servico}
              service={servicoService.findAll}
              required={true}
              erroMensagem={submit&&!( entity.servico.servico)?'Campo obrigatorio!':''}
            />


            <MoneyInput
              type="text"
              name="servico.valorFinal"
              label="Valor Final"
              placeholder=""
              required={true}
              className="col-12 col-sm-4"
              id={'valorFinal'}
              ref={input => {
                this._valorFinal = input;
              }}
              onChange={this.handleSelectChange}
              erroMensagem={submit&&!( entity.servico.valorFinal)?'Campo obrigatorio!':''}
              value={entity.servico.valorFinal}
            />
            {entity&&entity.servico&&entity.servico.servico&&entity.servico.servico.tipo&&entity.servico.servico.tipo === 'TROCA_BATERIA'&&(
              <React.Fragment>
              <FormInput
                type="number"
                name="servico.quantidade"
                label="Qtde."
                placeholder=""
                size={2}
                noSize={false}
                returnFullObject={true}
                searchable={true}
                id={'quantidade'}
                ref={input => {
                  this._quantidade = input;
                }}
                erroMensagem={submit&&!( entity.servico.quantidade)?'Campo obrigatorio!':''}
                onChange={this.handleChange}
                value={entity.servico.quantidade}

              />
              <FormInput
              type="number"
              name="servico.calibre"
              label="Calibre."
              placeholder=""
              size={4}
              noSize={false}
              returnFullObject={true}
              searchable={true}
              id={'calibre'}
              ref={input => {
              this._calibre = input;
            }}
              onChange={this.handleChange}
              erroMensagem={submit&&!( entity.servico.calibre)?'Campo obrigatorio!':''}
              value={entity.servico.calibre}

              />
              </React.Fragment>
            )}
          </FormRow>
          {this.getBotaoRemoveServico()}
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(CardServicosItem);
