//@flow
import * as React from 'react';
import type {ClienteType} from '../../types/cliente.type';
import {withRouter} from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import {Bar, HorizontalBar} from "react-chartjs-2";
import {relatorioService} from "../../../services/relatorio.service";
import _ from 'lodash';
import {marcaService} from "../../../services/marca.service";
import SelectInput from "../../../components/Inputs/SelectInput";
import {ordemServicoHelper} from "../../../helpers/ordemServico.helper";
import StatusSelectInput from "../../../components/Inputs/StatusSelectInput";
import {postoAutorizadoService} from "../../../services/postoAutorizadoService";
import {objectsConstants} from "../../../constants/objects.constants";
import {Can} from "@casl/react";

type Props = {
    cliente: ClienteType
};
const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                stacked: true,
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        xAxes: [
            {
                stacked: true,
            },
        ],
    },
}

class RepresamentoPreReparoSemanal extends React.Component<Props> {

    constructor() {
        super();
        this.state = {data: {}, represamento: {}, filter: {}}
    }

    componentDidMount() {
        this.reloadDashboard();
    }

    handleSelectChange = (name: string, option: any) => {
        const {filter} = this.state;
        let newfilter = _.cloneDeep(filter);
        _.set(newfilter, name, option);
        this.setState({filter: newfilter, submitFilter: false});
    };

    reloadDashboard = () => {
        if (this.state.filter && this.state.filter.statusOS) {
            this.state.filter.statusOsList = _.map(this.state.filter.statusOS, status => {
                return status.id
            });
        }
        relatorioService.represamentoPreReparoSemanal(this.state.filter).then(response => {
            let data = {
                labels: _.map(response.data.listRepresamentoStatusOs, (represamentoStatus) => {
                    return represamentoStatus.status.description;
                }),
                datasets: [
                    {
                        label: 'Até 5 dias',
                        data: _.map(response.data.listRepresamentoStatusOs, (represamentoStatus) => {
                            return represamentoStatus.represamento0A5Dias;
                        }),
                        backgroundColor: '#82ACFE',
                    },
                    {
                        label: 'Entre 06 e 15 dias',
                        data: _.map(response.data.listRepresamentoStatusOs, (represamentoStatus) => {
                            return represamentoStatus.represamento6A15Dias;
                        }),
                        backgroundColor: '#92FE82',
                    },
                    {
                        label: 'Entre 16 e 25 dias',
                        data: _.map(response.data.listRepresamentoStatusOs, (represamentoStatus) => {
                            return represamentoStatus.represamento16A25Dias;
                        }),
                        backgroundColor: '#FBFE82',
                    },
                    {
                        label: 'Mais de 25 dias',
                        data: _.map(response.data.listRepresamentoStatusOs, (represamentoStatus) => {
                            return represamentoStatus.represamentoMais26;
                        }),
                        backgroundColor: '#FE8282',
                    },
                ],
            }
            this.setState({data, represamento: response.data})
        })
    };

    render() {
        const {cliente, permissions} = this.props;
        const {filter} = this.state;
        let total = this.state.represamento.represamento0A5Dias + this.state.represamento.represamento6A15Dias + this.state.represamento.represamento16A25Dias + this.state.represamento.represamentoMais26

        return (
            <div className="card cardSeculus d-flex cardFull flex-column ">
                <div className="topoCard d-flex flex-column">
                    <h3 className="flex-grow-1 mb-0 ml-1">Represamento Pré-Reparo</h3>
                    <h4 className="flex-grow-1 ml-1">Fechamento Semanal</h4>

                </div>

                <div className="form-row searchBox">
                    <SelectInput
                        label="Status"
                        valueKey={'id'}
                        labelKey={'nome'}
                        placeholder={'Status'}
                        id={'statusOS'}
                        name="statusOS"
                        options={ordemServicoHelper.getListStatus()}
                        onChange={this.handleSelectChange}
                        multi={true}
                        returnFullObject={true}
                        size={12}
                        style={'col-sm-3'}
                        value={filter.statusOS}
                        clearable={true}
                    />
                    <Can
                        I={objectsConstants.VIEW}
                        a={objectsConstants.ABILITY_FILTRO_POSTO}
                        ability={permissions}
                    >
                    <SelectInput
                        type="text"
                        name="postosAutorizado"
                        label="Autorizada"
                        placeholder="Autorizada"
                        style={['col-2 col-sm-3']}
                        multi={true}
                        onFetchData={postoAutorizadoService.find}
                        valueKey="id"
                        labelKey="nomeFantasia"

                        returnFullObject={true}
                        searchable={true}
                        id={'posto'}
                        onChange={this.handleSelectChange}
                        value={filter.postosAutorizado}
                    />
                    </Can>
                </div>
                <div className="form-group col-12 col-sm-12 ">
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm float-right"
                        onClick={() => {
                            this.reloadDashboard()
                        }}
                    >
                        buscar
                    </button>
                </div>

                <div className="conteudoCard mt-2 ">
                    <div className="d-flex flex-row justify-content-between align-items-stretch mb-5">
                        <div className="mx-1 flex-even">
                            <h3>OS's Sem Imp./Invoice</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-branco mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2>{this.state.represamento.represamentoSemImportacao}</h2>
                                    <div>{((this.state.represamento.represamentoSemImportacao / total) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-1 flex-even">
                            <h3>OS's + 25 Dias Sem Imp./Invoice </h3>soli
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-vermelho mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2>{this.state.represamento.represamentoMais25SemImportacao}</h2>
                                    <div>{((this.state.represamento.represamentoMais25SemImportacao / this.state.represamento.represamentoSemImportacao) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-1 flex-even">
                            <h3>% Represamento</h3>

                            <h2> {((this.state.represamento.represamentoMais26 / total) * 100).toFixed(2)}%</h2>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between align-items-stretch mb-5">
                        <div className="mx-1 flex-even">
                            <h3>OS's 0 a 5 dias</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-azul mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2>{this.state.represamento.represamento0A5Dias}</h2>
                                    <div>{((this.state.represamento.represamento0A5Dias / total) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-1 flex-even">
                            <h3>OS's 06 a 15 dias</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-verde mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2> {this.state.represamento.represamento6A15Dias}</h2>
                                    <div>{((this.state.represamento.represamento6A15Dias / total) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-1 flex-even">
                            <h3>OS's 16 a 25 dias</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-amarelo mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2> {this.state.represamento.represamento16A25Dias}</h2>
                                    <div>{((this.state.represamento.represamento16A25Dias / total) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="mx-1 flex-even">
                            <h3>OS's + 25 dias</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-vermelho mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2>{this.state.represamento.represamentoMais26}</h2>
                                    <div>{((this.state.represamento.represamentoMais26 / total) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                        <div className="m-1 align-self-stretch">
                            <h3>Total</h3>
                            <div className={'d-flex flex-row'}>
                                <div className={`farol farol-branco mr-3 ml-1`}/>
                                <div className={'flex-grow-1'}>
                                    <h2>{total}</h2>
                                    <div>100%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">

                        <div className={'col-12 col-md-6'}>
                            {this.state.represamento.listRepresamentoStatusOs && (
                                <table className="table">
                                    <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Status</th>
                                        <th scope="col">Até 5 dias</th>
                                        <th scope="col">Entre 06 e 15 dias</th>
                                        <th scope="col">Entre 16 e 25 dias</th>
                                        <th scope="col">Mais de 25 dias</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(_.map(this.state.represamento.listRepresamentoStatusOs, (represamentoStatus) => {
                                            return (<tr>
                                                <th scope="row">{represamentoStatus.status.description}</th>
                                                <td>{represamentoStatus.represamento0A5Dias}</td>
                                                <td>{represamentoStatus.represamento6A15Dias}</td>
                                                <td>{represamentoStatus.represamento16A25Dias}</td>
                                                <td>{represamentoStatus.represamentoMais26}</td>
                                                <td>{represamentoStatus.represamento0A5Dias + represamentoStatus.represamento6A15Dias + represamentoStatus.represamento16A25Dias + represamentoStatus.represamentoMais26}</td>
                                            </tr>)
                                        })
                                    )
                                    }
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className={'col-12 col-md-6'}>
                            <HorizontalBar options={options} data={this.state.data}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user, permissions} = state.authentication;
    return {
        user,
        permissions
    };
}

export default connect(
    mapStateToProps,
    null
)(withRouter(RepresamentoPreReparoSemanal));
