//@flow
import * as React from 'react';
import CardRelatorio from '../../../components/Cards/CardRelatorio';

import { relatorioService } from '../../../services/relatorio.service';
import RelatorioPrazoMedioAtendimentoReparoChart from './prazoMedioAtendimentoReparoChart';
import RelatorioPrazoMedioAtendimentoReparoFormFilter from './prazoMedioAtendimentoReparoFormFilter';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import moment from "moment/moment";

export default class RelatorioPrazoMedioAtendimentoReparo extends React.PureComponent<{}> {
  constructor(props) {
    super(props);

    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      defaultFilter: {
        fimAno:ano,
        inicioAno:ano,
        inicioMes:1,
        fimMes:mes
      },
      filter: {}
    };
  }
  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" Prazo médio atendimento de reparo "
          canBack={true}
        />{' '}
        <CardRelatorio
          methodFindChart={relatorioService.prazoMedioAtendimentoReparo}
          FormFilter={RelatorioPrazoMedioAtendimentoReparoFormFilter}
          renderChartOnConstruct={true}
          defaultFilter={this.state.defaultFilter}
          Chart={RelatorioPrazoMedioAtendimentoReparoChart}
        />
      </React.Fragment>
    );
  }
}
