//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { Pie } from 'react-chartjs-2';
import { analiseFalhasService } from '../../../services/analiseFalhas.service';
import _ from 'lodash';

const options = {
  maintainAspectRatio: false,
  responsive: false,
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 10
    }
  }
};

class ChartPie extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  componentWillReceiveProps(nexProps) {
    if (nexProps.filter && !_.isEqual(nexProps.filter, this.props.filter)) {
      this.callService(nexProps.filter);
    }
  }

  componentDidMount() {
    this.callService(this.props.filter);
  }

  callService = (filter: any) => {
    analiseFalhasService
      .findChartResult({ goupByItem: this.props.groupItem }, filter)
      .then(response => {
        let data = response.data;
        this.setState({
          data: data.data
        });
        if (this.props.dispatcthTotal) {
          let total = 0;
          data.data.map(item => {
            total += item.count;
          });
          let totalName = this.props.totalItem;
          let payLoad = {};
          payLoad[totalName] = total;
          this.props.setTotal(payLoad);
        }
      });
  };

  render() {
    const { data } = this.state;
    let arrayLabels = [];
    let arrayData = [];
    data.map((item, i) => {
      arrayLabels.push(item.nome);
      arrayData.push(item.count);
    });

    const dataChart = {
      labels: arrayLabels,
      datasets: [
        {
          label: 'Análise de falhas',
          data: arrayData,
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FF6384',
            '#4BC0C0',
            '#FFCE56',
            '#E7E9ED',
            '#36A2EB'
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FF6384',
            '#4BC0C0',
            '#FFCE56',
            '#E7E9ED',
            '#36A2EB'
          ]
        }
      ]
    };

    return (
      <React.Fragment>
        <h3 className="pl-10">{this.props.title}</h3>
        {arrayData.length > 0 && (
          <Pie data={dataChart} width={500} options={options} />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ report: { setTotal } }) => ({
  setTotal: totals => setTotal(totals)
});

export default connect(
  null,
  mapDispatch,
  null
)(ChartPie);
