//@flow
import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import icon_alert from '../../img/icon_alert.svg';
import type { TaskType } from '../../types/task.type';
import { connect } from 'react-redux';
import SockJsClient from 'react-stomp';
import { oauthHeaderJson } from '../../helpers/oauth-header';
import { taskService } from '../../services/taskService';
import { withRouter } from 'react-router-dom';

type Props = {
  task: TaskType
};

class TaskRow extends React.PureComponent<Props> {
  constructor(props) {
    super(props);
    taskService.findLastTask(props.user).then(response => {
      this.handleTasks(response.data.data);
    });
    this.state = {
      active: false,
      tasks: [],
      numberTasks: 0,
      task: null
    };
  }
  handleTasks = data => {
    this.props.addAllTask(data);
    this.setState({ tasks: data.tarefas, numberTasks: data.size });
  };
  handleEvent = (task, props) => {
    let taskType = taskService.getTaskType(task.taskType);
    props.runTask(task);
    if (taskType.url !== props.location.pathname) {
      if (taskType.withParam) {
        props.history.push(`${taskType.url}${task.idEntity}`);
      } else {
        props.history.push(`${taskType.url}`);
      }
    }
  };
  render() {
    const { user, location } = this.props;
    //TODO colocar segurança e organizar codigo
    const wsSourceUrl = taskService.getWsUrl();
    const topic = taskService.getWsTopic(user);
    let headers = oauthHeaderJson();
    let handleEvent = this.handleEvent;
    let props = this.props;
    return (
      <Container>
        <div
          onClick={() => {
            handleEvent(this.props.task, this.props);
          }}
        >
          <Row className="rowStyle align-items-center ">
            <Col xs="1">
              <img src={icon_alert} width={30} height={30} alt="logo" />
            </Col>
            <Col xs="4">
              <span>{this.props.task.title}</span>
            </Col>
            <Col xs="4">
              <span>{this.props.task.description}</span>
            </Col>
            <Col xs="3">
              <span className="wb">{this.props.task.local}</span>
            </Col>
          </Row>
        </div>
        <SockJsClient
          url={wsSourceUrl}
          topics={[topic]}
          onMessage={msg => {
            this.handleTasks(msg);
          }}
          subscribeHeaders={headers}
          headers={headers}
          ref={client => {
            this.clientRef = client;
          }}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages
  };
}
const connectedTaskRowComponente = withRouter(TaskRow);
const mapDispatch = ({ task: { addAllTask, runTask } }) => ({
  addAllTask: listTask => addAllTask(listTask),
  runTask: tarefa => runTask(tarefa)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(connectedTaskRowComponente);
