import React, { Component } from "react";
import _ from "lodash";
import checkSucess from "../../img/checkSucess.png";
import "../../css/CadastroGarantia.css";

export default class CadastroGarantiaConcluido extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="SuccessPage">

                <img src={checkSucess} className={"CheckSuccessImg "} />

                <h1>Sucesso!</h1>
                <h4 className="m-5 ">
                    O cadastro da sua garantia foi concluido!
                </h4>
                <p> Além da garantia física você passa a ter agora a garantia eletrônica.
                </p>
                <p> Garantindo rapidez na identificação e o facíl acesso a todos os Centro de Serviço no Brasil.
                </p>
            </div>

        );
    }
}
