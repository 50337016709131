//@flow
import React from 'react';
import { authenticationService } from '../services/authentication.service';
import type { UserType } from '../types/user.type';
import { userService } from '../services/user.service';

import { getPermission} from '../config/ability';

type State = {
  loggedIn: boolean,
  user: UserType,
  refresh: boolean,
  permissions: any
};

type Payload = {
  user: UserType,
  company: any
};

export default {
  state: {
    loggedIn: false,
    user: {},
    refresh: false,
    company: {},
    permissions: {}
  },
  reducers: {
    success: (state: State, payload: Payload) => {
      let permissions = getPermission(payload.user);

      // ability.update([
      //   { subject: 'all', actions: 'manage' },
      //   { subject: 'all', actions: 'approve' }
      // ]);
      return Object.assign({}, state, {
        loggedIn: true,
        user: payload.user,
        permissions: permissions
      });
    },
    logout: (state: State) => {
      authenticationService.doLogout();
      return Object.assign({}, state, {
        loggedIn: false,
        user: {},
        permissions: {}
      });
    },
    refreshUser: (state: State, payload: Payload) => {
      //TODO: change user quando precisar
      return Object.assign({}, state, {
        refresh: true,
        user: payload.user
      });
    },
    loadCompany: (state: State, payload: Payload) => {
      return Object.assign({}, state, {
        refresh: true,
        company: payload.company
      });
    }
  },
  effects: (dispatch: any) => ({
    login: async (payload: { user: string, password: string }) => {
      console.log(dispatch.load)
      dispatch.load.loading({load:true});
      await authenticationService.doLogin(payload.user, payload.password).then(
        async () => {
          let user = await userService.me().then(response=>{
            const user = response.data;
            dispatch.authentication.success({ user });
            dispatch.load.loading({load:false});
          });

        },
        () => {
          dispatch.authentication.logout();
          dispatch.alert.error({
            message: 'Confira o seu login e senha e tente novamente!',
            code: 'Ops,'
          });
          dispatch.load.loading({load:false});
        }
      );
    }
  })
};
