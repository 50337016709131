import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TaskView from '../task/TaskView';
import BoxOrdemServico from "../boxOrdemServico/BoxOrdemServico";
type Props = {};
class HomeManausTroca extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: {} };
  }

  onChangeSelect = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };

  render() {
    return (
      <React.Fragment>
        <div className="flex-column">
          <TaskView />
        </div>
        <div className="flex-column">
          <BoxOrdemServico />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages
  };
}

export default connect(mapStateToProps)(HomeManausTroca);
