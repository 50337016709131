//@flow
import * as React from 'react';
import { Line } from 'react-chartjs-2';
export default class RelatorioPrazoMedioAtendimentoChart extends React.PureComponent<{}> {
  render() {
    let data = null;
    if (this.props.data) {
      let labels = [];
      let prazoMes = {
        label: 'Prazo médio dias',
        data: [],
        borderColor: 'blue',
        backgroundColor: 'blue',
        fill: false,
        lineTension: 0.1
      };
      let prazoAcumulado = {
        label: 'Acumulado',
        data: [],
        borderColor: 'red',
        backgroundColor: 'red',
        fill: false,
        lineTension: 0.1
      };

      for (var i = 0; i < this.props.data.length; i++) {
        labels.push(this.props.data[i].mes + '/' + this.props.data[i].ano);
        prazoMes.data.push(this.props.data[i].prazoMes);
        prazoAcumulado.data.push(this.props.data[i].prazoAcumulado);
      }
      let datasets = [];
      datasets.push(prazoMes);
      datasets.push(prazoAcumulado);
      data = { labels, datasets };
    }

    return <div>{data && <Line data={data} height={50} />}</div>;
  }
}
