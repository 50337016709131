//@flow
import { Can } from '@casl/react';
import _ from 'lodash';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../constants/objects.constants';
import '../../css/boxOS_Selecionada.css';
import { compraPecaService } from '../../services/compraPeca.service';
import { FooterTablePagination } from '../table/FooterTablePagination';
import OrdemServicoItem from './OrdemServicoItem';
import { userService } from '../../services/user.service';

type Props = { cliente: any, showNewOS: boolean };

const defautOptions = {
  pageSize: 10,
  page: 1,
  order: 'farol',
  direction: 'desc'
};
class CardCompraPecaList extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    let options = defautOptions;

    console.log(this.props.posto);

    let filter = {
      posto: {
        id: this.props.extension
          ? this.props.extension
          : _.get(this.props, 'posto.id')
      }
    };

    this.state = {
      ordens: [],
      allowSearch: false,
      options,
      filter,
      modal: false
    };
  }
  handleConfirm = () => {};

  componentDidMount() {
    this.requestData(this.state.options, this.state.filter);
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.refreshOs) {
      this.requestData(this.state.options);
    }
  }

  requestData = (options: any, filter: any) => {
    this.props.loading(true);
    compraPecaService
      .findByFiltro(options, filter)
      .then(response => {
        let data = response.data;
        this.setState({
          ordens: data.data,
          dataTotalSize: data.dataTotalSize,
          currentPage: options.page,
          order: options.order,
          direction: options.direction
        });
        this.props.loading(false);
      })
      .catch(error => {
        this.props.loading(false);
        console.log(error);
      });
  };

  handleSelectOS = (id: number) => {
    if (this.props.showNewOS) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confirm-ui success">
              <h3>
                <span className="txtRed">Cancelar Solicitação de peças?</span>
              </h3>
              <p className="p-msg">
                Ao clicar em outra solicitação de compra, os dados digitados
                serão perdidos.
              </p>
              <p>
                <b>Deseja realmente selecionar outra solicitação de compra?</b>
              </p>
              <button
                className="btn btn-primary my-4 mx-lg-3"
                onClick={() => {
                  this.loadItem(id, true);

                  onClose();
                }}
              >
                Selecionar compra
              </button>
              <button
                className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
                onClick={() => {
                  onClose();
                }}
              >
                Não, obrigado
              </button>
            </div>
          );
        }
      });
    } else {
      this.loadItem(id);
    }
  };

  loadItem = (id, redirect) => {
    this.props.loading(true);
    compraPecaService.getById(id).then(response => {
      this.props.setOrdemServico(response.data.data);
      this.props.loading(false);
      if (redirect) {
        this.props.history.push('/compraPeca/view');
      }
    });
  };

  openSearchBar = () => {
    this.setState({ allowSearch: true });
  };
  closeSearchBar = () => {
    this.setState({ allowSearch: false });
  };

  filterOS = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.filter);
    let valueUpper = _.isNil(value) ? null : _.toUpper(value);
    _.set(newFilter, name, valueUpper);
    this.setState({ filter: newFilter }, () => {
      if (this.state.filter.marcaOS && this.state.filter.marcaOS.length > 1) {
        this.requestData(this.state.options, this.state.filter);
      } else if (!this.state.filter.marcaOS) {
        this.requestData(this.state.options, this.state.filter);
      }
    });
  };

  getHeader = () => {
    const { permissions } = this.props;
    let _self = this;
    if (!this.props.showNewOS) {
      return (
        <div className="boxButon float-right flex-shrink-1 d-sm-flex">
          {!this.state.allowSearch && (
            <Can
              I={objectsConstants.VIEW}
              a={objectsConstants.ABILITY_SOLICITAR_COMPRA_PECA}
              ability={permissions}
            >
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={() => {
                  this.props.clearOS();
                  if (userService.isAdmin()) {
                    this.props.history.push('/compraPeca/novo', {
                      entity: { posto: this.props.posto }
                    });
                  } else {
                    this.props.history.push('/compraPeca/novo');
                  }
                }}
              >
                Nova SOLICITAÇÃO
              </button>
            </Can>
          )}
          {this.state.allowSearch && (
            <input
              type="text"
              className={`form-control boxInputSearch`}
              name="marcaOS"
              placeholder="Buscar Nº CP, Relógio"
              onChange={this.filterOS}
              value={this.state.filter.marcaOS}
            />
          )}
          {!this.state.allowSearch && (
            <i className="icon-search boxIcon" onClick={this.openSearchBar} />
          )}
          {this.state.allowSearch && (
            <i className="icon-x boxIcon" onClick={this.closeSearchBar} />
          )}
        </div>
      );
    }
  };

  handlePageChange = (page: number) => {
    const newOption = _.cloneDeep(this.state.options);
    newOption.page = page;
    this.setState(
      {
        options: newOption
      },
      () => {
        this.requestData(this.state.options, this.state.filter);
      }
    );
  };

  render() {
    let _self = this;
    return (
      <div className="card cardSeculus d-flex flex-column w1">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">
            compra de peças (
            {this.props.showNewOS
              ? this.state.ordens.length + 1
              : this.state.ordens.length}
            )
          </h3>
          {_self.getHeader()}
        </div>
        <div className="conteudoCard flex-fill">
          {this.props.showNewOS && (
            <div className="d-flex flex-row itemListaOS align-items-center  osSelecionada">
              <div className="col-4 tipo">{'CP '}--</div>
              <div className="col-3 item">--</div>
              <div className="col-4 text-right data">Em criação</div>
              <div className="col-1">
                <div className="farol farol-azul" />
              </div>
            </div>
          )}
          {this.state.ordens.map(function(os, i) {
            return (
              <OrdemServicoItem
                itemOS={os}
                key={os.id}
                onSelectOS={_self.handleSelectOS}
              />
            );
          })}
        </div>

        <FooterTablePagination
          pages={this.state.pages}
          sizePerPage={this.state.options.pageSize}
          dataTotalSize={this.state.dataTotalSize}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  const { user, permissions } = state.authentication;
  const { extension } = state.postoAutorizado;
  return {
    ordemServico,
    user,
    permissions,
    extension
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, createOrdemServico, clearOS },
  alert: { error },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  createOrdemServico: osType => createOrdemServico(osType),
  clearOS: () => clearOS(),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(CardCompraPecaList));
