//@flow
import * as React from 'react';
import {Route, Switch} from 'react-router-dom';
import ConfigurarPerfilListagem from "./ConfigurarPerfilListagem";

export default class ConfigurarPerfil extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/perfil"
            exact
            component={ConfigurarPerfilListagem}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
