//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import { translate } from '../../helpers/message.helper';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';

type Props = {
  name: string,
  label: string,
  onChange: any,
  size?: number,
  required: boolean,
  messageValidate?: Array<any>,
  value?: any,
  messages?: any
};

type State = {
  value?: any
};

const floatRegExp = new RegExp('^[+-]?([0-9]+([,][0-9]*)?|[,][0-9]+)$');

class NumberInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    required: false,
    value: ''
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }


  render() {
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }
    const { messages, name } = this.props;

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }

    return (
      <div className={`form-group ${this.props.className}`}>
        <React.Fragment>
          <label htmlFor={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </label>
          {this.props.helpText && (
            <React.Fragment>
              <i className="icon-duvida" id={'help_' + this.props.id} />
              <ToolTipHelp
                target={'help_' + this.props.id}
                message={this.props.helpText}
              />
            </React.Fragment>
          )}
        </React.Fragment>
        <input
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
          onChange={(e)=>{
            const value = e.target.value
            this.props.onChange(this.props.name , value)
          }}
          type=""
          name={this.props.name}
          value={this.props.value}
          placeholder={this.props.placeholder}
          maxLength={''}
          disabled={this.props.disabled}
          id={this.props.id}
        >
          {this.props.children}
        </input>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');
  return v;
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(NumberInput);
