//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import { docService } from '../../services/doc.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { DocListFilter } from './DocListFilter';
import {osAguardandoImportacaoService} from "../../services/osAguardandoImportacao.service";

const columns = [
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'status.description',
    title: 'Status',
    isKey: false,
    dataSort: false
  },
  { path: 'qtdPeca', title: 'Qtd de Peças', isKey: false, dataSort: true },


];

type State = {
  modal: boolean
};

class OsAguardandoImportacaoList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {fields};

  }



  handleRowClick = (row: e) => {
    this.props.history.push('/aguardandoImportacao/view/' + row.marca.id+'/'+row.status.name);
  };

  renderCustom(row: any, doc: any, column: any) {
    return tableHelper.renderCustom(row, doc, column);
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Doc´s" subTitle=" " canBack={false} />
        <ListagemPage
          title={`Peças Aguardando Importação(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={osAguardandoImportacaoService}
          labelNewButton="Nova OS"
          // classSignal="temSinal"
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={{}}
          onRowClick={this.handleRowClick}
          FormFilter={DocListFilter}
          showNewButton={false}
          controller="osAguardandoImportacao"
          fields={this.state.fields}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;
  const { user } = state.authentication;

  return {
    subTitle,
    mainTitle,
    user
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(OsAguardandoImportacaoList);
