//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const importacaoOrdemCompraClientePjService = {
  findAll,
  findByFiltro,
  importarPecas,
  findByClienteAndRelogio
};
function findAll() {
  return defaultService.doGet(`${urlsConstants.IMPORTACOES_ORDEM_COMPRA_CLIENTE_PJ_FINDALL}`);
}
function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.IMPORTACOES_ORDEM_COMPRA_CLIENTE_PJ}list`,
    options,
    filter
  );
}
function importarPecas(archive: any) {
  return defaultService.doPost(
    urlsConstants.IMPORTACOES_ORDEM_COMPRA_CLIENTE_PJ+"importar/",
    archive
  );
}

function findByClienteAndRelogio(cliente,relogio){
  return defaultService.doGet(urlsConstants.IMPORTACOES_ORDEM_COMPRA_CLIENTE_PJ+'findByClienteAndRelogio/'+cliente.id+'/'+relogio.id);
}