//@flow
import * as React from 'react';
import FormInput from '../../../components/Inputs/FormInput';
import SelectInput from '../../../components/Inputs/SelectInput';
import CpfCnpjInput from '../../../components/Inputs/CpfCnpjInput';
import { marcaService } from '../../../services/marca.service';
import moment from 'moment/moment';
import DatePickerInput from '../../../components/Inputs/DatePickerInput';
import StatusSelectInput from '../../../components/Inputs/StatusSelectInput';
import EstadoInput from '../../../components/Inputs/EstadoInput';
import CidadeInput from '../../../components/Inputs/CidadeInput';
import { postoAutorizadoService } from '../../../services/postoAutorizadoService';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { relogioService } from '../../../services/relogio.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class OrdemServicoListFilterAdmin extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    console.log(entity);
    return (
      <React.Fragment>
        <FormInput
          label="Número OS"
          placeholder={'Número OS'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <SelectInput
          type="text"
          name="marca"
          label="Marca"
          placeholder="Marca"
          parent={entity.marca}
          style={['col-6 col-sm-2']}
          onFetchData={marcaService.find}
          valueKey="id"
          labelKey="nome"
          returnFullObject={true}
          searchable={true}
          //messageValidate={validate.portadorDeficiencia}
          id={'marca'}
          onChange={this.props.onChangeSelect}
          value={entity.marca}
        />
        <SelectInput
          type="text"
          id={'relogioInput'}
          name={'relogio'}
          label={`Código do relógio*`}
          placeholder="Código do relógio"
          onFetchData={relogioService.find}
          valueKey="codigoRelogio"
          labelKey="codigoRelogio"
          returnFullObject={true}
          searchable={true}
          onChange={this.props.onChangeSelect}
          value={entity.relogio}
          style={['col-6 col-sm-2']}
          parent={entity.marca}
        />
        <EstadoInput
          name="estado"
          value={entity.estado}
          style={'col-2 col-sm-1'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          label="UF"
        />
        <CidadeInput
          name="cidade"
          value={entity.cidade}
          style={'col-4 col-sm-2'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          parentValue={entity.estado}
        />

        <SelectInput
          type="text"
          name="posto"
          label="Autorizada"
          placeholder="Autorizada"
          style={['col-2 col-sm-3']}
          onFetchData={postoAutorizadoService.find}
          valueKey="id"
          labelKey="nomeFantasia"
          returnFullObject={true}
          searchable={true}
          id={'posto'}
          onChange={this.props.onChangeSelect}
          value={entity.posto}
        />
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'statusOS'}
          name="statusOS"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.statusOS}
          service={ordemServicoHelper.getListStatus}
        />
        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataUltimoStatusInicio"
          label="Data do ultimo status. De"
          value={
            entity.dataUltimoStatusInicio
              ? moment(entity.dataUltimoStatusInicio)
              : null
          }
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataUltimoStatusInicio'}
        />
        <DatePickerInput
          name="dataUltimoStatusFim"
          label="Data do ultimo status. Até"
          placeholder={'--/--/--'}
          value={
            entity.dataUltimoStatusFim
              ? moment(entity.dataUltimoStatusFim)
              : null
          }
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-2'}
          id={'dataUltimoStatusFim'}
        />
        <SelectInput
          label="Garantia"
          id={'garantia'}
          options={[
            { label: 'Sim', value: true },
            { label: 'Não', value: false },
          ]}
          returnFullObject={true}
          valueKey={'value'}
          name={'filtroGarantia'}
          noSize={true}
          value={entity.filtroGarantia}
          onChange={this.props.onChangeSelect}
          size={12}
          style={'col-sm-3'}
        />
      </React.Fragment>
    );
  }
}
