//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import TaskButton from '../../components/Buttons/TaskButton';
import { HeaderCardComponent } from '../../components/RegisterComponents/HeaderCardComponent';
import { CardItem } from '../../components/templates/CardItem';
import RegisterFirstStepFormView from './RegisterFirstStepFormView';

import { TermoUsoView } from './TermoUsoView';
import { ImageProfile } from '../../components/Inputs/ImageProfile';
import { urlsConstants } from '../../constants/url.constant';
import RegisterSecondStepFormView from './RegisterSecondStepFormView';

type Props = { entity: any, step: number };
type State = {
  entity: any,
  isModalVisible: boolean
};

class RegisterFifthStep extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      entity: _.cloneDeep(this.props.entity),
      isModalVisible: false,
      step: props.step
    };
    this._togglemodal = this._togglemodal.bind(this);
  }

  _togglemodal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  getAvatar(entity: any) {
    if (entity.foto) {
      return `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    }
  }

  finalizeTask = task => {
    this.props.runTask(task, this.props.location);

    /*let { tarefas, step } = this.state;
    let openTasks = tarefas.filter(taskitem => {
      if (taskitem.id !== task.id) return taskitem;
    });
    //finalizou todas as tarefas conclui tudo
    if (openTasks.length === 0) {
      step = 6;
    }
    this.setState({ tarefas: openTasks, step });*/
  };

  componentWillReceiveProps(nextProps: any) {
    this.setState({ entity: _.cloneDeep(nextProps.entity) });
  }

  getContentEndTab = () => {
    let tarefas = this.props.taskList;
    if (tarefas && tarefas.length > 0) {
      return (
        <React.Fragment>
          <div className="form-row align-items-center m-1 my-4 m-lg-5">
            <div className="col-3 col-lg-3 text-center">
              <img
                src={require('../../img/icon_alert.svg')}
                className="icon-alert"
                alt="Ícone de Alerta"
              />
            </div>
            <div className="col-9 col-lg-8">
              <p className="m-0">
                Falta pouco para concluir seu cadastro, para isso basta concluir{' '}
                <b>{tarefas.length} tarefas</b> pendentes que são:
              </p>
            </div>
          </div>

          {tarefas.map((task, index) => (
            <TaskButton
              key={task.id}
              task={task}
              title={task.title}
              description={task.description}
              onClick={this.finalizeTask}
            />
          ))}
        </React.Fragment>
      );
    } else {
      return (
        <div className="form-row align-items-center justify-content-center m-1 my-4 m-lg-5">
          <div className="col-12 col-lg-12 text-center">
            <img
              src={require('../../img/icon_contract.svg')}
              className="icon-contract"
              alt="Ícone de um contrato"
            />
          </div>
          <div className="col-10 col-lg-10 text-center">
            <h1 className="mt-4 txtGreen">EM AVALIAÇÃO</h1>
            <p className="m-0">
              Parabéns seu cadastro foi concluído com sucesso!
              <br />
              <br />
              Nossos consultores estão avaliando e ao termino dessa avaliação
              você será informado por e-mail.
            </p>
          </div>
        </div>
      );
    }
  };
  render() {
    const { entity } = this.state;
    return (
      <section id="sectionCadastro finalize">
        <CardItem>
          <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
            {/* <div className="wizard-container"> */}
            <div className="wizard-card" data-color="orange" id="wizardProfile">
              <HeaderCardComponent
                onBack={this.onBack}
                disableForwardBtn={true}
                hideBtns={true}
                disableBackBtn={true}
                step={this.state.step}
              />
              <form className="cadastro" action="" method="">
                <div className="tab-content">
                  <div className="tab-pane active" id="conclusao">
                    {this.getContentEndTab()}
                  </div>
                  <div className="tab-pane" id="empresa">
                    <RegisterFirstStepFormView entity={entity} />
                  </div>
                  <div className="tab-pane" id="responsavel">
                    <RegisterSecondStepFormView entity={entity} />
                  </div>

                  <div className="tab-pane" id="termo">
                    <TermoUsoView />
                  </div>

                  <div className="tab-pane mt-4" id="confirmacao">
                    <div className="form-row justify-content-center">
                      <div className="col-10 col-lg-6 text-center">
                        <ImageProfile
                          name={''}
                          dataUrl={this.getAvatar(entity)}
                          className={'cadastro_foto'}
                        />
                      </div>
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-12 text-center">
                        <h2>
                          olá <b>{entity.nome}</b>
                        </h2>
                        <p className="txtBlue">{entity.email}</p>
                        <p>Seu e-mail foi confirmado com sucesso!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {/* </div> */}
        </CardItem>
      </section>
    );
  }
}

function mapStateToProps(state) {
  const { taskList, currentTask } = state.task;

  return {
    taskList
  };
}
const mapDispatch = ({ task: { runTask } }) => ({
  runTask: (tarefa, location) => runTask({ tarefa, location })
});
export default connect(
  mapStateToProps,
  mapDispatch
)(RegisterFifthStep);
