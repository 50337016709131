//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const analiseFalhasService = {
  findByFiltro,
  findChartResult
};

function findChartResult(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.doPost(`${urlsConstants.RELATORIO}analiseFalhasChart`, {
    goupByItem: options.goupByItem,
    countDistinct: options.countDistinct,
    filter
  });
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO}analiseFalhas`,
    options,
    filter
  );
}
