//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { FormRow } from '../../components/FormRow';
import CardFormEditView from '../../components/templates/CardFormEditView';
import { urlsConstants } from '../../constants/url.constant';
import { dateHelper } from '../../helpers/date.helper';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import RegisterSecondStepForm from './RegisterSecondStepForm';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  edit: boolean
};

class RegisterSecondStepFormView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.state = {
      entity: _.cloneDeep(this.props.entity),
      edit: false
    };
  }

  componentWillReceiveProps(nexProps) {
    this.setState({ entity: _.cloneDeep(nexProps.entity) });
  }

  getForm() {
    return this._form;
  }

  handleSaved = entity => {
    let newEntity = _.cloneDeep(entity);
    this.setState({ entity: newEntity });

    let newUser = _.cloneDeep(this.props.user);
    newUser.name = newEntity.nome;
    newUser.foto = newEntity.foto;
    newUser.cpf = newEntity.cpf;
    newUser.dataNascimento = newEntity.dataNascimento;
    newUser.celular = newEntity.celular;
    newUser.email = newEntity.email;
    this.props.refresh({ user: newUser });
  };

  updateUser = newEntity => {};

  componentWillReceiveProps(nextProps: any) {
    this.setState({ entity: _.cloneDeep(nextProps.entity) });
  }

  setEditMode = (edit: boolean) => {
    this.setState({ edit });
  };

  loadImage = () => {
    const { entity } = this.state;
    let url;
    if (entity.foto) {
      url = `${urlsConstants.MIDIA_IMAGE}${entity.foto.id}`;
    } else {
      url = require('../../img/avatarDefault.jpg');
    }
    return (
      <img
        src={url}
        className="cadastro_foto"
        name="fotoPerfil"
        alt="Foto de perfil"
      />
    );
  };

  getFormContent = () => {
    const { entity } = this.state;
    if (this.state.edit === true) {
      return (
        <RegisterSecondStepForm
          entity={entity}
          hidePassWord={true}
          ref={i => {
            this._form = i;
          }}
        />
      );
    } else {
      return (
        <React.Fragment>
          <FormRow>
            <div className="col-12 col-lg-3 text-center">
              <div className="col-12">
                <label>Foto</label>
              </div>
              <div className="col-12 px-0">{this.loadImage()}</div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="form-row">
                <div className="col-12 col-lg-12">
                  <h5 className="title">Nome completo:</h5>
                  <p>
                    <b>{entity.nome}</b>
                  </p>
                </div>
                <div className="col-6 col-lg-4">
                  <h5 className="title">Data de nasc.:</h5>
                  <p>
                    {dateHelper.format(entity.dataNascimento, {
                      pattern: 'DD/MM/YYYY'
                    })}
                  </p>
                </div>
                <div className="col-6 col-lg-4">
                  <h5 className="title">CPF:</h5>
                  <p>{entity.cpf}</p>
                </div>
                <div className="col-8 col-lg-4 pb-3 pb-lg-4">
                  <h5 className="title">Telefone Celular:</h5>
                  <a role="button" href="#">
                    {entity.celular}
                  </a>
                </div>
                <div className="col-12 col-lg-12 pb-4 pb-lg-4">
                  <h5 className="title">E-mail:</h5>
                  <a role="button" href="#">
                    {entity.email}
                  </a>
                </div>
              </div>
            </div>
          </FormRow>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <CardFormEditView
        setEditMode={this.setEditMode}
        title="Responsável pela empresa"
        onSaved={this.handleSaved}
        getForm={this.getForm}
        onSave={postoAutorizadoService.doSave}
      >
        {this.getFormContent()}
      </CardFormEditView>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

const mapDispatch = ({ authentication: { refreshUser } }) => ({
  refresh: user => refreshUser(user)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(RegisterSecondStepFormView);
