import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import _ from 'lodash';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { tableHelper } from '../../../helpers/tableHelper';
import moment from 'moment';
import { relatorioRelogiosReincidentesService } from '../../../services/relatorioRelogiosReincidentes.service';
import RelatorioRelogiosReincidentesFormFilter from './relatorioRelogiosReincidentesFormFilter';

const columns = [
  
  {
    path: 'codigoRelogio',
    title: 'Código do relógio',
    isKey: true,
    dataSort: true,
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: true,
  },
  {
    path: 'quantidadeReincidencia',
    title: 'Quantidade de entrada',
    isKey: false,
    dataSort: true,
  },
];

class RelatorioRelogiosReincidentes extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      fields,
      defaultFilter: {
        mes,
        ano,
      },
    };
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="Análise de relógios reincidentes"
          canBack={false}
        />
        <CardRelatorio
          title={`Análise de relógios reincidentes (${this.props.subTitle})`}
          FormFilter={RelatorioRelogiosReincidentesFormFilter}
          showTable={true}
          tableService={relatorioRelogiosReincidentesService}
          controller={'relatorio/exportListRencidencia'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          hideExport={false}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle,
  };
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load }),
});

export default connect(
  mapStateToProps,
  mapDispatch
)(RelatorioRelogiosReincidentes);
