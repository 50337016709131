//@flow
import * as React from 'react';

import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import moment from 'moment';
import connect from 'react-redux/es/connect/connect';
import AnaliseFalhasFormFilter from './AnaliseFalhasFormFilter';
import { analiseFalhasServicoService } from '../../../services/analiseFalhasServico.service';
import { tableHelper } from '../../../helpers/tableHelper';
import { analiseFalhasService } from '../../../services/analiseFalhas.service';
//Nº
const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código do relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'servico.nome',
    title: 'Serviço',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'questionamentoCliente',
    title: 'Questionamento do cliente',
    isKey: false,
    dataSort: false,
    yesNo: false
  }
];

const columnsPeca = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Código do relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'peca.nome',
    title: 'Parte',
    isKey: false,
    dataSort: true
  },
  {
    path: 'sintoma.nome',
    title: 'Sintoma',
    isKey: false,
    dataSort: true
  },
  {
    path: 'causa.nome',
    title: 'Causa',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'questionamentoCliente',
    title: 'Questionamento do cliente',
    isKey: false,
    dataSort: false,
    yesNo: false
  }
];

class AnaliseFalhasServico extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      
      defaultFilter: {
        mes,
        ano,
        sintetico: false
      },
      filter: {},
      type:'PECA'
    };
  }

  filterChange=(filter)=>{
   this.setState({type:filter.type})
  }
  render() {
    const {type} = this.state;
    console.log(type)
    let fields = _.map(type=='PECA'?columnsPeca:columns, function(coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(type=='PECA'?columnsPeca:columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle={`ANÁLISE DE FALHAS(${type=='PECA'?'PEÇAS':'SERVIÇOS'})`}  
          canBack={false}
        />
        <CardRelatorio
          title={`ANÁLISE DE FALHAS(${type=='PECA'?'PEÇAS':'SERVIÇOS'})  (${this.props.subTitle})`}
          FormFilter={AnaliseFalhasFormFilter}
         /* Chart={AnaliseFalhasChart}*/
          showTable={true}
          tableService={type=='PECA'?analiseFalhasService:analiseFalhasServicoService}
          type={type}
          controller={type=='PECA'?'relatorio/analiseFalhas':'relatorio/analiseFalhasServico'}
          fields={fields}
          filterChange={this.filterChange}
          defaultFilter={this.state.defaultFilter}
         
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(AnaliseFalhasServico);
