import React, { Component } from "react";
import { relogioService } from "../../services/relogio.service";
import NumberInput from "../../components/Inputs/NumberInput";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import "../../css/CadastroGarantia.css";
import _ from "lodash";
import SelectInput from "../../components/Inputs/SelectInput";
import FotoRelogio from "../../components/FotoRelogio";


export default class GarantiaDadosRelogio extends Component {
    constructor(props) {
        super(props);
        this.state =  { numeroNF: "", data: "", relogio: null };
    }



    render() {
        console.log(this.state);
        const {setFieldValue, values , errors} = this.props;
        return (
            <div className="row ">

                <div className="col-lg-8 col-12">
                    <NumberInput
                        className={"col-12"}
                        label={"N° da nota fiscal"}
                        type=''
                        name={"numeroNF"}
                        id={'numeroNF'}
                        placeholder={"Nota fiscal"}
                        value={values.numeroNF}
                        onChange={setFieldValue}
                        erroMensagem={errors && errors.numeroNF}
                    />

                    <DatePickerInput
                        className={"col-12"}
                        label={"Data da compra"}
                        name={"dataNF"}
                        id={'data'}
                        placeholder={"dd/mm/aa"}
                        value={values.dataNF}
                        onChange={setFieldValue}
                        erroMensagem={errors && errors.dataNF}
                    />

                    <SelectInput
                        type="text"
                        style={""}
                        className={"col-12"}
                        id={"relogioInput"}
                        name={"relogio"}
                        data={'relogio'}
                        noSize={true}
                        label={'Codigo do relógio'}
                        placeholder="Código do relógio"
                        onFetchData={relogioService.findByGarantia}
                        valueKey="relogio"
                        labelKey="codigoRelogio"
                        returnFullObject={true}
                        searchable={true}
                        onChange={setFieldValue}
                        value={values.relogio}
                        erroMensagem={errors && errors.relogio}
                    />
                </div>

                <div className="col-12 col-lg-4">
                    <FotoRelogio
                        relogio={
                            values.relogio ? values.relogio : null
                        }
                    />
                </div>
            </div>
        );
    }
}
