//@flow
import { Can } from '@casl/react';
import { Formik } from 'formik';
import React, { PureComponent } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import FormSelectInput from '../../../components/Inputs/FormSelectInput';
import NumberInput from '../../../components/Inputs/NumberInput';
import { objectsConstants } from '../../../constants/objects.constants';
import { causaOsService } from '../../../services/causa.service';
import { sintomaOsService } from '../../../services/sintoma.service';
import PostoImageLoad from '../../postoAutorizado/PostoImageLoad';
import { propertyConstantes } from '../../../constants/property.constantes';
import { defaultService } from '../../../services/defaultService';
import _ from 'lodash';
import { dateHelper } from '../../../helpers/date.helper';
import moment from 'moment';
import { motivoRejeicaoService } from "../../../services/motivoRejeicao.service";

type Props = {
  onConfirm: any,
  onCancel: any
};

const NewRejectInit = Yup.object().shape({
  motivoRejeicao: Yup.string().required('Campo obrigatório'),
  quantidadeRejeitada: Yup.string().required('Campo obrigatório'),
  fotoPecaRejeitada: Yup.string().test(
    'foto-peca',
    'Foto da peça é obrigatório',
    function(value) {
      const { parent, createError } = this;
      if (
        parent.fotoPecaRejeitada === null ||
        parent.fotoPecaRejeitada === undefined
      ) {
        return createError();
      } else {
        return true;
      }
    }
  )
});

class RejeitarPecasBtn extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      initialValues: props.pecaRegistroTrabalhoDocVO
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  getFotoURL = values => {
    if (!values.fotoPecaRejeitada) {
      return require('../../../img/EnviarFotoPeca.png');
    } else {
      return values.fotoPecaRejeitada.data;
    }
  };

  getButton = () => {
    const { ordemServico } = this.props;
    //não pode rejeitar peças depois de 15 dias de recebimento
    let dateLimite = moment(ordemServico.dataRecebimento).add(15, 'days');
    let isAfterNowReceiver = dateHelper.isAfterNow(dateLimite);

    if (
      ordemServico.osType === propertyConstantes.OS ||
      (ordemServico.statusOS.name !==
        propertyConstantes.AGUARDANDO_RECEBIMENTO &&
        ordemServico.statusOS.name !== propertyConstantes.AGUARDANDO_PECAS &&
        isAfterNowReceiver)
    ) {
      return (
        <button
          type="button"
          className="btn btn-link btn-sm pl-0 txtRed "
          onClick={this.toggle}
        >
          Rejeitar peça
        </button>
      );
    }
  };

  render() {
    const { permissions, ordemServico } = this.props;
    const { initialValues } = this.state;

    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_REJEITAR_PECA}
          ability={permissions}
        >
          <div className="btn-bold">{this.getButton()}</div>
        </Can>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <div className="container">
              <div className="row">
                <div className="col d-flex flex-column">
                  <h3>
                    <span className="txtRed"> REJEITAR PEÇA </span>
                    <br />
                    <span className="txtDarkGray">
                      {' '}
                      {this.props.peca.nome}{' '}
                    </span>
                  </h3>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={NewRejectInit}
                    validateOnChange={false}
                    onSubmit={(values, actions) => {
                      _.unset(values, 'status');
                      this.props.loading(true);
                      this.props.serviceSave(ordemServico.id, values).then(
                        result => {
                          let id = defaultService.getIdFromUrl(
                            result.headers.location
                          );
                          this.props.clear();
                          this.props.serviceGet(id).then(response => {
                            this.props.success({
                              message: 'A peça foi rejeitada!'
                            });
                            this.props.setOrdemServico(response.data.data);
                            this.props.loading(false);
                              this.setState({modal:false});
                          });
                        },
                        () => {
                          this.props.error('Falha ao rejeitar peças.');
                          this.props.loading(false);
                        }
                      );
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="conteudo">
                          <p>
                            Informe abaixo o motivo da rejeição da peça{' '}
                            <b>
                              {' '}
                              {this.props.peca.nome} (
                              {this.props.peca.codigoPeca})
                            </b>{' '}
                            e a quantidade rejeitada
                          </p>
                          {values.enviarFoto && (
                            <div className="row m-auto">
                              <PostoImageLoad
                                name={'fotoPecaRejeitada'}
                                idDom={'foto'}
                                title={'FOTO DA PEÇA'}
                                subititle={
                                  'A foto precisa mostrar toda a peça.'
                                }
                                dataUrl={() => {
                                  return this.getFotoURL(values);
                                }}
                                onChange={(name, value) =>
                                  setFieldValue('fotoPecaRejeitada', value)
                                }
                                entity={values}
                                erroMensagem={errors.fotoPecaRejeitada}
                                className={
                                  errors.fotoPecaRejeitada ? 'error' : ''
                                }
                              />
                            </div>
                          )}
                          {(errors.causa ||
                            errors.sintoma ||
                            errors.quantidadeRejeitada ||
                            !values.enviarFoto) && (
                            <div className="row">

                              <FormSelectInput
                                name="motivoRejeicao"
                                label="Motivo da Rejeição"
                                placeholder=""
                                size={4}
                                style={['col-12 col-sm-12']}
                                returnFullObject={true}
                                searchable={true}
                                valueKey={'value'}
                                labelKey={'descricao'}
                                id={'motivo'}
                                ref={input => {
                                  this._motivo = input;
                                }}
                                onChange={(name, value) =>
                                  setFieldValue('motivoRejeicao', value)
                                }
                                value={values.motivoRejeicao}
                                service={motivoRejeicaoService.findAll}
                                required={true}
                                erroMensagem={errors.causa}
                              />
                              <NumberInput
                                type="text"
                                name="quantidadeRejeitada"
                                label="Qtde."
                                placeholder="Qtde."
                                size={2}
                                className={'col-6 col-sm-6'}
                                returnFullObject={true}
                                searchable={true}
                                id="quantidadeRejeitada"
                                ref={input => {
                                  this._quantidadeRejeitada = input;
                                }}
                                onChange={setFieldValue}
                                value={values.quantidadeRejeitada}
                                erroMensagem={errors.quantidadeRejeitada}
                              />
                            </div>
                          )}
                        </div>

                        <div className="footer pt-5 pb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <button
                              type="button"
                              className="btn btn-primary justify-content-start white"
                              onClick={this.toggle}
                            >
                              <b>Cancelar</b>
                            </button>
                            {!values.enviarFoto && (
                              <button
                                type="submit"
                                className="btn btn-primary red"
                                onClick={() => {
                                  if (
                                    !errors.causa &&
                                    !errors.sintoma &&
                                    !errors.quantidadeRejeitada
                                  )
                                    setFieldValue('enviarFoto', true);
                                }}
                              >
                                Rejeitar
                              </button>
                            )}
                            {values.enviarFoto && (
                              <button
                                type="submit"
                                className="btn btn-primary red"
                                onClick={this.handleConfirm}
                              >
                                ENVIAR FOTO
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  const { user, permissions } = state.authentication;
  return {
    ordemServico,
    user,
    permissions
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { error, success, clear },
  load: { loading }
}) => ({
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  clearOS: () => clearOS(),
  error: (message, code) => error({ message, code }),
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  clear: () => clear()
});

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(RejeitarPecasBtn));
