import * as React from "react";
import AnaliseAtendimentoEprChart from "../analiseAtendimentoEpr/AnaliseAtendimentoEprChart";
import CardRelatorio from "../../../components/Cards/CardRelatorio";
import TopoTitleComponente from "../../Topo/TopoTitleComponente";
import { TableHeaderColumn } from "react-bootstrap-table";
import _ from "lodash";
import moment from "moment";
import { analiseAtendimentoEprService } from "../../../services/analiseAtendimentoEprService.service";
import connect from "react-redux/es/connect/connect";
import { tableHelper } from "../../../helpers/tableHelper";
import AnaliseAtendimentoEprFormFilter from "../analiseAtendimentoEpr/AnaliseAtendimentoEprFormFilter";

class RelatorioDisponibilidadePecasEPR extends React.PureComponent<{}> {
  constructor(props) {
    super(props);

    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      defaultFilter: {
        fimAno:ano,
        inicioAno:ano,
        inicioMes:1,
        fimMes:mes
      },
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="DISPONIBILIDADE DE PEÇAS EPR "
          canBack={false}
        />
        <CardRelatorio
          title={`DISPONIBILIDADE DE PEÇAS EPR`}
          FormFilter={AnaliseAtendimentoEprFormFilter}
          Chart={AnaliseAtendimentoEprChart}
          showTable={false}
          renderChartOnConstruct={true}
          methodFindChart={analiseAtendimentoEprService.findChart}
          defaultFilter={this.state.defaultFilter}
        >
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(RelatorioDisponibilidadePecasEPR);
