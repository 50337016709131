//@flow
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { volumeTrocaService } from '../../../services/volumeTrocaService.service';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';

const options = {
  responsive: true,
  tooltips: {
    mode: 'label'
  },
  elements: {
    line: {
      fill: false
    }
  },
  legend: {
    position: 'bottom',
    labels: {
      boxWidth: 10
    }
  }
};

class VolumesTrocasChart extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentWillReceiveProps(nexProps) {
    if (nexProps.entity && !_.isEqual(nexProps.entity, this.props.entity)) {
      this.callService(nexProps.entity);
    }
  }
  updateChart = filter => {
    this.callService(filter);
  };

  componentDidMount() {
    this.callService(this.props.entity);
  }

  callService = (filter: any) => {
    volumeTrocaService
      .findChartResult({ goupByItem: this.props.groupItem }, filter)
      .then(response => {
        let data = response.data;
        this.setState({
          data: data.data
        });
        let total = 0;
        data.data.map(item => {
          total += item.quantidade;
        });
        let totalName = 'totalTrocas';
        let payLoad = {};
        payLoad[totalName] = total;
        this.props.setTotal(payLoad);
      });
  };

  render() {
    const { data } = this.state;
    let arrayLabels = [];
    let arrayData = [];
    data.map((item, i) => {
      arrayLabels.push(item.ano);
      arrayData.push(item.quantidade);
    });

    let dataChart = {
      labels: arrayLabels,
      datasets: [
        {
          label: 'Quantidade de trocas realizadas no ano atual',
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FF6384',
            '#4BC0C0',
            '#FFCE56',
            '#E7E9ED',
            '#36A2EB'
          ],
          hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FF6384',
            '#4BC0C0',
            '#FFCE56',
            '#E7E9ED',
            '#36A2EB'
          ],
          data: arrayData
        }
      ]
    };

    return (
      <React.Fragment>
        <h3 className="text-center">{this.props.title}</h3>
        {arrayData.length > 0 && (
          <Bar data={dataChart} options={options} height={50} />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ report: { setTotal } }) => ({
  setTotal: totals => setTotal(totals)
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(VolumesTrocasChart);
