//@flow
import * as React from 'react';
import { causaOsService } from '../../services/causa.service';
import SelectInput from './SelectInput';

type Props = {
  label: string,
  name: string,
  value: any,
  onChange: any,
  returnFullObject: boolean,
  messageValidate?: any,
  disabled?: boolean,
  size?: number,
  required: boolean
};

type State = {
  options: Array<any>
};
export default class FormSelectInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      options: []
    };
  }

  componentDidMount() {
    this.props.service().then(
      data => {
        let options = [];
        options = options.concat(data.data);
        this.setState({ options: options });
      },
      error => {
        console.log('Erro ao montar o combo', error);
      }
    );
  }

  render() {
    return (
      <SelectInput
        id={this.props.id}
        label={this.props.label}
        valueKey={this.props.valueKey?this.props.valueKey:'id'}
        labelKey={this.props.labelKey?this.props.labelKey:'nome'}
        name={this.props.name}
        value={this.props.value}
        options={this.state.options}
        onChange={this.props.onChange}
        multi={false}
        returnFullObject={this.props.returnFullObject}
        messageValidate={this.props.messageValidate}
        disabled={this.props.disabled}
        size={this.props.size}
        style={this.props.style}
        required={this.props.required}
        disabled={this.props.disabled}
        erroMensagem={this.props.erroMensagem}
      />
    );
  }
}
