//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import {tableHelper} from '../../helpers/tableHelper';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {GrupoFilialService} from "../../services/grupoFilial.service";
import GrupoFilialListFilter from "./GrupoFilialListFilter";
import {grupoMarcaService} from "../../services/grupoMarca.service";
import GrupoMarcaListFilter from "../grupoMarca/GrupoMarcaListFilter";
import {objectsConstants} from "../../constants/objects.constants";

const columns = [
  {
    path: 'filialDocumento', //caminho no json
    title: 'Documento grupo', //texto de exibição para o usuario
    isKey: false,
    dataSort: false,
  },
  {
    path: 'tempoGarantia',
    title: 'Tempo Garantia',
    isKey: true,
    dataSort: false,
  }
];

const endPoint = '/grupofilial/new/';
const endPointView = '/grupofilial/view/';
class GrupoFilialList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };


  handleNewButton = (row: e) => {
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo grupo filial'
      }
    });
  };


  renderCustom(row: any, entity: any, column: any) {
      return tableHelper.renderCustom(row, entity, column);

  }


  render() {
    console.log('Grupo Filial List')
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={this.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    let filter = {
      extension: this.props.extension
        ? this.props.extension
        : this.props.user.extension
    };
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields
    };

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Grupo Filial"
          subTitle=" "
          canBack={false}
        />

        <ListagemPage
          title={`Grupo Filial`}
          newButtonActionClick={this.handleNewButton}
          roleNewButton={objectsConstants.ABILITY_BTN_CADASTRO_GRUPO_FILIAL}
          labelNewButton="Novo Grupo Filial"
          showNewButton={true}
          delete={() => {}}
          service={GrupoFilialService}
          defaultOrder={'id'}
          defaultDirection={'desc'}
          defaultFilter={filter}
          onRowClick={this.handleRowClick}
          FormFilter={GrupoFilialListFilter}
          controller="grupofilial"
          fields={this.state.fields}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(GrupoFilialList);
