import { Formik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';
import FileInput from '../Inputs/FileInput';
import { importacaoPecasService } from '../../services/importacaoPecasService';

type State = {
  isModalVisible: any,
};

const ImportacaoInit = Yup.object().shape({
  planilhaImportacao: Yup.string().required('Selecione um arquivo'),
});

class ImportacaoPecasModal extends PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      canSubmit: false,
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ isModalVisible: nextProps.isModalVisible });
  }

  toggleModal = () => {
    this.props.toggleModal();
  };

  render() {
    const { entity } = this.state;

    return (
      <Modal isOpen={this.state.isModalVisible} toggle={this.toggleModal}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column">
                <h3>
                  <span className="txtGreen"> Importação</span>- Peças
                </h3>
                <Formik
                  initialValues={{}}
                  validationSchema={ImportacaoInit}
                  onSubmit={(values, actions) => {
                    console.log(values);
                    if (!_.isEmpty(values)) {
                      console.log('validou');
                      this.props.loading(true);
                      importacaoPecasService
                        .importarPecas(values.planilhaImportacao)
                        .then(
                          (response) => {
                            console.log('Sucesso ao enviar os arquivos');
                            console.log(response);
                            this.props.successCallback();
                          },
                          (error) => {
                            console.log(error);
                            this.toggleModal();
                            this.props.errorCallback();
                            actions.setSubmitting(false);
                          }
                        )
                        .catch(() => {
                          actions.setSubmitting(false);
                          this.props.loading(false);
                        });
                    } else {
                      // this.props.error('Selecione um arquivo');
                      console.log('erro');
                    }
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="conteudo">
                        <p>
                          Selecione o arquivo contendo a planilha a ser
                          importada.
                        </p>
                        <div className="form-group text-center">
                          <FileInput
                            emptyLabel="Anexar planilha"
                            name="planilhaImportacao"
                            entity={entity}
                            fileItem={values.planilhaImportacao}
                            onChange={(name, value) => {
                              this.setState({ canSubmit: true });
                              setFieldValue(name, value);
                            }}
                            // erroMensagem={errors.planilhaImportacao}
                            className={`${
                              errors.planilhaImportacao ? 'is-invalid' : ''
                            }`}
                            accept=".csv"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-auto"
                        disabled={
                          !this.state.canSubmit || errors.planilhaImportacao
                        }
                      >
                        Importar arquivo
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatch = ({ alert: { success, error }, load: { loading } }) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
});

export default connect(
  null,
  mapDispatch
)(withRouter(ImportacaoPecasModal));
