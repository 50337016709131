import _ from 'lodash';
import React, { PureComponent } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { connect } from 'react-redux';
import CardSeculusFull from '../../components/panels/CardSeculusFull';
import ListagemPage from '../../components/templates/ListagemPage';
import Toast from '../../components/Toasts/Toast';
import OrdemServicoConsultaFilter from './OrdemServicoConsultaFilter';
import { consultaOrdemServicoService } from '../../services/consultaOrdemServico.service';
import { tableHelper } from '../../helpers/tableHelper';

import * as Yup from 'yup';
var logo_seculus = require('../../img/logo.svg');
var logo_mondaine = require('../../img/logo_mondaine.svg');

const validationModal = Yup.object().shape({
  //cidade: Yup.string().required('Obrigatório')
});

const columns = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'relogio.marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: false
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Cód. Relógio',
    isKey: false,
    dataSort: false
  },
  { path: 'cliente.nome', title: 'Cliente', isKey: false, dataSort: true },
  {
    path: 'cliente.documento',
    title: 'CPF/CNPJ',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'garantia', title: 'Garantia', yesNo: true, dataSort: false },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    path: 'dataStatusAtual',
    title: 'Data do status atual',
    isKey: false,
    dataSort: false,
    isDate: true
  }
];

class ConsultaOS extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });

    this.state = {
      entity: {},
      fields
    };
  }

  render() {
    // console.log(this.props.company)
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <Toast />
        <div className="flex-column pt-5 px-5 card-b-none">
          <CardSeculusFull>
            <div className="topoCard d-flex flex-column flex-sm-row">
              <h3 className="flex-grow-1">Acompanhe a sua OS</h3>
            </div>
            <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
              <div className="col-sm-6 d-flex flex-row align-items-center pr-3 border-right">
                <div className="justify-content-center">
                  {this.props.company ? (
                    <img
                      src={logo_mondaine}
                      alt="logo"
                      className="logoSeculus"
                    />
                  ) : (
                    <img
                      src={logo_seculus}
                      alt="logo"
                      className="logoSeculus"
                    />
                  )}
                </div>
                <div className="justify-content-center pl-3">
                  <h4 className="subDestaqueTxtCard" />
                </div>
              </div>
              <div className="col px-4">
                <h2 className="flex-grow-1">Serviço de consulta On-line</h2>

                <p className="mt-0">
                  Para acompanhar o andamento do reparo do relógio deixado em um
                  dos nossos parceiros Autorizados/Assistência técnica; basta se
                  digitar o número do CPF/CNPJ ou Ordem de Serviço e em seguida
                  selecionar a cidade.
                </p>

                <p>
                  <br />
                  <b>0800 9400 977</b> para telefones fixos e cidades do
                  interior <br />
                  <b>4005-1505</b> para capitais
                  <br />
                  <b>atendimento.cliente@seculus.com.br</b>
                </p>
              </div>
            </div>
            <hr />
            <div className="form-row ">
              <ListagemPage
                title={``}
                delete={() => {}}
                hideExport={true}
                showNewButton={false}
                service={consultaOrdemServicoService}
                defaultOrder={'dataStatusAtual'}
                defaultDirection={'desc'}
                defaultFilter={{}}
                FormFilter={OrdemServicoConsultaFilter}
                controller="ordemServico"
                fields={this.state.fields}
                classSignal={'temSinal'}
                validationModal={validationModal}
                loadOnInit={false}
              >
                {tableColumn}
              </ListagemPage>
            </div>
          </CardSeculusFull>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    loggedIn,
    alert,
    messages
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load })
});
export default connect(mapStateToProps, mapDispatch)(ConsultaOS);
