//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import Task from './Task';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';

type State = {
  entity: PostoAutorizadoType
};

class TaskView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return <Task pageSize={5} />;
  }
}

function mapStateToProps(state) {
  const { taskList, taskListSize } = state.task;

  return {
    taskList,
    taskListSize
  };
}

export default connect(mapStateToProps)(TaskView);
