//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import FotoRelogio from '../../components/FotoRelogio';
import FormInput from '../../components/Inputs/FormInput';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import { propertyConstantes } from '../../constants/property.constantes';
import { urlsConstants } from '../../constants/url.constant';
import '../../css/doc_manaus.css';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { ordemServicoService } from '../../services/ordemServico.service';
import { tipoTransporteService } from '../../services/tipoTransporte.service';
import { trocaModeloService } from '../../services/trocaModelo.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
type Props = { onChangeText: any, entity: any, onChangeValue: any };
class TrocaModeloView extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { entity: null };
  }

  componentDidMount() {
    this.props.loading(true);
    trocaModeloService.getById(this.props.match.params.id).then(response => {
      this.props.loading(false);
      this.setState({ entity: response.data });
    });
  }
  _handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity });
  };
  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity });
  };
  handleConcluirTroca() {
    this.props.loading(true);
    trocaModeloService
      .confirmarTroca(
        this.props.match.params.id,
        this.state.entity.codigoRastreio,
        this.state.entity.tipoTransporte
          ? this.state.entity.tipoTransporte.id
          : null
      )
      .then(response => {
        this.props.loading(false);
        this.props.success({
          message: 'Troca confirmada com sucesso.'
        });
        this.props.history.push('/trocaModelo');
      });
  }

  handleCancelarTroca() {
    this.props.loading(true);
    trocaModeloService
      .cancelarTroca(this.props.match.params.id)
      .then(response => {
        this.props.loading(false);
        this.props.success({
          message: 'Troca cancelada com sucesso.'
        });
        this.props.history.push('/trocaModelo');
      });
  }
  handleTrocaEmAndamento() {
    this.props.loading(true);
    trocaModeloService
      .trocaEmAndamento(this.props.match.params.id)
      .then(response => {
        this.props.loading(false);
        this.props.success({
          message: 'Status alterado com sucesso.'
        });
        this.props.history.push('/trocaModelo');
      });
  }
  handleClickItem = () => {
    const endPointOs = '/ordemServicoAdmin/view/';
    const entity = this.state.entity.ordemServico;
    if (entity.osType === propertyConstantes.OS) {
      let urlOS = urlsConstants.ORDEM_SERVICO + entity.id;
      ordemServicoService.doGet(urlOS).then(response => {
        let os = response.data.data;
        this.props.setOrdemServico(os);
        this.props.setCliente(os.cliente);
        this.props.history.push(endPointOs + entity.id);
      });
    }
  };
  render() {
    const { entity } = this.state;
    return (
      <React.Fragment>
        {entity && (
          <div className="card cardSeculus cardFull d-flex flex-column">
            <TopoTitleComponente
              mainTitle="Troca Modelo"
              subTitle={entity.ordemServico.numOs}
              canBack={true}
            />
            <div className="conteudoCard">
              <div className="row">
                <div className="col-6 col-lg-3">
                  <h2 className="marca">
                    <b>{entity.postoAutorizado.nomeFantasia}</b>
                  </h2>
                  <p>Código SAP: {entity.postoAutorizado.codigoSAP}</p>
                </div>
                <div className="col-6 col-lg-3">
                  <div>
                    {entity.postoAutorizado.rua},{' '}
                    {entity.postoAutorizado.numero}
                    {entity.postoAutorizado.complemento
                      ? ',' + entity.postoAutorizado.complemento
                      : ''}{' '}
                    - {entity.postoAutorizado.bairro}
                  </div>
                  <div>
                    {entity.postoAutorizado.cidade.nome}-
                    {entity.postoAutorizado.estado.nome}
                  </div>
                  <div>CEP {entity.postoAutorizado.cep}</div>
                </div>
                <FormSelectInput
                  type="text"
                  name="tipoTransporte"
                  label="Tipo transporte"
                  placeholder=""
                  size={3}
                  style={['col-2 col-lg-2']}
                  options={this.state.tipoTransporte}
                  returnFullObject={true}
                  searchable={true}
                  id={'tipoTransporte'}
                  ref={input => {
                    this._sintoma = input;
                  }}
                  onChange={this._handleSelectChange}
                  value={entity.tipoTransporte}
                  service={tipoTransporteService.findAll}
                  required={false}
                />
                <div className="form-row col-12 col-lg-4">
                  <div className="col-6 col-lg-6">
                    <label className="d-none d-sm-inline">
                      {' '}
                      Código de rastreio
                    </label>
                    <label className="d-inline d-sm-none"> Cód. rastreio</label>
                    <FormInput
                      type="text"
                      name="codigoRastreio"
                      placeholder="Cód. Rastreio"
                      required={true}
                      size={12}
                      id={'codigoRastreio'}
                      onChange={this._handleChangeText}
                      value={entity.codigoRastreio ? entity.codigoRastreio : ''}
                      noSize={true}
                    />
                  </div>
                  <div className="col-6 col-lg-6 form-group">
                    <button
                      type="button"
                      className="btn btn-primary mt-lbl"
                      disabled={
                        !this.state.entity.codigoRastreio ||
                        this.state.entity.codigoRastreio == ''
                      }
                      onClick={() =>
                        ConfirmAcationService.confirmMsg(
                          this.handleConcluirTroca.bind(this),
                          'Confirmar Troca',
                          'Deseja confirmar a troca?'
                        )
                      }
                    >
                      Confirmar Troca
                    </button>
                  </div>
                </div>
              </div>
              <div className="bloco mt-3 container-fluid">
                <div
                  className="numeroOs col-12 col-sm-6 col-md-3 col-lg-2"
                  onClick={this.handleClickItem}
                >
                  {/* <div className="farol farol-azul ml-0 mr-2"></div> */}

                  {entity.ordemServico.numOs}
                </div>
                <div className="col-12 col-md-8 col-lg-9 ">
                  <button
                    type="button"
                    className="btn btn-secondary mt-lbl red mt-0"
                    onClick={() =>
                      ConfirmAcationService.confirmMsg(
                        this.handleCancelarTroca.bind(this),
                        'Não atender troca',
                        'Deseja cancelar a troca?'
                      )
                    }
                  >
                    Cancelar Troca
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mt-lbl ml-2 white  mt-0"
                    onClick={() =>
                      ConfirmAcationService.confirmMsg(
                        this.handleTrocaEmAndamento.bind(this),
                        'Troca em andamento',
                        'Deseja alterar o status da troca?'
                      )
                    }
                  >
                    Troca em andamento
                  </button>
                </div>

                <div className="react-bs-table table-container-class">
                  <div className="react-bs-container-header table-header-wrapper table-header">
                    <table className="table table-hover tablePecas">
                      <thead>
                        <tr className="headerGrid text-left">
                          <th colSpan="2">Foto do Relógio </th>
                          <th colSpan="2">Código do Relógio </th>
                          <th>Marca</th>
                          <th>Descrição</th>
                          <th>Garantia</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div className="react-bs-container-body docView">
                    <table className="table tableDetailDoc">
                      <tbody>
                        <tr className="headerGrid text-left">
                          <td colSpan="2">
                            <FotoRelogio
                              relogio={entity.relogio ? entity.relogio : null}
                              width={'50%'}
                            />{' '}
                          </td>
                          <td colSpan="2">{entity.relogio.codigoRelogio} </td>
                          <td>
                            {entity.relogio.marca
                              ? entity.relogio.marca.nome
                              : ''}
                          </td>
                          <td>
                            {entity.relogio.descricao
                              ? entity.relogio.descricao
                              : ''}
                          </td>
                          <td>
                            {entity.ordemServico.garantia ? 'Sim' : 'Não'}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  alert: { error, success },

  postoAutorizado: { setExtension },
  ordemServico: { setOrdemServico, setCliente }
}) => ({
  loading: (load: boolean) => loading({ load }),
  error: (message, code) => error({ message, code }),
  success: message => success(message),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  setCliente: cliente => setCliente({ cliente }),
  setExtension: (extension: number) => setExtension(extension)
});

export default connect(null, mapDispatch, null, { withRef: true })(
  TrocaModeloView
);
