//@flow
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import type { ClienteType } from '../types/cliente.type';
import type { OrdemServicoType } from '../types/ordemServico.type';

export const perfilService = {
  find,
  findByFiltro,
  changePermissions,
  findAll,
  doSave,
};

function doSave(value) {
  return defaultService.doPost(`${urlsConstants.PERFIL}create`, {
    name: value,
  });
}

function changePermissions(useCase, checked, role) {
  return defaultService.doGet(
    `${urlsConstants.PERMISSOES_PERFIL}${role.id}/${useCase.id}/${checked}`
  );
}

function find(input) {
  return defaultService.doGet(`${urlsConstants.FIND_PERFIL}${input}`);
}
function findAll() {
  return defaultService.doGet(`${urlsConstants.PERFIL}findAll`);
}

function findByFiltro(options: Options, filter: any): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.PERMISSOES_PERFIL}list`,
    options,
    filter
  );
}
