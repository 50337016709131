//@flow
import * as React from 'react';
import Datatable from '../table/Datatable';
import { connect } from 'react-redux';
import _ from 'lodash';
import { objectsConstants } from './../../constants/objects.constants';
import axios from 'axios';
import CardSeculusFull from '../panels/CardSeculusFull';
import HeaderList from './HeaderList';
import Toast from '../Toasts/Toast';
import { Formik } from 'formik';

type Props = {
  title?: string,
  children: any,
  FormFilter?: any,
  newButtonActionClick: Function | string,
  showNewButton: boolean,
  defaultOrder: string,
  classSignal: string,
  defaultDirection: string,
  service: any,
  deleteSuccess: string,
  labelNewButton: string,
  defaultFilter: any,
  onRowClick: any,
  noFilter?: boolean,
};

type State = { filter: any, filterForm: any, submitFilter: boolean };

class ListagemPage extends React.Component<Props, State> {
  static defaultProps = {
    defaultOrder: 'id',
    showNewButton: true,
    deleteSuccess: 'Registro excluído com sucesso.',
    forceUpdate: false,
    loadOnInit: true,
  };

  _datatable: any;
  _source: any;

  constructor(props: any) {
    super(props);
    this._source = axios.CancelToken.source();
    let filter =
      this.props.filter && this.props.controller === this.props.page
        ? this.props.filter
        : this.props.defaultFilter
        ? this.props.defaultFilter
        : objectsConstants.defaultFilter;
    this.state = { filter, filterForm: {}, submitFilter: false };
  }

  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeData = (name, value) => {
    console.log(name);
    console.log(value);
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.filter);
    _.set(newFilter, name, type === 'checkbox' ? checked : value);
    this.setState({ filter: newFilter, submitFilter: false });
  };
  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false });
  };
  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      filterForm: Object.assign({}, this.state.filter),
      submitFilter: true,
    });
  };

  limpaPesquisa = () => {
    console.log('limpar pesquisa');

    this.setState({
      filter: {},
      filterForm: {},
      submitFilter: true,
    });
  };

  componentWillUnmount() {
    this._source.cancel('Operation canceled due component being unmounted.');
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.defaultFilter &&
      !_.isEqual(nextProps.defaultFilter, this.props.defaultFilter)
    ) {
      this.setState({ filter: nextProps.defaultFilter }, () => {
        !this.props.notUpdateOnChangeFilter &&
          this._datatable.getWrappedInstance().updateTable();
      });
    } else if (nextProps.forceUpdate) {
      this._datatable.getWrappedInstance().updateTable();
    }
    this.setState({ submitFilter: false });
  }

  updateTable = () => {
    this._datatable.getWrappedInstance().updateTable();
  };

  render() {
    const {
      newButtonActionClick,
      showNewButton,
      labelNewButton,
      title,
      FormFilter,
      roleNewButton,
      FormCardListagem,
      hideExport,
      validationModal,
    } = this.props;
    return (
      <CardSeculusFull>
        <HeaderList
          title={title}
          labelNewButton={labelNewButton}
          actionClick={newButtonActionClick}
          showNewButton={showNewButton}
          filter={this.state.filter}
          controller={this.props.controller}
          fields={
            this.props.fieldsExport
              ? this.props.fieldsExport
              : this.props.fields
          }
          labels={
            this.props.labelsExport
              ? this.props.labelsExport
              : this.props.labels
          }
          roleNewButton={roleNewButton}
          hideExport={hideExport}
        />
        <Toast />

        <div className="conteudoCard">
          {FormFilter && (
            <div className="form-row searchBox">
              <div className="col-12 mb-3">
                <div className="miniTxt">Pesquisa</div>
              </div>

              <Formik
                validationSchema={validationModal}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize={true}
                initialValues={this.state.filter}
                onSubmit={(values, actions) => {
                  this.handleFilter();
                }}
                ref={(form) => {
                  this.formRef = form;
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  validateForm,
                  setValues,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    className="form-row col-12"
                  >
                    <FormFilter
                      onChangeSelect={this.handleSelectChange}
                      onChangeText={this._handleChangeText}
                      onChangeValue={this.handleValue}
                      onChangeDate={this._handleChangeData}
                      entity={this.state.filter}
                      errors={errors}
                    />
                    <div className="py-0 py-lg-3 w-100 col-12 text-center text-lg-right">
                      <button
                        type="button"
                        className="btn btn-link miniTxt"
                        onClick={this.limpaPesquisa}
                      >
                        Limpar pesquisa
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary btn-sm float-right"
                      >
                        buscar
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          )}

          {FormCardListagem && (
            <FormCardListagem
              filter={this.state.filter}
              updateTable={this.updateTable}
            />
          )}

          <Datatable
            ref={(datatable) => (this._datatable = datatable)}
            defaultOrder={this.props.defaultOrder}
            defaultDirection={this.props.defaultDirection}
            service={this.props.service}
            classSignal={this.props.classSignal}
            filter={this.state.filter}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
            controller={this.props.controller}
            loadOnInit={this.props.loadOnInit}
          >
            {this.props.children}
          </Datatable>
        </div>
      </CardSeculusFull>
    );
  }
}

function mapStateToProps(state) {
  const { filter, page } = state.defaultFilter;

  return {
    filter,
    page,
  };
}
const mapDispatch = ({ alert: { error, success } }) => ({
  error: (message) => error({ message }),
  success: (message) => success({ message }),
});
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(ListagemPage);
