import React, { Component } from "react";
import _ from "lodash";
import "../../css/CadastroGarantia.css";
import ex1 from '../../img/EX 1 Certificado de garantia.jpg'
import ex2 from '../../img/EX 2 Cupom Fiscal.jpg'
import ex3 from '../../img/EX 3 Nota Fiscal Eletronica.jpg'
import BtnSecondary from '../../components/Buttons/BtnSecondary';
import ImageInput from "../../components/Inputs/ImageInput";
import ArrayFileInput from '../../components/Inputs/ArrayFileInput';
export default class CadastroNotaFiscal extends Component {
    constructor(props) {
        super(props);}

    render() {
        let {values,setFieldValue,errors} = this.props;
        return (

            <div className="row">
                <div className="CadastroNota col-12 m-2">
                    <h2>AGORA VAMOS PRECISAR DE UMA FOTO DA SUA NOTA FISCAL OU DO SEU CERTIFICADO DE GARANTIA </h2>
                    <h3>SIGA UM DOS EXEMPLOS ABAIXO</h3>
                </div>
                <div className="ImageInput col-12 col-md-4 ">
                    <img src={ex1}  className={'img-fluid mx-auto col-12'}/>

                </div >
                <div className="ImageInput col-12 col-md-4">
                    <img src={ex2}  className={'img-fluid mx-auto col-12'}/>
                </div >
                <div className="ImageInput col-12 col-md-4">
                    <img src={ex3}  className={'img-fluid mx-auto col-8'}/>

                </div >

                <div className="input-group mt-4 justify-content-center">
                    <ArrayFileInput
                        emptyLabel="Clique aqui para adicionar as fotos NF/Certificado*"
                        entity={values}
                        id="notaFiscal"
                        name="notaFiscal"

                        fileItem={values.notaFiscal}
                        singleFile={true}
                        onChange={files => {
                            setFieldValue('notaFiscal',files);
                        }}
                        className={`small nfinput ${
                            errors.notaFiscal ? 'is-invalid' : ''
                            }  p-4 `}
                        messageValidate={errors.notaFiscal}
                        accept="image/*"
                    >
                        <i className="icon-foto" />
                    </ArrayFileInput>

                </div>



            </div>
        )
    }
}