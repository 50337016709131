//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './taskInput.css';
type Props = {
  onCancel: any,
  onSave: any
};

class SaveCancelCardBtn extends PureComponent<Props> {
  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn btn-secondary white"
          onClick={this.props.onCancel}
        >
          Cancelar
        </button>
        <button
          type="button"
          className="btn btn-secondary green"
          onClick={this.props.onSave}
        >
          Salvar
        </button>
      </React.Fragment>
    );
  }
}

export default connect()(SaveCancelCardBtn);
