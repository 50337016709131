//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmAcationService } from '../../../services/ConfirmAcationService';
import { objectsConstants } from '../../../constants/objects.constants';
import ArrayFileInput from '../../../components/Inputs/ArrayFileInput';
import { Can } from '@casl/react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';

type Props = {
  onConfirm: any,
  onCancel: any
};

class SolicitarTrocaBtn extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      entity: { files: [] }
    };
  }

  handleValue = (value: any, name: string) => {
    const entity = _.cloneDeep(this.state.entity);
    _.set(entity, name, value);
    this.setState({ entity });
  };

  confirmTroca = () => {
    this.toggle();
    this.props.onConfirm(this.state.entity);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <h3 className="subH3">TROCAR MODELO </h3>
            <p>
              Você confirma que dentro das condições apresentadas,{' '}
              <b>
                o relógio do seu cliente não pode ser reparado e para isso
                solicita a troca de modelo?
              </b>
            </p>
            <div className="form-row">
              <ArrayFileInput
                emptyLabel="Anexar"
                id="files"
                name="files"
                onChange={this.handleValue}
                className={`pt-3 fileINput`}
                messageValidate={this.state.errors}
                accept="*"
              >
                <i className="icon-anexo" />
              </ArrayFileInput>
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button
              type="button "
              className="btn btn-primary white"
              onClick={this.toggle}
            >
              Cancelar
            </button>
            <button
              type="button "
              className="btn btn-primary "
              onClick={this.confirmTroca}
            >
              Confirmar
            </button>
          </ModalFooter>
        </Modal>

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_SOLICITAR_TROCA_MODELO}
          ability={permissions}
        >
          <div className="d-flex flex-row-reverse bd-highlight btn-bold pt-2">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={this.toggle}
            >
              {' '}
              SOLICITAR TROCA{' '}
            </button>
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(SolicitarTrocaBtn);
