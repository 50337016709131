//@flow
import moment from 'moment/moment';
import * as React from 'react';
import MonthPickerInput from '../../../components/Inputs/MonthPickerInput';
import connect from 'react-redux/es/connect/connect';

class RelatorioRelogiosReincidentesFormFilter extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    let dataAtual = moment();

    let year = dataAtual.year();
    let month = dataAtual.month() + 1;
    this.state = { month };
    this.props.onChangeMonthYear(1, year, 'inicio');
  }

  handleMesAno = (name, month, year) => {
    this.setState({ month, year });
    this.props.onChangeMonthYear(month, year, name);
  };

  render() {
    return (
      <React.Fragment>
        <MonthPickerInput
          placeholder={'Mês/Ano'}
          id={'mesAno'}
          label="Mês/ano"
          onChange={this.handleMesAno}
          size={3}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

export default connect(mapStateToProps)(
  RelatorioRelogiosReincidentesFormFilter
);
