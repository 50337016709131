//@flow
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';

export const grupoMarcaService = {
  findByFiltro,
  find,
  doSave,
  getById,
  doGet,
  findAll
};

function findAll() {
  return defaultService.doGet(`${urlsConstants.FIND_ALL_GRUPO_MARCA}`);
}

function doGet(url) {
  return defaultService.doGet(url);
}
function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.GRUPO_MARCA, entity);
}

function findByFiltro(options: Options, filter: any) {
  return defaultService.findByFiltro(
    `${urlsConstants.GRUPO_MARCA}list`,
    options,
    filter
  );
}

function find(input) {
  defaultService.doGet(`${urlsConstants.FIND_GRUPO_MARCA}${input}`);
}

function getById(id: number) {
  return defaultService.doGet(`${urlsConstants.GRUPO_MARCA}${id}`);
}
