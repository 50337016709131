export const propertyConstantes = {
  AGUARDANDO_REPARO: 'AGUARDANDO_REPARO',
  TROCA_MODELO_EM_ANDAMENTO: 'TROCA_MODELO_EM_ANDAMENTO',
  PECA_NAO_ATENDIDA: 'PECA_NAO_ATENDIDA',
  APOIO_COMERCIAL:'APOIO_COMERCIAL',
  PECA_REJEITADA: 'PECA_REJEITADA',
  AGUARDANDO_ENVIO_RELOGIO: 'AGUARDANDO_ENVIO_RELOGIO',
  ROLE_FISCAL: 'ROLE_FISCAL',
  TROCA_MAIS_MODELO: 'TROCA_MAIS_MODELOS',
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_MANAGER: 'ROLE_MANAGER',
  ROLE_SUDO: 'ROLE_SU',
  PECAS_NAO_ATENDIDAS: 'PECA_NAO_ATENDIDA',
  EM_CRIACAO: 'EM_CRIACAO',
  APROVAR_ORCAMENTO: 'APROVAR_ORCAMENTO',
  CENTRO_TECNICO: 'CENTRO_TECNICO',
  AGUARDANDO_PECAS: 'AGUARDANDO_PECAS',
  ORCAMENTO_REPROVADO: 'ORCAMENTO_REPROVADO',
  AGUARDANDO_RECEBIMENTO: 'AGUARDANDO_RECEBIMENTO',
  ATENDIDA_PARCIALMENTE: 'ATENDIDA_PARCIALMENTE',
  REPARADA: 'REPARADA',
  FINALIZADA: 'FINALIZADA',
  AGUARDANDO_IMPORTACAO: 'AGUARDANDO_IMPORTACAO',
  EXPIRADA: 'EXPIRADA',
  REJEITADA: 'REJEITADA',
  CANCELADA: 'CANCELADA',
  TRANSFERIDA: 'TRANSFERIDA',
  OS: 'OS',
  PA: 'PA',
  RG: 'RG',
  RE: 'RE',
  NOVO: 'NOVO',
  ATENDIDO: 'ATENDIDO',
  NAO_ATENDIDO: 'NAO_ATENDIDO',
  PENDENTE: 'PENDENTE',
  FINALIZADO: 'FINALIZADO',
  CELULAR: 'Celular',
  RESIDENCIAL: 'Residencial',
  COMERCIAL: 'Comercial',
  CELULAR_WHATSAPP: 'Celular / WhatsApp',
  AGUARDANDO_RELOGIO: 'AGUARDANDO_RELOGIO',
  AGUARDANDO_RELOGIO_DE_TROCA: 'AGUARDANDO_RELOGIO_DE_TROCA',
  TROCA_MODELO: 'TROCA_MODELO',
  ATIVO: 'ATIVO',
  INATIVO: 'INATIVO',
  ESCOLHA_DE_MODELO: 'ESCOLHA_DE_MODELO',
  ATENDIDA: 'ATENDIDA',
  MAQUINA: 'MAQUINA',
  MODULO: 'MODULO',
  TROCA_NAO_AUTORIZADA: 'TROCA_NAO_AUTORIZADA',
  TROCA_REVERTIDA: 'TROCA_REVERTIDA'
};
