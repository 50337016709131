//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { dateHelper } from '../../../helpers/date.helper';
import { objectsConstants } from '../../../constants/objects.constants';
import { Can } from '@casl/react';

type Props = {
  onReprove: any,
  onAprove: any
};

class AprovarOrcamentoBtn extends PureComponent<Props> {
  handleConfirm = () => {
    console.log(this.props.getValorOrcamento(this.props.ordemServico));
    let getValorOrcamento = this.props.getValorOrcamento;
    let ordemServico = this.props.ordemServico;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>Aprovar orçamento</h3>
            <p className="p-msg">
              Seu cliente confirma o orçamento de R${' '}
              <b>{getValorOrcamento(ordemServico)}</b>,
              para hoje{' '}
              <b>
                {dateHelper.format(dateHelper.now(), { pattern: 'DD/MM/YYYY' })}
              </b>
              ?
            </p>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.props.onAprove();
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_APROVAR_ORCAMENTO_OS}
          ability={permissions}
        >
          <div className="col-12 d-flex justify-content-between btn-bold">
            <button
              type="button"
              className="btn btn-primary btn-sm justify-content-start white"
              onClick={this.props.onReprove}
            >
              reprovar
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={this.handleConfirm}
            >
              aprovar
            </button>
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(AprovarOrcamentoBtn);
