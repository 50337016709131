//@flow
import * as React from 'react';

import { Route, Switch } from 'react-router-dom';
import MarcaCrudList from './MarcaCrudList';
import MarcaEdit from './MarcaEdit';

export default class MarcaCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/marca"
            exact
            component={MarcaCrudList}
          />
          <Route
            path="/marca/novo/"
            component={MarcaEdit}
          />
          <Route path="/marca/edit/:id" component={MarcaEdit} />
        </Switch>
      </React.Fragment>
    );
  }
}
