import * as React from 'react';
import { connect } from 'react-redux';
import { objectsConstants } from '../../constants/objects.constants';
// import { FormInput } from "../Inputs/FormInput";
import _ from 'lodash';
import CardSeculusFull from '../panels/CardSeculusFull';
import HeaderList from '../templates/HeaderList';
import Datatable from '../table/Datatable';

type Props = {
  title?: string,
  FormFilter?: any,
  methodFindChart: any,
  defaultFilter: any,
  renderChartOnConstruct: boolean,
  Chart: any
};
type State = {
  tasks: any,
  entity: any
};

class CardRelatorio extends React.PureComponent<Props, State> {
  dataTable = React.createRef();
  chartItem = React.createRef();
  constructor(props: Props) {
    super(props);

    let filter = props.defaultFilter
      ? props.defaultFilter
      : objectsConstants.defaultFilter;
    if (props.renderChartOnConstruct) {
      this.callService(filter, props.methodFindChart);
    }
    this.state = { filter, submitFilter: false };
  }
  componentDidMount() {
    if (this.props.renderChartOnConstruct) {
      this.callService(this.state.filter, this.props.methodFindChart);
    }
    
  }

  

  _handleChangeMonthYear = (month: number, year: number, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    if (!name) {
      _.set(filter, 'mes', month);
      _.set(filter, 'ano', year);
    } else {
      _.set(filter, `${name}Mes`, month);
      _.set(filter, `${name}Ano`, year);
    }
    this.setState({ filter, submitFilter: false });
  };
  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeData = (name, value) => {
    if (value !== null) {
      const filter = _.cloneDeep(this.state.filter);
      _.set(filter, name, value);
      this.setState({ filter, submitFilter: false });
    }
  };

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    const newFilter = _.cloneDeep(this.state.filter);
    _.set(newFilter, name, type === 'checkbox' ? checked : value);
    this.setState({ filter: newFilter, submitFilter: false });
  };
  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false });
  };

  handleChangeUpperCase = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let newfilter = _.cloneDeep(this.state.filter);
    console.log(value);
    _.set(newfilter, name, value.toUpperCase());
    this.setState({ filter: newfilter, submitFilter: false });
  };

  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }
    if(this.props.filterChange && this.props.type !== this.state.filter.type){
      this.props.filterChange(this.state.filter)
    }else{

    this.callService(this.state.filter, this.props.methodFindChart);
    }
  };

  callService = (filter: any, find: any) => {
    if (this.refs.dataTable) {
      this.refs.dataTable.getWrappedInstance().updateTable();
    }
    if (this.refs.chartItem && this.refs.chartItem.updateChart) {
      this.refs.chartItem.updateChart(filter);
    }
    try {
      if (this.refs.chartItem && this.refs.chartItem.getWrappedInstance) {
        this.refs.chartItem.getWrappedInstance().updateChart(filter);
      }
    } catch (error) {
      console.log('Canot update char with reference');
    }

    if (
      this.refs.detailPage &&
      this.refs.detailPage.getWrappedInstance().updateItem
    ) {
      this.refs.detailPage.getWrappedInstance().updateItem(filter);
    }

    if (this.props.onHandleFilter) {
      this.props.onHandleFilter(filter);
    }
    if (this.props.methodFindChart) {
      this.props.methodFindChart(filter).then(result => {
        this.setState({ data: result.data });
      });
    }
  };

  handleRefreshData = data => {
    if (!this.props.methodFindChart) {
      this.setState({ data });
    }
  };

  render() {
    const { title, FormFilter, Chart, DetalhePage } = this.props;
    return (
      <CardSeculusFull>
        <HeaderList
          title={title}
          showNewButton={false}
          filter={this.state.filter}
          controller={this.props.controller}
          fields={this.props.fields}
          hideExport={this.props.hideExport}
        />

        <div className="conteudoCard mh-320">
          {FormFilter && (
            <div className="form-row searchBox">
              <div className="col-12 mb-3">
                <div className="miniTxt">Pesquisa</div>
              </div>
              <FormFilter
                onChangeSelect={this.handleSelectChange}
                onChangeUpperCase={this.handleChangeUpperCase}
                onChangeText={this._handleChangeText}
                onChangeValue={this.handleValue}
                onChangeDate={this._handleChangeData}
                onChangeMonthYear={this._handleChangeMonthYear}
                entity={this.state.filter}
              />
              <div className="form-group col-12 col-sm-12 ">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm float-right"
                  onClick={this.handleFilter}
                >
                  buscar
                </button>
              </div>
            </div>
          )}

          {Chart && (
            <div className="row">
              <div className="row separator" />
              <div className="col-12">
                <Chart
                  entity={this.state.filter}
                  title={title}
                  data={this.state.data}
                  ref="chartItem"
                />
              </div>
            </div>
          )}
          {this.props.showTable && (
            <React.Fragment>
              <div className="row separator mt-5" />
              {this.props.DetalhePage && (
                <DetalhePage filter={this.state.filter} ref="detailPage" />
              )}
              <Datatable
                defaultOrder={this.props.defaultOrder}
                defaultDirection={this.props.defaultDirection}
                service={this.props.tableService}
                classSignal={this.props.classSignal}
                filter={this.state.filter}
                onRowClick={this.props.onRowClick}
                submitFilter={this.state.submitFilter}
                ref="dataTable"
                onDataUpdate={this.handleRefreshData}
              >
                {this.props.children}
              </Datatable>
            </React.Fragment>
          )}
        </div>
      </CardSeculusFull>
    );
  }
}
export default connect()(CardRelatorio);
