//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const analiseAtendimentoEprService = {
  findByFiltro,
  findChartResult,
  findChart
};

function findChartResult(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.doPost(
    `${urlsConstants.RELATORIO}analiseAtendimentoEprChart`,
    {
      goupByItem: options.goupByItem,
      countDistinct: options.countDistinct,
      filter
    }
  );
}
function findChart(
  filter: any
): Promise<Array<OrdemServicoType>> {
  console.log(filter)
  return defaultService.doPost(
    `${urlsConstants.RELATORIO}analiseAtendimentoEprChart`,
    {
      filter
    }
  );
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.RELATORIO}analiseAtendimentoEpr`,
    options,
    filter
  );
}
