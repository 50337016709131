//@flow
import * as React from 'react';
import { Bar } from 'react-chartjs-2';

export default class ConsumoPecasChart extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
  }
  static defaultProps = { data: [] };
  render() {
    let { data } = this.props;
    let arrayLabels = [];
    let arrayData = [];
    data.map((item, i) => {
      arrayLabels.push(item.nomePeca);
      arrayData.push(item.quantidade);
    });

    const dataChart = {
      labels: arrayLabels,
      datasets: [
        {
          label: 'Consumo de peças em Quantidade',
          fill: false,
          lineTension: 0.1,
          backgroundColor: '#36a2eb',
          borderColor: '#36a2eb',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: arrayData
        }
      ]
    };

    return (
      <React.Fragment>
        {arrayData.length > 0 && (
          <Bar
            data={dataChart}
            width={300}
            options={{
              maintainAspectRatio: false
            }}
          />
        )}
      </React.Fragment>
    );
  }
}
