//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FormInput from '../../components/Inputs/FormInput';
import { FormRow } from '../../components/FormRow';
import { LineVert } from '../../components/Utils/LineVert';
import CnpjInput from '../../components/Inputs/CnpjInput';
import TelephoneInput from '../../components/Inputs/TelephoneInput';
import ZipCodeInput from '../../components/Inputs/ZipCodeInput';
import { alertActions } from '../../actions/alert.action';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import SelectInput from '../../components/Inputs/SelectInput';
import { formsServices } from '../../services/forms.service';
import { objectsConstants } from '../../constants/objects.constants';
import { HeaderComponent } from '../../components/RegisterComponents/HeaderComponent';
import { validateInput } from '../../helpers/validateInputs.helper';
//import ApiCep from "../../services/zipCode.service";
import BtnPrimary from '../../components/Buttons/BtnPrimary';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  country: Array<any>,
  states: Array<any>,
  cities: Array<any>,
  validateObject: any,
  onBack: any
};

const objectValidateBranchVO = [
  objectsConstants.cnpjValidate,
  objectsConstants.razaoSocialValidate,
  objectsConstants.nomeFantasiaValidate,
  objectsConstants.inscEstadualValidate,
  objectsConstants.inscMunicipalValidate,
  objectsConstants.foneComercialValidate,
  objectsConstants.emailComercialValidate
];

const objectValidateAddressVO = [
  objectsConstants.cepValidate,
  objectsConstants.logradouroValidate,
  objectsConstants.numeroValidate,
  objectsConstants.complementoValidate,
  objectsConstants.bairroValidate,
  objectsConstants.paisValidate,
  objectsConstants.estadoValidate,
  objectsConstants.cidadeValidate
];

class RegisterOS extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity),
      country: formsServices.getCountry(),
      states: formsServices.getStates(),
      cities: formsServices.getCities(),
      validateObject: {}
    };
  }

  handleChangebranchVO = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(entity.branchVO, name, type === 'checkbox' ? checked : value);
    this.setState({ entity });
  };

  handleChangeAddressBranchVO = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, type, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(
      entity.branchVO.addressVO,
      name,
      type === 'checkbox' ? checked : value
    );
    this.setState({ entity });
  };

  handleSelectChange = (name: string, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);

    newEntity.branchVO.addressVO[name] =
      option !== null ? option.value : option;
    this.setState({ entity: newEntity });
  };

  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity.branchVO[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });
    objectValidateAddressVO.forEach(o => {
      const value = entity.branchVO.addressVO[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });
    this.setState({ validateObject: validate });

    if (_.isEmpty(validate)) {
      isValid = true;
    }

    return isValid;
  };

  nextStep = () => {
    const toastrOptionsSuccess = { icon: <IconErrorToast /> };
    if (this.validateForms()) {
      alertActions.clear();
      this.props.handleEntity(this.state.entity);
      this.props.step(2);
    } else {
      alertActions.clear();
      alertActions.error(
        objectsConstants.messageCampoObrigatorio,
        '',
        toastrOptionsSuccess
      );
    }
  };

  previousStep = () => {};

  render() {
    const { entity, country, cities, states, validateObject } = this.state;
    return (
      <React.Fragment>
        <div className="tab-content" style={{ maxWidth: 500, padding: 0 }}>
          <div className="tab-pane active" id="empresa">
            <div
              className="text-center"
              style={{
                backgroundColor: '#F8F8F8',
                padding: '10px 20px 10px 20px'
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  margin: 0
                }}
              >
                Não encontramos nenhum cadastro com esse CPF.
              </p>
              <p>
                Por Favor, termine de inserir os campos abaixo para cadastrar
                este novo cliente.
              </p>
            </div>
            <div style={{ padding: '0px 20px 10px 20px' }}>
              <h3>CLIENTE</h3>
              <FormRow>
                <FormInput
                  type="text"
                  name="nomeFantasia"
                  label="Nome "
                  placeholder="Digite o nome completo ou nome fantasia"
                  required={true}
                  messageValidate={validateObject.nomeFantasia}
                  style={['col-lg-12']}
                  id={'nomeFantasia'}
                  ref={input => {
                    this._nomeFantasia = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.branchVO.nomeFantasia}
                />
                <FormInput
                  type="text"
                  name="emailComercial"
                  label="E-mail "
                  placeholder="Digite o e-mail"
                  messageValidate={validateObject.emailComercial}
                  required={true}
                  style={['col-lg-12']}
                  id={'emailComercial'}
                  ref={input => {
                    this._emailComercial = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.branchVO.emailComercial}
                />
                <ZipCodeInput
                  type="number"
                  name="cep"
                  label="CEP"
                  placeholder="CEP"
                  messageValidate={validateObject.cep}
                  required={true}
                  style={['col-lg-4']}
                  size={6}
                  id={'cep'}
                  ref={input => {
                    this._cep = input;
                  }}
                  onChange={this.handleChangeAddressBranchVO}
                  value={entity.branchVO.addressVO.cep}
                />
                <SelectInput
                  type="text"
                  name="pais"
                  label="País"
                  placeholder="País"
                  messageValidate={validateObject.pais}
                  required={true}
                  style={['col-lg-4']}
                  size={6}
                  options={country}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'pais'}
                  ref={input => {
                    this._pais = input;
                  }}
                  onChange={this.handleSelectChange}
                  value={entity.branchVO.addressVO.pais}
                />

                <SelectInput
                  type="text"
                  name="estado"
                  label="Estado"
                  placeholder="Estado"
                  messageValidate={validateObject.estado}
                  required={true}
                  style={['col-lg-4']}
                  size={6}
                  options={states}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'estado'}
                  ref={input => {
                    this._estado = input;
                  }}
                  onChange={this.handleSelectChange}
                  value={entity.branchVO.addressVO.estado}
                />
                <FormInput
                  type="text"
                  name="logradouro"
                  label="Rua"
                  placeholder="Av, Rua, Alameda"
                  messageValidate={validateObject.logradouro}
                  required={true}
                  style={['col-lg-8']}
                  id={'logradouro'}
                  ref={input => {
                    this._logradouro = input;
                  }}
                  onChange={this.handleChangeAddressBranchVO}
                  value={entity.branchVO.addressVO.logradouro}
                />
                <FormInput
                  type="text"
                  name="numero"
                  label="Número"
                  placeholder="Nº"
                  messageValidate={validateObject.numero}
                  required={true}
                  style={['col-lg-4']}
                  size={4}
                  id={'numero'}
                  ref={input => {
                    this._numero = input;
                  }}
                  onChange={this.handleChangeAddressBranchVO}
                  value={entity.branchVO.addressVO.numero}
                />
                <FormInput
                  type="text"
                  name="bairro"
                  label="Bairro"
                  placeholder="Bairro"
                  messageValidate={validateObject.bairro}
                  required={true}
                  style={['col-lg-4']}
                  id={'bairro'}
                  ref={input => {
                    this._bairro = input;
                  }}
                  onChange={this.handleChangeAddressBranchVO}
                  value={entity.branchVO.addressVO.bairro}
                />

                <SelectInput
                  type="text"
                  name="cidade"
                  label="Cidade"
                  placeholder="Cidade"
                  messageValidate={validateObject.cidade}
                  required={true}
                  style={['col-lg-4']}
                  options={cities}
                  returnFullObject={true}
                  searchable={true}
                  //messageValidate={validate.portadorDeficiencia}
                  id={'cidade'}
                  ref={input => {
                    this._cidade = input;
                  }}
                  onChange={this.handleSelectChange}
                  value={entity.branchVO.addressVO.cidade}
                />
                <FormInput
                  type="text"
                  name="complemento"
                  label="Complemento"
                  placeholder="Complemento"
                  messageValidate={validateObject.complemento}
                  required={false}
                  style={['col-lg-4']}
                  size={8}
                  id={'complemento'}
                  ref={input => {
                    this._complemento = input;
                  }}
                  onChange={this.handleChangeAddressBranchVO}
                  value={entity.branchVO.addressVO.complemento}
                />

                <TelephoneInput
                  type="number"
                  name="foneComercial"
                  label="Telefone comercial"
                  placeholder="Somente números"
                  messageValidate={validateObject.foneComercial}
                  required={true}
                  style={['col-lg-8']}
                  size={6}
                  id={'foneComercial'}
                  ref={input => {
                    this._foneComercial = input;
                  }}
                  onChange={this.handleChangebranchVO}
                  value={entity.branchVO.foneComercial}
                />
              </FormRow>
              <div className="col d-flex justify-content-between align-items-center mb-3">
                <BtnPrimary
                  type={'button'}
                  className={'justify-content-start voltar my-4 mx-2'}
                  title={'CANCELAR'}
                  onSubmit={this.props.onBack}
                  disabled={this.props.disableBackBtn}
                >
                  <i className="icon_arrow" />
                </BtnPrimary>
                <BtnPrimary
                  type={'button'}
                  className={'my-4 mx-2'}
                  title={'SALVAR'}
                  onSubmit={this.props.onForward}
                  disabled={this.props.disableForwardBtn}
                />
              </div>
              <LineVert />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(RegisterOS);
