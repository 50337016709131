//@flow
import * as React from 'react';

import { relatorioService } from '../../../services/relatorio.service';
import VolumeTrocaFormFilter from './VolumeTrocaFormFilter';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import { TableHeaderColumn } from 'react-bootstrap-table';
import _ from 'lodash';
import { volumeTrocaService } from '../../../services/volumeTrocaService.service';
import { tableHelper } from '../../../helpers/tableHelper';
import VolumeTrocaDetails from './VolumeTrocaDetails';
import VolumesTrocasChart from './VolumesTrocasChart';

const columns = [
  {
    path: 'numOs',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'relogioOrigem.codigoRelogio',
    title: 'Referência do problema',
    isKey: false,
    dataSort: false
  },
  {
    path: 'relogioOrigem.marca.nome',
    title: 'Marca do Relógio problema',
    isKey: false,
    dataSort: false
  },
  {
    path: 'relogioNovo.codigoRelogio',
    title: 'Referência do Relógio novo',
    isKey: false,
    dataSort: true
  },
  {
    path: 'relogioNovo.marca.nome',
    title: 'Marca do Relógio trocado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'quantidade',
    title: 'Quantidade',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    path: 'cidade.nome',
    title: 'Cidade',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  }
];

export default class RelatorioVolumeTroca extends React.PureComponent<
  Props,
  State
> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    this.state = {
      fields
    };
  }
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="VOLUME DE TROCAS"
          canBack={false}
        />
        <CardRelatorio
          title="VOLUME DE TROCAS"
          methodFindChart={relatorioService.volumeTroca}
          FormFilter={VolumeTrocaFormFilter}
          renderChartOnConstruct={true}
          Chart={VolumesTrocasChart}
          showTable={true}
          tableService={volumeTrocaService}
          controller={'relatorio/volumeTroca'}
          fields={this.state.fields}
          DetalhePage={VolumeTrocaDetails}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}
