//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const causaOsService = {
  findAll,
  findBySintoma
};
function findBySintoma(sintoma) {
  return defaultService.doGet(
    `${urlsConstants.FIND_CAUSA_BY_SINTOMA}/${sintoma}`
  );
}
function findAll(sintoma, description) {
  return defaultService.doGet(
    `${urlsConstants.FIND_ALL_CAUSA}/${sintoma}/${description}`
  );
}
