//@flow
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import FormInput from '../../../components/Inputs/FormInput';
import '../../../css/doc_manaus.css';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { pecaService } from '../../../services/peca.service';
import MoneyInput from '../../../components/Inputs/MoneyInput';
type Props = { onChangeText: any, entity: any, onChangeValue: any };

class TablePecasItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { estoque: 0, entity: {} };
  }

  componentDidMount() {
    if (!this.props.marcaTerceiros) {
      this.getEstoque(this.props.peca.id);
    }
  }

  getEstoque(idPeca) {
    const { peca } = this.props;
    if (peca.dataAtendida === undefined) {
      pecaService.getEstoque(idPeca).then(result => {
        let estoqueLabel = result.data;
        if (result.data <= 0) {
          estoqueLabel = 'Sem estoque';
        }
        this.setState({ estoque: result.data, estoqueLabel });
      });
    } else {
      this.setState({ estoque: '--' });
    }
  }

  _handleChangeText = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    let valueFiltered = number(value);
    console.log(this.props.peca);
    _.set(newEntity, name, valueFiltered);

    this.setState({ entity: newEntity }, () => {
      if (this.state.entity.quantidadeSolicitada) {
        this.props.onSetPeca({
          peca: this.props.peca,
          quantidade: this.state.entity.quantidadeSolicitada,
          valorCompra: this.props.peca.valor,
          solicitarPeca: true
        });
      }
    });
  };

  _handleQuantidade = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    let valueFiltered = number(value);

    _.set(newEntity, name, valueFiltered);

    this.setState({ entity: newEntity }, () => {
      if (this.state.entity.quantidadeSolicitada) {
        console.log('Entidade', this.state.entity);
        this.props.onSetPeca({
          peca: this.props.peca,
          solicitarPeca: true,
          quantidade: this.state.entity.quantidadeSolicitada,
          codigoPeca: this.state.entity.codigoPeca,
          valorCompra: this.state.entity.valorCompra
        });
      }
    });
  };

  _handleCodigo = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    const newEntity = _.cloneDeep(this.state.entity);
    _.set(newEntity, name, value);
    this.setState({ entity: newEntity }, () => {
      if (this.state.entity.codigoPeca) {
        this.props.onSetPeca({
          peca: this.props.peca,
          solicitarPeca: true,
          quantidade: this.state.entity.quantidadeSolicitada,
          codigoPeca: this.state.entity.codigoPeca,
          valorCompra: this.state.entity.valorCompra
        });
      }
    });
  };

  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    this.setState({ entity: newEntity }, () => {
      if (this.state.entity.valorCompra) {
        console.log('Entidade', this.state.entity);
        this.props.onSetPeca({
          peca: this.props.peca,
          solicitarPeca: true,
          quantidade: this.state.entity.quantidadeSolicitada,
          codigoPeca: this.state.entity.codigoPeca,
          valorCompra: this.state.entity.valorCompra
        });
      }
    });
  };

  getStoqueColor = estoque => {
    let estoqueClass = '';
    if (estoque === 0) {
      estoqueClass = 'txtRed';
    } else if (estoque > 0 && estoque < 5) {
      estoqueClass = 'txtBlue';
    }
    return estoqueClass;
  };

  getGridByTercerios() {
    const { peca, lasItem, index, marcaTerceiros } = this.props;
    let helpTextValorFinal = `Preço Sugerido:\n Posto: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valor),
      2,
      ',',
      '.'
    )} \n Lojista: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valorLogista),
      2,
      ',',
      '.'
    )} \n Consumidor: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valorConsumidor),
      2,
      ',',
      '.'
    )} `;

    return (
      <React.Fragment>
        <tr className={` ${lasItem ? 'fim' : ''} `}>
          <td className="pr-0 pr-sm-2">
            <div data-title={'Peça.'}>{peca.nome}</div>
          </td>
          <td className="pr-0 pr-sm-2">
            <FormInput
              name="codigoPeca"
              placeholder="Cod.Peca"
              noSize={true}
              value={this.state.entity.codigoPeca}
              onChange={this._handleCodigo}
            />
          </td>
          <td className="pr-0 pr-sm-2">
            <div data-title={'Valor uni.'}>
              <MoneyInput
                type="text"
                name="valorCompra"
                placeholder="0.00"
                noSize={true}
                id={'valorCompra'}
                onChange={this.handleSelectChange}
                value={this.state.entity.valorCompra}
              />
            </div>
          </td>
          <td className="pr-0 pr-sm-2">
            <div className="bd-highlight" data-title={'Qtde.'}>
              <FormInput
                id={`quantidadeSolicitada${peca.id}`}
                name="quantidadeSolicitada"
                placeholder="qtde."
                noSize={true}
                onChange={this._handleQuantidade}
                value={this.state.entity.quantidadeSolicitada}
                min={0}
              />
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  getGridPadrao() {
    const { peca, lasItem, index, marcaTerceiros } = this.props;
    let helpTextValorFinal = `Preço Sugerido:\n Posto: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valor),
      2,
      ',',
      '.'
    )} \n Lojista: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valorLogista),
      2,
      ',',
      '.'
    )} \n Consumidor: ${ordemServicoHelper.formatMoney(
      parseFloat(peca.valorConsumidor),
      2,
      ',',
      '.'
    )} `;

    return (
      <React.Fragment>
        <tr className={` ${lasItem ? 'fim' : ''} `}>
          <td data-label={''} colSpan="2">
            <div data-title={'Peça.'}>{peca.nome}</div>
          </td>
          <td>
            <div data-title={'Código peça'}>{peca.codigoPeca}</div>
          </td>
          <td
            className={` pl-1 txtUppercase ${this.getStoqueColor(
              this.state.estoque
            )}`}
          >
            <div data-title={'Estoque'}>{this.state.estoqueLabel}</div>
          </td>
          <td className={`codigo  ${this.getStoqueColor(this.state.estoque)}`}>
            <div data-title={'Valor uni.'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(peca.valor),
                2,
                ',',
                '.'
              )}{' '}
            </div>
          </td>
          <td>
            <div className="bd-highlight" data-title={'Qtde.'}>
              <i
                className="icon-duvida"
                id={'help_' + `quantidadeSolicitada${peca.id}`}
                data-html={true}
                data-tip={helpTextValorFinal}
              />
              <ReactTooltip />
              <FormInput
                id={`quantidadeSolicitada${peca.id}`}
                name="quantidadeSolicitada"
                placeholder="qtde."
                size="12"
                value={this.state.entity.quantidadeSolicitada}
                disabled={peca.dataAtendida !== undefined}
                onChange={this._handleChangeText}
                min={0}
                max={this.state.estoque}
              />
            </div>
          </td>
        </tr>
      </React.Fragment>
    );
  }

  render() {
    const { marcaTerceiros } = this.props;

    if (marcaTerceiros) return this.getGridByTercerios();
    else return this.getGridPadrao();
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');
  return v;
}

const mapDispatch = ({ alert: { error, success } }) => ({
  error: message => error({ message }),
  success: message => success({ message })
});

export default connect(null, mapDispatch, null, { withRef: true })(
  TablePecasItem
);
