//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import axios from "axios";
import {oauthHeaderJson} from "../helpers/oauth-header";

export const avalicaoAtendimento = {
  doSave,
  doGet
};

function doGet(id) {
  return axios.get(`${urlsConstants.AVALIAR_ATENDIMENTO}${id}`);
}

function doSave(avaliacaoVO) {
  let entity = avaliacaoVO;
  if (entity.id === null || entity.id === undefined) {
    return axios.post(urlsConstants.AVALIAR_ATENDIMENTO, entity);
  } else {
    return axios.put(urlsConstants.AVALIAR_ATENDIMENTO + entity.id, entity);
  }
}
