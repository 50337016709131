//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import ConfiguracoesView from './ConfiguracoesView';
import ConfiguracoesSintomasCausas from './ConfiguracoesSintomasCausas';
import ConfiguracoesValoresGarantia from './ConfiguracoesValoresGarantia';
import ConfiguracoesValoresGarantiaPosto from './ConfiguracoesValoresGarantiaPosto';

export default class Configuracoes extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/configuracoes/view/:id"
            exact
            component={ConfiguracoesView}
          />
          <Route
            path="/configuracoes/valoresGarantia"
            exact
            component={ConfiguracoesValoresGarantia}
          />
          <Route
            path="/configuracoes/valoresGarantia/posto/:id"
            exact
            component={ConfiguracoesValoresGarantiaPosto}
          />
          <Route
            path="/configuracoes/sintomasCausas"
            exact
            component={ConfiguracoesSintomasCausas}
          />
        </Switch>
      </React.Fragment>
    );
  }
}
