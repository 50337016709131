import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Toast from '../../components/Toasts/Toast';
import RegisterFirstStep from './RegisterFirstStep';
import RegisterSecondStep from './RegisterSecondStep';
import RegisterThirdStep from './RegisterThirdStep';
import RegisterFourthStep from './RegisterFourthStep';
import RegisterOS from './RegisterOS';
import { BodyWhite } from '../../components/templates/BodyWhite';
import { HeaderNavBullet } from '../../components/RegisterComponents/HeaderNavBullet';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

type Props = {};
type State = {
  entity: any,
  step: number,
  goHome: boolean
};

let _branch = {};

if (process.env.NODE_ENV === 'development') {
  //_branch = {"id":0,"cep":"32.041-620","rua":"Rua verador","numero":"322","complemento":"abc","bairro":"Linda vista","pais":"BRASIL","estado":"MG","cidade":"Belo Horizonte","telefoneComercial":"(31) 33313-1313","cnpj":"12.121.212/1212-12","razaoSocial":"marph","nomeFantasia":"marph","inscricaoEstadual":"12121212","inscricaoMunicipal":"1212121212","emailComercial":"marph@marph.com","responsibleVO":{"nome":"wesllei gustavo ferreira","email":"wesllei.gustavo@gmail.com","confirmarEmail":"wesllei.gustavo@gmail.com","celular":"(31) 98651-606 ","dataNascimento":"2018-10-10T03:00:00.000Z","cpf":"106.459.296-11","senha":"123","confirmarSenha":"123"},"termAccepted":true};
}

const indexURl = 'index';
class RegisterMainForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.nextStep = this.nextStep.bind(this);
    this.goToStep = this.goToStep.bind(this);

    let tempEntity = localStorage.getItem('tempCadastro');
    if (tempEntity) {
      _branch = JSON.parse(tempEntity);
    }

    this.state = {
      entity: _branch,
      step: 1,
      goHome: false
    };
  }

  componentWillMount() {
    localStorage.removeItem('token');
  }

  handlCancel = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="cancel-ui">
            <h3>Deseja voltar?</h3>
            <p className="p-msg">
              Você vai perder todos os seus dados não salvos desta tela. Deseja
              realmente cancelar e voltar para a tela inicial?
            </p>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.goHome();
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  handleEntity = (entity: any, nexStep: number, finished) => {
    localStorage.setItem('tempCadastro', JSON.stringify(entity));
    this.setState({
      entity,
      step: nexStep,
      finished
    });
  };
  goHome = goToLogin => {
    this.setState({
      goHome: true,
      goToLogin
    });
  };
  onBack = () => {
    if (this.state.step === 1) {
      this.handlCancel();
    } else {
      this.setState({
        step: this.state.step - 1
      });
    }
  };
  nextStep = () => {
    this.currentStep.getWrappedInstance().nextStep();
  };

  goToStep(step: number) {
    if (this.state.step > step) {
      this.setState({
        step
      });
      return true;
    }
    return false;
  }

  render() {
    const { step, entity, finished, goToLogin } = this.state;
    if (this.state.goHome) {
      return (
        <Redirect
          to={{
            pathname: indexURl,
            state: { goToLogin }
          }}
        />
      );
    }
    return (
      <section id="sectionCadastro">
        <BodyWhite />
        <div className="container-fluid">
          <div className="wizard-container">
            <div
              className="card wizard-card"
              data-color="orange"
              id="wizardProfile"
            >
              <Toast />
              <div className="topoFixo">
                <HeaderNavBullet
                  onBack={this.onBack}
                  disableForwardBtn={false}
                  hideBtns={finished}
                  disableBackBtn={false}
                  step={this.state.step}
                  onForward={this.nextStep}
                  subtitle={
                    finished
                      ? ''
                      : 'Preencha os campos abaixo para se cadastrar'
                  }
                  clickStepBulet={this.goToStep}
                />
              </div>
              <div className="cadastro">
                <div className="tab-content">
                  {step === 1 && (
                    <RegisterFirstStep
                      entity={entity}
                      handleEntity={this.handleEntity}
                      step={step}
                      ref={thiStep => {
                        this.currentStep = thiStep;
                      }}
                    />
                  )}

                  {step === 2 && (
                    <RegisterSecondStep
                      entity={entity}
                      handleEntity={this.handleEntity}
                      step={step}
                      ref={thiStep => {
                        this.currentStep = thiStep;
                      }}
                    />
                  )}
                  {step === 3 && (
                    <RegisterThirdStep
                      entity={entity}
                      handleEntity={this.handleEntity}
                      step={step}
                      ref={thiStep => {
                        this.currentStep = thiStep;
                      }}
                    />
                  )}
                  {step === 4 && (
                    <RegisterFourthStep
                      entity={entity}
                      step={step}
                      onBack={this.goHome}
                      ref={thiStep => {
                        this.currentStep = thiStep;
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row rodapeFixed">
          <div className="col-12 text-right">
            <h5 className="rodape">
              <img className="iconRodape" src={require('../../img/icon.svg')} />
              © Seculus da Amazônia
            </h5>
          </div>
        </div>
      </section>
    );
  }
}
export default connect(
  null,
  null,
  null,
  { withRef: true }
)(RegisterMainForm);
