//@flow
import * as React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import '../../css/selectInput.css';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { Tooltip } from 'reactstrap';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipHelp } from '../Utils/ToolTipHelp';

type Props = {
  id?: string,
  label: string,
  name: string,
  value?: any,
  size?: number,
  onChange: any,
  parent?: any,
  onFetchData?: any,
  valueKey: string,
  labelKey: string,
  options?: any,
  multi?: boolean,
  required: boolean,
  messageValidate?: string,
  returnFullObject: boolean,
  clearable: boolean,
  creatable: boolean,
  onNewOptionClick: any,
  newOptionCreator: any,
  cache: boolean,
  placeholder: string,
  style: any,
  searchable?: boolean,
  disabled?: boolean
};

type State = {};
let timoutVar;
class SelectInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }
  static defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
    async: true,
    multi: false,
    label: '',
    required: false,
    returnFullObject: false,
    clearable: false,
    creatable: false,
    cache: true,
    placeholder: '',
    size: 12
  };

  handleSelectChange = (input: any) => {
    if (this.props.returnFullObject) {
      this.props.onChange(this.props.name, input);
    } else {
      this.props.onChange(
        this.props.name,
        input ? input[this.props.valueKey] : null
      );
    }
  };

  handleBorderColor = messageValidate => {
    let borderColor, borderWidth;
    if (messageValidate) {
      borderColor = '#ea3a45';
      borderWidth = 2;
    }
    return {
      borderColor,
      borderWidth
    };
  };

  handleFocus = () => {
    const { messageValidate } = this.props;
    if (messageValidate) {
      return (
        <ToolTipFeedBack
          target={this.props.id}
          messageValidate={messageValidate}
        />
      );
    }
  };

  clearThisTimout = () => {
    clearTimeout(timoutVar);
  };

  fetchData = (input: string, callback) => {
    this.clearThisTimout();
    let p: Promise<any> = Promise.resolve({ options: [] });
    if (!input) {
      return p;
    }
    timoutVar = setTimeout(() => {
      if (this.props.onFetchData) {
        let fetch;
        if (this.props.parent !== null && this.props.parent) {
          fetch = this.props.onFetchData(this.props.parent, input);
        } else {
          fetch = this.props.onFetchData(input);
        }
        fetch.then(
          json => {
            let result = {
              options: json.data.data ? json.data.data : json.data
            };
            callback(null, result);
          },
          () => {
            callback(null, p);
          }
        );
      } else {
        callback(null, p);
      }
    }, 1000);
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  };

  render() {
    const { messages, name } = this.props;
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    const value = this.props.value || '';
    let TypeComponent;
    if (this.props.onFetchData) {
      if (this.props.creatable) {
        TypeComponent = Select.AsyncCreatable;
      } else {
        TypeComponent = Select.Async;
      }
    } else {
      if (this.props.creatable) {
        TypeComponent = Select.Creatable;
      } else {
        TypeComponent = Select;
      }
    }

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <FormGroup
        className={`${this.props.noSize ? '' : colSize} ${this.props.style}`}
        onMouseEnter={this.toggle}
      >
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={'help_' + this.props.id} />
                <ToolTipHelp
                  target={'help_' + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <TypeComponent
          id={this.props.id ? this.props.id : this.props.name}
          name={this.props.name}
          loadOptions={this.fetchData}
          onChange={this.handleSelectChange}
          valueKey={this.props.valueKey}
          labelKey={this.props.labelKey}
          value={value}
          searchable={this.props.searchable}
          noResultsText={
            !this.props.noResultsText
              ? 'Nenhum resultado encontrado.'
              : this.props.noResultsText
          }
          searchPromptText="Digite para buscar"
          placeholder={
            this.props.placeholder ? this.props.placeholder : 'Selecione'
          }
          clearValueText="Limpar valor"
          loadingPlaceholder="Carregando"
          clearAllText="Limpar todos"
          promptTextCreator={(label: string) => {
            return `Deseja cadastrar "${label}"?`;
          }}
          cache={this.props.cache && {}}
          style={this.handleBorderColor(messageValidate)}
          multi={this.props.multi}
          options={this.props.options}
          clearable={this.props.clearable}
          onNewOptionClick={this.props.onNewOptionClick}
          newOptionCreator={this.props.newOptionCreator}
          required={this.props.required}
          disabled={this.props.disabled}
          className={`width-full ${this.props.className}`}
          inputProps={{
            autoComplete: 'new-input',
            autoCorrect: 'off',
            spellCheck: 'off'
          }}
        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(SelectInput);
