//@flow
import React, { PureComponent } from 'react';
import { FormGroup, Label } from 'reactstrap';
import Picker from 'react-month-picker';
import 'react-month-picker/css/month-picker.css';
import InputMask from 'react-input-mask';
import { translate } from '../../helpers/message.helper';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import moment from 'moment';
import { objectsConstants } from '../../constants/objects.constants';

type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean
};

let pickerLang = {
  months: objectsConstants.MONTH_ARRAY
};

class MonthPickerInput extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    let dataAtual = moment();

    let year = this.props.year ? this.props.year : dataAtual.year();
    let month = this.props.month ? this.props.month : dataAtual.month() + 1;

    this.state = {
      month,
      year,
      defaultValue: this.props.defaultValue
    };
  }
  _datePicker;
  static defaultProps = {
    required: false,
    size: 12
  };

  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  handleChange = (year: any, month: any) => {
    this.setState({ year, month, defaultValue: false });
    if (this.props.onChange) {
      this.props.onChange(this.props.name, month, year);
    }
  };

  render() {
    const { messages, name } = this.props;
    let mvalue = { year: this.state.year, month: this.state.month };
    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    let makeText = m => {
      return pickerLang.months[m.month - 1] + ' ' + m.year;
    };

    return (
      <FormGroup
        className={`${this.props.noSize ? '' : colSize} ${this.props.style}`}
      >
        {this.props.label && (
          <Label for={this.props.name}>
            {this.props.label}
            {this.props.required && '*'}
          </Label>
        )}

        <Picker
          ref="pickAMonth"
          //years={arrayYears}
          value={mvalue}
          lang={pickerLang.months}
          onChange={this.handleChange}
          className="form-control"
          placeholder={this.props.placeholder}
        >
          <div
            onClick={e => {
              this.refs.pickAMonth.show();
            }}
            className="pickerContent"
          >
            {this.state.defaultValue
              ? this.props.placeholder
              : makeText(mvalue)}
          </div>
        </Picker>

        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(MonthPickerInput);
