//exemplo de chamada do modal
// type State = {
//   isModalVisible: boolean
// };
// this.state = {
//   isModalVisible: false
// };
// <NovaOsModal
//   isModalVisible={this.state.isModalVisible}
//   togglemodal={this._togglemodal}
// />;
// _togglemodal = () => {
//   this.setState({ isModalVisible: !this.state.isModalVisible });
// };
import { Formik } from 'formik';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Modal, ModalBody } from 'reactstrap';
import * as Yup from 'yup';

import CpfCnpjInput from '../../components/Inputs/CpfCnpjInput';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';

type State = {
  isModalVisible: any
};

const NewCompraInit = Yup.object().shape({
  documentoModal: Yup.string()
    .min(14, 'CPF ou CNPJ inválido!')
    .max(18, 'CPF ou CNPJ inválido!')
    .required('CPF ou CNPJ é obrigatório')
});

class NovaCompraModal extends PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    this.setState({ isModalVisible: nextProps.isModalVisible });
  }

  toggleModal = () => {
    this.props.toggleModal();
  };

  render() {
    const { entity } = this.state;

    return (
      <Modal isOpen={this.state.isModalVisible} toggle={this.toggleModal}>
        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column">
                <h3>
                  <span className="txtGreen"> nova compra </span>- posto
                </h3>
                <Formik
                  initialValues={{ documentoModal: '' }}
                  validationSchema={NewCompraInit}
                  onSubmit={(values, actions) => {
                    postoAutorizadoService
                      .getByDocumento(values.documentoModal.replace(/\D/g, ''))
                      .then(posto => {
                        console.log('POSTO DATA DATA ', posto.data.data);
                        this.props.history.push('/compraPeca/novo', {
                          entity: { posto: posto.data.data }
                        });
                      })
                      .catch(() => {
                        actions.setSubmitting(false);
                      });
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="conteudo">
                        <p align="justify">
                          Identifique se o posto já está cadastrado no sistema.
                          Se ainda não estiver cadastrado, é preciso cadastrá-lo
                          para registrar uma nova compra.
                        </p>
                        <div className="form-group">
                          <label>CPF ou CNPJ</label>
                          <CpfCnpjInput
                            type="text"
                            className={`form-control ${errors.documentoModal &&
                              touched.documentoModal &&
                              'error'}`}
                            id={'documentoModal'}
                            name="documentoModal"
                            size={100}
                            placeholder="Digite o CPF ou CNPJ"
                            value={values.documentoModal}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mt-auto"
                        disabled={isSubmitting}
                      >
                        identificar posto
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const mapDispatch = ({ ordemServico: { setPosto, clearPosto } }) => ({
  setPosto: posto => setPosto({ posto }),
  clearPosto: () => clearPosto({})
});

export default connect(
  null,
  mapDispatch
)(withRouter(NovaCompraModal));
