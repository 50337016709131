//@flow
import * as React from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import AprovarAguardarCardBtn from '../../components/Buttons/AprovarAguardarCardBtn';
import { CardItem } from '../../components/templates/CardItem';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import ResponsavelLegalView from '../responsavelLegal/ResponsavelLegalView';
import PostoAutorizadoFormViewView from './PostoAutorizadoFormViewView';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';
import { connect } from 'react-redux';
import ColaboradorView from '../colaboradores/ColaboradorView';
import { PostoAutorizadoFinanceiroDisabled } from '../financeiro/PostoAutorizadoFinanceiroDisabled';
import { BoxPostoAutorizadoOSDisabled } from '../boxOrdemServico/BoxPostoAutorizadoOSDisabled';
import CardFinanceiro from '../../components/Cards/CardFinanceiro';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import BtnSecondary from '../../components/Buttons/BtnSecondary';
import { objectsConstants } from '../../constants/objects.constants';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import PostoAutorizadoFormView from "./PostoAutorizadoFormView";
import MarcasBloqueadasView from "../marcasBloqueadasPosto/MarcasBloqueadasView";

type Props = {
  match: any
};

type State = {
  entity: PostoAutorizadoType
};

class PostoAutorizadoView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: {},
      redirect: false,
      edit:false
    };
  }
  componentWillReceiveProps(nextProps: any) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      postoAutorizadoService.doGet(nextProps.match.params.id).then(response => {
        let posto = response.data.data;
        this.setState({ entity: posto });
      });
    }
  }

  componentDidMount() {
    postoAutorizadoService.doGet(this.props.match.params.id).then(response => {
      let posto = response.data.data;
      this.setState({ entity: posto });
    });
  }

  aprovePosto = () => {
    const { entity } = this.state;
    this.props.loading(true);
    postoAutorizadoService.autorizar(entity.id).then(
      r => {
        this.props.success({ message: 'Posto aprovado com sucesso.' });
        this.props.loading(false);
        this.props.history.push('/postoAutorizadoList');
      },
      () => {
        this.props.clear();
        this.props.error('Erro ao aprovar posto');
        this.props.loading(false);
      }
    );
  };

  changePosto = () => {
    this.setState({edit:true})
  };

  getBtnRemove = () => {
    const { entity } = this.state;
    return (
      <button
        type="button"
        className={'btn btn-primary btn-sm red'}
        data-toggle="modal"
        data-target="#editaDados"
        onClick={() => {
          ConfirmAcationService.confirmMsg(
            this.handleInativar,
            'REMOVER POSTO',
            'Você confirma que deseja REMOVER o posto:',
            '' + entity.nomeFantasia + '?'
          );
        }}
      >
        EXCLUIR
      </button>
    );
  };

  handleInativar = () => {
    const { entity } = this.state;
    this.props.loading(true);
    postoAutorizadoService
      .inativarPosto(entity.id)
      .then(response => {
        this.props.success({ message: 'Posto excluído com sucesso.' });
        this.props.loading(false);
        this.props.history.push('/postoAutorizadoList');
      })
      .catch(() => {
        this.props.loading(false);
      });
  };

  handleDelete = () => {
    const { entity } = this.state;
    this.props.loading(true);
    postoAutorizadoService
      .doDelete(entity.id)
      .then(response => {
        this.props.success({ message: 'Posto excluído com sucesso.' });
        this.props.loading(false);
        this.props.history.push('/postoAutorizadoList');
      })
      .catch(() => {
        this.props.loading(false);
      });
  };

  getBtns = () => {
    if (this.state.entity.paStatus === objectsConstants.HABILITADO) {
      return (
        <div className="form-row">
          {this.getBtnRemove()}
          {!this.state.entity.aprovado ? (
            <AprovarAguardarCardBtn onAprove={this.aprovePosto} />
          ) : (
            ''
          )}
        </div>
      );
    } else {
      if (!this.state.entity.aprovado) {
        return <AprovarAguardarCardBtn onAprove={this.aprovePosto} />;
      } else {
        return (
          ''
        );
      }
    }
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/home'} />;
    }
    return (
      <React.Fragment>
        <div className="flex-column">
          <CardItem title="" getBtns={this.getBtns}>
            {this.state.entity.id && (
              <React.Fragment>
                {' '}
                <PostoAutorizadoFormView
                  entity={this.state.entity}
                  readOnly={
                    this.state.entity.paStatus == objectsConstants.HABILITADO
                      ? this.state.edit
                      : true
                  }
                />
              </React.Fragment>
            )}

          </CardItem>

          {this.state.entity.id &&
            this.state.entity.paStatus !== 'HABILITADO' && (
              <ResponsavelLegalView id={this.state.entity.id} />
            )}
        </div>
        <div className="flex-column">
          {!this.state.entity.aprovado && (
            <React.Fragment>
              <PostoAutorizadoFinanceiroDisabled />
              <BoxPostoAutorizadoOSDisabled />
            </React.Fragment>
          )}

          {this.state.entity.aprovado && (
            <React.Fragment>
              <CardFinanceiro postoAutorizado={this.state.entity} />
              <BoxOrdemServico postoAutorizado={this.state.entity} />
              <ColaboradorView extension={this.state.entity.id} />
              <MarcasBloqueadasView id={this.state.entity.id} />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { error, clear, success },
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: message => success(message),
  loading: (load: boolean) => loading({ load })
});
export default connect(null, mapDispatch)(PostoAutorizadoView);
