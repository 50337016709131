//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import '../../../css/doc_manaus.css';
import { dateHelper } from '../../../helpers/date.helper';
import { pecaService } from '../../../services/peca.service';
import _ from 'lodash';
import { ordemServicoHelper } from '../../../helpers/ordemServico.helper';
import { Input } from 'reactstrap';
import RejeitarPecasBtn from './RejeitarPecasBtn';
import { propertyConstantes } from '../../../constants/property.constantes';
import { compraPecaService } from '../../../services/compraPeca.service';
import FileInput from '../../../components/Inputs/FileInput';
import { urlsConstants } from '../../../constants/url.constant';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class TablePecasViewItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = { estoque: 0, entity: {} };
  }

  getTrClass = () => {
    const { peca, ordemServico } = this.props;
    if (
      ordemServico.statusOS.name === propertyConstantes.ATENDIDA_PARCIALMENTE &&
      !peca.lastPecaRegistroTrabalhoDocVO
    ) {
      return 'txtRed';
    }
    if (
      peca.lastPecaRegistroTrabalhoDocVO &&
      peca.lastPecaRegistroTrabalhoDocVO.status.name ===
        propertyConstantes.REJEITADA
    ) {
      return 'txtBlue border2';
    }
  };
  getBtnRejectOrgetImagenPeca = () => {
    const { peca } = this.props;
    if (
      peca.lastPecaRegistroTrabalhoDocVO &&
      peca.lastPecaRegistroTrabalhoDocVO.status.name !==
        propertyConstantes.REJEITADA
    ) {
      return (
        <RejeitarPecasBtn
          peca={peca.peca}
          pecaRegistroTrabalhoDocVO={peca.lastPecaRegistroTrabalhoDocVO}
          serviceSave={compraPecaService.rejeitarPeca}
          serviceGet={compraPecaService.getById}
        />
      );
    } else if (
      peca.lastPecaRegistroTrabalhoDocVO &&
      peca.lastPecaRegistroTrabalhoDocVO.status.name ===
        propertyConstantes.REJEITADA
    ) {
      return (
        <FileInput
          emptyLabel="Enviar foto NF*"
          name="fotoPecaRejeitada"
          entity={peca.lastPecaRegistroTrabalhoDocVO}
          fileItem={peca.lastPecaRegistroTrabalhoDocVO.fotoPecaRejeitada}
          className="small txtBlue"
          accept="image/*"
          readOnly={true}
          url={urlsConstants.MIDIA_IMAGE_JSON}
          forceLabel="Peça rejeitada"
        >
          <i className="icon-foto" />
        </FileInput>
      );
    }
  };

  getCodPeca(peca) {
    // há codigoPeca(marcaTerceiros) ? return codigoPeca : return codigoPeca Integracao
    return peca.codigoPeca ? peca.codigoPeca : peca.peca.codigoPeca;
  }
  render() {
    const { peca } = this.props;
    return (
      <React.Fragment>
        <tr className={this.getTrClass()}>
          <td data-label={''} colSpan="2">
            <div data-title={'Peça'}>{peca.peca.nome}</div>
          </td>
          <td>
            <div data-title={'Código peça'}>{this.getCodPeca(peca)}</div>
          </td>
          <td>
            <div data-title={'Valor uni.'}>
              {ordemServicoHelper.formatMoney(
                parseFloat(peca.valorCompra),
                2,
                ',',
                '.'
              )}
            </div>
          </td>
          <td>
            <div data-title={'Quantidade'}>{peca.quantidade}</div>
          </td>
          <td>
            <div data-title={'	Qtd. atendida	'}>
              {peca.quantidadeAtendida ? peca.quantidadeAtendida : '--'}
            </div>
          </td>
          <td>{this.getBtnRejectOrgetImagenPeca()}</td>
        </tr>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { ordemServico } = state.ordemServico;
  return {
    ordemServico
  };
}

const mapDispatch = ({ alert: { error, success } }) => ({
  error: message => error({ message }),
  success: message => success({ message })
});

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  TablePecasViewItem
);
