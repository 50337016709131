import React, { PureComponent } from 'react';
import connect from 'react-redux/es/connect/connect';
import { FormRow } from '../../components/FormRow';
import SelectInput from '../../components/Inputs/SelectInput';
import { ViewOnly } from '../../components/Inputs/ViewOnly';
import { causaOsService } from '../../services/causa.service';
import { pecaService } from '../../services/peca.service';
import FormSelectInput from '../Inputs/FormSelectInput';
import { sintomaOsService } from '../../services/sintoma.service';
import _ from 'lodash';
import { objectsConstants } from '../../constants/objects.constants';
import { validateInput } from '../../helpers/validateInputs.helper';
import FormInput from '../Inputs/FormInput';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import MoneyInput from '../Inputs/MoneyInput';
import CascadeSelectInput from '../Inputs/CascadeSelectInput';
type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

const objectValidateBranchVO = [
  objectsConstants.pecaValidate,
  objectsConstants.sintomaValidate,
  objectsConstants.causaValidate,
  objectsConstants.garantiaValidate,
  objectsConstants.solicitarPecaValidate
];

class CardPecaItem extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let peca;
    let entity = _.cloneDeep(props.entity);
    if (props.entity && props.entity.peca) {
      peca = props.entity.peca;
    } else {
      peca = {};
    }
    if(props.ordemServico&&!props.ordemServico.garantia){
      entity.garantia = 'false';
    }
    this.state = {
      entity,
      idOs: props.idOs,
      peca
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.idOs != this.state.idOs) {
      this.setState({
        entity: nextProps.entity,
        idOs: nextProps.idOs
      });
    }
  }

  getBotaoRemovePeca = () => {
    return (
      <div className="text-right py-2">
        <a
          onClick={() =>
            this.props.onRemovePeca(this.props.posicao, this.state.entity.id)
          }
        >
          remover
        </a>
      </div>
    );
  };
  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(entity, name, value);
    if (name === 'quantidade' && entity.solicitarPeca) {
      for (var i = 0; i < objectsConstants.PARTES.length; i++) {
        console.log(value);
        console.log(objectsConstants.PARTES[i].qtd);
        if (
          objectsConstants.PARTES[i].nome === this.state.peca.parte.nome &&
          objectsConstants.PARTES[i].qtd < value
        ) {
          _.set(entity, 'quantidade', objectsConstants.PARTES[i].qtd);
        }
      }
    }
    this.calculaValorFinal(entity);
    this.setState({ entity }, () => {
      if (this.validateForms()) {
        this.props.onChange(this.props.id, entity);
      }
    });
  };

  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    console.log(this.props);
    let peca;
    let newEntity = _.cloneDeep(entity);
    if (name === 'valorFinal') {
      _.set(newEntity, 'valorFinalAlterado', true);
    }
    if (
      name === 'garantia' &&
      entity.causa &&
      entity.causa.id === objectsConstants.USO_INDEVIDO
    ) {
      _.set(newEntity, name, 'false');
    } else {
      if (
        name === 'causa' &&
        option &&
        option.id === objectsConstants.USO_INDEVIDO
      ) {
        this.props.info({
          message:
            'Para peças onde a causa foi uso indevido, não é possivel selecionar a opção "garantia:sim"'
        });
        _.set(newEntity, 'garantia', 'false');
      }
      _.set(newEntity, name, option);
    }
    //se alterou peça pega o valor sugerido
    if (name === 'peca') {
      if (option && this.props.cliente.type.name == objectsConstants.PJ) {
        _.set(newEntity, 'valorSugerido', option.valorLogista);
      } else {
        if (option) {
          _.set(newEntity, 'valorSugerido', option.valorConsumidor);
        }
      }

      peca = option;
    } else {
      peca = this.state.peca;
    }

    if (name === 'solicitarPeca' && option === 'true') {
      let quantidade = 1;
      for (var i = 0; i < objectsConstants.PARTES.length; i++) {
        if (objectsConstants.PARTES[i].nome === this.state.peca.parte.nome) {
          quantidade = 1;
        }
      }
      _.set(newEntity, 'quantidade', quantidade);
    }

    this.calculaValorFinal(newEntity);

    this.setState({ entity: newEntity, peca }, () => {
      if (this.validateForms()) {
        this.props.onChange(this.props.id, newEntity);
      }
    });
  };

  validateForms = () => {
    const { entity } = this.state;
    let isValid = true;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });


    console.log(this.props!=null&&this.props.ordemServico!=null&&this.props.ordemServico.relogio!=null&&this.props.ordemServico.relogio.marca!=null&&this.props.ordemServico.relogio.marca.tipo===objectsConstants.TIPO_MARCA_IMPORTADA);


    if (this.props!=null&&this.props.ordemServico!=null&&this.props.ordemServico.relogio!=null&&this.props.ordemServico.relogio.marca!=null&&this.props.ordemServico.relogio.marca.tipo===objectsConstants.TIPO_MARCA_IMPORTADA) {
      isValid =
          isValid &&
          !(entity['codigoPeca'] === undefined || entity['codigoPeca'] === null);
      if((entity['codigoPeca'] === undefined || entity['codigoPeca'] === null)){
        validate['codigoPeca'] = "Campo obrigatório";
      }

    }

    if (entity['solicitarPeca'] == 'false') {
      isValid =
        isValid &&
        !(entity['quantidade'] === undefined || entity['quantidade'] === null);
    }
    this.setState({ validateObject: validate });
    if (_.isEmpty(validate)) {
      isValid = true;
    }
    return isValid;
  };

  calculaValorFinal(newEntity) {
    console.log(newEntity);
    if (
      newEntity.valorSugerido &&
      newEntity.quantidade &&
      !newEntity.valorFinalAlterado
    ) {
      if (newEntity.garantia === false || newEntity.garantia === 'false') {
        _.set(
          newEntity,
          'valorFinal',
          newEntity.valorSugerido * newEntity.quantidade
        );
      }
    }
    if (newEntity.garantia === true || newEntity.garantia === 'true') {
      _.set(newEntity, 'valorFinal', 0);
    }
  }
  isBlank(str) {
    return !str || /^\s*$/.test(str);
  }
  getInputCodigoPeca = (entity, submit) => {
    if (this.props.ordemServico.relogio.marca.tipo === 'IMPORTADA') {
      return (
        <FormInput
          type="text"
          name="codigoPeca"
          label="Código da peça"
          placeholder="ex: 3023"
          size={3}
          noSize={false}
          returnFullObject={true}
          required={true}
          searchable={true}
          id={'codigoPeca'}
          ref={input => {
            this._codigoPeca = input;
          }}
          onChange={this.handleChange}
          erroMensagem={
            submit && !entity.codigoPeca && this.isBlank(entity.codigoPeca)
              ? 'Campo obrigatorio!'
              : ''
          }
          value={entity.codigoPeca}
          helpText="O código da peça será utilizado para indenticação ao atender a mesma."
        />
      );
    }
  };

  render() {
    const { entity, peca } = this.state;
    const { submit } = this.props;
    if (!entity) {
      return <React.Fragment />;
    }
    //if não esta na garantia e nem vai solicitar peças não pode alterar quantidade
    let disabledQuantidade = true;
    let garantia =
      typeof entity.garantia == 'boolean'
        ? entity.garantia
          ? 'true'
          : 'false'
        : entity.garantia;

    let solicitarPeca =
      typeof entity.solicitarPeca == 'boolean'
        ? entity.solicitarPeca
          ? 'true'
          : 'false'
        : entity.solicitarPeca;
    for (var i = 0; i < objectsConstants.PARTES.length; i++) {
      if (
        this.state.peca &&
        this.state.peca.parte &&
        objectsConstants.PARTES[i].nome === this.state.peca.parte.nome
      ) {
        disabledQuantidade = false;
      }
    }
    if (
      garantia &&
      garantia === 'false' &&
      solicitarPeca &&
      solicitarPeca === 'false'
    ) {
      disabledQuantidade = false;
    }
    //valor final pode ser alterado se a peça estiver na garantia
    let disabledValorFinal = true;
    if (garantia && garantia === 'false') {
      disabledValorFinal = false;
    }
    let helpTextValorFinal =
      peca && entity.valorFinal
        ? `O valor sugerido pela Seculus seria de ${ordemServicoHelper.formatMoney(
            parseFloat(peca.valor * entity.quantidade),
            2,
            ',',
            '.'
          )} para autorizada, ${ordemServicoHelper.formatMoney(
            parseFloat(peca.valorLogista),
            2,
            ',',
            '.'
          )} para logista, ${ordemServicoHelper.formatMoney(
            parseFloat(peca.valorConsumidor),
            2,
            ',',
            '.'
          )} para consumidor.`
        : null;
    console.log(entity, 'entidade logando lalala');
    console.log(submit && entity.sintoma ? 'Campo obrigatorio!' : '');
    return (
      <React.Fragment>
        <div className="borderForm">
          <FormRow>
            <CascadeSelectInput
              valueKey={'id'}
              labelKey={'nome'}
              name="peca"
              label="Peça"
              erroMensagem={submit && !entity.peca ? 'Campo obrigatorio!' : ''}
              placeholder=""
              size={4}
              style={['col-12 col-sm-4']}
              service={pecaService.findByRelogio}
              returnFullObject={true}
              searchable={true}
              id={'peca'}
              ref={input => {
                this.peca = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.peca}
              required={true}
              parent={this.props.ordemServico.relogio}
            />{' '}
            <CascadeSelectInput
              type="text"
              valueKey={'id'}
              labelKey={'nome'}
              erroMensagem={
                submit && !entity.sintoma ? 'Campo obrigatorio!' : ''
              }
              name="sintoma"
              label="Sintoma"
              placeholder=""
              size={4}
              style={['col-12 col-sm-4']}
              returnFullObject={true}
              searchable={true}
              parent={entity.peca ? entity.peca.id : 0}
              id={'sintoma'}
              ref={input => {
                this._sintoma = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.sintoma}
              service={sintomaOsService.findByPeca}
              required={true}
            />{' '}
            <CascadeSelectInput
              name="causa"
              label="Causa"
              valueKey={'id'}
              labelKey={'nome'}
              placeholder=""
              parent={entity.sintoma ? entity.sintoma.id : 0}
              size={4}
              style={['col-12 col-sm-4']}
              returnFullObject={true}
              searchable={true}
              id={'causa'}
              ref={input => {
                this._causa = input;
              }}
              onChange={this.handleSelectChange}
              erroMensagem={submit && !entity.causa ? 'Campo obrigatorio!' : ''}
              value={entity.causa}
              service={causaOsService.findBySintoma}
              required={true}
            />
          </FormRow>
          <FormRow>
            <SelectInput
              type="text"
              name="garantia"
              label="Peça na garantia?"
              placeholder=""
              size={2}
              style={[
                `col-6 col-sm-3 ${
                  entity.garantia === 'true' ? 'select-blue' : ''
                }`
              ]}
              options={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false' }
              ]}
              returnFullObject={false}
              searchable={true}
              id={'garantia'}
              ref={input => {
                this._garantia = input;
              }}
              onChange={this.handleSelectChange}
              disabled={
                (entity.causa &&
                entity.causa.id === objectsConstants.USO_INDEVIDO)||!this.props.ordemServico.garantia
              }
              erroMensagem={
                submit &&
                !(entity.causa &&
                entity.causa.id === objectsConstants.USO_INDEVIDO
                  ? 'false'
                  : typeof entity.garantia == 'boolean'
                  ? entity.garantia
                    ? 'true'
                    : 'false'
                  : entity.garantia)
                  ? 'Campo obrigatorio!'
                  : ''
              }
              value={
                entity.causa &&
                entity.causa.id === objectsConstants.USO_INDEVIDO
                  ? 'false'
                  : typeof entity.garantia == 'boolean'
                  ? entity.garantia
                    ? 'true'
                    : 'false'
                  : entity.garantia
              }
              required={true}
            />
            <SelectInput
              type="text"
              name="solicitarPeca"
              label="Solicitar Peça"
              placeholder=""
              size={2}
              style={['col-6 col-sm-3']}
              options={[
                { label: 'Sim', value: 'true' },
                { label: 'Não', value: 'false' }
              ]}
              returnFullObject={false}
              searchable={true}
              id={'solicitarPeca'}
              ref={input => {
                this._solicitarPeca = input;
              }}
              onChange={this.handleSelectChange}
              erroMensagem={
                submit &&
                !(typeof entity.solicitarPeca == 'boolean'
                  ? entity.solicitarPeca
                    ? 'true'
                    : 'false'
                  : entity.solicitarPeca)
                  ? 'Campo obrigatorio!'
                  : ''
              }
              value={
                typeof entity.solicitarPeca == 'boolean'
                  ? entity.solicitarPeca
                    ? 'true'
                    : 'false'
                  : entity.solicitarPeca
              }
              required={true}
            />
            <FormInput
              type="number"
              name="quantidade"
              label="Qtde."
              placeholder=""
              size={2}
              noSize={false}
              returnFullObject={true}
              searchable={true}
              id={'quantidade'}
              ref={input => {
                this._quantidade = input;
              }}
              onChange={this.handleChange}
              disabled={disabledQuantidade}
              erroMensagem={
                submit && !entity.quantidade ? 'Campo obrigatorio!' : ''
              }
              value={entity.quantidade}
              helpText="A quantidade de peças somente poderá ser alterada em caso de itens fora da garantia e quando não houver solicitação de peças."
            />
            <MoneyInput
              type="text"
              name="valorFinal"
              label="Valor Final"
              placeholder="Valor Final"
              size={3}
              className={['col-6 col-sm-4']}
              id={'valorFinal'}
              ref={input => {
                this._valorFinal = input;
              }}
              onChange={this.handleSelectChange}
              erroMensagem={
                submit && !entity.valorFinal ? 'Campo obrigatorio!' : ''
              }
              value={entity.valorFinal}
              disabled={disabledValorFinal}
              helpText={helpTextValorFinal}
            />
            {this.getInputCodigoPeca(entity, submit)}
          </FormRow>
          {this.getBotaoRemovePeca()}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, info },
  load: { loading },
  ordemServico: { setCliente }
}) => ({
  loading: (load: boolean) => loading({ load }),
  setCliente: cliente => setCliente({ cliente }),
  success: msg => success(msg),
  info: msg => info(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(
  null,
  mapDispatch
)(CardPecaItem);
