//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import { pecasNaoAtendidasEpr } from '../../../services/pecasNaoAtendidasEpr.service';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import PecasNaoAtendidasEprFormFilter from './PecasNaoAtendidasEprFormFilter';
import { tableHelper } from '../../../helpers/tableHelper';

const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  { path: 'endereco', title: 'Cidade', isKey: false, dataSort: true },
  {
    path: 'postoAutorizado',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'peca.codigoPeca',
    title: 'Cód. Peça',
    isKey: false,
    dataSort: true
  },
  {
    path: 'peca.nome',
    title: 'Peça',
    isKey: false,
    dataSort: true
  },
  {
    path: 'codigoRelogio',
    title: 'Cód. Relógio',
    isKey: false,
    dataSort: true
  },
  {
    path: 'nomeMarca',
    title: 'Marca',
    isKey: false,
    dataSort: true
  },
  {
    path: 'garantia',
    title: 'Garantia',
    isKey: false,
    dataSort: true,
    yesNo: true
  },
  {
    path: 'quantidade',
    title: 'Qtd.',
    isKey: false,
    dataSort: true
  },
  {
    path: 'peca.valor',
    title: 'Preço Un.',
    isKey: false,
    dataSort: true,
    isMoney: true
  },
  {
    path: 'total',
    title: 'Total',
    isKey: false,
    dataSort: true,
    isMoney: true
  }
];

class PecasNaoAtendidasEpr extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let dataInicio = dateNow.date(1);
    let dataFim = moment();

    this.state = {
      fields,
      defaultFilter: { dataInicio, dataFim },
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" PEÇAS NÃO ATENDIDAS EPR "
          canBack={false}
        />
        <CardRelatorio
          title={` PEÇAS NÃO ATENDIDAS EPR (${this.props.subTitle})`}
          FormFilter={PecasNaoAtendidasEprFormFilter}
          showTable={true}
          tableService={pecasNaoAtendidasEpr}
          controller={'relatorio/pecasNaoAtendidasEpr'}
          fields={this.state.fields}
          defaultFilter={this.state.defaultFilter}
          onHandleFilter={this._handleFilterChange}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(
  mapStateToProps,
  null
)(PecasNaoAtendidasEpr);
