//@flow
import type { Options } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const postoValorAtendimentoGarantiaService = {
  findByFiltro,
  saveValores
};

function saveValores(entity) {
  return defaultService.doPost(
    `${urlsConstants.POSTO_VALOR_ATENDIMENTO_GARANTIA}saveValores`,
    entity
  );
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.POSTO_VALOR_ATENDIMENTO_GARANTIA}list`,
    options,
    filter
  );
}
