//@flow
import * as React from 'react';
import { dateHelper } from '../../helpers/date.helper';
import avatarDefault from '../../img/avatarDefault.jpg';
import { midiaService } from '../../services/midiaService';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {marcaService} from "../../services/marca.service";
import SelectInput from "../../components/Inputs/SelectInput";
type State = {
  entity: PostoAutorizadoType
};

class MarcasBloqueadasView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: [],view:true };
  }

  componentDidMount() {
    this.loadResponsavel();
  }

  loadResponsavel = () => {
    postoAutorizadoService
      .findMarcaBloqueada(this.props.id)
      .then(response => {
        this.setState({ entity: response.data.data ? response.data.data : [] });
      });
  };

  save = ()=>{
    this.props.loading(true)
    postoAutorizadoService.saveMarcas(this.state.entity,this.props.id).then(r=>{
      this.props.success({
        message: 'Solicitação realizada com sucesso.'
      });
      this.props.loading(false)
      this.setState({view:true})
    })
  }

  handleChangeMarca=(marcas)=>{
    this.setState({ entity: marcas });
  }

  render() {
    const { entity } = this.state;

    return (
      <div className="card cardSeculus d-flex flex-column l1">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">Marcas bloqueadas</h3>
          <div className="boxButon float-right d-sm-flex">
            {this.state.view&&(
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              data-target="#novaMsg"
              onClick={() => {
               this.setState({view:false})
              }}
            >
              Editar
            </button>
            )}
            {!this.state.view&&(
                <React.Fragment>
                  <div className="col-4 col-lg text-center p-0  mx-2">
                    <button
                        type="button"
                        className="btn btn-secondary  btn-sm white"

                        onClick={() => {
                          this.loadResponsavel();
                          this.setState({view:true})
                        }}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-4 col-lg text-center p-0 mx-2">
                <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    data-toggle="modal"
                    data-target="#novaMsg"
                    onClick={() => {

                     this.save()
                    }}
                >
                  salvar
                </button>
                  </div>
                </React.Fragment>
            )}
          </div>
        </div>
        <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
          <div className="col-sm-12 d-flex flex-row align-items-center pr-3 border-right">
            {this.state.view&&(
                <React.Fragment>
                  {this.state.entity.map(m=>{
                    return m.nome;
                  })}
                </React.Fragment>
            )}
            {!this.state.view&&(
                <React.Fragment>
                  <SelectInput
                      type="text"
                      name="marca"
                      label="Marca"
                      placeholder="Marca"
                      required={true}
                      style={['col-12 col-sm-12']}
                      onFetchData={marcaService.find}
                      valueKey="id"
                      labelKey="nome"
                      multi={true}
                      returnFullObject={true}
                      searchable={true}
                      //messageValidate={validate.portadorDeficiencia}
                      id={'marca'}
                      onChange={(name, value) =>
                          this.handleChangeMarca(value)
                      }
                      value={this.state.entity}

                  />
                </React.Fragment>
            )}

          </div>

        </div>
      </div>
    );
  }
}
const mapDispatch = ({
                       alert: { success, error, clear },
                       load: { loading }
                     }) => ({
  loading: (load: boolean) => loading({ load }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});
export default connect(
    null,
    mapDispatch
)(MarcasBloqueadasView);
