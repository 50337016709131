//@flow
import { Can } from '@casl/react';
import { Formik } from 'formik';
import _, { values } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { objectsConstants } from '../../constants/objects.constants';
import { urlsConstants } from '../../constants/url.constant';
import { dateHelper } from '../../helpers/date.helper';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import { ConfirmAcationService } from '../../services/ConfirmAcationService';
import { defaultService } from '../../services/defaultService';
import { marcaService } from '../../services/marca.service';
import { ordemServicoService } from '../../services/ordemServico.service';
import { postoHabilitadoService } from '../../services/postoHabilitado.service';
import { relogioService } from '../../services/relogio.service';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
import FotoRelogio from '../FotoRelogio';
import CheckBoxInput from '../Inputs/CheckBoxInput';
import DatePickerInput from '../Inputs/DatePickerInput';
import FileInput from '../Inputs/FileInput';
import FormInput from '../Inputs/FormInput';
import FormTextArea from '../Inputs/FormTextArea';
import SelectInput from '../Inputs/SelectInput';
import type { OrdemServicoType } from '../../types/ordemServico.type';
import { userService } from '../../services/user.service';
import ArrayFileInput from '../Inputs/ArrayFileInput';
import { importacaoOrdemCompraClientePjService } from '../../services/importacaoOrdemCompraClientePjService';
import { Modal, ModalBody } from 'reactstrap';
import { garantiaClienteService } from '../../services/garantiaCliente.service';
import { Value } from 'react-select';
import { midiaService } from '../../services/midiaService';

type Props = { ordemServico: OrdemServicoType };

const NewOS = Yup.object().shape({
  tipoRecebimento: Yup.string().required('Obrigatório'),
  questionamentoCliente: Yup.string().required('Obrigatório'),
  postoAutorizado: Yup.string().test(
    'valid-postoAut',
    'Obrigatório',
    function() {
      const { parent, createError } = this;
      let postoAutorizado = parent.postoAutorizado;
      let extension = userService.getCurrentUser().extension;
      if (extension === undefined && postoAutorizado === undefined) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  //postoAutorizado: Yup.string().required('Obrigatório'),
  previsaoEntrega: Yup.string().test(
    'valid-dataNF',
    'Data está inválida ou Prazo é maior do que o permitido pelo Código de Defesa do Consumidor.',
    function() {
      const { parent, createError } = this;

      let dateValid = true;

      let maxDate = moment().add(30, 'days');
      let minDate = moment();

      let dataPrevisaoEntrega = moment(parent.previsaoEntrega);

      if (
        dataPrevisaoEntrega &&
        (dataPrevisaoEntrega.isBefore(minDate) ||
          dataPrevisaoEntrega.isAfter(maxDate))
      ) {
        dateValid = false;
      }

      if (parent.previsaoEntrega !== undefined && !dateValid) {
        return createError();
      } else {
        return true;
      }
    }
  ),
  notaFiscal: Yup.array().test(
    'valid-notaFiscal',
    'Nota fiscal obrigatoria',
    function(e) {
      const { parent, createError } = this;

      if (
        parent.garantia &&
        parent.garantia.value &&
        (!parent.relogioEstoque || !parent.relogioEstoque.value) &&
        (!parent.notaFiscal || parent.notaFiscal.length <= 0)
      ) {
        return createError({
          path: 'notaFiscal',
          message: 'Nota Fiscal obrigatório',
        });
      }
      return true;
    }
  ),
  dataNotaFiscal: Yup.string().test(
    'valid-dataNF',
    'Data da NF inválida ou fora do período de garantia.',
    function() {
      const { parent, createError } = this;

      if (
        !parent.relogio ||
        ((!parent.relogioEstoque || !parent.relogioEstoque.value) &&
          parent.garantia.value &&
          parent.dataNotaFiscal === undefined)
      ) {
        return createError({
          path: 'dataNotaFiscal',
          message: 'Data da compra/ NF obrigatório',
        });
      }
      let dateValid = true;

      let mesesGarantia =
        parent.relogio && parent.relogio.mesesGarantia
          ? parent.relogio.mesesGarantia * -1
          : -72;
      if (
        (!parent.relogioEstoque || !parent.relogioEstoque.value) &&
        parent.relogio &&
        parent.relogio.mesesGarantia === undefined &&
        parent.relogio.marca &&
        parent.relogio.marca.mesesGarantia
      ) {
        mesesGarantia = parent.relogio.marca.mesesGarantia * -1;
      }

      let maxDate = moment();
      let minDate = moment().add(mesesGarantia, 'months');
      let dataNotaFiscal = moment(parent.dataNotaFiscal);

      if (
        dataNotaFiscal &&
        (dataNotaFiscal.isBefore(minDate) || dataNotaFiscal.isAfter(maxDate))
      ) {
        dateValid = false;
      }

      if (
        parent.garantia.value &&
        parent.dataNotaFiscal !== undefined &&
        !dateValid
      ) {
        return createError();
      } else {
        return true;
      }
    }
  ),
});

class CardCriarOs extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      listaGarantia: [],
      modal: props.listaGarantia,
      showModalGarantia: false,
    };
  }

  handleConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>
              {this.props.ordemServico.osType === 'PA' ? 'Novo PA' : 'Nova OS'}{' '}
              - <span className="txtDarkGray">Confirmar?</span>
            </h3>
            <p className="p-msg">
              Ao clicar no botão criar {this.props.ordemServico.osType} você não
              terá mais acesso para editar ou apagar esse registro.
            </p>
            <p>
              <b>
                Deseja realmente criar{' '}
                {this.props.ordemServico.osType === 'PA'
                  ? 'este novo PA'
                  : 'está nova OS'}
                ?
              </b>
            </p>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.formRef.executeSubmit();
                onClose();
              }}
            >
              Criar {this.props.ordemServico.osType}
            </button>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              Não, obrigado
            </button>
          </div>
        );
      },
    });
  };

  componentDidMount() {
    console.log(this.props);
    if (this.props.cliente && this.props.cliente.documento) {
      garantiaClienteService
        .getByCpfCliente(this.props.cliente.documento)
        .then((response) => {
          let listaGarantia = response.data;
          let showModalGarantia = this.state.showModalGarantia;
          if (listaGarantia.length > 0) {
            showModalGarantia = true;
          }
          this.setState({ listaGarantia, showModalGarantia });
        });
    }
  }

  handleVoltar = () => {
    this.props.clearOS();
    this.props.history.push('/cliente/view/' + this.props.cliente.id);
  };
  handleChangeMarca(marca, setValues, values) {
    console.log(marca);
    console.log(values);
    const newValue = _.cloneDeep(values);
    _.set(newValue, 'marca', marca);
    if (
      marca &&
      marca.marcaTerceiros &&
      values &&
      (!values.codigoRelogio || values.codigoRelogio === '')
    ) {
      relogioService.find(marca, marca.nome.toUpperCase()).then((response) => {
        const relogios = response.data;
        if (relogios && relogios.length > 0) {
          _.set(newValue, 'relogio', relogios[0]);
          console.log(relogios);
        }
        setValues(newValue);
      });
    } else {
      setValues(newValue);
    }
  }

  showError = () => {
    this.props.error({ message: objectsConstants.messageCampoObrigatorio });
    return '';
  };

  getClientType = (cliente) => {
    console.log(cliente);
    if (cliente.documento.length > 14) {
      return objectsConstants.PJ;
    } else {
      return objectsConstants.PF;
    }
  };

  getMinDate = (marca) => {
    //data minita pelos meses de garantia do relogio
    let meses = -12;
    if (marca && marca.mesesGarantia) {
      meses = marca.mesesGarantia * -1;
    }
    return moment().add(meses, 'months');
  };

  getMaxDateEntrega = () => {
    //data maxima entrega 30 dias, codigo consumidor
    return moment().add(30, 'days');
  };

  toggle = () => {
    this.setState({ showModalGarantia: !this.state.showModalGarantia });
  };

  render() {
    const { cliente, permissions, user } = this.props;
    let ordemServico = _.cloneDeep(this.props.ordemServico);
    ordemServico.cliente = cliente;
    console.log('redmer crias os');
    const { handleChangeMarca } = this;
    let dias = '-- Dias';
    let valorOrcamento = '--,--';
    let clientType = cliente.type
      ? cliente.type.name
      : this.getClientType(cliente);
    console.log(user);
    return (
      <div
        className="tab-pane fade show active"
        id="relogio"
        role="tabpanel"
        aria-labelledby="relogio-tab"
      >
        <TopoTitleComponente
          mainTitle="Ordens de Serviço"
          subTitle={'Nova Ordem - ' + this.props.cliente.documento}
          canBack={false}
        />
        <div className="row topoTab">
          <div className="col-4 px-1 px-sm-2 px-md-3">
            <h4>Prazo de reparo</h4>
            <h1>
              <b>{dias}</b>
            </h1>
            <div className="miniTxt">Data de abertura</div>
            <p>
              {dateHelper.format(ordemServico.creationDateTime, {
                pattern: 'DD/MM/YY',
              })}
            </p>
          </div>
          <div className="col-4 px-1 px-sm-2 px-md-3 text-center">
            <h4>Orçamento</h4>
            <h1>
              <b>
                {ordemServicoHelper.formatMoney(
                  parseFloat(valorOrcamento),
                  2,
                  ',',
                  '.'
                )}
              </b>
            </h1>
          </div>
          <div className="col-4 text-right px-1 px-sm-2 px-md-3">
            <h4>Status</h4>
            <h5 className="sts">{ordemServico.statusOS.description}</h5>
            <div className="miniTxt">Data do Status</div>
            <p>
              {dateHelper.format(ordemServico.dataStatus, {
                pattern: 'DD/MM/YYYY',
              })}
            </p>
          </div>
        </div>
        <Formik
          validationSchema={NewOS}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={true}
          initialValues={ordemServico}
          onSubmit={(values, actions) => {
            this.props.loading(true);
            values.cliente = this.props.cliente;
            _.unset(values.cliente, 'type');
            _.unset(values, 'statusOS');
            let garantiaSeted = values.garantia;
            if (
              values.garantia != undefined &&
              values.garantia.value != undefined
            ) {
              values.garantia = values.garantia.value;
            }
            values.marca = '';
            if (
              values.relogioEstoque != undefined &&
              values.relogioEstoque.value != undefined
            ) {
              values.relogioEstoque = values.relogioEstoque.value;
            }

            ordemServicoService.doSave(values).then(
              (response) => {
                this.props.success({
                  message: 'OS criada com sucesso!',
                });

                if (!values.manterRelogio) {
                  let id = defaultService.getIdFromUrl(
                    response.headers.location
                  );
                  let urlOS = urlsConstants.ORDEM_SERVICO + id;
                  this.props.clear();
                  ordemServicoService.doGet(urlOS).then((response) => {
                    if (this.props.onSaved) {
                      this.props.onSaved();
                    }
                    this.props.setOrdemServico(response.data.data);
                    this.props.loading(false);
                  });
                } else {
                  _.set(values, 'statusOS', {
                    name: 'EM_CRIACAO',
                    description: 'Em criação',
                  });
                  _.set(values, 'garantia', garantiaSeted);
                  this.props.setOrdemServico(values);
                  if (this.props.onSaved) {
                    this.props.onSaved();
                  }
                  this.props.loading(false);
                }
              },
              (error) => {
                const messages = [error.response.data.messages];
                let err;
                console.log('erros', messages);
                messages.forEach(function(item, index) {
                  if (item[index].message.code === 'NOT_PERMITED')
                    err =
                      'Usuário não tem as permissões necessárias para cadastro de OS';
                });
                if (err !== undefined) {
                  this.props.error({
                    message: err,
                  });
                }
                this.props.loading(false);
              }
            );

            actions.setSubmitting(false);
          }}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            validateForm,
            setValues,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              className="row conteudoTab d-flex flex-column flex-lg-row "
            >
              {console.log(values.notaFiscal)}
              <div className="flex-column col-12 col-md-6">
                {!_.isEmpty(errors) && isSubmitting && this.showError()}

                {console.log(errors)}
                {userService.isAdmin() && (
                  <div className="form-row">
                    <SelectInput
                      type="text"
                      id={'postoAutorizado'}
                      name={'posto'}
                      label={`Posto Autorizado*`}
                      placeholder="Nome, CPF ou CNPJ do posto"
                      onFetchData={postoHabilitadoService.find}
                      valueKey="id"
                      labelKey="texto"
                      returnFullObject={true}
                      searchable={true}
                      onChange={(name, value) => {
                        setFieldValue('postoAutorizado', value);
                      }}
                      value={values.postoAutorizado}
                      style={['col-12 col-sm-12']}
                      erroMensagem={errors.postoAutorizado}
                    />
                  </div>
                )}
                {console.log(errors)}
                <div className="form-row">
                  <SelectInput
                    type="text"
                    id={'relogioInput'}
                    name={'codigoRelogio'}
                    label={`Código do relógio*`}
                    placeholder="Código do relógio"
                    onFetchData={relogioService.find}
                    valueKey="relogio"
                    labelKey="codigoRelogio"
                    returnFullObject={true}
                    searchable={true}
                    onChange={(name, value) => {
                      if (clientType == objectsConstants.PJ) {
                        //importacaoOrdemCompraClientePjService.findByClienteAndRelogio(this.props.cliente,value).then(response =>{
                        //setFieldValue('clientePjComGarantia', response.data);
                        //if(response.data) {
                        // console.log(response.data)
                        // setFieldValue('garantia', { label: 'Sim', value: true });
                        // setFieldValue('numeroNotaFiscal', response.data.numeroNotaFiscal);
                        // setFieldValue('dataNotaFiscal', response.data.data);
                        //setFieldValue('clientePjComGarantia', true);
                        /* }else{
                                                     setFieldValue('garantia', { label: 'Não', value: false });
                                                     setFieldValue('clientePjComGarantia', false);
                                                 }
                                             })*/
                      }
                      setFieldValue('relogio', value);
                    }}
                    value={values.relogio}
                    style={['col-12 col-sm-6']}
                    parent={values.marca}
                  />
                  <SelectInput
                    type="text"
                    name="marca"
                    label="Marca"
                    placeholder="Marca"
                    required={true}
                    style={['col-12 col-sm-6']}
                    onFetchData={marcaService.find}
                    valueKey="id"
                    labelKey="nome"
                    disabled={true}
                    returnFullObject={true}
                    searchable={true}
                    //messageValidate={validate.portadorDeficiencia}
                    id={'marca'}
                    onChange={(name, value) =>
                      handleChangeMarca(value, setValues, values)
                    }
                    value={values.marca}
                    value={
                      values.relogio && values.relogio.marca
                        ? values.relogio.marca
                        : values.marca
                    }
                  />

                  <div className="form-group col-12 col-sm-12">
                    <label>Descricao</label>
                    <input
                      readOnly={true}
                      type={'text'}
                      className={'form-control'}
                      name={'relogio.descricao'}
                      value={
                        values.relogio && values.relogio.descricao
                          ? values.relogio.descricao
                          : ''
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group col-12">
                    <label>Questionamento do cliente*</label>
                    <FormTextArea
                      id="questionamentoCliente"
                      name="questionamentoCliente"
                      placeholder="Questionamento do cliente"
                      value={values.questionamentoCliente}
                      onChange={handleChange}
                      rows="4"
                      noSize={true}
                      required={true}
                      erroMensagem={errors.questionamentoCliente}
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>Observações</label>
                    <FormTextArea
                      id="observacoes"
                      name="observacoes"
                      placeholder="Observações"
                      value={values.observacoes}
                      onChange={handleChange}
                      rows="4"
                      noSize={true}
                      required={true}
                    />
                  </div>
                  <div className="form-group col-12 col-sm-7">
                    <label>Previsão de entrega </label>
                    <DatePickerInput
                      placeholder="-- / -- / ----"
                      disabled={!values.relogio}
                      erroMensagem={errors.previsaoEntrega}
                      name="previsaoEntrega"
                      id="previsaoEntrega"
                      value={values.previsaoEntrega}
                      onChange={(name, value) =>
                        setFieldValue('previsaoEntrega', value)
                      }
                      noSize={true}
                      popperPlacement={'left'}
                      maxDate={this.getMaxDateEntrega()}
                      minDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-column col-12 col-md-4 offset-md-2 text-right">
                <FotoRelogio relogio={values.relogio ? values.relogio : null} />
                <div className="form-row">
                  {clientType == objectsConstants.PJ && (
                    <div className="form-group col-12">
                      <label>Relogio estoque*</label>
                      <SelectInput
                        id={'relogioEstoqueInput'}
                        options={[
                          { label: 'Sim', value: true },
                          { label: 'Não', value: false },
                        ]}
                        returnFullObject={true}
                        valueKey={'value'}
                        name={'relogioEstoque'}
                        noSize={true}
                        value={values.relogioEstoque}
                        onChange={(name, value) => {
                          setFieldValue('relogioEstoque', value);
                          /*if (!values.clientePjComGarantia && value.value) {
                                                        setFieldValue('garantia', { label: 'Não', value: false });

                                                    }*/
                        }}
                      />
                    </div>
                  )}
                  <div className="form-group col-12 col-sm-12">
                    <label>Tipo de Recebimento*</label>
                    <SelectInput
                      id={'tipoRecebimentoInput'}
                      options={[
                        { label: 'Recepção', value: 'RECEPCAO' },
                        { label: 'Correio', value: 'CORREIO' },
                      ]}
                      returnFullObject={false}
                      valueKey={'value'}
                      name={'tipoRecebimento'}
                      noSize={true}
                      value={values.tipoRecebimento}
                      onChange={(name, value) =>
                        setFieldValue('tipoRecebimento', value)
                      }
                      erroMensagem={errors.tipoRecebimento}
                    />
                  </div>
                  <div className="form-group col-12 col-sm-5">
                    <label>Garantia*</label>
                    <SelectInput
                      id={'garantiaInput'}
                      options={[
                        { label: 'Sim', value: true },
                        { label: 'Não', value: false },
                      ]}
                      //disabled={(!values.clientePjComGarantia&&values.relogioEstoque&&values.relogioEstoque.value&&(values.relogio&&values.relogio.marca&&(values.relogio.marca.id!=48&&values.relogio.marca.id!=6)))}
                      returnFullObject={true}
                      valueKey={'value'}
                      name={'garantia'}
                      noSize={true}
                      value={values.garantia}
                      onChange={(name, value) =>
                        setFieldValue('garantia', value)
                      }
                    />
                  </div>
                  <div className="form-group col-12 col-sm-7">
                    <label>Data da compra/NF*</label>
                    <DatePickerInput
                      placeholder="-- / -- / ----"
                      disabled={!values.garantia.value}
                      erroMensagem={errors.dataNotaFiscal}
                      name="dataNotaFiscal"
                      id="dataNotaFiscal"
                      value={values.dataNotaFiscal}
                      onChange={(name, value) =>
                        setFieldValue('dataNotaFiscal', value)
                      }
                      noSize={true}
                      popperPlacement={'left'}
                      maxDate={new Date()}
                      minDate={this.getMinDate(values.marca)}
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>Número da NF</label>
                    <FormInput
                      disabled={!values.garantia.value}
                      type="text"
                      id="numeroNotaFiscal"
                      name="numeroNotaFiscal"
                      placeholder="Número da NF"
                      value={values.numeroNotaFiscal}
                      onChange={handleChange}
                      noSize={true}
                      erroMensagem={errors.numeroNotaFiscal}
                    />
                  </div>
                </div>

                {this.state.listaGarantia &&
                  this.state.listaGarantia.length > 0 && (
                    <Modal
                      isOpen={this.state.showModalGarantia}
                      size="lg"
                      toggle={this.toggle}
                    >
                      <ModalBody>
                        <div className="justify-content-center mt-1">
                          <h3>
                            {' '}
                            <span className="txtGreen"> Atenção </span>- Lista
                            de garantias do Cliente
                          </h3>
                        </div>
                        <div className="justify-content-center mt-2">
                          <p className="text-center">
                            Passe o mouse sobre a imagem para vizualiza-la
                            ampliada.
                          </p>
                          <p className="text-center">
                            {' '}
                            Clique na linha para selecionar a garantia do
                            cliente.
                          </p>
                          <table class="table cont">
                            <thead class="thead-dark">
                              <tr>
                                <th className="col-2">Código do Relogio</th>
                                <th className="col-3">Foto do Relogio</th>
                                <th className="col-2">Número da nota fiscal</th>
                                <th className="col-2">Data da Compra</th>
                                <th className="col-3">Foto da Garantia</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listaGarantia.map(
                                (garantia, index) => {
                                  return (
                                    <tr
                                      onClick={() => {
                                        // enviar a variavel garantia para algum lugar
                                        setFieldValue(
                                          'relogio',
                                          garantia.relogio
                                        );
                                        setFieldValue(
                                          'numeroNotaFiscal',
                                          garantia.numeroNF
                                        );
                                        setFieldValue(
                                          'dataNotaFiscal',
                                          garantia.dataNF
                                        );
                                        console.log(garantia);
                                        setFieldValue(
                                          'notaFiscal',
                                          garantia.notaFiscal
                                        );
                                        this.toggle();
                                      }}
                                    >
                                      <td className="col-2">
                                        {garantia.relogio.codigoRelogio}
                                      </td>
                                      <td className="col-3">
                                        <FotoRelogio
                                          relogio={garantia.relogio}
                                          height={'75px'}
                                          width={'75px'}
                                          noMargin
                                        />
                                      </td>
                                      <td className="col-2">
                                        {garantia.numeroNF}
                                      </td>
                                      <td className="col-2">
                                        {garantia.dataNF}
                                      </td>
                                      <td
                                        className="col-3"
                                        style={{
                                          zIndex:
                                            this.state.hovered &&
                                            this.state.index === index
                                              ? 100000
                                              : 0,
                                        }}
                                      >
                                        {' '}
                                        <img
                                          src={midiaService.get(
                                            garantia.notaFiscal.id
                                          )}
                                          height={'75px'}
                                          width={'75px'}
                                          noMargin
                                          onMouseOver={() =>
                                            this.setState({
                                              hovered: true,
                                              index,
                                            })
                                          }
                                          onMouseOut={() =>
                                            this.setState({
                                              hovered: false,
                                              index: -1,
                                            })
                                          }
                                          style={{
                                            transform: `${
                                              this.state.hovered &&
                                              this.state.index === index
                                                ? 'scale(5.5,5.5)'
                                                : 'scale(1,1)'
                                            }`,
                                            zIndex:
                                              this.state.hovered &&
                                              this.state.index === index
                                                ? 100000
                                                : 0,
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </ModalBody>
                    </Modal>
                  )}

                {values.garantia.value && (
                  <ArrayFileInput
                    emptyLabel="Carregar Foto NF/Certificado*"
                    entity={values}
                    id="notaFiscal"
                    name="notaFiscal"
                    fileItem={values.notaFiscal}
                    onChange={(files) => {
                      console.log(files);
                      let valuesAux = _.cloneDeep(values);
                      valuesAux.notaFiscal = files;
                      setValues(valuesAux);
                    }}
                    className={`small nfinput ${
                      errors.notaFiscal ? 'is-invalid' : ''
                    }`}
                    messageValidate={errors.notaFiscal}
                    accept="image/*"
                  >
                    <i className="icon-foto" />
                  </ArrayFileInput>

                  // <ArrayFileInput
                  //   emptyLabel="Carregar Foto NF/Certificado*"
                  //   id="notaFiscal"
                  //   name="notaFiscal"
                  //   entity={values}
                  //   fileItem={values.notaFiscal}
                  //   onChange={this.handleValue}
                  //   erroMensagem={errors.fotoNotaFiscal}
                  //   className={`small nfinput ${
                  //     errors.fotoNotaFiscal ? 'is-invalid' : ''
                  //   }`}
                  //   accept="image/*"
                  // >
                  //   <i className="icon-foto" />
                  // </ArrayFileInput>
                )}
              </div>
              <div className="row col-12 align-items-end flex-column">
                <CheckBoxInput
                  label="Manter dados do relógio"
                  name="manterRelogio"
                  id={'manterRelogio'}
                  onChange={(checked, name) => setFieldValue(name, checked)}
                />
              </div>
              <div className="col-12 d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary justify-content-start white my-4 mx-2"
                  disabled={isSubmitting}
                  onClick={() =>
                    ConfirmAcationService.confirmMsg(
                      this.handleVoltar,
                      'Cancelar',
                      'Deseja cancelar criação da OS?',
                      'Ao confirmar os dados digitados serão perdidos.'
                    )
                  }
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary my-4 mx-2"
                  disabled={isSubmitting}
                  onClick={() => {
                    console.log(values, 'before validate');
                    validateForm().then((error) => {
                      if (_.isEmpty(error)) {
                        this.handleConfirm();
                      }
                    });
                  }}
                >
                  Concluir
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;
  const { user, permissions } = state.authentication;
  return {
    cliente,
    user,
    permissions,
  };
}

const mapDispatch = ({
  ordemServico: { setOrdemServico, clearOS },
  alert: { success, error, clear },
  load: { loading },
}) => ({
  setOrdemServico: (ordemServico) => setOrdemServico({ ordemServico }),
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  clearOS: () => clearOS({}),
  error: (msg) => error(msg),
  clear: () => clear(),
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(withRouter(CardCriarOs));
