import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { analiseFalhasService } from '../../../services/analiseFalhas.service';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class VolumeTrocaDetails extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { totalRelogio: 0 };
  }
  render() {
    return (
      <div className="col-12 col-md-6 col-xl-6 d-inline-flex pt-5 detailsChart">
        <div className="col flex-fill text-center">
          <div className="miniTxt">Total de trocas</div>
          <p>{this.props.totalTrocas}</p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { totalTrocas } = state.report;

  return {
    totalTrocas
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true }
)(VolumeTrocaDetails);
