//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import FinanceiroListFilter from './FinanceiroListFilter';
import { financeiroService } from '../../services/financeiroService';
import connect from 'react-redux/es/connect/connect';
import ListagemDetalhePage from '../../components/templates/ListagemDetalhePage';
import FinanceiroDetalheList from './FinanceiroDetalheList';
import { tableHelper } from '../../helpers/tableHelper';
import { dateHelper } from '../../helpers/date.helper';
import { userService } from '../../services/user.service';
import moment from "moment";
import MonthPickerInput from "../../components/Inputs/MonthPickerInput";
const columns = [
  {
    path: 'dadosOS',
    title: 'Nº OS / CP / PA',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  {
    path: 'codRelogio',
    title: 'Código do Relógio',
    isKey: false,
    dataSort: false
  },
  {
    path: 'marca',
    title: 'Marca',
    isKey: false,
    dataSort: false
  },
  {
    path: 'cidade',
    title: 'Cidade',
    isKey: false,
    dataSort: false
  },
  {
    path: 'postoAutorizado',
    title: 'Posto Autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'nomeCliente',
    title: 'Nome do Cliente',
    isKey: false,
    dataSort: false
  },{
    path: 'tipoCliente',
    title: 'Tipo do Cliente',
    isKey: false,
    dataSort: false
  },
  {
    path: 'dataEntradaRelogio',
    title: 'Data',
    isDate: true,
    isKey: false,
    dataSort: false
  },
  {
    path: 'garantia',
    title: 'Garantia',
    yesNo: true,
    isKey: false,
    dataSort: true
  },
  {
    path: 'dataReparo',
    title: 'Data do reparo',
    isDate: true,
    isKey: false,
    dataSort: false
  },
  {
    path: 'status.description',
    title: 'Status Atual',

    isKey: false,
    dataSort: false
  }
];

type State = {
  modal: boolean
};

class FinanceiroList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {};
  renderCustom(row: any, doc: any, column: any) {
    return tableHelper.renderCustom(row, doc, column);
  }

  render() {
    let extension = userService.getCurrentUser().extension;

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });

    let labels = _.map(columns, function(coluna) {
      return coluna.title;
    });

    let defaultFilter = {
      mes:(moment().subtract(1, 'months').month() +1),
      ano:moment().subtract(1, 'months').year()
    };
    if (this.props.location.state && this.props.location.state.filter) {
      defaultFilter = this.props.location.state.filter;
    }
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="FINANCEIRO"
          subTitle=" "
          canBack={false}
        />
        <ListagemDetalhePage
          title={`REGISTROS(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={financeiroService}
          showNewButton={false}
          defaultOrder={'dataConclusao'}
          defaultDirection={'desc'}
          defaultFilter={defaultFilter}
          DetalhePage={FinanceiroDetalheList}
          onRowClick={this.handleRowClick}
          FormFilter={FinanceiroListFilter}
          controller={'financeiro'}
          loadOnInit={extension === undefined ? false : true}
          fields={fields}
          labels={labels}
        >
          {tableColumn}
        </ListagemDetalhePage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;
  return {
    subTitle
  };
}
export default connect(mapStateToProps)(FinanceiroList);
