import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';

type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

export class PaCardPecaItemView extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: { quantidade: 1 }
    };
  }

  render() {
    const { entity } = this.props;

    return (
      <React.Fragment>
        <div className="form-row borderForm formView">
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Peça*</label>
            <p>{entity.peca.peca.nome}</p>
          </div>

          <div className="form-group col-6 col-sm-2">
            <label className="miniLabel">Qtd*</label>
            <p>{entity.peca.quantidade}</p>
          </div>
          <div className="form-group col-6 col-sm-2">
            <label className="miniLabel">Valor sugerido</label>
            <p className="txtDarkGray">
              {ordemServicoHelper.formatMoney(
                parseFloat(entity.valorSugerido),
                2,
                ',',
                '.'
              )}
            </p>
          </div>
          <div className="form-group col-12 col-sm-4">
            <label className="miniLabel">Valor Final*</label>
            <p>
              {entity.peca.valorFinal
                ? ordemServicoHelper.formatMoney(
                    parseFloat(entity.peca.valorFinal),
                    2,
                    ',',
                    '.'
                  )
                : '0,00'}
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(PaCardPecaItemView);
