import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import SockJsClient from 'react-stomp';
import { oauthHeaderJson } from '../../helpers/oauth-header';
import { taskService } from '../../services/taskService';
type Props = {
  id: number,
  onActive: any,
  activeComponente: number
};
type State = {
  entity: any
};

class TaskComponente extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.toggleClass = this.toggleClass.bind(this);
    taskService.findLastTask(props.user).then(response => {
      this.handleTasks(response.data.data);
    });
    this.state = {
      active: false,
      tasks: [],
      numberTasks: 0,
      task: null
    };
  }
  handleEvent(task, props) {
    let taskType = taskService.getTaskType(task.taskType);
    props.runTask(task);
    if (taskType.url !== props.location.pathname) {
      if (taskType.withParam) {
        props.history.push(`${taskType.url}${task.idEntity}`);
      } else {
        props.history.push(`${taskType.url}`);
      }
    }
  }
  componentWillReceiveProps(nexProps: any) {
    let active = nexProps.activeComponente === this.props.id;
    this.setState({ active });
  }
  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    this.props.onActive(this.props.id);
  }
  handleData(data) {
    let result = JSON.parse(data);
  }

  handleTasks(data) {
    this.props.addAllTask(data);
    this.setState({ tasks: data.tarefas, numberTasks: data.size });
  }
  render() {
    const { active, tasks, numberTasks } = this.state;
    const { user, location } = this.props;
    //TODO colocar segurança e organizar codigo
    const wsSourceUrl = taskService.getWsUrl();
    const topic = taskService.getWsTopic(user);
    let headers = oauthHeaderJson();
    let handleEvent = this.handleEvent;
    let props = this.props;
    return (
      <li
        className={`header-icon d-flex flex-row align-items-center ${
          active ? 'active' : ''
        }`}
        onClick={this.toggleClass}
      >
        <i className="icon-alert_x">
          <span className="badge badge-danger">{numberTasks}</span>{' '}
        </i>
        <span className="hideMobile">
          Você possui <br />
          {numberTasks} tarefa(s)
          <SockJsClient
            url={wsSourceUrl}
            topics={[topic]}
            onMessage={msg => {
              this.handleTasks(msg);
            }}
            subscribeHeaders={headers}
            headers={headers}
            ref={client => {
              this.clientRef = client;
            }}
          />
        </span>
        <div className="drop-down">
          <div className="dropdown-content-body">
            <ul className="listaTarefas">
              {tasks.map(function(object, i) {
                return (
                  <li key={object.id}>
                    <a
                      onClick={() => handleEvent(object, props)}
                      className="d-flex flex-row align-items-center"
                    >
                      <div className="col-2">
                        <i className="icon-alert_x" />
                      </div>
                      <div className="col-10 d-flex flex-column">
                        <div className="numeroOS py-1">{object.title}</div>
                        <div className="pecas py-1">{object.description}</div>
                        <div className="local py-1">{object.local}</div>
                      </div>
                    </a>
                  </li>
                );
              })}

              <li>
                <NavLink
                  to="/task"
                  onClick={() => {
                    this.toggleClass();
                    this.props.closeNav();
                  }}
                >
                  {' '}
                  Ver todas
                  <i className="pl-3 icon-acessar" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </li>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;

  return {
    user,
    alert,
    messages
  };
}
const connectedTaskComponente = withRouter(TaskComponente);
const mapDispatch = ({ task: { addAllTask, runTask } }) => ({
  addAllTask: listTask => addAllTask(listTask),
  runTask: tarefa => runTask(tarefa)
});
export default connect(
  mapStateToProps,
  mapDispatch
)(connectedTaskComponente);
