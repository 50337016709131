//@flow
import * as React from 'react';
import { connect } from 'react-redux';
import CurrencyInput from 'react-currency-input';
import { Label } from 'reactstrap';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
import * as _ from 'lodash';
type Props = {
  name: string,
  label: string,
  onChange: any,
  size?: number,
  required: boolean,
  messageValidate?: Array<any>,
  value?: any,
  messages?: any
};

type State = {
  value?: any
};

class MoneyInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    required: false,
    value: ''
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  handleMoneyChange = (event, maskedvalue, floatvalue) => {
    const { name } = event.nativeEvent.target;
    this.setState({ value: maskedvalue });
    this.props.onChange(name, floatvalue);
  };

  render() {
    let colSize = 'col';
    if (this.props.size && this.props.noSize !== true) {
      colSize += '-' + this.props.size;
    } else {
      colSize = '';
    }

    const { messageValidate, name, erroMensagem } = this.props;
    // console.log(erroMensagem);
    // console.log(messageValidate);
    let error = _.clone(erroMensagem);

    if (messageValidate && messageValidate.length > 0) {
      let errorCode = messageValidate.find(item => {
        return item.fieldName === name;
      });
      if (errorCode) {
        if (this.props.messages) {
          error = this.props.messages[errorCode.message.code];
        } else {
          error = 'Erro.';
        }
      }
    }

    return (
      <div className={`form-group ${this.props.className}`}>
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={'help_' + this.props.id} />
                <ToolTipHelp
                  target={'help_' + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <CurrencyInput
          decimalSeparator=","
          thousandSeparator="."
          prefix={'R$ '}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChangeEvent={this.handleMoneyChange}
          required={this.props.required}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className="form-control"
          allowEmpty={true}
        >
          {this.props.children}
        </CurrencyInput>

        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    );
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, '');
  return v;
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(MoneyInput);
