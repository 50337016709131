//@flow
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import CardCompraPecaList from '../../components/Cards/CardCompraPecaList';
import CardCriarCP from '../../components/Cards/CardCriarCP';
import { formatterHelper } from '../../helpers/formatter.helper';
import type { ClienteType } from '../../types/cliente.type';

type Props = { cliente: ClienteType };

class CompraPecaNew extends React.Component<Props> {
  render() {
    const { cliente } = this.props;

    let posto = {};

    if (
      this.props.location.state &&
      this.props.location.state.entity &&
      this.props.location.state.entity.posto
    ) {
      posto = this.props.location.state.entity.posto;
    } else {
      posto = this.props.company;
    }

    return (
      <React.Fragment>
        <div className="flex-column">
          <CardCompraPecaList showNewOS={true} posto={posto} />
        </div>
        <div className="flex-column">
          <div className="card cardSeculus cardTab d-flex flex-column w2">
            <h4 className=" text-center d-inline d-lg-none">{'CP --'}</h4>
            <div>
              <ul className="nav" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="relogio-tab"
                    data-toggle="tab"
                    data-target="#relogio"
                    role="tab"
                    aria-controls="relogio"
                    aria-selected="true"
                  >
                    <i className="icon-relogio" />{' '}
                    <span className="d-none d-md-inline">Peças</span>
                  </a>
                </li>
                <li className="nav-item" />
                <li className="nav-item" />
                <li className="nav-item" />
                <li className="nOs float-right d-none d-lg-inline">
                  {'CP --'}
                </li>
              </ul>

              <div className="tab-content">
                <CardCriarCP posto={posto} />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;
  const { company } = state.authentication;
  return {
    cliente,
    company
  };
}

const mapDispatch = ({ ordemServico: { createOrdemServico } }) => ({
  createOrdemServico: osType => createOrdemServico(osType)
});

export default connect(
  mapStateToProps,
  mapDispatch
)(CompraPecaNew);
