//@flow
import { FormGroup, Label, Input, InputType } from 'reactstrap';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { translate } from '../../helpers/message.helper';
import { ToolTipHelp } from '../Utils/ToolTipHelp';
//TODO ALTErar this.handleBorderColor(messageValidate)
type Props = {
  label: string,
  type: InputType,
  name: string,
  id: string,
  value?: string | string[] | number,
  size?: number,
  onChange?: any,
  onBlur?: any,
  children?: any,
  required: boolean,
  placeholder: string,
  disabled: boolean,
  maxLength?: number,
  style?: any,
  messageValidate?: string,
};

class FormInput extends PureComponent<Props> {
  _input: any;

  static defaultProps = {
    required: false,
    size: 12,
    disabled: false,
  };

  getInput = () => {
    return this._input;
  };

  handleBorderColor = (messageValidate) => {
    let borderColor, borderWidth;
    if (messageValidate) {
      borderColor = '#ea3a45';
      borderWidth = 2;
    }
    return {
      borderColor,
      borderWidth,
    };
  };

  render() {
    const { messages, name } = this.props;
    let colSize = 'form-group col';

    //FIXME NAO SE DEVE ALTERAR O VALOR DE UMA VARIAVEL DO TIPO PROPS. O PROPS É IMUTAVEL.
    let { messageValidate } = this.props;
    if (messages != null && messages.length > 0) {
      messages.forEach(function(message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }
    if (this.props.size && this.props.noSize !== true) {
      colSize += '-' + this.props.size;
    } else {
      colSize = '';
    }
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    const value = this.props.value ? this.props.value : '';
    const { emailValidation, passwordValidation } = this.props;
    return (
      <FormGroup
        className={`${this.props.noSize ? '' : colSize} ${this.props.style} ${
          this.props.className
        }`}
      >
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name}>
              {this.props.label}
              {this.props.required && '*'}
            </Label>{' '}
          </React.Fragment>
        )}
        {this.props.helpText && (
          <React.Fragment>
            <i className="icon-duvida" id={'help_' + this.props.id} />
            <ToolTipHelp
              target={'help_' + this.props.id}
              message={this.props.helpText}
            />
          </React.Fragment>
        )}
        <Input
          type={this.props.type}
          id={this.props.id}
          name={this.props.name}
          value={value}
          onBlur={this.props.onBlur}
          style={this.handleBorderColor(messageValidate)}
          onChange={this.props.onChange}
          required={this.props.required}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled ? this.props.disabled : false}
          maxLength={this.props.maxLength}
          innerRef={(input) => {
            this._input = input;
          }}
          className={`${this.props.className} ${
            messageValidate ? 'is-invalid' : ''
          }`}
        >
          {this.props.children}
        </Input>
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </FormGroup>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert,
  };
}

export default connect(mapStateToProps)(FormInput);
