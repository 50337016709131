import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormRow } from '../../components/FormRow';
import SelectInput from '../../components/Inputs/SelectInput';
import { ViewOnly } from '../../components/Inputs/ViewOnly';
import { causaOsService } from '../../services/causa.service';
import { pecaService } from '../../services/peca.service';
import FormSelectInput from '../Inputs/FormSelectInput';
import { sintomaOsService } from '../../services/sintoma.service';
import _ from 'lodash';
import { objectsConstants } from '../../constants/objects.constants';
import { validateInput } from '../../helpers/validateInputs.helper';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';
import NumberInput from '../Inputs/NumberInput';
import MoneyInput from '../Inputs/MoneyInput';
import CascadeSelectInput from '../Inputs/CascadeSelectInput';
type Props = { entity: any };
type State = {
  pecas: any,
  entity: any
};

const objectValidateBranchVO = [
  objectsConstants.pecaValidate,
  objectsConstants.sintomaValidate,
  objectsConstants.causaValidate
];

export class PaCardPecaItem extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let entity = _.cloneDeep(props.entity);
    if(props.ordemServico&&!props.ordemServico.garantia){
      entity.garantia = 'false';
    }
    this.state = {
      entity: props.entity,
      idOs: props.idOs
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.idOs != this.state.idOs) {
      this.setState({
        entity: nextProps.entity,
        idOs: nextProps.idOs
      });
    }
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.currentTarget;
    let entity = _.cloneDeep(this.state.entity);

    _.set(entity, name, value);
    this.calculaValorFinal(entity);
    this.setState({ entity });
  };

  handleSelectChange = (name: any, option: any) => {
    const { entity } = this.state;
    let newEntity = _.cloneDeep(entity);
    _.set(newEntity, name, option);
    if (name === 'valorFinal') {
      _.set(newEntity, 'valorFinalAlterado', true);
    }
    let peca;
    if (name === 'peca') {
      peca = option;
    } else {
      peca = this.state.peca;
    }
    //se alterou peça pega o valor sugerido
    this.calculaValorFinal(newEntity);
    this.setState({ entity: newEntity, peca }, () => {
      if (this.validateForms()) {
        this.props.onChange(this.props.id, newEntity);
      }
    });
  };
  calculaValorFinal(newEntity) {
    console.log(newEntity);
    if (
      newEntity.valorSugerido &&
      newEntity.quantidade &&
      !newEntity.valorFinalAlterado
    ) {
      _.set(
        newEntity,
        'valorFinal',
        newEntity.valorSugerido * newEntity.quantidade
      );
    }
  }

  validateForms = () => {
    const { entity } = this.state;
    let isValid;
    let validate = {};

    objectValidateBranchVO.forEach(o => {
      const value = entity[o.name];
      if (!validateInput(value, o)) {
        isValid = false;
        validate[o.name] = o.message;
      }
    });

    this.setState({ validateObject: validate });

    if (_.isEmpty(validate)) {
      isValid = true;
    }

    return isValid;
  };
  getBotaoRemovePeca = () => {
    return (
      <div className="text-right py-2">
        <a
          onClick={() =>
            this.props.onRemovePeca(this.props.posicao, this.state.entity.id)
          }
        >
          remover
        </a>
      </div>
    );
  };
  render() {
    const { entity, peca } = this.state;
    let helpTextValorFinal = entity.quantidade
      ? `O valor sugerido pela Seculus seria de ${ordemServicoHelper.formatMoney(
          parseFloat(peca.valor),
          2,
          ',',
          '.'
        )} para autorizada, ${ordemServicoHelper.formatMoney(
          parseFloat(peca.valorLogista),
          2,
          ',',
          '.'
        )} para logista, ${ordemServicoHelper.formatMoney(
          parseFloat(peca.valorConsumidor),
          2,
          ',',
          '.'
        )} para consumidor.`
      : null;
    return (
      <React.Fragment>
        <div className="borderForm">
          <FormRow>
            <CascadeSelectInput
              valueKey={'id'}
              labelKey={'nome'}
              name="peca"
              label="Peça"
              placeholder=""
              size={4}
              style={['col-12 col-sm-4']}
              service={pecaService.findByRelogio}
              returnFullObject={true}
              searchable={true}
              id={'peca'}
              ref={input => {
                this.peca = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.peca}
              required={true}
              parent={this.props.ordemServico.relogio}
            />{' '}
            <CascadeSelectInput
              type="text"
              valueKey={'id'}
              labelKey={'nome'}
              name="sintoma"
              label="Sintoma"
              placeholder=""
              size={4}
              style={['col-12 col-sm-4']}
              returnFullObject={true}
              searchable={true}
              parent={entity.peca ? entity.peca.id : 0}
              id={'sintoma'}
              ref={input => {
                this._sintoma = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.sintoma}
              service={sintomaOsService.findByPeca}
              required={true}
            />{' '}
            <CascadeSelectInput
              name="causa"
              label="Causa"
              valueKey={'id'}
              labelKey={'nome'}
              placeholder=""
              parent={entity.sintoma ? entity.sintoma.id : 0}
              size={4}
              style={['col-12 col-sm-4']}
              returnFullObject={true}
              searchable={true}
              id={'causa'}
              ref={input => {
                this._causa = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.causa}
              service={causaOsService.findBySintoma}
              required={true}
            />
          </FormRow>
          <FormRow>
            <NumberInput
              type="text"
              name="quantidade"
              label="Qtde."
              placeholder=""
              size={2}
              className={'col-6 col-sm-2'}
              returnFullObject={true}
              searchable={true}
              id={'quantidade'}
              ref={input => {
                this._quantidade = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.quantidade}
            />
            <MoneyInput
              type="text"
              name="valorFinal"
              label="Valor Final"
              placeholder="Valor Final"
              size={3}
              className={['col-6 col-sm-2']}
              returnFullObject={true}
              searchable={true}
              id={'valorFinal'}
              ref={input => {
                this._valorFinal = input;
              }}
              onChange={this.handleSelectChange}
              value={entity.valorFinal}
              helpText={helpTextValorFinal}
            />
          </FormRow>
          {this.getBotaoRemovePeca()}
        </div>
      </React.Fragment>
    );
  }
}
export default connect()(PaCardPecaItem);
