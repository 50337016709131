//@flow
import * as React from 'react';
import { mensagemService } from '../../services/messagem.service';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import TopoTitleComponente from '../../views/Topo/TopoTitleComponente';
// import '../../css/chat_style.css';
import '../../css/listagemConversasUsuarios_style.css';
import { dateHelper } from '../../helpers/date.helper';
import {
  constHelper,
  objectsConstants
} from '../../constants/objects.constants';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import { midiaService } from '../../services/midiaService';
import avatar from '../../img/avatarDefault.jpg';
import iconSearch from '../../img/icon_search_lupa.svg';
import Home from '../home/Home';
import Chat from './Chat';
class ListagemConversas extends React.PureComponent<{}> {
  constructor(props: Props) {
    super(props);
    if (this.props.location.state) {
      this.state = {
        messages: [],
        isNovaConversa: false,
        contacts: null,
        text: '',
        receiver: this.props.location.state.receiver,
        receiverName: this.props.location.state.receiverName
      };
    } else {
      this.state = {
        messages: [],
        isNovaConversa: false,
        contacts: null,
        text: ''
      };
    }
    mensagemService.findMyMessage().then(response => {
      this.setState({ messages: response.data });
    });
  }

  novaConversa() {
    this.props.loading(true);
    mensagemService.findContact().then(response => {
      this.props.loading(false);
      this.setState({ isNovaConversa: true, contacts: response.data });
    });
  }

  render() {
    const { messages } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle={'CONVERSAs'}
          subTitle={''}
          canBack={false}
        />
        {/* TODO Trocar para push*/}
        {this.state.receiver && (
          <Chat
            receiver={this.state.receiver}
            receiverName={this.state.receiverName}
          />
        )}
        {!this.state.receiver && (
          <div className="flex-column ">
            <div className="card cardSeculus d-flex flex-column">
              <div className="topoCard d-flex flex-column flex-sm-row">
                <h3 className="flex-grow-1">CONVERSAS</h3>
                <div className="boxButon float-right d-sm-flex">
                  <button
                    onClick={this.novaConversa.bind(this)}
                    type="button"
                    className="btn btn-primary btn-sm"
                  >
                    Nova Mensagem
                  </button>
                </div>
              </div>
              <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
                <ul className="listaMensagensBox w-100">
                  {messages.map((item, index) => (
                    <li key={item.sender.id}>
                      <a
                        onClick={() => {
                          this.props.history.push({
                            pathname: `/chat/private/${item.sender.id}`,
                            state: { receiverName: item.sender.name }
                          });
                        }}
                        className="d-flex flex-row align-items-center"
                      >
                        {item.sender.foto && item.sender.foto.id && (
                          <img
                            src={midiaService.get(item.sender.foto.id)}
                            className="rounded-circle avatar-img"
                          />
                        )}
                        {!item.sender.foto && (
                          <img
                            src={avatar}
                            className="rounded-circle avatar-img"
                          />
                        )}

                        <div className="resumoMsg flex-grow-1">
                          <div className="nomeMsg">{item.sender.name}</div>
                          <div className="msg">{item.lastMessage.mensagem}</div>
                        </div>
                        <div className="dataMsg">
                          <div className="data">
                            {dateHelper.format(item.lastMessage.dateTime)}
                          </div>
                          <div className="badge badge-success">
                            {item.countMessageNotView}
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}
        {!this.state.receiver && this.state.isNovaConversa && (
          <div className="flex-column ">
            <div className="card cardSeculus w1">
              <div className="topoCard d-flex flex-column flex-sm-row ">
                <h3 className="w-100 txtGreen">
                  enviar para ({this.state.contacts.length})
                </h3>
                <div className="boxButon float-right flex-shrink-1 d-sm-flex">
                  <input
                    type="text"
                    className="form-control boxInputSearch"
                    name="modeloOS"
                    placeholder="Buscar Contatos"
                    value={this.state.text}
                    onChange={(e: SyntheticInputEvent<HTMLInputElement>) => {
                      const { value } = e.currentTarget;
                      this.setState({ text: value });
                    }}
                  />
                  <a href="#">
                    <img
                      src={iconSearch}
                      className="icon_lupa"
                      alt="Ícone Lupa"
                    />
                  </a>
                </div>
              </div>
              <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
                <ul className="listaMensagensBox w-100">
                  {this.state.contacts
                    .filter(user => user.name.indexOf(this.state.text) !== -1)
                    .map((item, index) => (
                      <li key={item.id}>
                        <a
                          onClick={() => {
                            this.props.history.push(`/chat/private/${item.id}`);
                          }}
                          className="d-flex flex-row align-items-center"
                        >
                          {item.foto && item.foto.id && (
                            <img
                              src={midiaService.get(item.foto.id)}
                              className="rounded-circle avatar-img"
                            />
                          )}
                          {!item.foto && (
                            <img
                              src={avatar}
                              className="rounded-circle avatar-img"
                            />
                          )}

                          <div className="resumoMsg flex-grow-1">
                            <div className="nomeMsg">{item.name}</div>
                            <div className="setor">{item.endereco}</div>
                            {/* <div className="localidade"> </div> */}
                          </div>
                          <div className="cargo">
                            {_.get(constHelper, item.role)}
                          </div>
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

export default connect(
  null,
  mapDispatch
)(withRouter(ListagemConversas));
