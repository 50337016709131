//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormRow } from '../../components/FormRow';
import CardFormEditView from '../../components/templates/CardFormEditView';
import { LineVert } from '../../components/Utils/LineVert';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import RegisterFirstStepForm from './RegisterFirstStepForm';
import RegisterEmpresaFormCard from './RegisterEmpresaFormCard';

type Props = { entity: any, step: number, handleEntity: any };
type State = {
  entity: any,
  edit: boolean
};

class RegisterFirstStepFormView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.state = {
      entity: _.cloneDeep(this.props.entity),
      edit: false
    };
  }

  componentWillReceiveProps(nexProps) {
    this.setState({ entity: _.cloneDeep(nexProps.entity) });
  }

  getForm() {
    return this._form;
  }

  handleSaved = (entity: any) => {
    this.setState({ entity: _.cloneDeep(entity) });
  };
  componentWillReceiveProps(nextProps: any) {
    this.setState({ entity: _.cloneDeep(nextProps.entity) });
  }

  setEditMode = (edit: boolean) => {
    this.setState({ edit });
  };

  getFormContent = () => {
    const { entity } = this.state;
    if (this.state.edit === true) {
      return (
        <RegisterFirstStepForm
          entity={entity}
          ref={forms => {
            this._form = forms;
          }}
          FormContent={RegisterEmpresaFormCard}
        />
      );
    } else {
      return (
        <React.Fragment>
          <FormRow>
            <div className="col-8 col-lg-4">
              <h5 className="title">CNPJ:</h5>
              <p>{entity.cnpj}</p>
            </div>
            <div className="col-12 col-lg-8">
              <h5 className="title">Razão social:</h5>
              <p>{entity.razaoSocial}</p>
            </div>
            <div className="col-12 col-lg-4">
              <h5 className="title">Nome fantásia:</h5>
              <p>
                <b>{entity.nomeFantasia}</b>
              </p>
            </div>
            <div className="col-7 col-lg-4">
              <h5 className="title">Inscrição estadual:</h5>
              <p>{entity.inscricaoEstadual ? entity.inscricaoEstadual : '-'}</p>
            </div>
            <div className="col-5 col-lg-4">
              <h5 className="title">Inscrição municipal:</h5>
              <p>
                {entity.inscricaoMunicipal ? entity.inscricaoMunicipal : '-'}
              </p>
            </div>
            <div className="col-12 col-lg-8 pb-3 pb-lg-4">
              <h5 className="title">E-mail comercial:</h5>
              <p>{entity.emailComercial}</p>
            </div>
            <div className="col-8 col-lg-4 pb-4 pb-lg-4">
              <h5 className="title">Telefone comercial:</h5>
              <p>{entity.telefoneComercial}</p>
            </div>
          </FormRow>
          <LineVert />
          <FormRow>
            <div className="col-12 col-lg-7">
              <h3 className="text-lg-left text-center mt-5">Endereço</h3>
            </div>
            <div className="col-12">
              <h5 className="title">Endereço:</h5>
              <p>
                {entity.rua}, {entity.numero}
                {entity.complemento ? ',' + entity.complemento : ''}
                <br />
                {entity.bairro}
                <br />
                {entity.cep}
              </p>
            </div>
            <div className="col-8 col-lg-8">
              <p>
                <b>
                  {_.get(entity.cidade, 'nome')} -{' '}
                  {_.get(entity.estado, 'nome')}
                </b>
              </p>
            </div>
            <div className="col-8 col-lg-4">
              <p>{_.get(entity.pais, 'nome')}</p>
            </div>
          </FormRow>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <CardFormEditView
        setEditMode={this.setEditMode}
        title="Dados da empresa"
        onSaved={this.handleSaved}
        getForm={this.getForm}
        onSave={postoAutorizadoService.doSave}
      >
        {this.getFormContent()}
      </CardFormEditView>
    );
  }
}

export default connect()(RegisterFirstStepFormView);
