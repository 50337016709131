//@flow
import * as React from 'react';
import FormInput from '../../components/Inputs/FormInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { colaboradoresHelper } from '../../helpers/colaboradores.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class GrupoMarcaListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Nome"
          placeholder={'Nome'}
          id={'nome'}
          name="nome"
          onChange={this.props.onChangeText}
          type={'text'}
          size={12}
          style={'col-sm-4'}
          value={entity.nome}
        />

        {/* TODO*/}
        {/* Inseri um status que é de OS não sei qual o status do colaborador */}
        <StatusSelectInput
          label="Status"
          placeholder={'Status'}
          id={'status'}
          name="status"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-2'}
          value={entity.status}
          service={colaboradoresHelper.getListStatus}
        />
      </React.Fragment>
    );
  }
}
