//@flow
import * as React from 'react';
import CardColaborador from '../../components/Cards/CardColaborador';
import CardHistorico from '../../components/Cards/CardHistorico';
import connect from 'react-redux/es/connect/connect';
import { colaboradorService } from '../../services/colaborador.service';
import { propertyConstantes } from '../../constants/property.constantes';
import BoxOrdemServico from '../boxOrdemServico/BoxOrdemServico';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';

type State = { colaborador: any };

class ColaboradorCrudView extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { colaborador: {} };
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      colaboradorService.getById(this.props.match.params.id).then(result => {
        let colaborador = result.data.data;
        this.setState({ colaborador });
      });
    }
  }

  render() {
    const { colaborador } = this.state;
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Colaboradores"
          subTitle=" "
          canBack={true}
        />
        <div className="flex-column">
          <CardColaborador
            colaborador={colaborador}
            titlePageEdit="Colaboradores"
          />
        </div>
        <div className="flex-column">
          {colaborador.id && <CardHistorico id={colaborador.id} />}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { cliente } = state.ordemServico;

  return {
    cliente
  };
}

const mapDispatch = ({ ordemServico: { createOrdemServico } }) => ({
  createOrdemServico: osType => createOrdemServico(osType)
});

export default connect()(ColaboradorCrudView);
