export const objectsConstants = {
  //ABILITY
  ABILITY_SOLICITAR_HP: 'Solicitar HP',
  ABILITY_TROCA_MAIS_MODELOS: 'Troca Mais Modelos',
  ABILITY_ESCOLHA_MODELO_TROCA: 'Escolha do modelos para troca',
  ABILITY_FINANCEIRO: 'Financeiro',
  ABILITY_REPARAR_OS: 'Reparar OS',
  ABILITY_CADASTRAR_OS: 'Cadastrar OS',
  ABILITY_IMPORTAR_PECA_DOC: 'Importar Peça Doc',
  ABILITY_DOC_PECAS_IMPORTACAO:'Doc Peças em Importação',
  ABILITY_EDITAR_CLIENTE: 'Editar dados Cliente',
  ABILITY_APROVAR_ORCAMENTO_OS: 'Aprovar orçamento da OS',
  ABILITY_EDITAR_COLABORADOR: 'Editar colaborador',
  ABILITY_FILTRO_POSTO: 'Filtro posto autorizado',
  ABILITY_CADASTAR_DOC: 'Cadastrar DOC',
  ABILITY_FINALIZAR_OS: 'Finalizar OS',
  ABILITY_FILTRO_CODIGO_SAP: 'Filtro Codigo SAP',
  ABILITY_SOLICITAR_TROCA_MODELO: 'Solicitar troca de modelo',
  ABILITY_CONFIGURACOES: 'Configurações',
  ABILITY_REJEITAR_PECA: 'Rejeitar Peça',
  ABILITY_DOC: 'DOC',
  ABILITY_TROCA_MODELO: 'Troca de Modelo',
  ABILITY_RELATORIO_FINANCEIRO: 'Relatório Financeiro',
  ABILITY_SOLICITAR_COMPRA_PECA: 'Cadastra compra peca',
  ABILITY_EXPIRAR_OS: 'Expirar OS',
  ABILITY_CANCELAR_OS: 'Cancelar OS',
  ABILITY_CANCELAR_RE: 'Cancelar RE',
  ABILITY_REPROVAR_TROCA_MODELO: 'Reprovar Troca de Modelo',
  ABILITY_CONFIGURAR_PERFIL: 'Configurar Perfil',
  ABILITY_PAINEL_SITUACAO:"Painel de Situação",
  ABILITY_ORDEM_SERVICO: 'Ordem Servico',
  ABILITY_ORDEM_SERVICO_VISUALIZACAO_POSTO:
    'Ordem de Serviço visualização de Posto Autorizado ',
  ABILITY_COMPRA_PECA_VISUALIZACAO_POSTO:
    'Compra de Peça visualização de Posto Autorizado',
  ABILITY_COMPRA_PECA: 'Compra de Peça',
  ABILITY_POSTO: 'Cadastro de posto autorizado',
  ABILITY_COLABORADORES: 'Cadastro de Colaborador',
  ABILITY_CADASTRO_RELOGIO: 'Cadastro de relógio',
  ABILITY_CADASTRO_GRUPO_MARCAS: 'Cadastro de grupo de marcas',
  ABILITY_CADASTRO_MARCAS: 'Cadastro de marcas',
  ABILITY_HOME_TASK: 'Caixa de tarefas (Pagina inicial)',
  ABILITY_HOME_DOCS: 'Caixa de Docs (Pagina inicial)',
  ABILITY_HOME_COLABORADOR: 'Caixa de colaboradores(Pagina inicial)',
  ABILITY_POSTO_HOME: 'Caixa dados do Posto(Pagina Inicial)',
  ABILITY_HOME_SELECT_POSTO:
    'Caixa de seleção de Posto Autorizado(Pagina Inicial)',
  ABILITY_HOME_ORDEM_SERVICO:
    'Caixa de selecionar Ordem de Serviço(Pagina Inicial)',
  ABILITY_HOME_COMPRA_PECA:
    'Caixa de selecionar Compra de peça(Pagina Inicial)',
  ABILITY_CADASTRAR_POSTO_HABILITADO: 'Cadastrar Posto Habilitado',
  ABILITY_ORDEM_SERVICO_ASSOCIAR_POSTO_HABILITADO: 'Associar Posto Habilitado',
  ABILITY_VISUALIZAR_POSTOS_OS: 'Visualizar Postos(Ordem Servico)',
  ABILITY_VISUALIZAR_ESTOQUE_OS: 'Visualizar Estoque(Ordem Servico)',
  ABILITY_ALTERAR_STATUS_OS: 'Alterar Status de Ordem de Serviço',
  ABILITY_CADASTRO_DE_RELOGIO: 'Cadastro de Relogio',
  ABILITY_REATIVAR_OS: 'Reativar Ordem de Serviço',
  ABILITY_SOLICITAR_ATSP_BTN: 'SolicitarATSP Btn',
  ABILITY_LISTAGEM_OS_ADMIN: 'Listagem OS ADMIN',
  ABILITY_ALTERAR_PERFIL: 'Alterar Perfil de Usuario',
  ABILITY_IMPORTACAO: 'Importação de peças e valores',


  ABILITY_BTN_GRUPO_MARCA: 'Visualizar Novo Grupo de Marca',
  ABILITY_BTN_CADASTRO_MARCA: 'Visualizar Nova Marca',

  ABILITY_GRUPO_FILIAL: 'Grupo Filial',
  //FIM ABILITY
  SOLICITAR_INVOICE:"SOLICITAR_INVOICE",
  TIPO_MARCA_IMPORTADA:"IMPORTADA",

  USO_INDEVIDO: 6,
  PARTES: [
    { nome: 'PARAFUSO', qtd: 8 },
    { nome: 'PINO', qtd: 8 },
    { nome: 'TERMINAL', qtd: 2 }
  ],
  cnpjValidate: {
    name: 'cnpj',
    message: 'Campo obrigatório',
    type: 'cnpj',
    required: true
  },

  codeGoogle: 'AIzaSyCgj5WxP4p7O9K-9WZIry2zqdw4C2LbxIA',

  razaoSocialValidate: {
    name: 'razaoSocial',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  nomeFantasiaValidate: {
    name: 'nomeFantasia',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  inscricaoEstadualValidate: {
    name: 'inscricaoEstadual',
    message: 'Campo obrigatório',
    type: 'number',
    required: true
  },
  inscricaoMunicipalValidate: {
    name: 'inscricaoMunicipal',
    message: 'Campo obrigatório',
    type: 'number',
    required: true
  },
  emailComercialValidate: {
    name: 'emailComercial',
    message: 'Campo obrigatório',
    type: 'email',
    required: true
  },
  telefoneComercialValidate: {
    name: 'telefoneComercial',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  // Address
  cepValidate: {
    name: 'cep',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  ruaValidate: {
    name: 'rua',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  numeroValidate: {
    name: 'numero',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  complementoValidate: {
    name: 'complemento',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  bairroValidate: {
    name: 'bairro',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  paisValidate: {
    name: 'pais',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  estadoValidate: {
    name: 'estado',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  cidadeValidate: {
    name: 'cidade',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  horaFuncionamentoValidate: {
    name: 'horaFuncionamento',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  messageCampoObrigatorio: 'Campos obrigatórios não foram preenchidos',
  messageSucesso: 'Registro salvo com sucesso.',
  messageError: 'Ops, desculpe houve um erro interno ao processar requisição.',
  defaultFilter: {
    status: 'true'
  },
  PAIS_DEFAULT: { id: 1, nome: 'Brasil' },
  ROLE_REPRESENTANTE_LEGAL: 'ROLE_REPRESENTANTE_LEGAL',
  VIEW: 'VIEW',
  REPORT: 'REPORT',
  MENU_OS_ADMIN_MANAUS: 'MenuOSAdminManaus',
  MENU_DOC_ADMIN_MANAUS: 'MENU_DOC_ADMIN_MANAUS',
  MENU_DOC_ADMIN_MANAUS_POSTO: 'MENU_DOC_ADMIN_MANAUS_POSTO',
  MENU_OS_POSTO: 'MENU_OS_POSTO',
  ROLE_FINANCEIRO: 'ROLE_FINANCEIRO',
  ROLE_MANAUS: 'ROLE_MANAUS',
  ROLE_MANAUS_TROCA: 'ROLE_MANAUS_TROCA',
  ROLE_ASSISTENCIA_TECNICA_SAO_PAULO: 'ROLE_ASSISTENCIA_TECNICA_SAO_PAULO',
  ROLE_POSTO: 'ROLE_POSTO',
  ROLE_FINANCEIRO_POSTO: 'ROLE_FINANCEIRO_POSTO',
  ROLE_ADMIN: 'ROLE_ADMIN',
  MENU_COLABORADOR: 'MENU_COLABORADOR',
  EDIT_PROFILE: 'EDIT_PROFILE',
  ADMIN_CONSULTA: 'ADMIN_CONSULTA',
  ROLE_ADMIN_POSTO: 'ROLE_ADMIN_POSTO',
  ROLE_REPARAR_OS: 'ROLE_REPARAR_OS',
  CONSULTA_OS: 'CONSULTA_OS',
  PJ: 'PJ',
  PF: 'PF',
  pecaValidate: {
    name: 'peca',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  sintomaValidate: {
    name: 'sintoma',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  causaValidate: {
    name: 'causa',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  garantiaValidate: {
    name: 'garantia',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  solicitarPecaValidate: {
    name: 'solicitarPeca',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  servicoValidate: {
    name: 'servico',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  valorFinalValidate: {
    name: 'valorFinal',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  MONTH_ARRAY: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ],
  TIPO_OS: [
    { value: 'OS', label: 'Ordem de Serviço' },
    { value: 'PA', label: 'Pronto atendimento' }
  ],
  TIPO_PESSOA_ARRAY: [
    { name: 'PF', nome: 'Pessoa física' },
    { name: 'PJ', nome: 'Pessoa jurídica' },
    { name: 'TODOS', nome: 'Todos' }
  ],
  QUESTIONARIO_PESQUISA_ARRAY: [
    { id: 'VELOCIDADE_SERVICO', label: 'Velocidade do serviço' },
    { id: 'SOLUCAO_PROBLEMA', label: 'Solução do problema' },
    { id: 'RELACIONAMENTO', label: 'Relacionamento' }
  ],
  ROLES_POSTO: [
    { id: 'ATENDENTE', nome: 'Atendente' },
    { id: 'ROLE_REPRESENTANTE_LEGAL', nome: 'Responsável' },
    { id: 'CENTRO_TECNICO', nome: 'Técnico' }
  ],
  ROLES_ADMIN: [
    { id: 'ROLE_ADMIN', nome: 'Administrador' },
    { id: 'ROLE_MANAUS', nome: 'Manaus' },
    { id: 'ROLE_MANAUS_TROCA', nome: 'Manaus troca de modelo' },
    {
      id: 'ROLE_ASSISTENCIA_TECNICA_SAO_PAULO',
      nome: 'Assistência Tec São Paulo'
    },
    {
      id: 'ADMIN_CONSULTA',
      nome: 'Consulta OS'
    }
  ],
  HOME_MANAUS: 'HOME_MANAUS',
  EDIT_DOC: 'EDIT_DOC',
  IMPORT_PECA: 'IMPORT_PECA',
  HOME_MANAUS_TROCA: 'HOME_MANAUS_TROCA',
  MENU_MANAUS_TROCA: 'MENU_MANAUS_TROCA',
  MENU_SAO_PAULO_DOC: 'MENU_SAO_PAULO_DOC',
  HABILITADO: 'HABILITADO',
  CREDENCIADO: 'CREDENCIADO',

  PERFIL_ATENDENTE: 'ATENDENTE',
  PERFIL_RESPONSAVEL:'ROLE_REPRESENTANTE_LEGAL',
  PERFIL_TECNICO:'CENTRO_TECNICO'
};

export const constHelper = {
  ROLE_ADMIN: 'Administrador',
  ROLE_REPRESENTANTE_LEGAL: 'Responsável',
  CENTRO_TECNICO: 'Técnico',
  ROLE_MANAUS: 'Manaus',
  ATENDENTE: 'Atendente',
  ROLE_MANAUS_TROCA: 'Manaus troca',
  ROLE_ASSISTENCIA_TECNICA_SAO_PAULO: 'Assistência SP',
  ADMIN_CONSULTA: 'Consulta OS'
};

export const responsibleConstant = {
  nomeValidate: {
    name: 'nome',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  dataNascimentoValidate: {
    name: 'dataNascimento',
    message: 'Campo obrigatório',
    type: 'date',
    required: true
  },
  cpfValidate: {
    name: 'cpf',
    message: 'Campo obrigatório',
    type: 'cpf',
    required: true
  },
  emailValidate: {
    name: 'email',
    message: 'Email inválido',
    type: 'email',
    required: true
  },
  confirmarEmailValidate: {
    name: 'confirmarEmail',
    message: 'Email inválido',
    type: 'email',
    required: true
  },
  celularValidate: {
    name: 'celular',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  senhaValidate: {
    name: 'senha',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
  confirmarSenhaValidate: {
    name: 'confirmarSenha',
    message: 'Campo obrigatório',
    type: 'text',
    required: true
  },
};