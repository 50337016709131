//@flow
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ConfirmAcationService } from '../../../services/ConfirmAcationService';
import { objectsConstants } from '../../../constants/objects.constants';
import ArrayFileInput from '../../../components/Inputs/ArrayFileInput';
import { Can } from '@casl/react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import _ from 'lodash';

type Props = {
  onConfirm: any,
  onCancel: any
};

class SolicitarHp extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      entity: { files: [] }
    };
  }

  confirmTroca = () => {
    this.toggle();
    this.props.onConfirm(this.state.entity);
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  render() {
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalBody>
            <h3 className="subH3">SOLICITAR HP (Código de postagem) </h3>
            <p>Você confirma a solicitação do código de postagem (HP)? </p>
            <p>
              <b>
                Caso já tenha solicitado HP para mesmo cliente em outra OS hoje,
                não será necessário solicitar novamente.
              </b>
            </p>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <button
              type="button "
              className="btn btn-primary white"
              onClick={this.toggle}
            >
              Cancelar
            </button>
            <button
              type="button "
              className="btn btn-primary "
              onClick={this.confirmTroca}
            >
              Confirmar
            </button>
          </ModalFooter>
        </Modal>

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_SOLICITAR_HP}
          ability={permissions}
        >
          <div className="d-flex flex-row-reverse bd-highlight btn-bold pt-2">
            <button
              type="button"
              className="btn btn-primary btn-sm justify-content-start red"
              onClick={this.toggle}
            >
              {' '}
              SOLICITAR HP{' '}
            </button>
          </div>
        </Can>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(SolicitarHp);
