//@flow
import * as React from 'react';
import '../Components/table.css';
import TablePecasItem from './TablePecasItem';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class TablePecas extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }
  render() {
    console.log(this.props.listPecas);
    return (
      <React.Fragment>
        <div className="react-bs-table tablePecas">
          <div className="react-bs-container-header table-header-wrapper table-header">
            <table className="table table-hover tablePecas">
              <thead>
                {!this.props.marcaTerceiros && (
                  <tr className="headerGrid text-left">
                    <th>Peça</th>
                    <th />
                    <th>Código peça</th>
                    <th>Estoque</th>
                    <th>Valor uni.</th>
                    <th>Qtde.</th>
                  </tr>
                )}
                {this.props.marcaTerceiros && (
                  <tr className="headerGrid text-left">
                    <th>Peça</th>
                    <th>Código peça</th>
                    <th>Valor uni.</th>
                    <th>Qtde.</th>
                  </tr>
                )}
              </thead>
            </table>
          </div>
          <div className="react-bs-container-body tablePecas">
            <table className="table tablePecas">
              <tbody>
                {this.props.listPecas.map((peca, index) => (
                  <TablePecasItem
                    key={peca.id}
                    peca={peca}
                    lasItem={this.props.listPecas.length - 1 == index}
                    index={index}
                    onSetPeca={this.props.onSetPeca}
                    marcaTerceiros={this.props.marcaTerceiros}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TablePecas;
