import * as React from 'react';
import { defaultService } from '../../services/defaultService';
import { urlsConstants } from './../../constants/url.constant';
import './ImageInput.css';
import { connect } from 'react-redux';
import { taskService } from '../../services/taskService';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
let download = require('downloadjs');

let fileInput;
class FileInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataFile: null
    };
    this.loadFile = this.loadFile.bind(this);
  }

  componentDidMount() {
    if (
      this.props.currentTask &&
      taskService.getTaskType(this.props.currentTask.taskType) &&
      taskService.getTaskType(this.props.currentTask.taskType).field ===
        this.props.name
    ) {
      this.inputFile.click();
    }
    if (this.props.fileItem) {
      this.loadFile(this.props.fileItem);
    }
  }
  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.currentTask &&
      taskService.getTaskType(nextProps.currentTask.taskType) &&
      taskService.getTaskType(nextProps.currentTask.taskType).field ===
        nextProps.name
    ) {
      this.inputFile.click();
    }

    if (nextProps.fileItem) {
      this.loadFile(nextProps.fileItem);
    }
  }
  loadFile = fileItem => {
    let _this = this;
    if (fileItem.id) {
      let url = `${
        _this.props.url ? _this.props.url : urlsConstants.MIDIA_FILE
      }${fileItem.id}`;
      defaultService.doGet(url).then(result => {
        _this.setState({ dataFile: result.data.data });
      });
    }
  };

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;

      var midiaVO = {
        filename: files[0].name,
        length: files[0].size,
        contentType: files[0].type
      };
      resize(files[0], maxWidth, maxHeight, function(resizedDataUrl) {
        midiaVO.data = resizedDataUrl;
        if (self.props.saveData) {
          self.props.loading(true);
          self.props.saveData(self.props.entity.id, midiaVO).then(r => {
            self.props.onChange(self.props.name, { id: r.data.data });
            self.props.loading(false);
          });
        } else {
          self.props.onChange(self.props.name, midiaVO);
        }
        self.setState({ dataFile: midiaVO });
      });
    }
  };

  openInputFile = (event: any) => {
    event.preventDefault();
    if (this.props.readOnly) {
      var dataFile = this.state.dataFile;
      if (dataFile) {
        let uri = `data:${dataFile.contentType};base64,${dataFile.data}`;
        download(uri, dataFile.filename, dataFile.contentType);
      }
      return false;
    }
    this.inputFile.click();
  };

  render() {
    var file;
    var dataFile = this.state.dataFile;
    if (
      this.props.currentTask &&
      taskService.getTaskType(this.props.currentTask.taskType) &&
      taskService.getTaskType(this.props.currentTask.taskType).field ===
        this.props.name
    ) {
      this.inputFile.click();
    }
    file = (
      <a
        role="button"
        href="#"
        onClick={this.openInputFile}
        id={this.props.id}
        className={this.props.className}
      >
        {this.props.children}
        {dataFile && !this.props.children && (
          <img
            src={require('../../img/icon_archive.svg')}
            className="icon_archive"
            alt="Ícone de um arquivo"
          />
        )}
        {dataFile &&
          (this.props.forceLabel ? this.props.forceLabel : dataFile.filename)}
        {!dataFile && this.props.emptyLabel}
      </a>
    );

    return (
      <React.Fragment>
        <div className={this.props.className}>
          <input
            type="file"
            accept={this.props.accept ? this.props.accept : '*'}
            onChange={this.onChange}
            className="d-none img-profile-input"
            maxLength="1024"
            ref={input => {
              this.inputFile = input;
            }}
          />
          {file}
          {this.props.erroMensagem && (
            <ToolTipFeedBack
              target={this.props.id}
              messageValidate={this.props.erroMensagem}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function(event) {
    var dataUrl = event.target.result;
    fn(dataUrl);
  };
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});
function mapStateToProps(state) {
  const { currentTask } = state.task;

  return {
    currentTask
  };
}
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(FileInput);
