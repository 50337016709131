//@flow

import _ from 'lodash';
import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router-dom';
import {Can} from "@casl/react";
import {objectsConstants} from "../../constants/objects.constants";
import TopoTitleComponente from "../../views/Topo/TopoTitleComponente";
import {ordemServicoHelper} from "../../helpers/ordemServico.helper";
import {GrupoFilialService} from "../../services/grupoFilial.service";
import {clienteService} from "../../services/cliente.service";


const endPoint = '/grupofilial/edit/';
const endPointView = '/grupofilial/view/';
class GrupoFilial extends React.Component<Props> {

  render() {
    console.log('Card Grupo Filial')
    console.log(this.props)
    console.log(this.props.grupoFilial)
    const {grupoFilial, permissions, titlePageEdit} = this.props;


    return (
      <div className="card cardSeculus miniCard d-flex flex-column ">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">Grupo de Filiais</h3>
          <div className="boxButon float-right d-sm-flex">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  this.props.history.push({
                    pathname: endPoint,
                    state: {
                      grupoFilial: this.props.grupoFilial,
                      titlePage: titlePageEdit
                    }
                  });
                }}
              >
                editar dados
              </button>
          </div>

        </div>


        <div className="conteudoCard mt-2">
          <TopoTitleComponente
            mainTitle={this.props.titlePageEdit}
            subTitle={'Grupo de Filiais - ' + grupoFilial.filialDocumento}
            canBack={true}
          />

          <div className="row ">
            <div className="col-12">
              <label className="miniLabel">Documento Grupo Filiais:</label>
              <div className="mb-2">
                <b>{grupoFilial.filialDocumento}</b>
              </div>
            </div>

            <div className="col-12">
              <label className="miniLabel">Tempo de garantia:</label>
              <div className="mb-2">{grupoFilial.tempoGarantia}</div>
            </div>

            <div className="col-12">
              <label className="miniLabel">Clientes do Grupo:</label>
              <div>{_.get(grupoFilial, `clienteLista`, [])
                .map((cliente) => cliente.nome)
                .join(', ')}</div>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = ({
                       alert: {success, error, clear},
                       load: {loading}
                     }) => ({
  loading: (load: boolean) => loading({load}),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

function mapStateToProps(state) {
  const {user, permissions} = state.authentication;
  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(GrupoFilial));
