//@flow
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import CpfInput from '../../components/Inputs/CpfInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import FormInput from '../../components/Inputs/FormInput';
import PhoneInput from '../../components/Inputs/PhoneInput';
import RoleSelectInput from '../../components/Inputs/RoleSelectInput';
import Toast from '../../components/Toasts/Toast';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { objectsConstants } from '../../constants/objects.constants';
import { grupoMarcaService } from '../../services/grupoMarca.service';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import FormTextArea from '../../components/Inputs/FormTextArea';
import MoneyInput from '../../components/Inputs/MoneyInput';

// type Props = { titlePage: any };
type State = { initialValues: any };

const NewGrupoMarca = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório')
});

class GrupoMarcaCrudEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialValues: {
        nome: '',
        descricao: '',
        status: true,
        valor: null
      },
      grupoMarca: {}
    };
  }

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.grupoMarca) {
      let grupoMarca = this.props.location.state.grupoMarca;
      if (grupoMarca.dataNascimento) {
        grupoMarca.dataNascimento = moment(grupoMarca.dataNascimento);
      }
      this.setState({ initialValues: grupoMarca });
    }
  }

  showError = messageError => {
    this.props.error({ message: messageError });
    return '';
  };

  clearError = () => {
    this.props.clear();
    return '';
  };

  render() {
    const { grupoMarca } = this.state;
    let titlePage;
    if (this.props.location.state) {
      titlePage = this.props.location.state.titlePage;
    }

    return (
      <Formik
        validationSchema={NewGrupoMarca}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={this.state.initialValues}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
          this.props.loading(true);
          grupoMarcaService
            .doSave(values)
            .then(response => {
              actions.setSubmitting(false);
              this.props.success({
                message: 'Grupo de marcas salvo com sucesso.'
              });
              this.props.loading(false);
              this.props.history.push('/grupoMarca');
            })
            .catch(() => {
              actions.setSubmitting(false);
              this.props.loading(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <div className="flex-column">
              <Toast />
              <TopoTitleComponente
                mainTitle={this.props.location.state.titlePage}
                subTitle={
                  values.id != undefined
                    ? ' Editar dados'
                    : 'Novo grupo de marca'
                }
                canBack={true}
              />
              {!_.isEmpty(errors) &&
                this.showError(objectsConstants.messageCampoObrigatorio)}
              {values.id && this.clearError()}
              <div className="cardSeculus d-flex flex-column mb-0">
                <div className="topoCard d-flex flex-column flex-sm-row">
                  <h3 className="flex-grow-1">Grupo de Marca</h3>
                </div>

                <div className="form-row">
                  <div className="form-group col-12 col-lg-12">
                    <label>Nome*</label>
                    <input
                      type="text"
                      className={`form-control ${errors.nome &&
                        touched.nome &&
                        'error'}`}
                      name="nome"
                      placeholder="Digite o nome do grupo de marca"
                      value={values.nome}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group col-12">
                    <label>Descrição</label>
                    <FormTextArea
                      id="description"
                      name="description"
                      placeholder="Descrição do grupo de marca"
                      value={values.description}
                      onChange={handleChange}
                      rows="4"
                      noSize={true}
                      required={false}
                    />
                  </div>

                  <MoneyInput
                    type="text"
                    name="valor"
                    label="Valor por atendimento"
                    placeholder="Valor por atendimento"
                    size={3}
                    className={['col-6 col-sm-6']}
                    returnFullObject={true}
                    searchable={true}
                    id={'valor'}
                    ref={input => {
                      this._valor = input;
                    }}
                    onChange={(name, value) => setFieldValue(name, value)}
                    value={values.valor}
                  />
                </div>

                <div className="d-flex flex-row flex-sm-row ">
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="button"
                      className="btn btn-secondary btn30 white"
                      disabled={isSubmitting}
                      onClick={() => this.props.history.goBack()}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                    <button
                      type="submit"
                      className="btn btn-secondary btn30 green"
                      disabled={isSubmitting}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear },
  load: { loading }
  // grupoMarcaSet: { setGrupoMarca }
}) => ({
  loading: (load: boolean) => loading({ load }),
  // setGrupoMarca: grupoMarca => setGrupoMarca({ grupoMarca }),
  success: msg => success(msg),
  error: msg => error(msg),
  clear: () => clear()
});

export default connect(null, mapDispatch)(GrupoMarcaCrudEdit);
