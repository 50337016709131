//@flow
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../actions/alert.action';
import IconErrorToast from '../../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../../components/ToastIcons/IconSucessToast';
import { objectsConstants } from '../../constants/objects.constants';
import SaveCancelCardBtn from '../Buttons/SaveCancelCardBtn';

type Props = {
  entity: any,
  step: number,
  getFormContent: any,
  onSave: any,
  getForm: any
};
type State = {
  entity: any,
  edit: boolean
};

class CardFormEditView extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      entity: _.cloneDeep(this.props.entity),
      edit: false
    };
  }

  componentWillReceiveProps(nexProps) {
    this.setState({ entity: _.cloneDeep(nexProps.entity) });
  }

  saveForm = () => {
    this.props.loading(true);
    let newEntity = _.cloneDeep(this.props.getForm().getEntity());
    let toastrOptionsSuccess = { icon: <IconErrorToast /> };
    alertActions.clear();
    if (this.props.getForm().validateForms()) {
      alertActions.clear();
      toastrOptionsSuccess = { icon: <IconSucessToast /> };
      this.props.onSave(newEntity).then(
        json => {
          alertActions.success(
            objectsConstants.messageSucesso,
            '',
            toastrOptionsSuccess
          );

          this.setState({ edit: false, entity: newEntity }, () => {
            this.props.setEditMode(this.state.edit);
            this.props.loading(false);
          });

          if (this.props.onSaved) this.props.onSaved(newEntity);
        },
        () => {
          this.props.loading(false);
        }
      );
    } else {
      alertActions.error(
        objectsConstants.messageCampoObrigatorio,
        '',
        toastrOptionsSuccess
      );
    }
  };

  setEditMode = () => {
    this.setState({ edit: true }, () => {
      this.props.setEditMode(this.state.edit);
    });
  };

  cancelForm = () => {
    this.setState({ edit: false }, () => {
      this.props.setEditMode(this.state.edit);
    });
  };

  getBtns = () => {
    if (this.state.edit) {
      return (
        <SaveCancelCardBtn onSave={this.saveForm} onCancel={this.cancelForm} />
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={this.setEditMode}
        >
          Editar
        </button>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="topoCard d-flex flex-column flex-sm-row align-items-center">
          <h3 className="flex-grow-1">{this.props.title}</h3>
          <div className="boxButon float-right d-sm-flex">{this.getBtns()}</div>
        </div>
        {this.props.children}
        {/* {this.props.getFormContent(this.saveForm, this.cancelForm, this.setEditMode, this.state.edit)} */}
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

export default connect(
  null,
  mapDispatch,
  null,
  { withRef: true }
)(CardFormEditView);
