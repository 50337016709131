//@flow
import * as React from 'react';
import { dateHelper } from '../../helpers/date.helper';
import avatarDefault from '../../img/avatarDefault.jpg';
import { midiaService } from '../../services/midiaService';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import type { PostoAutorizadoType } from '../../types/postoAutorizado.type';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
type State = {
  entity: PostoAutorizadoType
};

class ResponsavelLegalView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { entity: {} };
  }

  componentDidMount() {
    this.loadResponsavel();
  }

  loadResponsavel = () => {
    postoAutorizadoService
      .findResponsavelLegal(this.props.id)
      .then(response => {
        this.setState({ entity: response.data.data ? response.data.data : {} });
      });
  };

  render() {
    const { entity } = this.state;

    return (
      <div className="card cardSeculus d-flex flex-column l1">
        <div className="topoCard d-flex flex-column flex-sm-row">
          <h3 className="flex-grow-1">responsável</h3>
          <div className="boxButon float-right d-sm-flex">
            <button
              type="button"
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              data-target="#novaMsg"
              onClick={() => {
                this.props.history.push({
                  pathname: `/chat/private/${entity.id}`,
                  state: { receiverName: entity.name }
                });
              }}
            >
              enviar mensagem
            </button>
          </div>
        </div>
        <div className="conteudoCard flex-fill d-flex flex-column flex-sm-row">
          <div className="col-sm-7 d-flex flex-row align-items-center pr-3 border-right">
            <div className="justify-content-center">
              <img
                src={
                  entity.foto ? midiaService.get(entity.foto.id) : avatarDefault
                }
                height="70"
                width="70"
                className="fotoPerfil rounded-circle"
                name="fotoPerfil"
                alt="Foto de perfil"
              />
            </div>
            <div className="justify-content-center pl-2">
              <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                <h4 className="title">{entity.name}</h4>
                <h5 className="subTitle">Respónsavel</h5>
              </div>
              <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                <h5 className="title">Tel. Celular</h5>
                <p role="subtitle">
                  <i>{entity.celular}</i>
                </p>
              </div>
              <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                <h5 className="title">E-mail:</h5>
                <p role="subtitle">
                  <i>
                    <u>{entity.email}</u>
                  </i>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-5 d-flex align-items-center px-4">
            <div className="justify-content-center pl-3">
              <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                <h5 className="title">CPF:</h5>
                <p role="button" href="">
                  <b>{entity.cpf}</b>
                </p>
              </div>
              <div className="col-12 col-lg-12 mt-4 mt-lg-1">
                <h5 className="title">Data de Nascimento:</h5>
                <p role="button" href="">
                  <b>
                    {dateHelper.format(entity.dataNascimento, {
                      pattern: 'DD/MM/YYYY'
                    })}
                  </b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResponsavelLegalView);
