//@flow
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TrocaModeloList from './TrocaModeloList';
import TrocaModeloView from './TrocaModeloView';

export default class TrocaModelo extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/trocaModelo/view/:id" component={TrocaModeloView} />
          <Route path="/trocaModelo" exact component={TrocaModeloList} />
        </Switch>
      </React.Fragment>
    );
  }
}
