//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';
import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';

export const ConfirmAcationService = {
  confirmMsg
};

function confirmMsg(onConfirm, title, mensagem, subMensagem) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="confirm-ui success">
          <h3>{title}</h3>
          <p className="p-msg">{mensagem}</p>
          <p>
            <b>{subMensagem}</b>
          </p>
          <button
            className="btn btn-primary my-4 mx-lg-3"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Confirmar
          </button>
          <button
            className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
            onClick={() => {
              onClose();
            }}
          >
            Não, obrigado
          </button>
        </div>
      );
    }
  });
}

function doGet(url) {
  return defaultService.doGet(url);
}
function doSave(entity: any) {
  return defaultService.doSave(urlsConstants.ORDEM_SERVICO, entity);
}

function findByCliente(cliente: any) {
  return defaultService.doGet(
    `${urlsConstants.ORDEM_SERVICO_FIND_BY_CLIENTE}${cliente.id}`
  );
}
function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<OrdemServicoType>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ORDEM_SERVICO}list`,
    options,
    filter
  );
}

function find(input) {
  defaultService.doGet(`${urlsConstants.FIND_OS}${input}`);
}

function getTotalOSMes() {
  return defaultService.doGet(urlsConstants.OS_TOTAL_MES);
}

function saveOrcamento(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_ORCAMENTO}${OSid}`,
    entity
  );
}

function reproveOrcamento(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_REPROVAR_ORCAMENTO}${OSid}`,
    entity
  );
}

function aproveOrcamento(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_APROVAR_ORCAMENTO}${OSid}`,
    {}
  );
}

function reabrirOrcamento(OSid) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_COLOCAR_EM_AVALIACAO}${OSid}`,
    {}
  );
}

function reabrirOs(OSid, entity: any) {
  return defaultService.doPost(
    `${urlsConstants.ORDEM_SERVICO_REABRIR_OS}${OSid}`,
    entity
  );
}
