//@flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import * as serviceWorker from './serviceWorker';

// CSS files

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import './index.css';
import './css/cadastro_style.css';
import './css/x-editable.css';
import './css/cadastroWizard_style.css';
import './css/toastDefault.css';
import './css/default.css';
import './css/errorMessage.css';
import './css/checkBoxInput.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './css/default.css';
import './css/index_style.css';
import './css/icomoon/style.css';
import './App.css';
import './css/card_postoAutorizado.css';
import './css/boxOS_Cadastro.css';
import './css/novaOS_style.css';

const root = document.getElementById('root');
String.prototype.toProperCase = function(){
  return this.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g,
    function($1){
      return $1.toUpperCase();
    }
  );
};

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  );

}
serviceWorker.unregister();
