//@flow
import moment from 'moment/moment';
import * as React from 'react';
import CidadeInput from '../../components/Inputs/CidadeInput';
import DatePickerInput from '../../components/Inputs/DatePickerInput';
import EstadoInput from '../../components/Inputs/EstadoInput';
import FormInput from '../../components/Inputs/FormInput';
import SelectInput from '../../components/Inputs/SelectInput';
import StatusSelectInput from '../../components/Inputs/StatusSelectInput';
import { docHelper } from '../../helpers/doc.helper';
import { postoAutorizadoService } from '../../services/postoAutorizadoService';
import { Can } from '@casl/react';
import { objectsConstants } from '../../constants/objects.constants';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
import { ordemServicoHelper } from '../../helpers/ordemServico.helper';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

class DocListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    const { permissions } = this.props;
    return (
      <React.Fragment>
        <FormInput
          label="Número DOC"
          placeholder={'Número DOC'}
          id={'id'}
          name="id"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.id}
        />
        <FormInput
          label="Número OS"
          placeholder={'Número OS'}
          id={'numOs'}
          name="numOs"
          onChange={this.props.onChangeText}
          type={'text'}
          size={6}
          style={'col-sm-2'}
          value={entity.numOs}
        />
        <EstadoInput
          name="estado"
          value={entity.estado}
          style={'col-2 col-sm-1'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          label="UF"
        />
        <CidadeInput
          name="cidade"
          value={entity.cidade}
          style={'col-4 col-sm-2'}
          parentValue={{ id: 1 }}
          handleSelectChange={this.props.onChangeSelect}
          parentValue={entity.estado}
        />

        <Can
          I={objectsConstants.VIEW}
          a={objectsConstants.ABILITY_FILTRO_POSTO}
          ability={permissions}
        >
          <SelectInput
            type="text"
            name="posto"
            label="Autorizada"
            placeholder="Autorizada"
            style={['col-2 col-sm-3']}
            onFetchData={postoAutorizadoService.find}
            valueKey="id"
            labelKey="nomeFantasia"
            returnFullObject={true}
            searchable={true}
            id={'posto'}
            onChange={this.props.onChangeSelect}
            value={entity.posto}
          />
        </Can>

        <DatePickerInput
          placeholder={'--/--/--'}
          name="dataInicio"
          label="Data de"
          value={entity.dataInicio ? moment(entity.dataInicio) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataInicio'}
        />
        <DatePickerInput
          name="dataFim"
          label="Data até"
          placeholder={'--/--/--'}
          value={entity.dataFim ? moment(entity.dataFim) : null}
          onChange={this.props.onChangeDate}
          size={6}
          style={'col-sm-1'}
          id={'dataFim'}
        />

        <StatusSelectInput
          label="Status DOC"
          placeholder={'Status'}
          id={'statusDoc'}
          name="statusDoc"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-2'}
          value={entity.statusDoc}
          service={docHelper.getListStatus}
        />

        <StatusSelectInput
          label="Status OS"
          placeholder={'Status OS'}
          id={'statusOs'}
          name="statusOs"
          onChange={this.props.onChangeSelect}
          type={'text'}
          size={12}
          style={'col-sm-3'}
          value={entity.statusOs}
          service={ordemServicoHelper.getListStatus}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions,
  };
}

const connectedMenu = withRouter(
  connect(
    mapStateToProps,
    null
  )(DocListFilter)
);
export { connectedMenu as DocListFilter };
