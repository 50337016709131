//@flow
import * as React from 'react';
import CardRelatorio from '../../../components/Cards/CardRelatorio';

import { relatorioService } from '../../../services/relatorio.service';
import RelatorioPrazoMedioEntregaPosReparoFormFilter from './prazoMedioEntregaPosReparoFormFilter';
import RelatorioPrazoMedioEntregaPosReparoChart from './prazoMedioEntregaPosReparoChart';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import moment from "moment/moment";

export default class RelatorioPrazoMedioEntregaPosReparo extends React.PureComponent<{}> {

  constructor(props) {
    super(props);

    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      defaultFilter: {
        fimAno: ano,
        inicioAno: ano,
        inicioMes: 1,
        fimMes: mes
      },
      filter: {}
    };
  }


  render() {
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle="Prazo médio de entrega pós reparo "
          canBack={false}
        />
        <CardRelatorio
          methodFindChart={relatorioService.prazoMedioEntregaPosReparo}
          FormFilter={RelatorioPrazoMedioEntregaPosReparoFormFilter}
          renderChartOnConstruct={true}
          defaultFilter={this.state.defaultFilter}
          Chart={RelatorioPrazoMedioEntregaPosReparoChart}
        />
      </React.Fragment>
    );
  }
}
