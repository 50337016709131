//@flow
import React, { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import { FormGroup } from 'reactstrap';

type Props = {
  onReprove: any,
  onAprove: any
};

class ReprovarTrocaBtn extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = { entity: '' };
  }

  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ observacaoReprovarTroca: value });
  };
  handleConfirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui success">
            <h3>Não autorizar troca de modelo</h3>
            <p className="p-msg">
              Deseja <b>não autorizar</b> a Troca de modelo ?
            </p>
            <FormGroup className="form-group">
              <label>Observações</label>
              <textarea
                id="observacaoReprovarTroca"
                name="observacaoReprovarTroca"
                placeholder="Observações"
                value={this.state.observacaoReprovarTroca}
                onChange={this.handleChange}
                rows="4"
                noSize={true}
                required={false}
                className="form-control"
              />
            </FormGroup>
            <button
              className="btn btn-primary btn btn-primary justify-content-start white my-4 mx-lg-3"
              onClick={() => {
                onClose();
              }}
            >
              CANCELAR
            </button>
            <button
              className="btn btn-primary my-4 mx-lg-3"
              onClick={() => {
                this.props.onConfirm(this.state.observacaoReprovarTroca);
                onClose();
              }}
            >
              SIM
            </button>
          </div>
        );
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="justify-content-between btn-bold ">
          <button
            type="button"
            className="btn btn-primary danger btn-sm"
            onClick={this.handleConfirm}
          >
            Troca não autorizada
          </button>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { permissions } = state.authentication;

  return {
    permissions
  };
}

export default connect(mapStateToProps)(ReprovarTrocaBtn);
