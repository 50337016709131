//@flow
import * as React from 'react';
import {connect} from 'react-redux';
import '../../css/doc_manaus.css';
import {ordemServicoHelper} from '../../helpers/ordemServico.helper';
import {propertyConstantes} from '../../constants/property.constantes';
import {compraPecaService} from '../../services/compraPeca.service';
import {withRouter} from 'react-router-dom';
import {ordemServicoService} from '../../services/ordemServico.service';
import {urlsConstants} from '../../constants/url.constant';
import AguardandoImportacaoPecaDetailsItemPeca from "./AguardandoImportacaoPecaDetailsItemPeca";

type Props = {
  onChangeText: any,
  entity: any,
  onChangeValue: any,
  onTrocaPeca: any
};

const endPointOs = '/ordemServicoAdmin/view/';
const endPointCP = '/compraPeca/view';

class OsAguardandoImportacaoDetailsItem extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  getFarolItem = () => {
    const { entity } = this.props;
    let classFarol = ordemServicoHelper.getFarol(entity);
    return <div className={`farol ${classFarol} ml-0 mr-2`} />;
  };

  handleClickItem = () => {
    const { entity } = this.props;
    if (entity.osType === propertyConstantes.OS) {
      let urlOS = urlsConstants.ORDEM_SERVICO + entity.id;
      ordemServicoService.doGet(urlOS).then(response => {
        let os = response.data.data;
        this.props.setOrdemServico(os);
        this.props.setCliente(os.cliente);
        this.props.history.push(endPointOs + entity.id);
      });
    } else {
      this.props.loading(true);
      compraPecaService.getById(entity.id).then(response => {
        let ordemServico = response.data.data;
        this.props.setExtension(ordemServico.postoAutorizado.id);
        this.props.setOrdemServico(ordemServico);
        this.props.loading(false);
        this.props.history.push(endPointCP);
      });
    }
  };

  aguardandoImportacao = id => {
    this.props.loading(true);
    ordemServicoService.aguardandoImportacao(id).then(response => {
      this.props.loading(false);
      this.props.reloadDoc();
      this.props.success({ message: 'Status alterado com sucesso!' });
    });
  };

  render() {
    const { entity, permissions } = this.props;
    return (
      <React.Fragment>
        <div className="bloco mt-3">
          <div className="col-2 numeroOs" onClick={this.handleClickItem}>
            {/* <div className="farol farol-azul ml-0 mr-2"></div> */}
            <div className={'row'}>
              <div className={'col-12 numeroOs'}>
                {this.getFarolItem()}

                {entity.numOs}
              </div>
              <div className={'h6 col-12 font-weight-normal'}>
                <b>Status: </b>
                <span> {entity.statusOS.description}</span>
              </div>
            </div>
          </div>


          <div className="react-bs-table table-container-class">
            <div className="react-bs-container-header table-header-wrapper table-header">
              <table className="table table-hover tablePecas">
                <thead>
                  <tr className="headerGrid text-left">
                    <th>Relógio </th>
                    <th>Peça</th>
                    <th>Cód. Peça </th>
                    <th>Cód. Peça Generica </th>
                    <th>Qtd Solic.</th>
                    <th />
                  </tr>
                </thead>
              </table>
            </div>
            <div className="react-bs-container-body docView">
              <table className="table tableDetailDoc">
                <tbody>
                  {entity.listPecaOrdemServico.map((peca, index) => (
                    <AguardandoImportacaoPecaDetailsItemPeca
                      onTrocaPeca={this.props.onTrocaPeca}
                      key={peca.id}
                      peca={peca}
                      lasItem={entity.listPecaOrdemServico.length - 1 == index}
                      entity={entity}
                      index={index}
                      onSetPeca={this.props.onSetPeca}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  load: { loading },
  alert: { error, success },

  postoAutorizado: { setExtension },
  ordemServico: { setOrdemServico, setCliente }
}) => ({
  loading: (load: boolean) => loading({ load }),
  error: (message, code) => error({ message, code }),
  success: message => success(message),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico }),
  setCliente: cliente => setCliente({ cliente }),
  setExtension: (extension: number) => setExtension(extension)
});

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;

  return {
    user,
    permissions
  };
}

export default connect(mapStateToProps, mapDispatch, null, { withRef: true })(
  withRouter(OsAguardandoImportacaoDetailsItem)
);
