//@flow
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import CardRelatorio from '../../../components/Cards/CardRelatorio';
import TopoTitleComponente from '../../Topo/TopoTitleComponente';
import {tableHelper} from '../../../helpers/tableHelper';
import {trocaBateriaAjustePulseira} from "../../../services/trocaBateriaAjustePulseira.service";
import TrocaBateriaAjustePulseiraFormFilter from "./TrocaBateriaAjustePulseiraFormFilter";

const columns = [
  {
    path: 'numOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: true
  },

  {
    path: 'codRelogio',
    title: 'Código do Relógio',
    isKey: false,
    dataSort: false
  },
  {
    path: 'creationDateTime',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  {
    path: 'postoAutorizado.nomeFantasia',
    title: 'Posto Autorizado',
    isKey: false,
    dataSort: true
  },
  {
    path: 'postoAutorizado.codigoSAP',
    title: 'Código SAP',
    isKey: false,
    dataSort: true
  },
  {
    path: 'tipo',
    title: 'Tipo',
    isKey: false,
    dataSort: false
  },
  { path: 'cliente.nome', title: 'Cliente', isKey: false, dataSort: true },

  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false
  },
  {
    path: 'marca.nome',
    title: 'Marca',
    isKey: false,
    dataSort: true
  }
];

class TrocaBateriaAjustePulseira extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let dateNow = moment();
    let mes = dateNow.month() + 1;
    let ano = dateNow.year();
    this.state = {
      fields,
      defaultFilter: {},
      filter: {}
    };
  }
  _handleFilterChange = filter => {
    this.setState({ filter });
  };
  render() {
    let defaultFilter = {
      dataInicio: moment().date(1),
      dataFim: moment()
    };
    if (this.props.location.state && this.props.location.state.filter) {
      defaultFilter = this.props.location.state.filter;
    }

    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="RELATÓRIO"
          subTitle=" Troca de bateria e ajuste de pulseira "
          canBack={false}
        />
        <CardRelatorio
          title={` Troca de bateria e ajuste de pulseira (${this.props.subTitle})`}
          FormFilter={TrocaBateriaAjustePulseiraFormFilter}
          showTable={true}
          tableService={trocaBateriaAjustePulseira}
          controller={'relatorio/trocaBateriaAjustePulseira'}
          fields={this.state.fields}
          defaultFilter={defaultFilter}
          onHandleFilter={this._handleFilterChange}
        >
          {tableColumn}
        </CardRelatorio>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle } = state.title;

  return {
    subTitle
  };
}

export default connect(mapStateToProps, null)(TrocaBateriaAjustePulseira);
