//@flow
import _ from 'lodash';
import * as React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import { tableHelper } from '../../helpers/tableHelper';
import TrocaModeloFilter from './TrocaModeloFilter';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import { trocaModeloService } from '../../services/trocaModelo.service';

const columns = [
  {
    path: 'numeroOS',
    title: 'Nº OS',
    isKey: true,
    dataSort: false,
    className: 'colunaDestaque'
  },
  { path: 'cidadeEstado', title: 'Local', isKey: false, dataSort: true },
  {
    path: 'posto.nomeFantasia',
    title: 'Posto autorizado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'dataCriacao',
    title: 'Data',
    isKey: false,
    dataSort: false,
    isDate: true
  },
  {
    path: 'relogio.codigoRelogio',
    title: 'Relógio Selecionado',
    isKey: false,
    dataSort: false
  },
  {
    path: 'dias',
    title: 'Prazo de Reparo',
    isKey: false,
    dataSort: false,
    isDate: false
  },
  { path: 'marca.nome', title: 'Marca', isKey: false, dataSort: false },
  {
    path: 'statusOS.description',
    title: 'Status',
    isKey: false,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    path: 'garantia',
    title: 'Garantia',
    yesNo: true,
    dataSort: false,
    className: 'txtUppercase'
  },
  {
    path: 'codigoCliente',
    title: 'Código cliente SAP',

    dataSort: false,

  }
];

type State = {
  modal: boolean
};

class TrocaModeloList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
    let modal = false;

    if (this.props.location.state && this.props.location.state.showModal) {
      modal = this.props.location.state.showModal;
    }

    let fields = _.map(columns, function(coluna) {
      return coluna.path;
    });
    let labels = _.map(columns, function(coluna) {
      return coluna.title;
    });
    this.state = {
      modal,
      fields
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleRowClick = (row: e) => {
    this.props.history.push('/trocaModelo/view/' + row.id);
  };

  renderCustom(row: any, doc: any, column: any) {
    return tableHelper.renderCustom(row, doc, column);
  }

  render() {
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente
          mainTitle="Troca Modelo"
          subTitle=" "
          canBack={false}
        />
        <ListagemPage
          title={`ORDENS DE SERVIÇO(${this.props.subTitle})`}
          newButtonActionClick={this.toggle}
          delete={() => {}}
          service={trocaModeloService}
          labelNewButton="Nova OS"
          defaultOrder={'creationDateTime'}
          defaultDirection={'desc'}
          defaultFilter={{ endereco: {} }}
          onRowClick={this.handleRowClick}
          FormFilter={TrocaModeloFilter}
          showNewButton={false}
          controller="trocaModelo"
          fields={this.state.fields}
          labels={this.state.labels}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { subTitle, mainTitle } = state.title;

  return {
    subTitle,
    mainTitle
  };
}

const mapDispatch = ({
  ordemServico: { setCliente },
  ordemServico: { setOrdemServico }
}) => ({
  setCliente: cliente => setCliente({ cliente }),
  setOrdemServico: ordemServico => setOrdemServico({ ordemServico })
});

export default connect(
  mapStateToProps,
  mapDispatch
)(TrocaModeloList);
