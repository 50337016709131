import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../index/customIndex.css';
import SectionLoginBase from './SectionLoginBase';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class RecoverPasswordSucess extends PureComponent<Props, State> {
  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px'
    });
  }

  render() {
    const { email } = this.props;
    return (
      <SectionLoginBase>
        <div className="index_btCadastro">
          <NavLink className="btn btn-secondary" to="/cadastro">
            Cadastro
          </NavLink>
        </div>
        <h1 className="mb-2 text-center">Redefinir Senha</h1>
        <p className="mb-4 text-center">
          Foi enviado um link para redefinir senha no seu e-mail:
        </p>

        <form className="index_form" onSubmit={this.handleRecoverPassword}>
          {/* <!--toast error--> */}
          <div className="mx-3 pt-3 pb-5">
            <label className="bold txtBlue">{email}</label>
          </div>
          <div className="mb-5">
            <NavLink
              to={{
                pathname: `/index`,
                state: { goToLogin: true }
              }}
            >
              Voltar
            </NavLink>
          </div>
        </form>
      </SectionLoginBase>
    );
  }
}

export default connect()(RecoverPasswordSucess);
