//@flow
import type { OrdemServicoType } from '../types/ordemServico.type';
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';

export const trocaModeloService = {
  findByFiltro,
  getById,
  confirmarTroca,
  cancelarTroca,
  trocaEmAndamento
};

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.TROCA_MODELO}list`,
    options,
    filter
  );
}

function getById(id){
  return defaultService.doGet(
    `${urlsConstants.TROCA_MODELO}${id}`
  );
}

function confirmarTroca(id,codigoRastreio,tipoTransporte){
  if(tipoTransporte) {
    return defaultService.doGet(
      `${urlsConstants.TROCA_MODELO}${id}/${codigoRastreio}/${tipoTransporte}`
    );
  }else{
    return defaultService.doGet(
      `${urlsConstants.TROCA_MODELO}${id}/${codigoRastreio}/`
    );
  }
}

function cancelarTroca(id){
  return defaultService.doGet(
    `${urlsConstants.TROCA_MODELO}cancelar/${id}`
  );
}

function trocaEmAndamento(id){
  return defaultService.doGet(
    `${urlsConstants.TROCA_MODELO}trocaEmAndamento/${id}`
  );
}