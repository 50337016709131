import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BtnPrimary from '../Buttons/BtnPrimary';
type Props = {
  modal: boolean,
  dataUrl: any,
  width: number,
  height: number,
  titleBtn: string,
  title: string,
  onCropper: any
};
export default class CropperView extends Component<Props, State> {
  constructor(props) {
    super(props);
    this._crop = this._crop.bind(this);
    this.state = {
      modal: props.modal,
      dataUrl: props.dataUrl
    };
  }
  onAccpet = () => {
    this.toggle();
    this.props.onCropper(this.refs.cropper.getCroppedCanvas().toDataURL());
  };
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };
  componentWillReceiveProps(nextProps: any) {
    this.setState({ dataUrl: nextProps.dataUrl, modal: nextProps.modal });
  }
  render() {
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        className={'customModal ' + this.props.className}
      >
        <ModalBody>
          <h3>{this.props.title}</h3>
          <p>{this.props.subititle}</p>
          <div
            className="row justify-content-md-center"
            style={{
              width: this.props.width + 'px',
              height: this.props.height + 'px'
            }}
          >
            <Cropper
              ref="cropper"
              src={this.props.dataUrl}
              style={{
                height: this.props.maxHeight,
                width: this.props.maxWidth,
                maxHeight: this.props.maxHeight,
                maxWidth: this.props.maxWidth
              }}
              // Cropper.js options

              guides={false}
              crop={this._crop.bind(this)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <BtnPrimary
            type={'button'}
            className={'my-4 mx-2'}
            title={this.props.titleBtn}
            onSubmit={this.onAccpet}
            disabled={false}
          />
        </ModalFooter>
      </Modal>
    );
  }
  _crop() {
    // image in dataUrl
  }
}
