import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import CropperView from '../../components/Imagen/CropperView';
import ImageInput from '../../components/Inputs/ImageInput';
import { taskService } from '../../services/taskService';
import { ToolTipFeedBack } from '../../components/Utils/ToolTipFeedBack';

type Props = {
  onChange: any,
  name: string,
  dataUrl: any,
  title: string,
  subititle: string
};
type State = {
  entity: any
};

class PostoImageLoad extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      entity: props.entity,
      image: null
    };
  }
  // componentWillReceiveProps(nexProps: any) {
  //   this.setState({ dataUrl: nexProps.dataUrl });
  // }
  componentDidMount() {
    if (
      this.props.currentTask &&
      taskService.getTaskType(this.props.currentTask.taskType) &&
      taskService.getTaskType(this.props.currentTask.taskType).field ===
        this.props.name
    ) {
      this._imageInputRef.openInputFile();
    }
  }
  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.currentTask &&
      taskService.getTaskType(nextProps.currentTask.taskType) &&
      taskService.getTaskType(nextProps.currentTask.taskType).field ===
        nextProps.name
    ) {
      this._imageInputRef.openInputFile();
    }
  }
  changeImage = image => {
    this.setState({ image });
  };
  onCropper = dataUrl => {
    let { image } = this.state;
    image.data = dataUrl;
    this.setState({ image: null });
    if (this.props.saveData) {
      this.props.loading(true);
      this.props.saveData(this.props.entity.id, image).then(r => {
        this.props.onChange(this.props.name, { id: r.data.data });
        this.props.loading(false);
      });
    } else {
      this.props.onChange(this.props.name, image);
    }
  };

  render() {
    let { image } = this.state;
    let dataURL = this.props.dataUrl();
    let messageValidate;
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (
      <React.Fragment>
        <CropperView
          modal={image ? true : false}
          onCropper={this.onCropper}
          titleBtn={'Cortar e enviar imagem'}
          title={this.props.title}
          subititle={this.props.subititle}
          maxHeight={150}
          maxWidth={300}
          dataUrl={image ? image.data : null}
          onChange={this.props.onChange}
        />
        <div className={`buttonImage action m-auto ${this.props.className}`}>
          <ImageInput
            maxHeight={300}
            maxWidth={150}
            // height={150}
            onChange={this.changeImage}
            dataUrl={dataURL}
            alt={this.props.title}
            className="fotosLoja"
            readOnly={this.props.readOnly}
            ref={input => {
              this._imageInputRef = input;
            }}
            idDom={this.props.idDom}
          />
          {messageValidate && (
            <ToolTipFeedBack
              target={this.props.idDom}
              messageValidate={messageValidate}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});

function mapStateToProps(state) {
  const { currentTask } = state.task;

  return {
    currentTask
  };
}

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(PostoImageLoad);
